import { MDBCol, MDBContainer, MDBRow, MDBCard } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import './cart.css'

//redux
import { useSelector } from 'react-redux'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add_to_cart, get_cart, update_cart_by_id, delete_cart_by_id, delete_cart } from '../../redux/cart/cart-actions'

import CartStep1 from './CartStep1';
import CartStepper from './CartStepper';
import CartStep2 from './CartStep2';
import CartStep3 from './CartStep3';
import CartStep4 from './CartStep4';
import MyModal from '../../components/MyModal';

const Cart = (props) => {
    const history = useHistory()
    const [load, setLoad] = useState(true)
    const [toalOfAll, setTotalOfAll] = useState(0)
    const [activeStep, setActiveStep] = useState(0)
    const [personData, sePersonData] = useState(null)
    const [checkedStep2, setcheckedStep2] = useState(false)
    const [checkedStep3, setcheckedStep3] = useState(false)
    const [modalShow, setModalSHow] = useState(false)
    const [orderNumber, setOrderNumber] = useState('')
    const [deliveryId, setDeliveryId] = useState('')

    //redux STATE
    let onlineDeliveryState = useSelector((state) => state.onlineDeliveriesReducer.onlineDeliveries)

    //set deliveryId of current week
    useState(() => {
        onlineDeliveryState.length > 0 &&
            setDeliveryId(onlineDeliveryState[0]._id)

    }, [])

    useEffect(() => {
        document.title = "משק אלמוג - סל קניות"

        props.get_cart().then(() => {

            setLoad(false)
            let total = 0;

            if (props.cart.length > 0) {

                props.cart.map(c => {
                    if (c.Code === 10)
                        total += (c.Price * c.Quantity) / 2
                    else
                        total += c.Price * c.Quantity
                })

                setTotalOfAll(total)
            }
            else
                setTotalOfAll(0)

        })

    })

    useEffect(() => {

        if (props.cart.length > 0)
            window.onbeforeunload = function () {
                return 'Are you sure you want to leave?';
            };

    }, [props.cart])

    const stepSertter = (num) => {
        setActiveStep(num)
    }

    const personDetails = (data) => {
        sePersonData(data)
    }

    return (
        <div className='container-fluid'>
            <MDBContainer className='firstDivAfterNav'>

                <h2 className='text-center font-weight-bold'>סל הקניות&nbsp;
                    <i className="fas fa-cart-arrow-down"></i>
                </h2>

                <CartStepper
                    activeStep={activeStep}
                    toalOfAll={toalOfAll}
                    stepSertter={stepSertter}
                    personData={personData != null ? personData : ''}
                    checkedStep2={checkedStep2}
                    checkedStep3={checkedStep3}
                />

                <MDBRow>
                    <MDBCol sm='12'>
                        <MDBCard>
                            {
                                activeStep == 0 &&
                                <CartStep1 cartData={props.cart}
                                    setActiveStep={setActiveStep}
                                    toalOfAll={toalOfAll}
                                />
                            }
                            {
                                activeStep == 1 &&
                                <CartStep2
                                    personDetails={personDetails}
                                    setActiveStep={setActiveStep}
                                    setcheckedStep2={setcheckedStep2}
                                    checkedStep2={checkedStep2}
                                />
                            }
                            {
                                activeStep == 2 &&
                                <CartStep3
                                    personData={[personData]}
                                    cartData={props.cart}
                                    setActiveStep={setActiveStep}
                                    setcheckedStep3={setcheckedStep3}
                                    toalOfAll={toalOfAll}
                                    setOrderNumber={setOrderNumber}
                                    deliveryId={deliveryId}
                                />
                            }
                            {
                                activeStep == 3 &&
                                <CartStep4
                                    cartData={props.cart}
                                    orderNumber={orderNumber}
                                    deliveryId={deliveryId}
                                />
                            }
                        </MDBCard>
                    </MDBCol>

                    {
                        !load &&
                        <MDBCol sm='12' className='text-center'>
                            <MDBCard>
                                {
                                    // toalOfAll != 0 ?
                                    // <h3 className='text-dark'>סה"כ לתשלום: {toalOfAll} ₪ </h3>
                                    toalOfAll == 0 &&
                                    <>
                                        <h3 className='text-dark'>סל הקניות ריק </h3>
                                        <Link to='/reservation'>מעבר להזמנה</Link>
                                    </>

                                }

                            </MDBCard>
                        </MDBCol>
                    }
                </MDBRow>
            </MDBContainer>

        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        cart: state.cartReducer.cart
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            add_to_cart,
            get_cart,
            update_cart_by_id,
            delete_cart_by_id
        },
        dispatch
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Cart);
