import React, { useState } from 'react'
import { MDBContainer, MDBCard } from 'mdbreact'
import { Link } from 'react-router-dom'

const AllUsers = () => {
    const [usersChose, setUsersChose] = useState([])

    const setUsersChosen = (e) => {
        while (usersChose.length) {
            usersChose.pop()
        }

        let object = {
            name: 'אסף אלמוג',
            email: 'asafalmog119@gmail.com<'
        }

        if (e.target.checked) {
            usersChose.push(object)
            document.getElementById(`order_${1}_checkbox`).checked = "checked"
            document.getElementById(`order_${2}_checkbox`).checked = "checked"
        }
        else {
            usersChose.pop()
            document.getElementById(`order_${1}_checkbox`).checked = ""
            document.getElementById(`order_${2}_checkbox`).checked = ""
        }

        console.log('usersChose', usersChose)
    }

    const setUserChose = (e) => {

        let object = {
            name: 'אסף אלמוג',
            email: 'asafalmog119@gmail.com<'
        }

        if (e.target.checked) {
            // seIsOrderCheckd(false)

            usersChose.push(object)
        }
        else {

            try {
                document.getElementById(`order${1}`).disabled = true
            } catch (error) {

            }

            // for (let index = 0; index < object.length; index++) {

            //     if (object[index] == orderId) {
            //         ordersForChanges.splice(index, 1)
            //         ordersNumbersForChanges.splice(index, 1)
            //         if (ordersForChanges.length == 0)
            //             seIsOrderCheckd(true)

            //     }
            // }

        }
        // console.log("ordersForChanges", ordersForChanges)
        // console.log("ordersNumbersForChanges", ordersNumbersForChanges)
        console.log('usersChose', usersChose)
    }

    return (
        <div className='container-fluid'>

            <MDBContainer className="firstDivAfterNav text-center">
                <MDBCard>
                    <h2 className="text-center font-weight-bold">משתמשים</h2>
                    <Link to='/admin/users/add'>הוספת משתמשים</Link>
                    <Link to='/admin/users/messege'>שליחת מייל למשתמשים</Link>

                    <div className='table-responsive '>
                        <table id="dataTable"
                            className="table table-striped col-sm-12 text-center"
                            border='1' style={{ margin: 'auto', tableLayout: 'fixed' }}>
                            <thead className="thead-dark">
                                <tr className="header">
                                    <th className="actionsCB">
                                        <input type="checkbox" style={{ transform: 'scale(1.5)' }}
                                            onChange={(e) => setUsersChosen(e)}>
                                        </input>
                                    </th>
                                    <th>שם מלא</th>
                                    <th>אימייל</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <input id={`order_${1}_checkbox`} style={{ transform: 'scale(1.5)' }}
                                        type="checkbox" onChange={(e) => setUserChose(e)}>
                                    </input>
                                    <td>אסף אלמוג</td>
                                    <td>asafalmog119@gmail.com</td>
                                </tr>
                                <tr>
                                    <input id={`order_${2}_checkbox`} style={{ transform: 'scale(1.5)' }}
                                        type="checkbox" onChange={(e) => setUserChose(e)}>
                                    </input>
                                    <td>אסף בדיקה</td>
                                    <td>asafalmog@walla.com</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </MDBCard>
            </MDBContainer >
        </div >
    )
}
export default AllUsers