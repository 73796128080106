import React from 'react'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import './contextMenu.css'
import { Container, Menu, Message } from "semantic-ui-react";
import { useHistory } from 'react-router-dom';



const MyContextMenu = (props) => {

    const history = useHistory()

    const handleClick = (value) => {
        if (value == 1)
            window.open(`${props.delivery}`)
        else if (value == 2)
            window.open(`${props.update}`)
        else if (value == 3)
            window.open(`${props.form}`)
        else if (value == 4)
            window.open(`${props.managerView}`)
        else if (value == 5)
            window.open(`${props.groupPage}`)
        else if (value == 6)
            window.open(`${props.messegePage}`)
    }
    const handleClick2 = (value) => {
        console.log("test")
        if (value == 1)
            history.push(props.order)
        // window.open(`${props.order}`)
        else if (value == 2)
            history.push(props.message.link, { orders: props.message.orders, deliveryId: props.message.link.deliveryId, groupName: props.message.link.groupName })
        else if (value === 3)
            window.open(`${props.lastFormPage}`)
    }

    return (
        <>
            <Menu as={ContextMenu} id="some_unique_identifier" vertical>

                {
                    props.isDeliveriesPage ?
                        <>
                            <MenuItem data={"some_data"} onClick={() => handleClick(1)}>
                                <Menu.Item>צפייה בהזמנות</Menu.Item>
                            </MenuItem>
                            <MenuItem data={"some_data"} onClick={() => handleClick(2)}>
                                <Menu.Item>עריכה</Menu.Item>
                            </ MenuItem>
                            <MenuItem data={"some_data"} onClick={() => handleClick(3)}>
                                <Menu.Item>פתיחת הטופס</Menu.Item>
                            </ MenuItem>
                            <MenuItem data={"some_data"} onClick={() => handleClick(4)}>
                                <Menu.Item>תצוגת מנהל</Menu.Item>
                            </ MenuItem>
                            <MenuItem data={"some_data"} onClick={() => handleClick(5)}>
                                <Menu.Item>עמוד הקבוצה</Menu.Item>
                            </ MenuItem>
                            <MenuItem data={"some_data"} onClick={() => handleClick(6)}>
                                <Menu.Item>שיתוף הטופס</Menu.Item>
                            </ MenuItem>
                        </>

                        : <>
                            <MenuItem data={"some_data"} onClick={() => handleClick2(1)}>
                                <Menu.Item>עריכת הזמנה</Menu.Item>
                            </MenuItem>
                            <MenuItem data={"some_data"} onClick={() => handleClick2(2)}>
                                <Menu.Item>שליחת הודעה</Menu.Item>
                            </MenuItem>
                            <MenuItem data={"some_data"} onClick={() => handleClick2(3)}>
                                <Menu.Item>צפייה בעמוד סיום</Menu.Item>
                            </MenuItem>

                        </>

                }

            </Menu>
        </>


    );

}
export default MyContextMenu;