import React, { useState, useEffect, Fragment } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer, MDBInput, MDBCollapse, MDBCollapseHeader } from 'mdbreact';

import { useDispatch } from 'react-redux'
import MyModal from '../../../components/MyModal'
import GetProducts from '../../../components/GetProducts'
import { useHistory } from "react-router-dom";
import EditorInput from '../../../components/EditorInput'
import moment from 'moment-timezone'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroups } from "../../../redux/groups/groups-actions";
import { getGroupDeleveriesByCode, addGroupDelivery, getLastGroupDeliveryByGroupCode } from "../../../redux/deliveries/deliveries-actions";
import { addOnlineDelivery } from '../../../redux/onlineDeliveries/onlineDeliveries-actions';


const CreateOnlineDelivery = (props) => {

    // const [eventAdrress, setEventAdrress] = useState('')
    const [eventDate, setEventDate] = useState('')
    const [eventHour, setEventHour] = useState('')
    const [eventDuration, setEventDuration] = useState('')
    const [eventMinimumTotalPrice, setMinimumTotalPrice] = useState(0)
    const [eventPayBoxLink, setEventPayBoxLink] = useState('')
    const [groupCode, setGroupCode] = useState('')
    const [copyMessage, setCopyMessage] = useState('')
    const [modalShow, setModalShow] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [productsInDelivery, setProductsInDelivery] = useState([])
    const [isCopyForm, setIsCopyForm] = useState(false)
    const [showCopyBtn, setShowCopyBtn] = useState(false)
    const [productsFromDelivery, setProductsFromDelivery] = useState([])
    const [htmlHeader, setHtmlHeader] = useState(null)
    const [htmlFooter, setHtmlFooter] = useState(null)
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [collapseID, setCollapseID] = useState("collapseHeader")
    const [collapseIDFooter, setCollapseIDFooter] = useState("footerCollapse")

    const [arrowIcon, setArrowIcon] = useState("fa fa-angle-down rotate-icon")
    const [arrowFooterIcon, setArrowFooterIcon] = useState("fa fa-angle-down rotate-icon")

    const history = useHistory()
    // const [inputFields, setInputFields] = useState([
    //     { DeliveryDate: '', DeliveryTime: '08:00', Duration: '300' }
    // ]);

    // const[htmlEditor, setHtmlEditor] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {

        // console.log('groups')

        setGroupCode(999)

        props.getGroups()

        setEventDuration(300)
        setMinimumTotalPrice(30)
        let groupEventDate = new Date(new Date()).toISOString().slice(0, 10)
        let groupEventHour = new Date(new Date()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

        setEventDate(groupEventDate)
        setEventHour(groupEventHour)

        // if (inputFields.length > 0)
        //     setDisabledBtn("disabled")

    }, []);


    const toggleCollapse = newCollapseID => () => {

        if (collapseID !== newCollapseID) {
            setCollapseID(newCollapseID)
            setArrowIcon("fa fa-angle-up rotate-icon")
        }
        else {
            setCollapseID("")
            setArrowIcon("fa fa-angle-down rotate-icon")
        }
    }

    const toggleCollapseFooter = newCollapseID => () => {

        if (collapseIDFooter !== newCollapseID) {
            setCollapseIDFooter(newCollapseID)
            setArrowFooterIcon("fa fa-angle-up rotate-icon")
        }
        else {
            setCollapseIDFooter("")
            setArrowFooterIcon("fa fa-angle-down rotate-icon")
        }
    }

    const postEvent = () => {

        // console.log('htmlEditor', htmlEditor)

        // inputFields.map((field, i) => {
        //     if (field.DeliveryDate != null)
        //         field.DeliveryDate = new Date(`${field.DeliveryDate} ${field.DeliveryTime}`);

        // })

        const dateAndHour = new Date(`${eventDate} ${eventHour}`);

        const objectPost = {
            "GroupCode": groupCode,
            // "Days": inputFields[0],
            "DeliveryDate": dateAndHour,
            "Duration": eventDuration,
            "MinimumTotalPrice": eventMinimumTotalPrice,
            "PayboxGroupLink": eventPayBoxLink ? eventPayBoxLink : null,
            "Products": productsInDelivery,
            "IsActive": true,
            "Header": htmlHeader,
            "Footer": htmlFooter
        }

        console.log('objectPost', objectPost)

        props.addOnlineDelivery(objectPost).then(() => {
            history.push("/admin/online/online-delivery")
        })
    }


    const loadGroups = props.groups.map(group => {
        {
            return group.IsActive &&
                <option key={group.Code} value={group.Code}>{group.Name}</option>
        }
    })

    //callBack for Products chosen
    const handleCallback = (childData) => {

        // console.log('childData', childData)
        setProductsInDelivery(childData)
    }

    //callBack for HTML editor - Header
    const parentCallbackOfHtmlHeaderInput = (childData) => {

        // console.log('header', childData)
        setHtmlHeader(childData)
    }

    //callBack for HTML editor - Footer
    const parentCallbackOfHtmlFooterInput = (childData) => {

        // console.log('footer', childData)
        setHtmlFooter(childData)
        // console.log(htmlTexts)
    }

    const setGroupCodeAndShowLastDeliveryBtn = (e) => {
        dispatch(getLastGroupDeliveryByGroupCode(e.target.value))
        setGroupCode(e.target.value)
        if (e.target.value > 0) {
            setShowCopyBtn(true)
        }
        else {
            setShowCopyBtn(false)
            setCopyMessage('')
        }

        // document.getElementById('lastDelivery').style.display = 'block'
        // :  document.getElementById('lastDelivery').style.display = 'none'
    }

    const showLastDeliveryOfgroup = () => {
        dispatch(getLastGroupDeliveryByGroupCode(groupCode)).then((data) => {
            // setEventAdrress(pr)
            // console.log('data', props.lastDelivery)
            try {

                // setProductsFromDelivery(props.lastDelivery.Products)
                setIsCopyForm(true)
                setEventDate(new Date(props.lastDelivery.DeliveryDateTime).toISOString().slice(0, 10))
                parentCallbackOfHtmlHeaderInput(props.lastDelivery.Header)
                setMinimumTotalPrice(props.lastDelivery.MinimumTotalPrice)
                {
                    props.lastDelivery.Header !== undefined &&
                        setCollapseID(true)
                }
                parentCallbackOfHtmlFooterInput(props.lastDelivery.Footer)
                {
                    props.lastDelivery.Footer !== undefined &&
                        setCollapseIDFooter(true)
                }
                // setHtmlFooter(props.lastDelivery.Footer)

                // { address: '', hour: '', duration: '' }

                //reset array of places
                // while (inputFields.length > 1) {
                //     inputFields.pop();
                // }

                //initial array of places
                // setInputFields([{ DeliveryDate: '', DeliveryTime: '', Duration: '' }])

                // const values = [...inputFields];

                // props.lastDelivery.Places.map((place, index) => {

                //     if (index === 0) {
                //         values[index].DeliveryDate = place.DeliveryDate
                //         values[index].DeliveryTime = new Date(place.DeliveryTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                //         values[index].Duration = place.Duration
                //     }
                //     else if (index > 0) {
                //         values.push({
                //             DeliveryDate: place.DeliveryDate,
                //             DeliveryTime: new Date(place.DeliveryTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                //             Duration: place.Duration
                //         });
                //         setDisabledBtn("")
                //     }
                // })

                // setInputFields(values);

                // setEventAdrress(props.lastDelivery.Address)
                // setEventHour( new Date(props.lastDelivery.DeliveryDateTime).toLocaleTimeString('en-US', { hour12: false }))
                // setEventDuration((props.lastDelivery.Duration))
                setCopyMessage('נתוני הטופס הקודם הועתקו לטופס זה')


            } catch (error) {
                alert('לא קיימים טפסים לקבוצה הנבחרת')
            }

        })
    }

    // ******************************
    // const handleAddFields = () => {
    //     const values = [...inputFields];
    //     values.push({ DeliveryDate: '', DeliveryTime: '', Duration: '' });
    //     setInputFields(values);
    //     setDisabledBtn("")
    // };

    // const handleRemoveFields = index => {
    //     const values = [...inputFields];
    //     values.splice(index, 1);
    //     setInputFields(values);

    //     //   console.log('inputFields.length', inputFields.length)
    //     if (inputFields.length === 2)
    //         setDisabledBtn("disabled")
    // };

    // const handleInputChange = (index, event) => {

    //     // { address: '', hour: '', duration: '' }

    //     const values = [...inputFields];
    //     if (event.target.name === "DeliveryDate") {
    //         values[index].DeliveryDate = event.target.value;
    //     }
    //     else if (event.target.name === 'DeliveryTime') {
    //         values[index].DeliveryTime = event.target.value
    //     }
    //     else if (event.target.name === 'Duration') {
    //         values[index].Duration = event.target.value;
    //     }

    //     setInputFields(values);
    // };

    //   const handleSubmit = e => {
    //     e.preventDefault();
    //     console.log("inputFields", inputFields);
    //   };
    // ******************************

    const loadProducts = () => {
        return <GetProducts
            isCreateForm={true}
            isCreateOrUpdateForm={true}
            parentCallback={handleCallback}
            productsFromDelivery={productsFromDelivery}
        // isCopyForm = {isCopyForm}
        />
    }
    return (
        <div className='container-fluid'>
            <MDBContainer id='formContainer' className='firstDivAfterNav'>
                <MDBRow>
                    <MDBCol md="12" style={{ margin: 'auto' }}>
                        <MDBCard>
                            <MDBCardBody>

                                <form style={{ background: 'white' }}>

                                    <h2 className='text-center font-weight-bold'>יצירת חלוקות השבוע </h2>
                                    {/* {moment()} */}
                                    <div className="black-text">

                                        <div className='col-sm-8' style={{ margin: 'auto' }}>
                                            <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>
                                            {/* <h3>* קבוצה</h3>
                                            <select className="browser-default custom-select" onChange={e => setGroupCodeAndShowLastDeliveryBtn(e)}>
                                                <option value='0'>* בחר/י קבוצה</option>
                                                {loadGroups}

                                            </select> */}
                                            {/* {
                                                showCopyBtn ?
                                                    <div className='col-sm-12 text-center animated fadeIn '>
                                                        <MDBBtn rounded outline color="secondary"
                                                            onClick={() => showLastDeliveryOfgroup()}
                                                        ><i className="far fa-copy fa-x"></i>&nbsp;
                                                            העתק טופס קודם
                                                        </MDBBtn>
                                                    </div>
                                                    : ''
                                            } */}

                                            <h5 className='text-center'>{copyMessage}</h5>

                                            <div className="form-group col-sm-12">
                                                <label htmlFor="DeliveryDate">תאריך החלוקה</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="DeliveryDate"
                                                    name="DeliveryDate"
                                                    value={eventDate}
                                                    onChange={event => setEventDate(event.target.value)}
                                                />
                                            </div>

                                            <div className="form-group col-sm-12">
                                                <label htmlFor="DeliveryTime">שעה</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    id="DeliveryTime"
                                                    name="DeliveryTime"
                                                    value={eventHour}
                                                    onChange={event => setEventHour(event.target.value)}
                                                />
                                            </div>

                                            <div className="form-group col-sm-12">
                                                <label htmlFor="Duration">זמן החלוקה בדקות</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="Duration"
                                                    name="Duration"
                                                    min="10"
                                                    value={eventDuration}
                                                    onChange={event => setEventDuration(event.target.value)}
                                                />
                                            </div>

                                            {/* <h3>* חלוקה</h3> */}
                                            {/* {inputFields.map((inputField, index) => (
                                                <Fragment key={`${inputField}~${index}`}>
                                                    <div >
                                                        <div className="form-group col-sm-12">
                                                            <label htmlFor="DeliveryDate">תאריך החלוקה</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="DeliveryDate"
                                                                name="DeliveryDate"
                                                                value={inputField.DeliveryDate}
                                                                onChange={event => handleInputChange(index, event)}
                                                            />
                                                        </div>

                                                        <div className="form-group col-sm-12">
                                                            <label htmlFor="DeliveryTime">שעה</label>
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                id="DeliveryTime"
                                                                name="DeliveryTime"
                                                                value={inputField.DeliveryTime}
                                                                onChange={event => handleInputChange(index, event)}
                                                            />
                                                        </div>

                                                        <div className="form-group col-sm-12">
                                                            <label htmlFor="Duration">זמן החלוקה בדקות</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                id="Duration"
                                                                name="Duration"
                                                                min="10"
                                                                value={inputField.Duration}
                                                                onChange={event => handleInputChange(index, event)}
                                                            />
                                                        </div>

                                                        <div >
                                                            <button className='rounded-button'
                                                                type="button"
                                                                onClick={() => handleAddFields()}
                                                            >
                                                                +
                                                            </button>&nbsp;

                                                            <button className='rounded-button' disabled={disabledBtn}
                                                                type="button"
                                                                onClick={() => handleRemoveFields(index)}
                                                            >
                                                                -
                                                            </button>

                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))} */}
                                            <hr />

                                            <h3>מינימום ש"ח להזמנה</h3>
                                            <MDBInput label="* " value={eventMinimumTotalPrice}
                                                onChange={e => setMinimumTotalPrice(e.target.value)}
                                                required icon="hour" group type="number" validate
                                                error="wrong"
                                                min="10"
                                                success="right" />
                                            <br />

                                            <h3 style={{ cursor: 'pointer' }} onClick={toggleCollapse("basicCollapse")}
                                            >טקסט עליון&nbsp;
                                                <i className={arrowIcon}></i>
                                            </h3>
                                            <span> (אופציונאלי)</span>
                                            <MDBCollapse id="basicCollapse" isOpen={collapseID}>
                                                <EditorInput
                                                    initialValue={htmlHeader}
                                                    parentCallback={parentCallbackOfHtmlHeaderInput}
                                                />
                                            </MDBCollapse>
                                            <hr />

                                            <h3>לינק ל PayBox</h3>
                                            <MDBInput value={eventPayBoxLink} onChange={e => setEventPayBoxLink(e.target.value)}
                                                icon='money-bill' group type="link" validate
                                                error="wrong"
                                                success="right"
                                            />
                                        </div>
                                        <hr />

                                        <MDBRow >
                                            <MDBCol>

                                                {loadProducts()}
                                                <div className='col-sm-8' style={{ margin: 'auto' }}>
                                                    <h3 style={{ cursor: 'pointer' }} onClick={toggleCollapseFooter("basicCollapse2")}
                                                    >טקסט תחתון&nbsp;
                                                        <i className={arrowFooterIcon}></i>
                                                    </h3>
                                                    <span> (אופציונאלי)</span>

                                                    <hr />

                                                    <MDBCollapse id="basicCollapse2" isOpen={collapseIDFooter}>
                                                        <EditorInput
                                                            parentCallback={parentCallbackOfHtmlFooterInput}
                                                            initialValue={htmlFooter}
                                                        />
                                                    </MDBCollapse>

                                                </div>
                                                <div className=' col-sm-12 text-center'>

                                                    <p className='text-center'>{errorMessage}</p>
                                                    <MDBBtn rounded outline color="secondary"
                                                        variant="primary" type="" value="Send"
                                                        className='' validate
                                                        style={{ margin: 'auto' }}

                                                        onClick={() =>
                                                            groupCode != '' && eventDate != '' &&
                                                                // && inputFields[0].DeliveryDate != '' &&
                                                                // inputFields[0].DeliveryTime != '' &&
                                                                eventMinimumTotalPrice > 0
                                                                && productsInDelivery.length > 0 ?
                                                                setModalShow(true)
                                                                : setErrorMessage('מלא/י את כל שדות החובה')}

                                                    > יצירת חלוקה


                                                    </MDBBtn>
                                                </div>

                                            </MDBCol>
                                        </MDBRow >

                                        <MyModal />

                                        <MyModal
                                            headlineText='האם ברצונך ליצור את הטופס?'
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            ButtonText='ביטול'
                                            ButtonDeleteText='אישור'
                                            ButtonDinamicFunction={() => postEvent()}
                                        />

                                    </div>

                                </form>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer >
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
        // deliveries: state.deliveriesReducer.deliveries,
        onlineDeliveries: state.onlineDeliveriesReducer.onlineDeliveries,
        lastDelivery: state.deliveriesReducer.lastDelivery

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroups,
            getGroupDeleveriesByCode,
            addOnlineDelivery,
            getLastGroupDeliveryByGroupCode
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOnlineDelivery);
