import Modal from 'react-bootstrap/Modal'
import React from 'react'
import { MDBBtn } from 'mdbreact';

function MyModal(props) {

  const almogIcon = require('./img/almog.PNG')

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className='flex'>
          {/* משק אלמוג */}
          <img style={{ height: '150px' }} src={almogIcon} alt='almog'></img>
          {props.headline && props.headline}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.headlineText}</h4>
        <p>
          {props.headlineBody}
        </p>
      </Modal.Body>
      <Modal.Footer>
        {props.ButtonDeleteText ?
          <MDBBtn rounded outline color="secondary"
            onClick={props.ButtonDinamicFunction} name={props.ButtonDeleteText}>{props.ButtonDeleteText}&nbsp;
            <i className="fas fa-check fa-2x"></i>
          </MDBBtn>
          : ''
        }
        {props.ButtonText !== '' ?
          <MDBBtn rounded outline color="secondary"
            // className="fas fa-times-circle"
            onClick={props.onHide}>{props.ButtonText}&nbsp;
            <i className="fas fa-times-circle fa-2x"></i>
          </MDBBtn>
          : ''
        }
        {props.ButtonCloseText ?
          <MDBBtn rounded outline color="secondary"
            className="fas fa-times-circle"
            onClick={props.onHide} name={props.ButtonCloseText} >{props.ButtonCloseText}&nbsp;
            <i className="fas fa-check fa-2x"></i>
          </MDBBtn>
          : ''
        }
        {props.editreservationtext ?
          <MDBBtn rounded outline color="secondary"
            onClick={props.ButtonDinamicFunction} name={props.editreservationtext} >{props.editreservationtext}&nbsp;
            <i className="fas fa-edit fa-2x"></i>
          </MDBBtn>
          : ''
        }
        {props.newreservationtext ?
          <MDBBtn rounded outline color="secondary"
            onClick={props.onHide} name={props.newreservationtext} >{props.newreservationtext}&nbsp;
            <i className="fa fa-plus fa-2x"></i>
          </MDBBtn>
          : ''
        }

      </Modal.Footer>
    </Modal>
  );
}
export default MyModal