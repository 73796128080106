export function validateEmail(email) {

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // console.log('validate Email', re.test(String(email).toLowerCase()))
    let result;

    if (email.includes('.con') || email.includes('.cim') || email.includes('gmail.co.il') || email.includes('ci.il') || email.includes('gmil.com') || email.includes('fmail.com') || email.includes('hmail.com'))
        result = false;
    else
        result = re.test(String(email).toLowerCase());

    return result;

}