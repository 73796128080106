import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBInput } from 'mdbreact';
import '../../dist/css/reservation.css'
import MyModal from '../../components/MyModal'
import Button from 'react-bootstrap/Button';
// import emailjs from 'emailjs-com';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add_to_person, get_person, update_person_by_id } from "../../redux/person/person-actions";
import Places from "../../components/Places/Places";

const Form = (props) => {

  const [modalShow, setModalShow] = React.useState(false);

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [additionalPhone, setAditionalPhone] = useState('')
  const [userNote, setUserNote] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')
  const [eventDate, setEventDate] = useState('')
  const [hour, setHour] = useState('')
  const [paymentChecked, setPaymentChecked] = useState('')
  const [approved, setApproved] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    props.get_person().then(() => {
      // console.log('props.person ', props.person)

      if (props.person.length > 0) {
        setFirstName(props.person[0].firstName)
        setLastName(props.person[0].lastName)
        setEmail(props.person[0].email)
        setPhone(props.person[0].phone)
        setAditionalPhone(props.person[0].additionalPhone)
        setUserNote(props.person[0].notes)
        setCity(props.person[0].city)
        setStreet(props.person[0].street)
        setEventDate(props.person[0].date)
        setHour(props.person[0].hour)
        setPaymentChecked(props.person[0].payment)
        setApproved(props.person[0].approved == "מאשר/ת את התנאים" ? true : '')
      }
      else {
        setFirstName('אסף')
        setLastName('אלמוג')
        setEmail('asafalmog119@gmail.com')
        setPhone('0502820405')
        setAditionalPhone('0502820404')
        setUserNote('בדיקה')
        setCity('אחיטוב')
        setStreet('האורן 4')
        setEventDate(("2021-08-20"))
        setHour(1)
        setPaymentChecked('מזומן')
        setApproved(true)
      }
    })

  }, [])


  const setSelected = e => {
    e.preventDefault();
    let target = e.target;

    //remove all active
    let elements = document.getElementsByClassName('selectedTextDecoration');

    for (let index = 0; index < elements.length; index++) {
      elements[index].classList.remove('selectedTextDecoration');
    }

    //add one active
    target.classList.add("selectedTextDecoration");

    setHour(e.target.value)
  }
  const loadHours = () => {

    return <>
      <button value='normal'
        className={`buttonOfProperty`}
        id={'1'}
        value={'08:00-10:00'}
        onClick={e => setSelected(e)}
      >08:00-10:00</button>
      <button value='normal'
        className={`buttonOfProperty`}
        id={'2'}
        value={'10:00-12:00'}
        onClick={e => setSelected(e)}
      >10:00-12:00</button>
      <button value='normal'
        className={`buttonOfProperty`}
        id={'3'}
        value={'12:00-14:00'}
        onClick={e => setSelected(e)}
      >12:00-14:00</button>
    </>
  }
  const submitForm = (e) => {
    e.preventDefault()

    let objOfPerson = {
      firstName,
      lastName,
      email,
      phone,
      notes: userNote,
      date: eventDate,
      hour,
      city,
      street,
      approved: approved == true ? "מאשר/ת את התנאים" : '',
      'payment': paymentChecked
    }

    approved == true &&
      props.personDetails(objOfPerson)
    props.setcheckedStep2(true)
    if (props.person.length > 0) {
      props.update_person_by_id(objOfPerson).then(() => {
        props.setActiveStep(2)
      })
    }
    else {
      props.add_to_person(objOfPerson).then(() => {
        props.setActiveStep(2)
      })
    }

  }
  return (
    // <React.Fragment id='formContainer'>
    <MDBRow>
      <MDBCol md="8" style={{ margin: 'auto' }}>

        <form id='reservationForm' onSubmit={(e) => submitForm(e)}>
          {/* <h3 className="h5 text-center mb-4">מלא את הפרטים הבאים</h3> */}


          <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>
          <div className="black-text">

            <MDBInput label="* תאריך" value={eventDate} onChange={e => setEventDate(e.target.value)}
              required icon="hour" group type="date" validate
              min="2021-08-20" max="2021-08-20"
              error="wrong"
              success="right" />

            <label>שעת המשלוח</label>
            {/* <select className="form-control">
                <option>10:00-12:00</option>
                <option>12:00-14:00</option>
              </select> */}
            {
              loadHours()
            }

            {/* <MDBInput label="* כתובת" value={addrress} onChange={e => setAddress(e.target.value)}
              required icon="home" group type="text"
              error="wrong" name="user_name"
              success="right" /> */}
            <Places
              setAddress={setCity}
              addrress={city}
            />
            <MDBInput label="* רחוב ומספר בית" value={street} onChange={e => setStreet(e.target.value)}
              required icon="home" group type="text"
              error="wrong" name="user_name"
              success="right" />

            <MDBInput label="* שם פרטי" value={firstName} onChange={e => setFirstName(e.target.value)}
              required icon="user" group type="text"
              error="wrong" name="user_firstName"
              success="right" />

            <MDBInput label="* שם משפחה" value={lastName} onChange={e => setLastName(e.target.value)}
              required icon="user" group type="text"
              error="wrong" name="user_lastName"
              success="right" />

            <MDBInput label="* דואר אלקטרוני" value={email} onChange={e => setEmail(e.target.value)}
              required icon="envelope" group type="email"
              error="wrong" name="user_email"
              success="right" />

            <MDBInput label="* טלפון" value={phone} onChange={e => setPhone(e.target.value)}
              required icon="phone" group type="tel"
              error="wrong" name="user_phone"
              success="right" />
            <MDBInput label=" טלפון נוסף" value={additionalPhone} onChange={e => setAditionalPhone(e.target.value)}
              icon="phone" group type="tel"
              error="wrong" name="user_additionalPhone"
              success="right" />

            <div>
            </div>

            <MDBInput type="textarea" rows="3" label="הערות" icon="pencil-alt"
              value={userNote} onChange={e => setUserNote(e.target.value)} name='user_notes' />

            <div id="divOfInputPayment">

              <h5>* אופן התשלום <i className=" formIcon fas fa-money-bill"></i></h5>
              {/* <input type='radio'></input> */}
              <label className='myCheckbox'>
                <input
                  id='payment'
                  type='radio'
                  name='payment'
                  required
                  value='מזומן'
                  checked={paymentChecked === 'מזומן'}
                  onChange={() => {
                    document.getElementById("divOfInputPayment").classList.remove('divOfInputPaymentClass')
                    setPaymentChecked('מזומן')
                  }
                  }
                /> מזומן</label>

              <br />
              <label className='myCheckbox'>
                <input type='radio'
                  name='payment'
                  required
                  value='PayBox'
                  checked={paymentChecked === 'PayBox'}
                  onChange={() => {
                    document.getElementById("divOfInputPayment").classList.remove('divOfInputPaymentClass')
                    setPaymentChecked('PayBox')
                  }
                  }
                /> PayBox</label>
              <br /> <br />
              <label className='myCheckbox'>
                <input type='checkbox'
                  required
                  // value={approved}
                  checked={approved}
                  onChange={() => setApproved(true)}
                ></input>&nbsp;
                אני מאשר/ת את  <a href='#' target='_blank'>התנאים</a>
              </label>

            </div>
            <div>

              {/* <h5 className='col-sm-12'>  סה"כ סכום ההזמנה: <b>{props.productsDetails.totalPrice} ₪</b></h5> */}

            </div>

            <p>{errorMessage}</p>
          </div>
          <div>
            <div className="text-center">
              <MyModal />
              {/* <Button variant="primary" type="submit" value="Send"
                  className='submitBtn'>
                  מעבר לשלב האחרון <MDBIcon far icon="next" className="ml-1" />
                </Button> */}
              <button className="btn text-center almogBtn BottomBtnCart"
                type='button'
                onClick={() => props.setActiveStep(0)}
              >
                <i className="fas fa-arrow-alt-circle-right fa-2x"></i>
                הקודם&nbsp;
              </button>

              <button className="btn text-center almogBtn BottomBtnCart"
                type='submit'
                form='reservationForm'
              // disabled={name != '' && email != '' && addrress != '' && phone != ''
              //   && paymentChecked != '' && hour != '' && approved != '' ? '' : 'disabled'}
              // onClick={() => this.props.setActiveStep(1)}
              >הבא&nbsp;
                <i className="fas fa-arrow-alt-circle-left fa-2x"></i>
              </button>

            </div>
          </div>
        </form>
      </MDBCol>
    </MDBRow>
  );
};
const mapStateToProps = (state) => {
  return {

    person: state.personReducer.person,

  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      get_person,
      add_to_person,
      update_person_by_id
    },
    dispatch
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(Form);
