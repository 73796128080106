import React from 'react';
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';

import { EmailIcon, WhatsappIcon } from "react-share";

function ContactIcons() {
    return (
        <MDBRow className="text-center" size='12' style={{ margin: 'auto', paddingBottom: '10px' }}>

            <MDBCol size="4">
                <button className='rounded-button' aria-label="Right Align" style={{ width: '32px', height: '32px' }}>
                    <a href='tel: 972-502820402'>
                        <MDBIcon icon="phone" className='reactShareIcons' />
                    </a>
                </button>
            </MDBCol>

            <MDBCol size="4">
                <button className='rounded-button' aria-label="Center Align" style={{ width: '32px', height: '32px', padding: '0' }}>
                    <a href='https://wa.me/972502820402' target="_blank" rel="noopener noreferrer">
                        <WhatsappIcon className='reactShareIcons' size={32} round={true} />
                    </a>
                </button>
            </MDBCol>

            <MDBCol size="4">
                <button className='rounded-button' aria-label="Left Align" style={{ width: '32px', height: '32px', padding: '0' }}>
                    <a href='mailto:meshekalmog@gmail.com'>
                        <EmailIcon className='reactShareIcons' size={32} round={true} />
                    </a>
                </button>
            </MDBCol>

        </MDBRow>
    )
}

export default ContactIcons
