import React, { Component } from "react";
import "./RoundedButton.scss";

class RoundedButton extends Component {
  state = {};

  render() {
    return (
      <button
        disabled={this.props.disabled}
        style={this.props.style}
        onClick={this.props.onClick}
        className={
          this.props.className +
          (this.props.primary ? " rounded-button primary" : " rounded-button")
        }
      >
        <i className={this.props.icon} />
      </button>
    );
  }
}

export default RoundedButton;
