import React, { useEffect, useState } from 'react'
import { MDBCard, MDBCol, MDBContainer, MDBRow, MDBInput, MDBBtn } from 'mdbreact'
import ImportUsers from './ImportUsers'
import EditorInput from '../../../components/EditorInput'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendEmailToMuiltipleUsers } from "../../../redux/groupOrders/group-orders-action";
import { Link } from 'react-router-dom';

const AddUsers = (props) => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [names, setNames] = useState([])
    const [emails, setEmails] = useState([])

    const [usersObject, setUsersObject] = useState({})
    const [emailHeadline, setEmailHeadline] = useState("")
    const [htmlTemplate, setHtmlTemplate] = useState("")
    useEffect(() => {

    })

    const sendEmail = (e) => {

        let myObject = {
            'Emails': usersObject.emails,
            'Subject': emailHeadline,
            'Body': htmlTemplate
        }
        console.log('myObject', myObject)
        props.sendEmailToMuiltipleUsers(myObject).then(() => {
            // console.log('Sent!')
            alert('המייל נשלח')
        }).catch(() => alert('אירעה שגיאה'))
    }


    const setActionsFromSelected = (e) => {
        if (e.target.value == 0) {
            setHtmlTemplate("")
            setEmailHeadline("")
        }
        else if (e.target.value == 1) {
            setInvitedMessege()

        }
    }

    const setInvitedMessege = () => {
        setEmailHeadline(" הזמנה לרכישה דרך האתר - משק אלמוג")
        // setEmail(usersObject.emails[0])
        usersObject.length > 0 ? setEmail(usersObject.emails[0]) : setEmail('asafalmog119@gmail.com')
        setHtmlTemplate(
            `<p>שלום ${usersObject.names[0]},<br/></p>         
                  </p>
                  <p>זוכרים את <b style = "color: green">המלפפונים </b> הפריכים<b style = 'color: red'> ועגבניות השרי </b> הטריות 
                   שקניתם ישירות מהחקלאי במהלך השנה האחרונה?
                  <br/>
                  אלה אנחנו :)<br/>
                נפגשנו בנסיבות הקורונה ונשמח להיפגש שוב.</p>
                   <p><b> אנו מזמינים אותך לחזור לרכוש ירקות אצלנו והפעם - ישירות באתר.</b></p>
                    <p>ההזמנות יחולקו ישירות עד הבית!</p>
                    <a href="https://www.meshekalmog.co.il">לפרטים נוספים לחץ/י כאן</a>
                    <p>
                    תודה, 
                     <br/>
                    משק אלמוג
                    <p/>
                    <a href = 'https://www.meshekalmog.co.il'> <img src='https://meshekalmog.co.il/static/media/almog.2cf9a1e4.PNG' width='200' height='200' /></a>
                    <small><a href="/emails/removed/${usersObject.emails[0]}">להסרה מרשימת התפוצה</a><small/>
                    `
        )
    }

    const parentCallbackOfHtmlHeaderInput = (childData) => {

        setHtmlTemplate(childData)
    }

    // const addUser = () => {
    //     alert('המשתמש נוסף!')
    //     setFirstName('')
    //     setLastName('')
    //     setEmail('')
    // }
    const submitForm = (e) => {
        e.preventDefault()

        alert('המשתמש נוסף!')
        setFirstName('')
        setLastName('')
        setEmail('')
    }
    return (
        <div className='container-fluid'>

            <MDBContainer className="firstDivAfterNav text-center">
                <h2 className="text-center font-weight-bold">הוספת משתמשים</h2>
                <Link to='/admin/users/import'>ייבוא משתמשים</Link>
                <MDBRow>

                    <MDBCol sm='12'>
                        <MDBCard>

                            <form className='col-lg-8' onSubmit={(e) => submitForm(e)}>
                                <MDBInput id='firstName' label="* שם פרטי"
                                    value={firstName} onChange={e => setFirstName(e.target.value)}
                                    required icon="user" group type="text"
                                    error="wrong" name="user_firstName" validate
                                    success="right" />

                                <MDBInput id='lastName' label="* שם משפחה"
                                    value={lastName} onChange={e => setLastName(e.target.value)}
                                    required icon="user" group type="text"
                                    error="wrong" name="user_lastName" validate
                                    success="right" />

                                <MDBInput id='email' label="* דואר אלקטרוני" value={email} onChange={e => setEmail(e.target.value)}
                                    required icon="envelope" group type="email"
                                    error="wrong" name="user_email" validate
                                    success="right" />

                                <MDBBtn
                                    rounded outline color="secondary"
                                    variant="primary" type="submit" value="Send" validate
                                // onClick={(e) => addUser(e)}
                                > הוספה</MDBBtn>
                            </form>
                        </MDBCard>

                    </MDBCol>

                </MDBRow>
            </MDBContainer>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {

        orders: state.groupOrdersReducer.orders,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            sendEmailToMuiltipleUsers
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUsers);
