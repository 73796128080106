const initialState = {
  deliveries: [],
  lastDelivery: {},
  totalPricesForDelivery: [],
  filteredProduct: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_GROUPS_DELIVERIES":
      return { ...state, deliveries: action.payload };
    case "GET_GROUPS_DELIVERIES_BY_WEEK_NUMBER":
      return { ...state, deliveries: action.payload };
    case "GET_DELIVERIES_BY_GROUP_CODE":
      return { ...state, deliveries: action.payload };
    case "GET_WEEK_SUMMARY":
      return { ...state, deliveries: action.payload };
    case "GET_DELIVERIES_BY_DELIVERY_ID":
      return { ...state, deliveries: action.payload };
    case "GET_DELIVERIES_TOTALS_BY_DELIVERY_ID":
      // console.log(action.payload[0])
      return { ...state, totalPricesForDelivery: action.payload[0] };
    case "GET_LAST_DELIVERY_BY_GROUP_CODE":
      return { ...state, lastDelivery: action.payload };
    case "ADD_DELIVERIES_BY_GROUP_CODE":
      return { ...state };
    case "UPDATE_GROUP_DELIVERY_BY_ID":
      return { ...state };
    case "SET_GROUP_DELIVERY_REVENUE":
      return { ...state }
    case "DELETE_DELIVERY_BY_ID":
      return { ...state };
    case "UPDATE_FILTERD_PRODUCT":
      return { ...state, filteredProduct: action.payload };

    default:
      return state;
  }
}
