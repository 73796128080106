
import React from "react";
import GedDayOfWeek from "../GedDayOfWeek";
import GetLocalDayAndHour from "../GetLocalDayAndHour";
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import moment from "moment";
import '../../dist/css/admin.css';
// Redux
import _ from "lodash";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";
import { getGroupDeliveriesByWeekNumber, setGroupDeliveryRevenue } from "../../redux/deliveries/deliveries-actions";
import { getGroups } from "../../redux/groups/groups-actions";

class MakingOrders extends React.Component {
    state = {
        revenues: [],
        groupNames: [],
        active: '',
        items: [],
        currentPage: 0,
        startDate: null,
        endDate: null,
        currentWeek: 'שבוע נוכחי',
        isDisplayed: "none",
        floatedMenuDelivery: null,
        floatedMenuUpdate: null,
        floatedMenuForm: null,
        floatedMenuManagerView: null,
        floatedMenuGroupPage: null,
        floatedMenuMessegePage: null
    };

    getDeliveriesByWeekNum = (weekAgo) => {
        //reset revenues array
        let revenuesArray = []
        this.props.getGroupDeliveriesByWeekNumber(weekAgo).then(() => {
            this.props.deliveries.map(delivery => {
                // console.log('delivery', delivery.Revenue)
                revenuesArray.push(delivery.Revenue)

                console.log('revenuesArray', revenuesArray)
            })
            this.setState({ revenues: revenuesArray })
        })

        let weekText = ''
        const week = weekAgo
        if (weekAgo != 0) {
            weekAgo = -weekAgo * 7;
            weekText = ''
        }
        else
            weekText = 'שבוע נוכחי'

        this.props.getGroupDeliveriesByWeekNumber(week)
            .then(() => {
                this.setState({
                    currentPage: week,
                    startDate: moment().weekday(weekAgo).format("DD/MM"),
                    endDate: moment().weekday(weekAgo + 6).format("DD/MM"),
                    currentWeek: weekText
                })

            })
            .catch((e) => console.log(e));
    }

    render() {

        return (
            <div className='container-fluid firstDivAfterNav'>
                <h2 className="text-center">הכנת הזמנות</h2>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="12" style={{
                            margin: '20px auto',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <div className="load-deliveries">
                                {this.loadDeliveries()}
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div >
        );
    }
    rowClicked = (link) => {
        this.props.history.push(link)
    }

    updateRevenue = (id, e, i) => {
        this.debouncedUpdateRevenue(id, e.target.value)
        let items = [...this.state.revenues];
        let item = { ...items[i] };
        item = e.target.value
        items[i] = item;
        this.setState({ revenues: items });
    }

    debouncedUpdateRevenue = _.debounce((id, revenue) => {
        if (revenue != null) {
            this.props.setGroupDeliveryRevenue({ "Id": id, "Revenue": revenue })
                .then(() => {
                    this.loadGroupDeliveries(this.state.currentPage);
                })
                .catch((e) => console.log(e))
        }
    }, 500);

    setClickableRow = (e, link) => {
        // console.log('e.target', e.target.tagName)
        debugger
        if (e.target.tagName !== 'INPUT')
            this.rowClicked(link)
    }

    loadDeliveriesSum = () => {
        let totalPrices = 0
        for (let index = 0; index < this.props.deliveries.length; index++) {
            totalPrices += this.props.deliveries[index].TotalOrdersPrice
        }
        return totalPrices
    }

    loadDeliveriesSumOfTotal = () => {
        let totalPricesOfTotal = 0
        for (let index = 0; index < this.state.revenues.length; index++) {
            totalPricesOfTotal += this.state.revenues[index]
        }
        return totalPricesOfTotal
    }

    loadDeliveries = () => this.props.deliveries.sort((a, b) =>
        b.dateOfDelivery - a.dateOfDelivery).map((delivery, index) => {
            // console.log('delivery', delivery)

            let name = ''
            let count = 0

            while (count < this.state.groupNames.length) {
                count++
                switch (delivery.GroupCode) {
                    case count:
                        name = this.state.groupNames[count - 1]
                    default:
                        break;
                }
            }

            let IsActive = ''
            let activeText = ''
            if (delivery.IsActive) {
                activeText = 'פעיל'

            }
            else {
                activeText = 'סגור'
                IsActive = 'isNotActive'
            }

            // console.log('delivery', index)

            let color = '#dddddd'
            let deliveryDay = new Date(delivery.DeliveryDateTime).getDay('he-IL', { hour12: false })
            let day = new Date(delivery.DeliveryDateTime).getDay();
            let prevDay;
            if (index > 0)
                prevDay = new Date(this.props.deliveries[index - 1].DeliveryDateTime).getDay();

            // console.log('day', day)
            // console.log('prevDay', prevDay)

            if (deliveryDay % 2 === 0)
                // color = 'rgba(0,0,0,.05)'
                color = 'white'
            else
                color = 'white'

            // counter++
            return (
                <Button style={{ width: "100%", margin: "10px auto" }}
                    className="submitBtn"
                    color="primary" onClick={() => window.open(`/admin/deliveries/preparation/${delivery.GroupCode}/${delivery._id}/1`)}
                >{name},
                    <span>&nbsp;
                        < GedDayOfWeek
                            dayOfWeek={delivery.DeliveryDateTime}
                        />
                    </span>&nbsp;
                    {
                        delivery.Places != null &&
                        delivery.Places.map((place, index) => {
                            return <span key={index}>
                                {
                                    (delivery.Places.length !== index && index !== 0) &&
                                    ', '
                                }
                                <GetLocalDayAndHour
                                    hourOfDelivery={place.Hour}
                                />
                            </span>
                        })
                    }</Button>
            )
            // }
        })

    loadGroupDeliveries = (weekNum) => {
        // console.log('weekNum', weekNum)
        this.props.getGroupDeliveriesByWeekNumber(weekNum).then(() => {
            this.props.deliveries.map(delivery => {
                // console.log('delivery', delivery)
                revenuesArray.push(delivery.Revenue)
            })
            this.setState({ revenues: revenuesArray })
        })

        // console.log('window', window)
        let myArrayForQunatities = []
        let revenuesArray = []
        this.props.getGroups()
            .then(() => {

                for (let index = 0; index < this.props.groups.length; index++) {
                    myArrayForQunatities.push(this.props.groups[index].InternalName)
                }
                // console.log('myArrayForQunatities1:', myArrayForQunatities)
                this.setState({
                    groupNames: [...myArrayForQunatities],
                    active: 1
                })
            })
            .catch((e) => {
                console.log(e);
            });

        this.setState({
            startDate: moment().weekday(0).format("DD/MM"),
            endDate: moment().weekday(6).format("DD/MM")
        })
    }

    componentDidMount() {
        document.title = "משק אלמוג - טפסים"
        this.loadGroupDeliveries(this.state.currentPage);
    }
}

const mapStateToProps = (state) => {
    return {
        deliveries: state.deliveriesReducer.deliveries,
        groups: state.groupReducer.groups,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            // GetGroupsDeleveries,
            getGroupDeliveriesByWeekNumber,
            getGroups,
            setGroupDeliveryRevenue
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MakingOrders);
