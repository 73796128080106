import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdbreact';
import UpdateOrderProducts from '../update_order/UpdateOrderProducts'
import GetLocalDayAndHour from '../../../../../components/GetLocalDayAndHour'
import moment from "moment";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetGroupDeliveryById } from "../../../../../redux/deliveries/deliveries-actions";
import { getGroupByCode } from "../../../../../redux/groups/groups-actions";
import { getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber, updateGroupOrderPaidStatus } from "../../../../../redux/groupOrders/group-orders-action";

import { getOnlineOrderByGroupDeliveryIdAndOrderNumber } from "../../../../../redux/onlineOrders/online-orders-action";
import Places from '../../../../../components/Places/Places';


class UpdateOrder extends React.Component {
    constructor() {
        super()
        this.state = {
            firstName: '',
            lastName: '',
            city: '',
            address: '',
            email: '',
            phone: '',
            additionalPhone: '',
            comment: '',
            internalComments: '',
            paymentChecked: '',
            approved: '',
            groups: [],
            isLoadingDeliveries: false,
            deliveryId: '',
            groupCode: '',
            orderNumber: '',
            products: [],
            totalPrice: '',
            isPaid: null,
            place: '',
            OrderId: '',
            deliveryDate: ''
        }
    }

    componentDidMount() {


        document.title = `משק אלמוג - עריכת הזמנה`
        const { match } = this.props;

        // console.log(match.params)

        this.setState({
            deliveryId: match.params.deliveryId,
            groupCode: match.params.groupCode,
            orderNumber: match.params.orderNumber,
        })

        this.setState({ groups: this.props.dataOfGroup })
        this.props.getGroupByCode(match.params.groupCode)

        this.props.getOnlineOrderByGroupDeliveryIdAndOrderNumber(match.params.deliveryId, match.params.orderNumber).then(() => {
            console.log('order', this.props.onlineOrders)


            this.props.onlineOrders.map(order => {
                // console.log('order', order)
                this.setState({
                    hour: order.DeliveryTime,
                    city: order.PersonInfo.City,
                    address: order.PersonInfo.Address,
                    firstName: order.PersonInfo.FirstName,
                    lastName: order.PersonInfo.LastName,
                    email: order.PersonInfo.Email,
                    phone: order.PersonInfo.Phone,
                    additionalPhone: order.PersonInfo.AdditionalPhone,
                    approved: order.PersonInfo.Approved,
                    comment: order.Comment,
                    internalComments: order.InternalComment,
                    paymentChecked: order.PaymentMethod,
                    totalPrice: order.TotalPrice,
                    isPaid: order.IsPaid,
                    place: order.Place,
                    OrderId: order._id,
                    deliveryDate: order.DeliveryDate
                })
                // console.log('state', this.state)

            })
            // console.log(' this.props.onlineOrders', this.props.onlineOrders)
            this.setState({ products: this.props.onlineOrders[0].Products })
        })

        this.props.GetGroupDeliveryById(match.params.deliveryId).then(() => {
            this.setState({ isLoadingDeliveries: false })

        })
    }

    setCity = (cityFromInput) => {
        this.setState({ city: cityFromInput })
    }

    //show the details about the chosen form
    loadGroups = () => this.props.deliveries.map(delivery => {

        let groupDate = new Date(delivery.DeliveryDateTime).toLocaleString('en-US', { hour12: false })
        // let isCash = false
        // let isPayBox = false
        let payment = ''
        // console.log('props.orders', props.orders[0].Products)
        return (

            <div key={this.state.orderNumber} className="container">
                <div className="container">
                    {/* <h2 className='text-center font-weight-bold'>הזמנה</h2> */}
                    <div className="card shopping-cart text-center ">
                        <div>
                            <MDBContainer id='formContainer'>
                                <MDBRow>
                                    <MDBCol md="8" style={{ margin: 'auto' }}>
                                        <form>
                                            <br /> <br /> <br />
                                            {/* <h2 className='text-center font-weight-bold'>{this.props.groups[0].Name}
                                                {delivery.Places.length === 0 && ", " + delivery.Address}

                                            </h2> */}

                                            <h4 className='text-center font-weight-bold' style={{ color: '#827717' }}>
                                                <GetLocalDayAndHour
                                                    style={{ display: 'inline' }}
                                                    dateOfDelivery={delivery.DeliveryDateTime}
                                                />
                                                {delivery.Places.length === 0 &&
                                                    <> |&nbsp;

                                                        <GetLocalDayAndHour
                                                            style={{ display: 'inline' }}
                                                            hourOfDelivery={delivery.DeliveryDateTime}
                                                        />
                                                    </>
                                                }
                                            </h4>

                                            <br />
                                            <h3 id='formHeadline' className="h5 text-center mb-4 font-weight-bold"> הזמנה {this.state.orderNumber} </h3>

                                            <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>

                                            {

                                                this.props.onlineOrders.map(order => {
                                                    // console.log("order", order)
                                                    return (
                                                        <div className="black-text" key={order.Id}>
                                                            {moment(order.DeliveryDate).format("DD/MM/YYYY")}
                                                            <MDBInput label="* תאריך"
                                                                valueDefault={moment(order.DeliveryDate).format("DD/MM/YYYY")}
                                                                onChange={e => this.setState({ deliveryDate: e.target.value })}
                                                                required icon="hour" group type="date" validate
                                                                error="wrong"
                                                                success="right" />
                                                            <label>שעת המשלוח: {order.DeliveryTime}
                                                            </label>
                                                            {/* 
                                                            <Places
                                                                setAddress={this.setState({addrress: })}
                                                                addrress={city}
                                                            /> */}
                                                            <Places
                                                                setAddress={this.setCity}
                                                                addrress={this.state.city}
                                                            />
                                                            <MDBInput label="* רחוב ומספר בית"
                                                                valueDefault={order.PersonInfo.Address}
                                                                onChange={e => this.setState({ address: e.target.value })}
                                                                required icon="home" group type="text"
                                                                error="wrong" name="user_name"
                                                                success="right" />

                                                            <MDBInput id='firstName'
                                                                label="* שם פרטי"
                                                                valueDefault={order.PersonInfo.FirstName}
                                                                onChange={e => this.setState({ firstName: e.target.value })}
                                                                required icon="user" group type="text" validate
                                                                error="wrong" name="user_firstname"
                                                                success="right"></MDBInput>

                                                            <MDBInput id='lastName'
                                                                label="* שם משפחה"
                                                                valueDefault={order.PersonInfo.LastName}
                                                                onChange={e => this.setState({ lastName: e.target.value })}
                                                                required icon="user" group type="text" validate
                                                                error="wrong" name="user_lastname"
                                                                success="right" />

                                                            <MDBInput id='email'
                                                                label="* דואר אלקטרוני (לקבלת פירוט ההזמנה למייל)"
                                                                valueDefault={order.PersonInfo.Email}
                                                                onChange={e => this.setState({ email: e.target.value })}
                                                                required icon="envelope" group type="email" validate
                                                                error="wrong" name="user_email"
                                                                success="right" />

                                                            <MDBInput id='phone'
                                                                label="* טלפון נייד"
                                                                valueDefault={order.PersonInfo.Phone}
                                                                onChange={e => this.setState({ phone: e.target.value })}
                                                                required icon="phone" group type="tel" validate
                                                                error="wrong" name="user_phone"
                                                                success="right" />

                                                            <MDBInput label=" טלפון נוסף"
                                                                valueDefault={order.PersonInfo.AdditionalPhone}
                                                                onChange={e => this.setState({ additionalPhone: e.target.value })}
                                                                icon="phone" group type="tel"
                                                                error="wrong" name="user_additionalPhone"
                                                                success="right" />

                                                            <h5>* אופן התשלום <i className="formIcon fas fa-money-bill"></i></h5>
                                                            <select className="form-control" id="paymentMethod" name="paymentMethod"
                                                                onChange={(e) => this.setState({ paymentChecked: e.target.value })}
                                                            >
                                                                {

                                                                    order.PaymentMethod === 'מזומן' ?
                                                                        <>
                                                                            <option value={"PayBox"}> PayBox</option>
                                                                            <option selected={true} value={"מזומן"}>מזומן</option>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <option selected={true} value={"PayBox"}> PayBox</option>
                                                                            <option value={"מזומן"}> מזומן</option>
                                                                        </>
                                                                }
                                                            </select>
                                                            <br />
                                                            <label className='myCheckbox'>
                                                                <input type='checkbox'
                                                                    required
                                                                    // value={approved}
                                                                    checked={order.PersonInfo.Approved == "מאשר/ת את התנאים" ? true : false}
                                                                ></input>&nbsp;
                                                                אני מאשר/ת את  <a href='#' target='_blank'>התנאים</a>

                                                            </label>
                                                            {/* {

                                                                delivery.Places.length > 0 &&

                                                                <div>
                                                                    <h5>* נקודת חלוקה  <i className="formIcon fas fa-map-marker-alt"></i></h5>
                                                                    <select className="form-control" id="place" name="place"
                                                                        onChange={(e) => this.setState({ place: e.target.value })}
                                                                    >
                                                                        {

                                                                            delivery.Places.map((place, index) => {
                                                                                // console.log('place.Address', place.Address)
                                                                                // console.log('order.Place', order.Place)

                                                                                let oldDateObj = new Date(place.Hour);
                                                                                let newDateObj = new Date();
                                                                                newDateObj.setTime(oldDateObj.getTime() + (place.Duration * 60 * 1000));

                                                                                if (place.Address === order.Place)
                                                                                    return <option selected value={place.Address}>
                                                                                        {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                                        -
                                                                                        {new Date(newDateObj).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                                        &nbsp;&nbsp;{place.Address}
                                                                                    </option>
                                                                                else
                                                                                    return <option value={place.Address}>
                                                                                        {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                                        -
                                                                                        {new Date(newDateObj).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                                        &nbsp;&nbsp;{place.Address}
                                                                                    </option>

                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            } */}

                                                            <br />
                                                            <h5>סטאטוס תשלום <i className="formIcon fas fa-user-check"></i></h5>
                                                            <select className="form-control" id="isPaid" name="isPaid"
                                                                onChange={(e) => this.setState({ isPaid: e.target.value })}
                                                            >
                                                                {
                                                                    order.IsPaid ?
                                                                        <>
                                                                            <option value={false}> לא שולם</option>
                                                                            <option selected={true} value={true}>שולם</option>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <option selected={true} value={false}> לא שולם</option>
                                                                            <option value={true}> שולם</option>
                                                                        </>
                                                                }
                                                            </select>


                                                            {/* <input
                                                                id='payment'
                                                                type='radio'
                                                                name='payment'
                                                                value='מזומן'
                                                                checked={isCash}
                                                                // checked={paymentChecked === 'מזומן'}
                                                                onChange={() => setPaymentChecked('מזומן')}
                                                            /> מזומן

                                                            <br />
                                                            <input type='radio'
                                                                name='payment'
                                                                value='PayBox'
                                                                checked={isPayBox}
                                                                // checked={paymentChecked === 'PayBox'}
                                                                onChange={() => setPaymentChecked('PayBox')}
                                                            /> PayBox */}
                                                            <br />
                                                            <MDBInput type="textarea" rows="3" label="הערות" icon="pencil-alt"
                                                                valueDefault={order.Comment}
                                                                onChange={e => this.setState({ comment: e.target.value })}
                                                                name='user_notes' />

                                                            <MDBInput type="textarea" rows="2" label="הערות פנימיות" icon="pen"
                                                                valueDefault={order.InternalComment}
                                                                onChange={e => this.setState({ internalComments: e.target.value })}

                                                                name='manager_notes' />

                                                            <h3 id='formSecondHeadline' className="h5 text-center mb-"></h3>
                                                        </div>
                                                    )
                                                })
                                            }


                                        </form>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>

                            {/* <hr /> */}
                            <div className="card-header bg-dark text-light">
                                בחר/י מוצרים&nbsp;
                                <i className="fa fa-shopping-cart" aria-hidden="true" />
                                {/* <a href className="btn btn-outline-info btn-sm pull-right">המשך להזמנה</a> */}
                                <div className="clearfix" />
                            </div>
                            <br />
                            {
                                // this.state.totalPrice > 0 ?
                                this.state.OrderId !== '' ?
                                    <div className="card-body">
                                        <UpdateOrderProducts
                                            // InActiveProductsFromDelivery={delivery.InActiveProducts}
                                            deliveryPlaces={delivery.Places}
                                            place={this.state.place != '' ? this.state.place :
                                                delivery.Places.length === 1 ?
                                                    delivery.Places[0].Address
                                                    : ''
                                            }
                                            OrderId={this.state.OrderId}
                                            deliveryDateTime={delivery.DeliveryDateTime}
                                            // groupName={this.props.groups[0].Name}
                                            payBoxLink={delivery.PayboxGroupLink}
                                            productsFromDelivery={delivery.Products}
                                            groupCode={this.state.groupCode}
                                            orderNumber={this.state.orderNumber}
                                            deliveryId={this.state.deliveryId}
                                            firstName={this.state.firstName}
                                            lastName={this.state.lastName}
                                            email={this.state.email}
                                            phone={this.state.phone}
                                            comment={this.state.comment}
                                            internalComments={this.state.internalComments}
                                            paymentChecked={this.state.paymentChecked}
                                            isPaid={this.state.isPaid}
                                            productsFromOrder={this.state.products}
                                            totalPrice={this.state.totalPrice}
                                            city={this.state.city}
                                            address={this.state.address}
                                            hour={this.state.hour}
                                            additionalPhone={this.state.additionalPhone}

                                        />
                                    </div>
                                    : <h5 className='text-center'>טוען מוצרים...</h5 >
                            }

                            {/* <Form/> */}

                        </div>
                    </div>
                </div>
            </div >
        )




    })


    render() {
        return (
            <div className='container-fluid' id='myContainerFluid' >
                {
                    this.state.firstName != '' ?
                        this.loadGroups()
                        : <h5 className='text-center'>טוען מידע...</h5 >
                }
            </div >
        )
    }

}
const mapStateToProps = (state) => {
    return {
        deliveries: state.deliveriesReducer.deliveries,
        groups: state.groupReducer.groups,
        orders: state.groupOrdersReducer.orders,
        onlineOrders: state.onlineOrdersReducer.onlineOrders,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOnlineOrderByGroupDeliveryIdAndOrderNumber,
            GetGroupDeliveryById,
            getGroupByCode,
            getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber,
            updateGroupOrderPaidStatus
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrder);

