import React, { lazy, Suspense, useState } from "react";
// import NotFound from './components/NotFound'

// import Home from "./pages/Home/Home";
// import Gallery from "./pages/Gallery/Gallery";
// import Contact from "./pages/Contact/Contact";
// import About_us from "./pages/About_Us/About_Us";
// import Our_Vegtables from "./pages/Our_Vegtables/Our_Vegtables";
// import Reservation from "./pages/Reservation/Reservation";
// import AdminPage from "./pages/Admin/Admin";

import Nav from "./components/Nav";
import Footer from "./components/Footer";



// import FormOfGroup from './pages/Admin/groups/form/FormOfGroup'


import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NavigationBtn from "./components/NavigationBtn";

import Login from "./pages/Admin/Login";
import LoadForm from "./pages/Admin/form/LoadForm";
import GroupDeliveries from "./pages/Admin/groups/GroupDeliveries";
import GroupDeliveriesStatitstics from "./pages/Admin/groups/GroupDeliveriesStatitstics";
import FormLastPage from './pages/Admin/form/FormLastPage'
import GroupsList from "./pages/Admin/groups/GroupsList";
import CreateDelivery from "./pages/Admin/delivery/CreateDelivery";
import CreateGroup from "./pages/Admin/groups/CreateGroup";
import CreateProduct from "./pages/Admin/products/CreateProduct";
import UpdateGroup from "./pages/Admin/groups/UpdateGroup";
import DeliveriesList from "./pages/Admin/delivery/DeliveriesList";
import UpdateDelivery from "./pages/Admin/delivery/UpdateDelivery";
import SendMessege from './pages/Admin/delivery/SendMessege'
import UserUpdateOrder from "./pages/Admin/orders/update_order/UserUpdateOrder";
import GetUseres from "./pages/Admin/useres/GetUseres";
import CreateUser from './pages/Admin/useres/CreateUser'
import WeekOrdersSummary from "./pages/Admin/orders/WeekOrdersSummary"
import "bootstrap/dist/css/bootstrap.css";
import "../src/App.css";
// import FormFields from "./pages/Admin/form/FormFields";
import ProductsList from "./pages/Admin/products/ProductsList";
import UpdateProductByID from "./pages/Admin/products/UpdateProductByID";
import LoadDeliveryOrders from "./pages/Admin/orders/show_orders/admin_view/LoadDeliveryOrders";
import FormStatistics from "./pages/Admin/orders/show_orders/admin_view/FormStatistics";
import ManagerLoadDeliveryOrders from "./pages/Admin/orders/show_orders/manager_view/ManagerLoadDeliveryOrders"
import AdminUpdateOrder from "./pages/Admin/orders/update_order/AdminUpdateOrder";
import SendMessageAboutOrder from './pages/Admin/orders/show_orders/admin_view/Messege/SendMessageAboutOrder'
import Statistics from './pages/Admin/statistics/Statistics'
import Cart from "./pages/Cart/Cart";
import Reports from "./pages/Reports/Reports";

import AddUsers from "./pages/Admin/users/AddUsers";
import UsersUnSubscribe from "./pages/Admin/users/UsersUnSubscribe";
import LastPage from "./pages/Admin/onLine/orders/userUpdateForm.js/LastPage";
import AllUsers from "./pages/Admin/users/AllUsers";
import SendMail from "./pages/Admin/users/SendMail";
import ImportUsers from "./pages/Admin/users/ImportUsers";

//Online Orders
import GetOnlineDelivery from "./pages/Admin/onLine/GetOnlineDelivery";
import CreateOnlineDelivery from "./pages/Admin/onLine/CreateOnlineDelivery";
import UpdateOnlineDelivery from "./pages/Admin/onLine/UpdateOnlineDelivery";
import OrdersLoad from "./pages/Admin/onLine/orders/OrdersLoad";
import UpdateOrder2 from "./pages/Admin/onLine/orders/update_order/UpdateOrder";
import UserUpdateOnlineOrder from "./pages/Admin/onLine/orders/userUpdateForm.js/UserUpdateOnlineOrder";
import PreparationList from "./pages/Admin/orders/preparation/PreparationList";
import PreparationOrder from "./pages/Admin/orders/preparation/PreparationOrder/PreparationOrder";
import MakingOrdersBtn from "./components/MakingOrdersBtn/MakingOrdersBtn";
import LeftSideMenu from "./components/LeftSideMenu/LeftSideMenu";

// import DinamicReservation from './pages/Reservation/DinamicReservation/DinamicReservation'

const Home = lazy(() => import("./pages/Home/Home"));
const Gallery = lazy(() => import("./pages/Gallery/Gallery"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const About_us = lazy(() => import("./pages/About_Us/About_Us"));
const Our_Vegtables = lazy(() => import("./pages/Our_Vegtables/Our_Vegtables"));
const Reservation = lazy(() => import("./pages/Reservation/Reservation"));
const AdminPage = lazy(() => import("./pages/Admin/Admin"));

// const Nav = lazy(() => import("./components/Nav"));
// const Footer = lazy(() => import("./components/Footer"));

// import FormContainer from "./pages/Reservation/FormContainer";
function App() {

  const [showLeftMenu, setShowLeftMenu] = useState(false)

  return (
    <div>
      <Router>

        <Nav />
        {/* <Router> */}
        <Switch>
          <Suspense fallback={<div>טוען...</div>}>

            {/* דפי מידע */}
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About_us} />
            <Route exact path="/vegeatbles" component={Our_Vegtables} />
            <Route exact path="/reservation" component={Reservation} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/contact" component={Contact} />
            {/* <Route exact path='/form_group' component={FormOfGroup} /> */}
            {/* </Suspense> */}
            {/* <Route component={NotFound} /> */}

            {/*דפי ממשק ניהול */}
            <Route exact path="/login" component={Login} />
            <Route exact path="/admin" component={AdminPage} />

            {/* טופס קבוצות */}
            <Route exact path="/groups/:groupCode/:deliveryId" component={LoadForm} />
            <Route exact path={`/groups/:groupCode/:deliveryId/:regId/thanks`} component={FormLastPage} />
            <Route exact path="/groups/update/:groupCode/:deliveryId/:orderNumber" component={UserUpdateOrder} />

            {/* טופס קבוצות פנימי*/}
            <Route exact path="/groups/admin-form/:groupCode/:deliveryId/:isAdminForm" component={LoadForm} />

            {/* מוצרים */}
            <Route exact path="/admin/products" component={ProductsList} />
            <Route exact path="/admin/products/create-product/" component={CreateProduct} />
            <Route exact path="/admin/products/edit/:productCode" component={UpdateProductByID} />

            {/* הזמנות */}
            <Route exact path="/admin/deliveries" component={DeliveriesList} />
            <Route exact path="/admin/deliveries/create-delivery/" component={CreateDelivery} />
            <Route exact path="/admin/deliveries/edit/:groupCode/:deliveryId" component={UpdateDelivery} />
            <Route exact path="/admin/deliveries/edit/:groupCode/:deliveryId/statistics" component={FormStatistics} />

            <Route exact path="/admin/deliveries/send-messege/:groupCode/:deliveryId" component={SendMessege} />
            <Route exact path="/admin/deliveries/groups-orders/:groupCode/:deliveryId" component={LoadDeliveryOrders} />
            <Route exact path="/admin/deliveries/groups-orders/update/:groupCode/:deliveryId/:orderNumber" component={AdminUpdateOrder} />
            <Route exact path="/admin/deliveries/groups-orders/send-message" component={SendMessageAboutOrder} />

            {/* הכנת הזמנות */}
            <Route exact path="/admin/deliveries/preparation/:groupCode/:deliveryId" component={PreparationList} />
            <Route exact path="/admin/deliveries/preparation/:groupCode/:deliveryId/:index" component={PreparationOrder} />


            {/* הזמנות אונליין */}
            <Route exact path="/admin/online/online-delivery" component={GetOnlineDelivery} />
            <Route exact path="/admin/online/create-delivery" component={CreateOnlineDelivery} />
            <Route exact path="/admin/online/update-delivery/:groupCode/:deliveryId" component={UpdateOnlineDelivery} />
            <Route exact path="/admin/online/orders/:groupCode/:deliveryId" component={OrdersLoad} />
            <Route exact path="/admin/online/orders/update/:groupCode/:deliveryId/:orderNumber" component={UpdateOrder2} />
            <Route exact path="/online/orders/userUpdate/:groupCode/:deliveryId/:orderNumber" component={UserUpdateOnlineOrder} />
            <Route exact path={`/online/orders/:groupCode/:deliveryId/:orderNumber/thanks`} component={LastPage} />

            {/* ניהול משתמשים */}
            <Route exact path="/admin/users/add" component={AddUsers} />
            <Route exact path="/admin/users/import" component={ImportUsers} />

            <Route exact path="/emails/removed/:emailId/" component={UsersUnSubscribe} />
            <Route exact path="/admin/users" component={AllUsers} />
            <Route exact path="/admin/users/messege" component={SendMail} />


            {/* עגלת קניות */}
            <Route exact path="/cart" component={Cart} />

            {/* תצוגת מנהל קבוצה */}
            <Route exact path="/admin/deliveries/groups-orders/manager/:groupCode/:deliveryId/" component={ManagerLoadDeliveryOrders} />

            {/* קבוצות   */}
            <Route exact path="/admin/groups/" component={GroupsList} />
            <Route exact path="/admin/groups/create-group/" component={CreateGroup} />
            <Route exact path="/admin/groups/edit/:groupCode/" component={UpdateGroup} />
            <Route exact path={`/admin/groups/groups-orders/:groupCode/`} component={GroupDeliveries} />
            <Route exact path={`/admin/groups/groups-orders/statistics/:groupCode/`} component={GroupDeliveriesStatitstics} />

            {/* סיכום שבועי */}
            <Route exact path="/admin/groups-orders/" component={WeekOrdersSummary} />

            {/* מחשבון הכנסות */}
            <Route exact path="/admin/statistics" component={Statistics} />

            {/*סטטיסטיקות */}
            <Route exact path="/admin/reports" component={Reports} />


            {/* דף בבניה */}
            {/* <Route exact path="/admin/users" component={GetUseres} />
            <Route exact path="/admin/users/create-users" component={CreateUser} /> */}
          </Suspense>

        </Switch>
        {/* </Router> */}
        <Footer />
        <NavigationBtn />
        <MakingOrdersBtn setShowLeftMenu={setShowLeftMenu} showLeftMenu={showLeftMenu} />
        {showLeftMenu && <LeftSideMenu />}
      </Router>
    </div>
  );
}
export default App;
