import React, { useEffect, useState, useRef } from "react";
import { useTable, useSortBy } from "react-table";
import { MDBCardBody, MDBRow, MDBCol } from 'mdbreact';
import { ContextMenuTrigger } from "react-contextmenu";
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone'

//components
import SerachInout from '../../../../../components/SerachInout'
import Print from '../../../../../components/Print'
import MyModal from '../../../../../components/MyModal'
import DownloadBtn from '../../../../../components/DownloadBtn'
import Tooltip from '../../../../../components/Tooltip/Tooltip'
import GetHourByDuration from '../../../../../components/GetHourByDuration'
import MyContextMenu from '../../../../../components/ContextMenu/MyContextMenu'
import RoundedButton from "../../../../../components/RoundedButton/RoundedButton";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { changeGroupDeliveryStatus, getGroupOrdersByGroupDeliveryId, updateGroupOrderPaidStatus } from "../../../../../redux/groupOrders/group-orders-action";
import { deleteGroupOrderByOrderId } from "../../../../../redux/groupOrders/group-orders-action";

//css
import "../groupOrdersSummary.css";
import { updateFilteredProduct } from "../../../../../redux/deliveries/deliveries-actions";


// const mapStateToProps = (state) => {
//   return {
//     // orders: state.groupOrdersReducer.orders,
//     // deliveries: state.deliveriesReducer.deliveries,
//     // products: state.productReducer.products,
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deleteGroupOrderByOrderId,
      getGroupOrdersByGroupDeliveryId,
      changeGroupDeliveryStatus,
      updateGroupOrderPaidStatus,
      updateFilteredProduct
    },
    dispatch
  );
};

function ShowDeliveryOrders({ columns, data, updateData, groupName, delivery, groupCode, csvData }) {
  const [deliveryDate, setDeliveryDate] = useState('')
  const [deliveryHour, setDeliveryHour] = useState('')
  const [modalShow, setModalShow] = useState(false)
  // const [orderId, setOrderId] = useState('')
  const [activeStatus, setActiveStatus] = useState(null)
  const [statusText, setStatusText] = useState(null)
  const [disabled, setDisabled] = useState("disabled")
  const [ordersForChanges, setOrdersForChanges] = useState([])
  const [ordersNumbersForChanges, setOrdersNumbersForChanges] = useState([])
  const [isOrderCheckd, seIsOrderCheckd] = useState(true)
  const [headlineTextForModal, setHeadlineTextForModal] = useState(false)
  const [valueOfAction, setValueOfAction] = useState("")
  const [buttonText, setButtonText] = useState("")
  const [displayArrowButtons, setDisplayArrowButtons] = useState("block")
  const [floatedMenuOrderUpdate, seFloatedMenuOrderUpdate] = useState(null)
  const [floatedMenuorderSendMessage, seFloatedMenuorderSendMessage] = useState(null)
  const [floatedMenuOrderDelete, setFloatedMenuOrderDelete] = useState(null)
  const [formURL, setFormURL] = useState("")
  const [lastFormPage, setLastFormPage] = useState("")
  const [token, setToken] = useState(null)
  const componentRef = useRef();
  const history = useHistory()
  const dispatch = useDispatch();
  // let myRef = React.createRef()



  const totals = useSelector((state) => state.deliveriesReducer.totalPricesForDelivery)
  const filteredProduct = useSelector((state) => state.deliveriesReducer.filteredProduct)
  useEffect(() => {
    // console.log('delivery Date', delivery.DeliveryDateTime)

    if (delivery != undefined) {
      if (localStorage.getItem("token")) {
        setToken("manager")
        setDisabled("")
      }
      else if (sessionStorage.getItem("token")) {
        setToken("manager")
        setDisabled("")
      }

      // console.log('props.history', history.location.state.totalPrice)
      try {
        setDeliveryDate(new Date(delivery.DeliveryDateTime).toLocaleDateString('he-IL', { hour12: false }))
        setActiveStatus(delivery.IsActive)
        { delivery.IsActive ? setStatusText("פתוח") : setStatusText("סגור") }
        // setDeliveryHour(new Date(delivery.DeliveryDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))
        setDeliveryHour(moment(new Date(delivery.DeliveryDateTime).format('HH:mm')))
        document.getElementById('myNav').style.display = 'block';


      } catch (error) {

      }
      // console.log('delivery', delivery);
      let linkFormLink = `meshekalmog.co.il/groups/${delivery.GroupCode}/${delivery._id}`
      // console.log('linkFormLink', linkFormLink);
      var params = {
        "ds3.formurl": linkFormLink
      };
      let paramsAsString = JSON.stringify(params);
      let encodedParamsConverted = encodeURIComponent(paramsAsString)
      // console.log('encodedParamsConverted', encodedParamsConverted);

      setFormURL(encodedParamsConverted)
    }

  }, [delivery])

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const actionAndUpdate = () => {

    // console.log("valueOfAction", valueOfAction)

    //change payment status of order
    if (valueOfAction == 1) {
      updateOrderStatus()
    }
    //delete order
    else if (valueOfAction == 3) {
      deleteOrder()
    }
    // dispatch(deleteGroupOrderByOrderId(orderId));
    dispatch(getGroupOrdersByGroupDeliveryId()).then(() => {
      updateData();
      setModalShow(false)
    });
  }

  const updateOrderStatus = () => {
    ordersNumbersForChanges.map(order => {

      dispatch(updateGroupOrderPaidStatus({
        GroupDeliveryId: delivery._id,
        OrderNumber: order,
      }));

    })
  }
  const deleteOrder = () => {
    // console.log("in delete")

    ordersForChanges.map(order => {
      // console.log("order", order)
      dispatch(deleteGroupOrderByOrderId(order));

    })

  }

  const setDeliveryFormStatus = (e) => {

    let objectPost = {
      "Id": delivery._id
    }

    dispatch(changeGroupDeliveryStatus(objectPost))

    setActiveStatus(e.target.checked)
    if (e.target.checked)
      setStatusText("פתוח")
    else
      setStatusText("סגור")

  }

  const handleFilterByProduct = (event) => {
    const { value } = event.target;
    console.log(value);
    dispatch(updateFilteredProduct(value == 0 ? null : value));

  }

  const loadData = () => {
    let count = data.length
    return (

      <div className='firstDivAfterNav' style={{ background: "white" }}>

        <MDBRow key={delivery._id}>
          <MDBCol md="12" style={{ margin: 'auto' }}>
            <MyContextMenu
              order={floatedMenuOrderUpdate}
              message={floatedMenuorderSendMessage}
              delete={floatedMenuOrderDelete}
              lastFormPage={lastFormPage}
            />
            {/* <MDBCard> */}
            <MDBCardBody>
              <div className='text-center'>
                <h2 className='text-center font-weight-bold' style={{ display: 'inline' }}> {groupName} |&nbsp;
                  {delivery.Places.length > 0 ? '' : delivery.Address + " | "}
                  {deliveryDate}   |&nbsp;
                </h2>
                <h3 className='text-center' style={{ display: 'inline' }}>
                  <label id='myCheckbox'>
                    {delivery.IsActive ?
                      <span className='myCheckbox'> רישום {statusText}</span>
                      : <span className='myCheckbox'>רישום {statusText}</span>
                    }
                    &nbsp;<input disabled={disabled} style={{ width: '20px', height: '20px' }} value={'close'} type="checkbox" checked={activeStatus} onChange={(e) => setDeliveryFormStatus(e)}></input>
                  </label>
                </h3>

                <br />
                <div id='test' style={{ display: 'inline-block', textAlign: 'right' }}>

                  {

                    delivery.Places.map((place, index) => {
                      // console.log("place", place)
                      if (delivery.Places.length === 1) {
                        return <p key={place._id}>
                          <span>
                            &nbsp;&nbsp;<span className="font-italic">
                              {/* {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                              {moment(new Date(place.Hour)).tz("Asia/Jerusalem").format('HH:mm')}
                              -{<GetHourByDuration
                                hour={place.Hour}
                                duration={place.Duration}
                              />}</span>
                          </span>
                          <b> {place.Address}</b>
                        </p>
                      }
                      else {
                        return <li key={place._id}>
                          <p style={{ display: 'inline' }}>
                            <span>
                              &nbsp;&nbsp;<span className="font-italic">
                                {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                -{<GetHourByDuration
                                  hour={place.Hour}
                                  duration={place.Duration}
                                />}</span>
                            </span>
                            <b> {place.Address}</b>

                          </p>
                        </li>
                      }

                    })

                  }

                </div>
              </div >
              {
                delivery.PayboxGroupLink !== null && delivery.PayboxGroupLink !== '' &&
                <div className="col-sm-12 text-center" style={{ margin: 'auto' }}>
                  <Tooltip text="Paybox">
                    <img src='../../../../../img/Paybox.png'
                      style={{ width: '40px' }} alt="payBox"
                      onClick={() => window.open(`${delivery.PayboxGroupLink}`)}
                    />&nbsp;
                  </Tooltip>
                </div>
              }
              <div className='col-sm-6 roudedButtonsAboveTable' style={{ margin: 'auto', textAlign: 'center' }}>

                <Tooltip text="עריכת טופס רישום">
                  <RoundedButton
                    disabled={disabled}
                    icon="fas fa-edit"
                    onClick={() => rowClicked(`/admin/deliveries/edit/${groupCode}/${delivery._id}`)}
                  >
                  </RoundedButton>
                </Tooltip>

                <button className='rounded-button' style={{ float: 'right' }}
                  onClick={() => rowClicked('/admin/deliveries')}>
                  <Tooltip text="חזרה">
                    <i className="fas fa-chevron-circle-right"></i>
                  </Tooltip>
                </button>
                <Tooltip text="טופס רישום">
                  <RoundedButton
                    icon="fas fa-solid fa-users"
                    onClick={() => rowClicked(`/groups/${groupCode}/${delivery._id}`, true)}
                  >
                  </RoundedButton>
                </Tooltip>

                <Tooltip text="טופס פנימי">
                  <RoundedButton
                    icon="fas fas fa-solid fa-lock"
                    onClick={() => rowClicked(`/groups/admin-form/${groupCode}/${delivery._id}/1`, false)}
                  >
                  </RoundedButton>
                </Tooltip>


                <Tooltip text={'שיתוף'}>
                  <button id='clipBoard' className='rounded-button' color='tw' style={{ display: 'inline' }}
                    onClick={() => rowClicked(`/admin/deliveries/send-messege/${delivery.GroupCode}/${delivery._id}`)}
                  >
                    <i className="fas fa-share-alt"></i>
                  </button>

                </Tooltip>

                <Tooltip text="הורדה">
                  <DownloadBtn csvData={csvData} name={`${groupName} ${deliveryDate}`} className='col-sm-2' />
                </Tooltip>
                <Tooltip text="הדפסה">
                  <Print className='col-sm-2'
                    titleToPrint={
                      groupName + ", " + delivery.Address + ", " + deliveryDate + ",  " + deliveryHour
                    }
                    componentRef={componentRef.current}
                  />
                </Tooltip>

                <Tooltip text="תצוגת מנהל">
                  <RoundedButton
                    icon="fas fa-address-card"
                    onClick={() => rowClicked(`/admin/deliveries/groups-orders/manager/${groupCode}/${delivery._id}`)}
                  >
                  </RoundedButton>
                </Tooltip>

                <Tooltip text="סטטיסטיקות">
                  <RoundedButton
                    icon="fas fa-solid fa-chart-line"
                    onClick={() => history.push(`/admin/deliveries/edit/${groupCode}/${delivery._id}/statistics`, { formURL })}
                  >
                  </RoundedButton>
                </Tooltip>

                <Tooltip text="הכנת הזמנות">
                  <RoundedButton
                    icon="fa fa-shopping-cart"
                    onClick={() => history.push(`/admin/deliveries/preparation/${groupCode}/${delivery._id}`)}
                  >
                  </RoundedButton>
                </Tooltip>
                {/* <Link
                  to={{
                    pathname: `/admin/deliveries/edit/:groupCode/${delivery._id}/statistics`,
                    state: { formURL: formURL }
                  }}
                >סטטיסטיקות</Link> */}

              </div>

              {/* 
        <button onClick={() => downloadToCsv()}>
          <i className='fa fa-download col-sm-4 text-center'></i>
        </button> */}


              {
                data.length !== 1 ?
                  <div>
                    <SerachInout />
                    {/* <div className='col-sm-12 arrowsOfTable'>
          <a href={`#header0`}> <button style={{ float: 'right' }}><i class="fas fa-arrow-right fa-2x" ></i></button></a>
          <a href={`#header${headerGroups[0].headers.length - 1}`}> <button style={{ float: 'left' }} ><i class="fas fa-arrow-left fa-2x"></i></button></a>
        </div > */}


                    <div className="d-flex filter-actions">
                      <select
                        style={{ display: 'inline-block' }} onChange={(e) => setActionsFromSelected(e)} disabled={isOrderCheckd}>
                        <option value="0">בחר/י פעולה</option>
                        <option value="1">שינוי סטאטוס תשלום </option>
                        <option value="2">שליחת הודעה </option>
                        {/* <option value="3">עריכת סה"כ לתשלום</option> */}
                        <option value="3">מחיקה</option>
                      </select>
                      <select
                        className="mx-2"
                        style={{ display: 'inline-block' }}
                        onChange={handleFilterByProduct}
                        value={filteredProduct}
                      >
                        <option value="0">סינון לפי מוצר</option>
                        {
                          totals.Products && totals.Products.length > 0 &&
                          totals.Products
                            .filter(product => product.Amount > 0)
                            .map(product => {
                              return <option
                                key={product.Code}
                                value={product.Name}
                              >
                                {product.Name}
                              </option>
                            })
                        }
                      </select>
                    </div>
                    <div className='table-responsive'>

                      <table id="dataTable"
                        className="ordersSummaryTable table table-striped col-sm-12 text-center tableOfOrders staticTopRowTable"
                        // ref={myRef}
                        {...getTableProps()} ref={componentRef}>
                        <thead className="thead-dark">
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              <th></th>
                              <th className="actionsCB">
                                <input type="checkbox" style={{ transform: 'scale(1.5)' }}
                                  onChange={(e) => setAllOrderForChange(e, rows)}>
                                </input>
                              </th>
                              {

                                headerGroup.headers.map((column, i) => (
                                  <th {...column.getHeaderProps(column.getSortByToggleProps())} id={`header${i}`}>
                                    {column.render("Header")}
                                    {/* {console.log('column', column)} */}
                                    <span>
                                      {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                    </span>
                                  </th>
                                ))
                              }
                            </tr >
                          ))}
                        </thead >
                        <tbody {...getTableBodyProps()}>
                          {rows
                            .filter(r => filteredProduct ? r.values[filteredProduct] > 0 : r)
                            .map((row, i) => {
                              count--
                              // data.length--
                              if (i == data.length - 1) {
                                // LAST ROW - TOTALS
                                // return (
                                //   <tr>
                                //     {Object.keys(row.original).map(function (key, i) {
                                //       if (i == 0) {
                                //         return <td>{row.original[i]}</td>;
                                //       }
                                //       return <td>{row.original[i]}</td>;
                                //     })}
                                //   </tr>
                                // );
                                return;
                              }
                              // const dianamicOrderId = row.original.Id;
                              // console.log("row", row.original)
                              const orderNumber = row.original.orderNumber
                              const orderID = row.original.Id;
                              // console.log('row.original', row.original["שם מלא"])
                              // let isPaid = row.original.IsPaid
                              // console.log("row: ", row.original);
                              //   if (i == 0) {
                              //     console.log("row: ", row.original.Id);
                              //     setOrderId(row.original.Id);
                              //     return;
                              //   }

                              prepareRow(row);
                              // console.log("row", row)
                              return (
                                <ContextMenuTrigger id="some_unique_identifier">
                                  <tr {...row.getRowProps()}
                                    onClick={(e) => setClickableRow(e, `/admin/deliveries/groups-orders/update/${groupCode}/${delivery._id}/${orderNumber}`)}
                                    onContextMenu={() =>
                                    // alert("test")
                                    {
                                      seFloatedMenuOrderUpdate(`/admin/deliveries/groups-orders/update/${groupCode}/${delivery._id}/${orderNumber}`)
                                      seFloatedMenuorderSendMessage(
                                        {
                                          link: "/admin/deliveries/groups-orders/send-message",
                                          orders: orderNumber,
                                          deliveryId: delivery._id,
                                          groupName: groupName
                                        }
                                      )
                                      setLastFormPage(`/groups/${groupCode}/${delivery._id}/${orderNumber}/thanks#`)
                                    }
                                      // seFloatedMenuorderDelete()

                                    }
                                  >
                                    <td>{count}</td>
                                    <td className="actionsCB">
                                      <input id={`order_${orderID}_checkbox`} style={{ transform: 'scale(1.5)' }}
                                        type="checkbox" onChange={(e) => setOrderForChange(e, orderID, orderNumber)}>

                                      </input>
                                    </td>
                                    {/* <td className='roundedBtn toolTipInTable'>
                                  <Tooltip text="עריכה">
                                    <RoundedButton
                                      icon="fas fa-edit"
                                      onClick={() => rowClicked(`/ admin / deliveries / groups - orders / update / ${ groupCode } / ${ delivery._id } / ${ orderNumber }`)}

                                    />
                                  </Tooltip>
                                  <Tooltip text="מחיקה">
                                    <RoundedButton
                                      icon="fas fa-trash-alt"
                                      onClick={
                                        () => {
                                          setOrderId(dianamicOrderId)
                                          setModalShow(true)
                                        }
                                      }
                                    />
                                  </Tooltip>
                                </td> */}
                                    {/* {setClinetName(row.original["שם מלא"])} */}

                                    {row.cells.map((cell, index) => {
                                      if (index === 0) {
                                        {
                                          return window.screen.width < 800 ?
                                            <td className="sticky-columnForOrders" {...cell.getCellProps()}>
                                              <a href={`/groups/${groupCode}/${delivery._id}/${orderNumber}/thanks#`} target="_blank"> {cell.render("Cell")}</a>
                                            </td>
                                            : <td  {...cell.getCellProps()} className="last-page-form">
                                              <a href={`/groups/${groupCode}/${delivery._id}/${orderNumber}/thanks#`} target="_blank"> {cell.render("Cell")}</a>
                                            </td>;

                                        }
                                      }
                                      else if (index !== row.cells.length - 1)
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                      else
                                        return <td{...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}

                                  </tr>
                                </ContextMenuTrigger>
                              );
                            })}
                        </tbody>
                      </table >
                    </div>

                    <br />
                    <h2 className='text-center font-weight-bold'>סיכום הזמנות</h2>
                    <div className='table-responsive'>
                      <table id='dataTable' className=" table table-striped col-sm-12 text-center tableOfOrders">
                        <thead className="thead-dark">
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              <th></th>
                              {/* {headerGroup.headers.map((column, index) =>
                                (index > 5 && index != headerGroup.headers.length - 1 && index != headerGroup.headers.length - 3 && index != headerGroup.headers.length - 4) ?
                                  (
                                    <th {...column.getHeaderProps()}>
                                      {column.render("Header")}

                                    </th>
                                  )
                                  : ''
                              )
                              } */}
                              <th className="font-weight-bold " style={{ width: '70px' }}>סה"כ</th>

                              {totals.Products &&
                                totals.Products.map((p) => {
                                  return <th className="font-weight-bold" style={{ width: '70px' }}>{p.Name}</th>
                                })}

                            </tr >
                          ))}
                        </thead >
                        <tbody>

                          <tr>

                            {/* {Object.keys(rows[rows.length - 1].original).map(function (key, i) {

                              //  console.log('rows', rows[rows.length - 1])
                               console.log("totals in map: ", totals)
                              // console.log('i', i)
                              if (i == 0) {
                                return <td>{rows[rows.length - 1].original[i]}</td>;
                              }

                              else if (i > 5 && i < rows[rows.length - 1].original.length - 1)
                                return <td>{rows[rows.length - 1].original[i]}</td>

                              else if (i === rows[rows.length - 1].original.length - 1)
                                return <td>{rows[rows.length - 1].original[rows[rows.length-1].original.length]}</td>
                            })
                            } */}
                            <td>סה"כ</td>
                            <td className="font-weight-bold sticky-columnForOrders">{totals.TotalPrice} ₪</td>

                            {totals.Products &&
                              totals.Products.map((p) => {
                                return <td>{p.Amount}</td>
                              })}



                          </tr>


                        </tbody>
                      </table>



                    </div>
                  </div>
                  : <>
                    <br />
                    <h4 className='text-center font-weight-bold'>לא קיימים רישומים לטופס זה</h4>
                  </>
              }


              <MyModal />

              <MyModal
                headlineText={headlineTextForModal}
                show={modalShow}
                onHide={() => setModalShow(false)}
                ButtonText='ביטול'
                ButtonDeleteText={buttonText}
                ButtonDinamicFunction={() => actionAndUpdate()}
              />


            </MDBCardBody>
            {/* </MDBCard> */}
          </MDBCol >
        </MDBRow >
      </div >

    )
  }
  const setActionsFromSelected = (e) => {
    // console.log(e.target.value)
    if (e.target.value == 1) {
      setHeadlineTextForModal(`האם ברצונך לשנות סטאטוס תשלום להזמנות המסומנות`)
      setButtonText("עדכן")
      setValueOfAction(1)
      setModalShow(true)
    }
    else if (e.target.value == 2) {
      history.push("/admin/deliveries/groups-orders/send-message", { orders: ordersNumbersForChanges, deliveryId: delivery._id, groupName: groupName })
    }
    else if (e.target.value == 3) {
      setHeadlineTextForModal(`האם ברצונך למחוק את ההזמנות המסומנות`)
      setButtonText("מחק")
      setValueOfAction(3)
      setModalShow(true)
    }

  }
  const setOrderForChange = (e, orderId, orderNumber) => {
    if (e.target.checked) {
      seIsOrderCheckd(false)

      ordersForChanges.push(orderId)
      ordersNumbersForChanges.push(orderNumber)
    }
    else {

      try {
        document.getElementById(`order${orderNumber}`).disabled = true
      } catch (error) {

      }

      for (let index = 0; index < ordersForChanges.length; index++) {

        if (ordersForChanges[index] == orderId) {
          ordersForChanges.splice(index, 1)
          ordersNumbersForChanges.splice(index, 1)
          if (ordersForChanges.length == 0)
            seIsOrderCheckd(true)

        }
      }

    }
    // console.log("ordersForChanges", ordersForChanges)
    // console.log("ordersNumbersForChanges", ordersNumbersForChanges)

  }
  const setAllOrderForChange = (e, orders) => {
    while (ordersForChanges.length) {
      ordersForChanges.pop()
      ordersNumbersForChanges.pop()
    }
    // console.log("ordersForChanges", ordersForChanges)

    orders.map((order, index) => {
      if (index < orders.length - 1) {


        if (e.target.checked) {
          seIsOrderCheckd(false)
          ordersForChanges.push(order.original.Id)
          ordersNumbersForChanges.push(order.original.orderNumber)
          document.getElementById(`order_${order.original.Id}_checkbox`).checked = "checked"

        }
        else {
          ordersForChanges.pop()
          ordersNumbersForChanges.pop()
          document.getElementById(`order_${order.original.Id}_checkbox`).checked = ""
          seIsOrderCheckd(true)

        }

      }
    })

    // console.log("ordersForChanges", ordersForChanges)
    // console.log("ordersNumbersForChanges", ordersNumbersForChanges)

  }

  const setClickableRow = (e, link) => {
    console.log('e.target', e.target.tagName)
    if (e.target.tagName !== 'INPUT' && e.target.className !== "actionsCB" && e.target.tagName !== "SPAN" && e.target.className !== "last-page-form")
      rowClicked(link)
  }

  const rowClicked = (link, openInNew) => {
    if (openInNew)
      window.open(link)
    else
      history.push(link)
  }

  // Render the UI for your table
  return (

    data.length > 0 ?



      delivery !== null ?
        token !== "manager" ?
          <div className='firstDivAfterNav' style={{ background: "white" }}>
            <h2 className='text-center font-weight-bold' style={{ paddingTop: '150px', paddingBottom: '150px' }}>
              אין באפשרותך לצפות בעמוד זה</h2>
          </div>
          : loadData()
        : ''
      :
      <div className='firstDivAfterNav' style={{ background: "white" }}>
        <h2 className='text-center font-weight-bold' style={{ padding: '150px' }}>טוען...</h2>
      </div>



  );

}
const mapStateToProps = (state) => {
  return {
    totals: state.deliveriesReducer.totals
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowDeliveryOrders);
