const initialState = {
    onlineOrders: [],
    currentOnlineOrderNum: 0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case "ADD_ONLINE_ORDER":
            return { ...state, currentOnlineOrderNum: action.payload[0].OrderNumber };

        case "GET_GROUP_ONLINE_ORDERS":
            return { ...state, onlineOrders: action.payload };

        case "GET_ONLINE_ORDER_BY_GROUP_DELIVERY_ID_AND_ORDER_NUMBER":
            return { ...state, onlineOrders: action.payload };

        case "UPDATE_GROUP_ONLINE_ORDER_BY_GROUP_DELIVERY_ID_AND_ORDER_NUMBER":
            return { ...state }

        case "UPDATE_GROUP_ORDER_PAID_STATUS":
            return { ...state }

        case "CHANGE_GROUP_DELIVERY_STATUS":
            return { ...state }

        case "DELETE_ONLINE_ORDER_BY_ID":
            return { ...state };
        case "SEND_GROUP_ORDER_EMAILS_OF_CHOSEN_ORDERS":
            return { ...state };

        default:
            return state;
    }
}
