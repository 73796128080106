import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBCardImage, MDBInput } from 'mdbreact';
import UpdateOrderProducts from '../../../onLine/orders/update_order/UpdateOrderProducts'
import GetLocalDayAndHour from '../../../../../components/GetLocalDayAndHour'
import GedDayOfWeek from '../../../../../components/GedDayOfWeek'
import parse from 'html-react-parser';
import { MDBAnimation } from "mdbreact";
import moment from 'moment-timezone'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetGroupDeliveryById } from "../../../../../redux/deliveries/deliveries-actions";
import { getGroupByCode } from "../../../../../redux/groups/groups-actions";
import { getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber, updateGroupOrderPaidStatus } from "../../../../../redux/groupOrders/group-orders-action";
import { getOnlineOrderByGroupDeliveryIdAndOrderNumber } from "../../../../../redux/onlineOrders/online-orders-action";
import Places from '../../../../../components/Places/Places';

class UserUpdateOnlineOrder extends React.Component {
    constructor() {
        super()
        this.state = {
            firstName: '',
            lastName: '',
            city: '',
            address: '',
            email: '',
            phone: '',
            additionalPhone: '',
            comment: '',
            internalComments: '',
            paymentChecked: '',
            approved: '',
            groups: [],
            isLoadingDeliveries: false,
            deliveryId: '',
            groupCode: '',
            orderNumber: '',
            products: [],
            totalPrice: '',
            isPaid: null,
            place: '',
            OrderId: '',
            isOrderPaid: false,
            display: "none"
        }
    }

    componentDidMount() {

        const { match } = this.props;
        document.title = `משק אלמוג - עריכת הזמנה`
        window.scrollTo(0, 0);
        document.getElementById('myNav').style.display = 'none'

        // console.log(match.params)

        this.setState({
            deliveryId: match.params.deliveryId,
            groupCode: match.params.groupCode,
            orderNumber: match.params.orderNumber,
        })

        this.setState({ groups: this.props.dataOfGroup })
        this.props.getGroupByCode(match.params.groupCode)

        this.props.getOnlineOrderByGroupDeliveryIdAndOrderNumber(match.params.deliveryId, match.params.orderNumber).then(() => {
            console.log('onlineOrders', this.props.onlineOrders)


            this.props.onlineOrders.map(order => {
                // console.log('order', order)
                this.setState({
                    hour: order.DeliveryTime,
                    city: order.PersonInfo.City,
                    address: order.PersonInfo.Address,
                    firstName: order.PersonInfo.FirstName,
                    lastName: order.PersonInfo.LastName,
                    email: order.PersonInfo.Email,
                    phone: order.PersonInfo.Phone,
                    additionalPhone: order.PersonInfo.AdditionalPhone,
                    comment: order.Comment,
                    internalComments: order.InternalComment,
                    paymentChecked: order.PaymentMethod,
                    totalPrice: order.TotalPrice,
                    isPaid: order.IsPaid,
                    place: order.Place,
                    OrderId: order._id,
                    isOrderPaid: order.IsPaid
                })
                // console.log('state', this.state)

            })

            this.setState({ products: this.props.onlineOrders[0].Products })
        })

        this.props.GetGroupDeliveryById(match.params.deliveryId).then(() => {
            this.setState({ isLoadingDeliveries: false })

        })



    }
    setCity = (cityFromInput) => {
        this.setState({ city: cityFromInput })
    }
    updatePaymentStatus = (e, OrderNumber) => {

        const { match } = this.props;

        this.setState({ isPaid: e.target.value })

        this.props.updateGroupOrderPaidStatus(match.params.deliveryId, OrderNumber).then(() => {
            // window.location.reload()
        })

    }
    //show the details about the chosen form
    loadGroups = () => this.props.deliveries.map(delivery => {

        document.title = `משק אלמוג - מהחקלאי ל${this.props.groups[0].Name}`
        console.log('delivery', delivery)

        if (delivery.IsActive) {
            return (

                <div key={delivery.id} className="container">
                    <div className="container">
                        {/* <h2 className='text-center font-weight-bold'>הזמנה</h2> */}
                        <div className="card shopping-cart text-center ">
                            <div>
                                <MDBContainer id='formContainer'>
                                    <MDBRow>
                                        <MDBCol md="8" style={{ margin: 'auto' }}>
                                            <form>

                                                <MDBCardImage
                                                    style={{ width: '20%', margin: 'auto' }}
                                                    hover
                                                    overlay='white-light'
                                                    className='card-img-top'
                                                    src={require('../../../../../components//img/almog.PNG')}
                                                    alt='man'
                                                />

                                                <h2 className='text-center font-weight-bold'> הזמנת ירקות ממשק אלמוג - עדכון הזמנת אונליין
                                                    {/* <br />  {delivery.Places.length === 0 && delivery.Places[0].Address} */}
                                                </h2>

                                                <h4 className='text-center font-weight-bold' style={{ color: '#827717' }}>
                                                    {/* יום <GedDayOfWeek dayOfWeek={delivery.DeliveryDateTime} />,&nbsp;
                                                    <GetLocalDayAndHour
                                                        style={{ display: 'inline' }}
                                                        dateOfDelivery={delivery.DeliveryDateTime}
                                                    /> */}
                                                    {delivery.Places.length === 0 &&
                                                        <>
                                                            &nbsp;בשעה&nbsp;
                                                            <GetLocalDayAndHour
                                                                style={{ display: 'inline' }}
                                                                hourOfDelivery={delivery.DeliveryDateTime}
                                                            />
                                                        </>
                                                    }
                                                </h4>


                                                <br />
                                                {/* <h3 id='formHeadline' className="h5 text-center mb-4 font-weight-bold"> עדכון הזמנה {this.state.orderNumber} </h3> */}

                                                {
                                                    delivery.Header !== undefined && delivery.Header !== null && delivery.Header !== '' ?
                                                        <div className="col-12 text-center" id="headerOfForm">
                                                            <hr />
                                                            {parse(`${delivery.Header}`)}
                                                            <hr />
                                                        </div>
                                                        : ''
                                                }
                                                <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>

                                                {

                                                    this.props.onlineOrders.map(order => {
                                                        let myDate = moment(order.DeliveryDate).format("DD/MM/YYYY")

                                                        return (
                                                            <div className="black-text" key={order.Id}>

                                                                {myDate}
                                                                <MDBInput label="* תאריך"
                                                                    valueDefault={myDate}
                                                                    onChange={e => this.setState({ deliveryDate: e.target.value })}
                                                                    required icon="hour" group type="date" validate
                                                                    error="wrong"
                                                                    success="right" />
                                                                <label>שעת המשלוח: {order.DeliveryTime}
                                                                </label>

                                                                <Places
                                                                    setAddress={this.setCity}
                                                                    addrress={this.state.city}
                                                                />

                                                                <MDBInput label="* רחוב ומספר בית"
                                                                    valueDefault={order.PersonInfo.Address}
                                                                    onChange={e => this.setState({ address: e.target.value })}
                                                                    required icon="home" group type="text"
                                                                    error="wrong" name="user_name"
                                                                    success="right" />

                                                                <MDBInput id='firstName'
                                                                    label="* שם פרטי"
                                                                    valueDefault={order.PersonInfo.FirstName}
                                                                    onChange={e => this.setState({ firstName: e.target.value })}
                                                                    required icon="user" group type="text" validate
                                                                    error="wrong" name="user_firstname"
                                                                    success="right"></MDBInput>

                                                                <MDBInput id='lastName'
                                                                    label="* שם משפחה"
                                                                    valueDefault={order.PersonInfo.LastName}
                                                                    onChange={e => this.setState({ lastName: e.target.value })}
                                                                    required icon="user" group type="text" validate
                                                                    error="wrong" name="user_lastname"
                                                                    success="right" />

                                                                <MDBInput id='email'
                                                                    label="* דואר אלקטרוני (לקבלת פירוט ההזמנה למייל)"
                                                                    valueDefault={order.PersonInfo.Email}
                                                                    onChange={e => this.setState({ email: e.target.value })}
                                                                    required icon="envelope" group type="email" validate
                                                                    error="wrong" name="user_email"
                                                                    success="right" />

                                                                <MDBInput id='phone'
                                                                    label="* טלפון נייד"
                                                                    valueDefault={order.PersonInfo.Phone}
                                                                    onChange={e => this.setState({ phone: e.target.value })}
                                                                    required icon="phone" group type="tel" validate
                                                                    error="wrong" name="user_phone"
                                                                    success="right" />

                                                                <MDBInput label=" טלפון נוסף"
                                                                    valueDefault={order.PersonInfo.AdditionalPhone}
                                                                    onChange={e => this.setState({ additionalPhone: e.target.value })}
                                                                    icon="phone" group type="tel"
                                                                    error="wrong" name="user_additionalPhone"
                                                                    success="right" />

                                                                <h5>* אופן התשלום <i className=" formIcon fas fa-money-bill"></i></h5>
                                                                <select className="form-control" id="paymentMethod" name="paymentMethod"
                                                                    onChange={(e) => this.setState({ paymentChecked: e.target.value })}
                                                                >
                                                                    {
                                                                        order.PaymentMethod === 'מזומן' ?
                                                                            <>
                                                                                <option value={"PayBox"}> PayBox</option>
                                                                                <option selected={true} value={"מזומן"}>מזומן</option>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <option selected={true} value={"PayBox"}> PayBox</option>
                                                                                <option value={"מזומן"}> מזומן</option>
                                                                            </>
                                                                    }
                                                                </select>
                                                                <br />
                                                                <label className='myCheckbox'>
                                                                    <input type='checkbox'
                                                                        required
                                                                        // value={approved}
                                                                        checked={order.PersonInfo.Approved == "מאשר/ת את התנאים" ? true : false}
                                                                    ></input>&nbsp;
                                                                    אני מאשר/ת את  <a href='#' target='_blank'>התנאים</a>

                                                                </label>

                                                                <MDBInput type="textarea" rows="3" label="הערות" icon="pencil-alt"
                                                                    valueDefault={order.Comment}
                                                                    onChange={e => this.setState({ comment: e.target.value })}
                                                                    name='user_notes' />

                                                                <h3 id='formSecondHeadline' className="h5 text-center mb-"></h3>
                                                            </div>
                                                        )
                                                    })
                                                }


                                            </form>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>

                                {/* <hr /> */}
                                <div className="card-header bg-dark text-light">
                                    בחר/י מוצרים&nbsp;
                                    <i className="fa fa-shopping-cart" aria-hidden="true" />
                                    {/* <a href className="btn btn-outline-info btn-sm pull-right">המשך להזמנה</a> */}
                                    <div className="clearfix" />
                                </div>
                                <br />
                                {
                                    this.state.totalPrice > 0 ?
                                        <div className="card-body">
                                            <UpdateOrderProducts
                                                place={this.state.place != '' ? this.state.place : delivery.Places[0].Address}
                                                deliveryPlaces={delivery.Places}
                                                // place={this.state.place != '' ? this.state.place :
                                                OrderId={this.state.OrderId}
                                                deliveryDateTime={delivery.DeliveryDateTime}
                                                groupName={this.props.groups[0].Name}
                                                payBoxLink={delivery.PayboxGroupLink}
                                                minimumTotalPrice={delivery.MinimumTotalPrice}
                                                isUserUpdate={true}
                                                productsFromDelivery={delivery.Products}
                                                groupCode={this.state.groupCode}
                                                orderNumber={this.state.orderNumber}
                                                deliveryId={this.state.deliveryId}
                                                firstName={this.state.firstName}
                                                lastName={this.state.lastName}
                                                email={this.state.email}
                                                phone={this.state.phone}
                                                comment={this.state.comment}
                                                internalComments={this.state.internalComments}
                                                paymentChecked={this.state.paymentChecked}
                                                isPaid={this.state.isPaid}
                                                productsFromOrder={this.state.products}
                                                totalPrice={this.state.totalPrice}
                                                footer={parse(`${delivery.Footer}`)}
                                                city={this.state.city}
                                                address={this.state.address}
                                                hour={this.state.hour}
                                                additionalPhone={this.state.additionalPhone}
                                            />
                                        </div>
                                        : <h5 className='text-center'>טוען מוצרים...</h5 >
                                }

                                {/* <Form/> */}

                            </div>
                        </div>
                    </div>
                </div >
            )

        }
        else {

            return <div style={{ padding: '150px', margin: 'auto' }}>
                <h2 className='text-center text-white font-weight-bold'>הטופס נסגר</h2>
            </div>

        }

    })


    render() {

        return (
            <div className='container-fluid' id='myContainerFluid' >
                <MDBAnimation type="fadeIn" delay=".5s" className="text-center">
                    {
                        this.props.groups[0] != null ?

                            !this.state.isPaid ?

                                this.loadGroups()
                                :
                                <div className="text-center" style={{ height: '350px' }}>
                                    <div style={{ paddingTop: '150px' }}>
                                        <h2 className='text-center text-white'>

                                            הזמנה זו שולמה ולא ניתנת לעדכון.
                                            <br />
                                            <a className='text-white' href={`/groups/${this.state.groupCode}/${this.state.deliveryId}`}>
                                                <b className="font-weight-bold"> ליצירת הזמנה נוספת לחץ/י כאן</b></a>
                                        </h2>
                                    </div>
                                </div>
                            : ''
                    }
                </MDBAnimation>
            </div >
        )
    }

}
const mapStateToProps = (state) => {
    return {
        deliveries: state.deliveriesReducer.deliveries,
        groups: state.groupReducer.groups,
        // orders: state.groupOrdersReducer.orders,
        onlineOrders: state.onlineOrdersReducer.onlineOrders,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            GetGroupDeliveryById,
            getGroupByCode,
            getOnlineOrderByGroupDeliveryIdAndOrderNumber,
            updateGroupOrderPaidStatus
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdateOnlineOrder);

