import React from 'react'
import { useLottie } from "lottie-react";
import TractorFile from '../../dist/lotttie-files/tractor.json'
import TomatoFile from '../../dist/lotttie-files/tomato.json'
import './FormLottie.scss'
import { MDBCol, MDBRow } from 'mdbreact';
const FormLottie = () => {

    const TractorOptions = {
        animationData: TractorFile,
        loop: true,
    };

    const { View: TractorLottie } = useLottie(TractorOptions);

    const TomatoOptions = {
        animationData: TomatoFile,
        loop: true,
    };

    const { View: TomatoLottie } = useLottie(TomatoOptions);

    return (
        <MDBRow className='form-lottie-wrapper'>
            <MDBCol sm='12' lg='6' className='lottie-col'>
                <div className='form-lottie'>
                    <div className='tomato-lottie'>
                        {TomatoLottie}
                    </div>
                    <div className='tractor-lottie'>
                        {TractorLottie}
                    </div>
                </div>
            </MDBCol>
        </MDBRow>
    )
}

export default FormLottie