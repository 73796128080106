import React, { useEffect, useState } from "react";
// Redux
import { MDBCard, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import GedDayOfWeek from "../../../../components/GedDayOfWeek";
import SerachInout from "../../../../components/SerachInout";
import Switch from "../../../../components/Switch/Switch";
import {
  GetGroupDeliveryById, getGroupDeliveryTotalsById
} from "../../../../redux/deliveries/deliveries-actions";
import {
  getGroupOrdersByGroupDeliveryId
} from "../../../../redux/groupOrders/group-orders-action";
import { getGroupByCode } from "../../../../redux/groups/groups-actions";
import { getProducts } from "../../../../redux/products/products-actions";
import './preparation.scss';

const PreparationList = (props) => {
  const dispatch = useDispatch();
  let { groupCode, deliveryId } = useParams();
  const history = useHistory()
  const groups = useSelector((state) => state.groupReducer.groups);
  const sortedOrders = useSelector((state) => state.groupOrdersReducer.sortedOrders);
  const deliveries = useSelector((state) => state.deliveriesReducer.deliveries);
  const totalPricesForDelivery = useSelector((state) => state.deliveriesReducer.totalPricesForDelivery);
  const products = useSelector((state) => state.productReducer.products);
  const [productsList, setProductsList] = useState([])
  const [filterBy, setFilterBy] = useState(0)
  const [showTable, setShowTable] = useState(true)

  useEffect(() => {
    document.getElementById('myNav').style.display = 'block'
  }, [])

  //call APIs
  useEffect(() => {
    document.title = "משק אלמוג - הכנת הזמנות";

    if (groupCode && deliveryId) {
      dispatch(getGroupByCode(groupCode));
      dispatch(getGroupOrdersByGroupDeliveryId(deliveryId));
      dispatch(GetGroupDeliveryById(deliveryId))
      dispatch(getProducts())
      dispatch(getGroupDeliveryTotalsById(deliveryId));
    }
  }, [groupCode, deliveryId]);


  //setCurrentOrder
  useEffect(() => {
    if (totalPricesForDelivery.length != 0 && totalPricesForDelivery.Products.length > 0) {
      setProductsList(totalPricesForDelivery.Products.filter(p => p.Amount > 0).map(p => ({ ...p, ready: "not-active" })))
    }
  }, [totalPricesForDelivery]);

  const setActionsFromSelected = (e) => {
    setFilterBy(e.target.value == 1 ? false : true)
  }

  const handleClick = (orderId, index) => {
    console.log(orderId);
    history.push(`/admin/deliveries/preparation/${groupCode}/${deliveryId}/${index + 1}`)
  }

  const handleClickProduct = (id) => {

    setProductsList(prev => {
      return prev.map(p => p.Code == id ? ({ ...p, ready: p.ready == "active" ? " not-active" : "active" }) : p)
    })

  }

  return (
    <div className='container-fluid preparation-list'>
      <MDBContainer className='firstDivAfterNavOfLogin animated fadeIn'>

        <h2 className="text-center font-weight-bold">
          הכנת הזמנות
        </h2>
        {
          deliveries.length > 0 && groups.length &&
          <div className="text-center">
            {deliveries[0].Places.length > 0 &&
              <h4>
                {groups[0].Name},
                יום&nbsp;
                <GedDayOfWeek
                  dayOfWeek={deliveries[0].DeliveryDateTime}
                />,&nbsp;
                {moment(new Date(deliveries[0].DeliveryDateTime)).format("DD/MM/YYYY")}
                {/* בשעה&nbsp;
                {moment(new Date(deliveries[0].Places[0].Hour).format('HH:mm'))} */}
              </h4>
            }

          </div>
        }

        <MDBRow>
          <MDBCol sm="12">
            <MDBCard>

              <button className='rounded-button' style={{ float: 'right' }}
                onClick={() => history.push(`/admin/deliveries/groups-orders/${groupCode}/${deliveryId}`)}>
                <i className="fas fa-chevron-circle-right"></i>
              </button>
              <SerachInout />

              <div className="col-12 flex-row" style={{ width: '98%', margin: 'auto', padding: '0', justifyContent: "center" }}>
                {
                  showTable &&
                  <div className="text-right">
                    <select
                      style={{ display: 'inline-block', margin: "0 25px" }}
                      onChange={(e) => setActionsFromSelected(e)}
                    >
                      <option defaultChecked value="1">לא מוכן </option>
                      <option value="2">מוכן </option>
                    </select>
                  </div>
                }

                <Switch
                  defaultChecked={showTable}
                  func={() => setShowTable(!showTable)}
                  label={"תצוגת טבלה"}
                />

              </div>

              {
                showTable ?

                  <table id="dataTable"
                    className="table table-striped col-sm-12 text-center"
                    border='1' style={{ margin: 'auto', tableLayout: 'fixed' }}>
                    <thead className="thead-dark">
                      <tr className="header">
                        <th>שם</th>
                        <th>תשלום</th>
                        <th>סה"כ</th>
                        <th>האם מוכן</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedOrders.length > 0 &&
                        sortedOrders.map((order, i) => {
                          if (filterBy == order.IsReady) {
                            return (
                              <tr key={order.Id} onClick={() => handleClick(order.Id, i)}>
                                <td>
                                  {order.InternalComment != "" && <i className="fa fa-solid fa-quote-right"></i>}&nbsp;
                                  {order.PersonInfo.FirstName} {order.PersonInfo.LastName}
                                </td>
                                <td>{order.IsPaid ? <i className="fas fa-check"></i> : order.PaymentMethod}</td>
                                <td>{order.TotalPrice} ₪</td>
                                <td>{order.IsReady ? "מוכן" : "לא מוכן"}</td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                  :
                  <>
                    <br />
                    {productsList.length > 0 &&
                      productsList.map((product, i) => {
                        return <div key={product.Code}
                          className={"flex-row " + product.ready}
                          id={product.Code}
                          onClick={() => handleClickProduct(product.Code)}
                        >
                          <p style={{ flex: 1 }}>{product.Amount}</p>
                          {/* PRODCUTS */}
                          {
                            products.map((p, i) => {
                              if (product.Code == p.Code)
                                return <React.Fragment key={p.Code}>
                                  <p style={{ flex: 1 }}>{p.ShortNameForPreperation}</p>
                                  {/* <img src={`../../../../${p.ReservationImage}`} width={"100px"} /> */}
                                </React.Fragment>
                            })
                          }
                          {/* < hr style={{ border: "1px solid black", width: "100%" }} /> */}
                        </div>
                      })
                    }
                  </>

              }
            </MDBCard>
          </MDBCol>
        </MDBRow>

      </MDBContainer>
    </div >
  );
};

export default PreparationList;
