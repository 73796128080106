import React, { useEffect, useState, useRef } from "react";
// import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import RoundedButton from "../../../../components/RoundedButton/RoundedButton";
import { MDBCardBody, MDBRow, MDBCol, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';

import SerachInout from '../../../../components/SerachInout'
import Print from '../../../../components/Print'
import MyModal from '../../../../components/MyModal'
import DownloadBtn from '../../../../components/DownloadBtn'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import CopyToClipBoard from '../../../../components/CopyToClipBoard'
import GetHourByDuration from '../../../../components/GetHourByDuration'
import MyContextMenu from '../../../../components/ContextMenu/MyContextMenu'
import { ContextMenuTrigger } from "react-contextmenu";
import { useHistory } from 'react-router-dom';
import "../../orders/show_orders/groupOrdersSummary.css";
import moment from 'moment-timezone'

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { changeGroupDeliveryStatus, getGroupOrdersByGroupDeliveryId, updateGroupOrderPaidStatus } from "../../../../redux/groupOrders/group-orders-action";
import { getOnlineOrdersByGroupDeliveryId } from "../../../../redux/onlineOrders/online-orders-action";


import { deleteGroupOrderByOrderId } from "../../../../redux/groupOrders/group-orders-action";
import { deleteOnlinerderByOrderId } from "../../../../redux/onlineOrders/online-orders-action";

// const mapStateToProps = (state) => {
//   return {
//     // orders: state.groupOrdersReducer.orders,
//     // deliveries: state.deliveriesReducer.deliveries,
//     // products: state.productReducer.products,
//   };
// };

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            // deleteGroupOrderByOrderId,
            deleteOnlinerderByOrderId,
            getOnlineOrdersByGroupDeliveryId,
            changeGroupDeliveryStatus,
            updateGroupOrderPaidStatus
        },
        dispatch
    );
};

function OrdersShow({ columns, data, updateData, groupName, delivery, groupCode, csvData }) {
    const [deliveryDate, setDeliveryDate] = useState('')
    const [deliveryHour, setDeliveryHour] = useState('')
    const [modalShow, setModalShow] = useState(false)
    // const [orderId, setOrderId] = useState('')
    const [activeStatus, setActiveStatus] = useState(null)
    const [statusText, setStatusText] = useState(null)
    const [disabled, setDisabled] = useState("disabled")
    const [ordersForChanges, setOrdersForChanges] = useState([])
    const [ordersNumbersForChanges, setOrdersNumbersForChanges] = useState([])

    const [isOrderCheckd, seIsOrderCheckd] = useState(true)
    const [headlineTextForModal, setHeadlineTextForModal] = useState(false)
    const [valueOfAction, setValueOfAction] = useState("")
    const [buttonText, setButtonText] = useState("")
    const [displayArrowButtons, setDisplayArrowButtons] = useState("block")
    const [floatedMenuOrderUpdate, seFloatedMenuOrderUpdate] = useState(null)
    const [floatedMenuorderSendMessage, seFloatedMenuorderSendMessage] = useState(null)
    const [floatedMenuOrderDelete, setFloatedMenuOrderDelete] = useState(null)
    const [token, setToken] = useState(null)



    // const [clinetName, setClinetName] = useState(null)


    const componentRef = useRef();
    const history = useHistory()

    let myRef = React.createRef()
    const dispatch = useDispatch();

    const totals = useSelector((state) => state.onlineDeliveriesReducer.onlineTotalPricesForDelivery)
    useEffect(() => {
        // console.log('totals', totals)

        if (localStorage.getItem("token")) {
            setToken("manager")
            setDisabled("")
        }
        else if (sessionStorage.getItem("token")) {
            setToken("manager")
            setDisabled("")
        }

        // console.log('props.history', history.location.state.totalPrice)
        try {
            setDeliveryDate(new Date(delivery.DeliveryDateTime).toLocaleDateString('he-IL', { hour12: false }))
            setActiveStatus(delivery.IsActive)
            { delivery.IsActive ? setStatusText("פתוח") : setStatusText("סגור") }
            // setDeliveryHour(new Date(delivery.DeliveryDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))
            setDeliveryHour(moment(new Date(delivery.DeliveryDateTime).format('HH:mm')))
            document.getElementById('myNav').style.display = 'block';


        } catch (error) {

        }

    }, [delivery])

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    );

    const actionAndUpdate = () => {

        // console.log("valueOfAction", valueOfAction)

        //change payment status of order
        if (valueOfAction == 1) {
            updateOrderStatus()
        }
        //delete order
        else if (valueOfAction == 3) {
            deleteOrder()
        }
        // dispatch(deleteGroupOrderByOrderId(orderId));
        dispatch(getOnlineOrdersByGroupDeliveryId()).then(() => {
            updateData();
            setModalShow(false)
        });
    }

    const updateOrderStatus = () => {
        ordersNumbersForChanges.map(order => {

            dispatch(updateGroupOrderPaidStatus({
                GroupDeliveryId: delivery._id,
                OrderNumber: order,
            }));

        })
    }
    const deleteOrder = () => {
        // console.log("in delete")

        ordersForChanges.map(order => {
            console.log("order", order)
            dispatch(deleteOnlinerderByOrderId(order));

        })

    }

    const setDeliveryFormStatus = (e) => {

        let objectPost = {
            "Id": delivery._id
        }

        dispatch(changeGroupDeliveryStatus(objectPost))

        setActiveStatus(e.target.checked)
        if (e.target.checked)
            setStatusText("פתוח")
        else
            setStatusText("סגור")

    }
    const loadData = () => {
        let count = data.length
        return (

            <div className='firstDivAfterNav'>

                <MDBRow key={delivery._id}>
                    <MDBCol md="12" style={{ margin: 'auto' }}>
                        <MyContextMenu
                            order={floatedMenuOrderUpdate}
                            message={floatedMenuorderSendMessage}
                            delete={floatedMenuOrderDelete}
                        />
                        {/* <MDBCard> */}
                        <MDBCardBody>
                            <div className='text-center'>
                                {/* <h2 className='text-center font-weight-bold' style={{ display: 'inline' }}> {groupName} |&nbsp;
                                    {delivery.Places.length > 0 ? '' : delivery.Address + " | "}
                                    {deliveryDate}   |&nbsp;
                                </h2> */}
                                <h3 className='text-center' style={{ display: 'inline' }}>
                                    <label id='myCheckbox'>
                                        {delivery.IsActive ?
                                            <span className='myCheckbox'> רישום {statusText}</span>
                                            : <span className='myCheckbox'>רישום {statusText}</span>
                                        }
                                        &nbsp;<input disabled={disabled} style={{ width: '20px', height: '20px' }} value={'close'} type="checkbox" checked={activeStatus} onChange={(e) => setDeliveryFormStatus(e)}></input>
                                    </label>
                                </h3>

                                <br />
                                <div id='test' style={{ display: 'inline-block', textAlign: 'right' }}>

                                    {

                                        // delivery.Places.map((place, index) => {
                                        //     // console.log("place", place)
                                        //     if (delivery.Places.length === 1) {
                                        //         return <p key={place._id}>
                                        //             <span>
                                        //                 &nbsp;&nbsp;<span className="font-italic">
                                        //                     {/* {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                        //                     {moment(new Date(place.Hour)).tz("Asia/Jerusalem").format('HH:mm')}
                                        //                     -{<GetHourByDuration
                                        //                         hour={place.Hour}
                                        //                         duration={place.Duration}
                                        //                     />}</span>
                                        //             </span>
                                        //             <b> {place.Address}</b>
                                        //         </p>
                                        //     }
                                        //     else {
                                        //         return <li key={place._id}>
                                        //             <p style={{ display: 'inline' }}>
                                        //                 <span>
                                        //                     &nbsp;&nbsp;<span className="font-italic">
                                        //                         {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        //                         -{<GetHourByDuration
                                        //                             hour={place.Hour}
                                        //                             duration={place.Duration}
                                        //                         />}</span>
                                        //                 </span>
                                        //                 <b> {place.Address}</b>

                                        //             </p>
                                        //         </li>
                                        //     }

                                        // })

                                    }

                                </div>
                            </div >
                            <div className='col-sm-6 roudedButtonsAboveTable' style={{ margin: 'auto', textAlign: 'center' }}>
                                <Tooltip text="עריכת טופס רישום">
                                    <RoundedButton
                                        disabled={disabled}
                                        icon="fas fa-edit"
                                        onClick={() => rowClicked(`/admin/online/update-delivery/${groupCode}/${delivery._id}`)}
                                    >
                                    </RoundedButton>
                                </Tooltip>
                                {/* <Tooltip text="עריכת טופס רישום">
            < MDBBtn rounded outline
              color="secondary" style={{ textAlign: 'center' }} target='_blank' href={`/admin/deliveries/edit/${groupCode}/${delivery._id}`}>
              <i class="fa fa-edit" aria-hidden="true"></i>
            </MDBBtn>
          </Tooltip> */}
                                <button className='rounded-button' style={{ float: 'right' }}
                                    onClick={() => rowClicked('/admin/online/online-delivery')}>
                                    <Tooltip text="חזרה">
                                        <i className="fas fa-chevron-circle-right"></i>
                                    </Tooltip>
                                </button>

                                <Tooltip text="הורדה">
                                    <DownloadBtn csvData={csvData} name={`${groupName} ${deliveryDate}`} className='col-sm-2' />
                                </Tooltip>
                                <Tooltip text="הדפסה">
                                    <Print className='col-sm-2'
                                        titleToPrint={
                                            groupName + ", " + delivery.Address + ", " + deliveryDate + ",  " + deliveryHour
                                        }
                                        componentRef={componentRef.current}
                                    />
                                </Tooltip>

                                {/* <Tooltip text="תצוגת מנהל">
                                    <RoundedButton
                                        icon="fas fa-address-card"
                                        onClick={() => rowClicked(`/admin/deliveries/groups-orders/manager/${groupCode}/${delivery._id}`)}
                                    >
                                    </RoundedButton>

                                </Tooltip> */}

                            </div>

                            {/* 
        <button onClick={() => downloadToCsv()}>
          <i className='fa fa-download col-sm-4 text-center'></i>
        </button> */}

                            {
                                data.length !== 1 ?
                                    <div>
                                        <SerachInout />
                                        {/* <div className='col-sm-12 arrowsOfTable'>
          <a href={`#header0`}> <button style={{ float: 'right' }}><i class="fas fa-arrow-right fa-2x" ></i></button></a>
          <a href={`#header${headerGroups[0].headers.length - 1}`}> <button style={{ float: 'left' }} ><i class="fas fa-arrow-left fa-2x"></i></button></a>
        </div > */}


                                        <div className="col-12" style={{ width: '98%', margin: 'auto', padding: '0' }}>
                                            <div className="text-right">
                                                <select
                                                    style={{ display: 'inline-block' }} onChange={(e) => setActionsFromSelected(e)} disabled={isOrderCheckd}>
                                                    <option value="0">בחר/י פעולה</option>
                                                    <option value="1">שינוי סטאטוס תשלום </option>
                                                    <option value="2">שליחת הודעה </option>
                                                    {/* <option value="3">עריכת סה"כ לתשלום</option> */}
                                                    <option value="3">מחיקה</option>
                                                </select>
                                            </div>

                                        </div>


                                        <div className='table-responsive'>


                                            <table id="dataTable"
                                                className="ordersSummaryTable table table-striped col-sm-12 text-center tableOfOrders"
                                                ref={myRef}
                                                {...getTableProps()} ref={componentRef}>
                                                <thead className="thead-dark">
                                                    {headerGroups.map((headerGroup) => (
                                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                                            <th></th>
                                                            <th className="actionsCB">
                                                                <input type="checkbox" style={{ transform: 'scale(1.5)' }}
                                                                    onChange={(e) => setAllOrderForChange(e, rows)}>
                                                                </input>
                                                            </th>
                                                            {

                                                                headerGroup.headers.map((column, i) => (
                                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} id={`header${i}`}>
                                                                        {column.render("Header")}
                                                                        {/* {console.log('column', column)} */}
                                                                        <span>
                                                                            {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                                                        </span>
                                                                    </th>
                                                                ))
                                                            }
                                                        </tr >
                                                    ))}
                                                </thead >
                                                <tbody {...getTableBodyProps()}>
                                                    {rows.map((row, i) => {
                                                        count--
                                                        // data.length--
                                                        if (i == data.length - 1) {
                                                            // LAST ROW - TOTALS
                                                            // return (
                                                            //   <tr>
                                                            //     {Object.keys(row.original).map(function (key, i) {
                                                            //       if (i == 0) {
                                                            //         return <td>{row.original[i]}</td>;
                                                            //       }
                                                            //       return <td>{row.original[i]}</td>;
                                                            //     })}
                                                            //   </tr>
                                                            // );
                                                            return;
                                                        }
                                                        // const dianamicOrderId = row.original.Id;
                                                        // console.log("row", row.original)
                                                        const orderNumber = row.original.orderNumber
                                                        const orderID = row.original.Id;
                                                        // console.log('row.original', row.original["שם מלא"])
                                                        // let isPaid = row.original.IsPaid
                                                        // console.log("row: ", row.original);
                                                        //   if (i == 0) {
                                                        //     console.log("row: ", row.original.Id);
                                                        //     setOrderId(row.original.Id);
                                                        //     return;
                                                        //   }

                                                        prepareRow(row);
                                                        // console.log("row", row)
                                                        return (
                                                            <ContextMenuTrigger id="some_unique_identifier">
                                                                <tr {...row.getRowProps()}
                                                                    onClick={(e) => setClickableRow(e, `/admin/online/orders/update/${groupCode}/${delivery._id}/${orderNumber}`)}
                                                                    onContextMenu={() =>
                                                                    // alert("test")
                                                                    {
                                                                        seFloatedMenuOrderUpdate(`/admin/deliveries/groups-orders/update/${groupCode}/${delivery._id}/${orderNumber}`)
                                                                        seFloatedMenuorderSendMessage(
                                                                            {
                                                                                link: "/admin/deliveries/groups-orders/send-message",
                                                                                orders: orderNumber,
                                                                                deliveryId: delivery._id,
                                                                                groupName: groupName
                                                                            }
                                                                        )
                                                                    }
                                                                        // seFloatedMenuorderDelete()

                                                                    }
                                                                >
                                                                    <td>{count}</td>
                                                                    <td className="actionsCB">
                                                                        <input id={`order_${orderID}_checkbox`} style={{ transform: 'scale(1.5)' }}
                                                                            type="checkbox" onChange={(e) => setOrderForChange(e, orderID, orderNumber)}>

                                                                        </input>
                                                                    </td>
                                                                    {/* <td className='roundedBtn toolTipInTable'>
                                  <Tooltip text="עריכה">
                                    <RoundedButton
                                      icon="fas fa-edit"
                                      onClick={() => rowClicked(`/ admin / deliveries / groups - orders / update / ${ groupCode } / ${ delivery._id } / ${ orderNumber }`)}

                                    />
                                  </Tooltip>
                                  <Tooltip text="מחיקה">
                                    <RoundedButton
                                      icon="fas fa-trash-alt"
                                      onClick={
                                        () => {
                                          setOrderId(dianamicOrderId)
                                          setModalShow(true)
                                        }
                                      }
                                    />
                                  </Tooltip>
                                </td> */}
                                                                    {/* {setClinetName(row.original["שם מלא"])} */}

                                                                    {row.cells.map((cell, index) => {
                                                                        if (index === 0) {
                                                                            {
                                                                                return window.screen.width < 800 ?
                                                                                    <td className="sticky-columnForOrders" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                                                    : <td  {...cell.getCellProps()}>{cell.render("Cell")}</td>;

                                                                            }
                                                                        }
                                                                        else if (index !== row.cells.length - 1)
                                                                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                                                        else
                                                                            return <td{...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                                                    })}

                                                                </tr>
                                                            </ContextMenuTrigger>
                                                        );
                                                    })}
                                                </tbody>
                                            </table >
                                        </div>

                                        <br />
                                        <h2 className='text-center font-weight-bold'>סיכום הזמנות</h2>
                                        <div className='table-responsive'>
                                            <table id='dataTable' className=" table table-striped col-sm-12 text-center tableOfOrders">
                                                <thead className="thead-dark">
                                                    {headerGroups.map((headerGroup) => (
                                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                                            <th></th>
                                                            {/* {headerGroup.headers.map((column, index) =>
                                (index > 5 && index != headerGroup.headers.length - 1 && index != headerGroup.headers.length - 3 && index != headerGroup.headers.length - 4) ?
                                  (
                                    <th {...column.getHeaderProps()}>
                                      {column.render("Header")}

                                    </th>
                                  )
                                  : ''
                              )
                              } */}
                                                            <th className="font-weight-bold " style={{ width: '70px' }}>סה"כ</th>

                                                            {totals.Products &&
                                                                totals.Products.map((p) => {
                                                                    return <th className="font-weight-bold" style={{ width: '70px' }}>{p.Name}</th>
                                                                })}

                                                        </tr >
                                                    ))}
                                                </thead >
                                                <tbody>

                                                    <tr>

                                                        {/* {Object.keys(rows[rows.length - 1].original).map(function (key, i) {

                              //  console.log('rows', rows[rows.length - 1])
                               console.log("totals in map: ", totals)
                              // console.log('i', i)
                              if (i == 0) {
                                return <td>{rows[rows.length - 1].original[i]}</td>;
                              }

                              else if (i > 5 && i < rows[rows.length - 1].original.length - 1)
                                return <td>{rows[rows.length - 1].original[i]}</td>

                              else if (i === rows[rows.length - 1].original.length - 1)
                                return <td>{rows[rows.length - 1].original[rows[rows.length-1].original.length]}</td>
                            })
                            } */}
                                                        <td>סה"כ</td>
                                                        <td className="font-weight-bold sticky-columnForOrders">{totals.TotalPrice} ₪</td>

                                                        {totals.Products &&
                                                            totals.Products.map((p) => {
                                                                return <td>{p.Amount}</td>
                                                            })}



                                                    </tr>


                                                </tbody>
                                            </table>



                                        </div>
                                    </div>
                                    : <>
                                        <br />
                                        <h4 className='text-center font-weight-bold'>לא קיימים רישומים לטופס זה</h4>
                                    </>
                            }


                            <MyModal />

                            <MyModal
                                headlineText={headlineTextForModal}
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                ButtonText='ביטול'
                                ButtonDeleteText={buttonText}
                                ButtonDinamicFunction={() => actionAndUpdate()}
                            />


                        </MDBCardBody>
                        {/* </MDBCard> */}
                    </MDBCol >
                </MDBRow >
            </div >

        )
    }
    const setActionsFromSelected = (e) => {
        // console.log(e.target.value)
        if (e.target.value == 1) {
            setHeadlineTextForModal(`האם ברצונך לשנות סטאטוס תשלום להזמנות המסומנות`)
            setButtonText("עדכן")
            setValueOfAction(1)
            setModalShow(true)
        }
        else if (e.target.value == 2) {
            history.push("/admin/deliveries/groups-orders/send-message", { orders: ordersNumbersForChanges, deliveryId: delivery._id, groupName: groupName })
        }
        else if (e.target.value == 3) {
            setHeadlineTextForModal(`האם ברצונך למחוק את ההזמנות המסומנות`)
            setButtonText("מחק")
            setValueOfAction(3)
            setModalShow(true)
        }

    }
    const setOrderForChange = (e, orderId, orderNumber) => {
        if (e.target.checked) {
            seIsOrderCheckd(false)

            ordersForChanges.push(orderId)
            ordersNumbersForChanges.push(orderNumber)
        }
        else {

            try {
                document.getElementById(`order${orderNumber}`).disabled = true
            } catch (error) {

            }

            for (let index = 0; index < ordersForChanges.length; index++) {

                if (ordersForChanges[index] == orderId) {
                    ordersForChanges.splice(index, 1)
                    ordersNumbersForChanges.splice(index, 1)
                    if (ordersForChanges.length == 0)
                        seIsOrderCheckd(true)

                }
            }

        }
        // console.log("ordersForChanges", ordersForChanges)
        // console.log("ordersNumbersForChanges", ordersNumbersForChanges)

    }
    const setAllOrderForChange = (e, orders) => {
        while (ordersForChanges.length) {
            ordersForChanges.pop()
            ordersNumbersForChanges.pop()
        }
        // console.log("ordersForChanges", ordersForChanges)

        orders.map((order, index) => {
            if (index < orders.length - 1) {


                if (e.target.checked) {
                    seIsOrderCheckd(false)
                    ordersForChanges.push(order.original.Id)
                    ordersNumbersForChanges.push(order.original.orderNumber)
                    document.getElementById(`order_${order.original.Id}_checkbox`).checked = "checked"

                }
                else {
                    ordersForChanges.pop()
                    ordersNumbersForChanges.pop()
                    document.getElementById(`order_${order.original.Id}_checkbox`).checked = ""
                    seIsOrderCheckd(true)

                }

            }
        })

        // console.log("ordersForChanges", ordersForChanges)
        // console.log("ordersNumbersForChanges", ordersNumbersForChanges)

    }

    const setClickableRow = (e, link) => {
        // console.log('e.target', e.target.tagName)
        if (e.target.tagName !== 'INPUT' && e.target.className !== "actionsCB")
            rowClicked(link)
    }

    const rowClicked = (link, openInNew) => {
        if (openInNew)
            window.open(link)
        else
            history.push(link)
    }

    // Render the UI for your table
    return (

        data.length > 0 ?



            delivery !== null ?
                token !== "manager" ?
                    <div className='firstDivAfterNav'>
                        <h2 className='text-center font-weight-bold' style={{ paddingTop: '150px', paddingBottom: '150px' }}>
                            אין באפשרותך לצפות בעמוד זה</h2>
                    </div>
                    : loadData()
                : ''
            :
            <div className='firstDivAfterNav'>
                <h2 className='text-center font-weight-bold' style={{ padding: '150px' }}>טוען...</h2>
            </div>



    );

}
const mapStateToProps = (state) => {
    return {
        totals: state.deliveriesReducer.totals
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersShow);
