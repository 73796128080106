import React from 'react'
import './css/footer.css'
import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor() {
    super()
    this.state = {
      Name: 'asaf'
    }
  }
  setPageToTop = (e) => {
    window.scrollTo({ top: 0 });

    document.title = `משק אלמוג -מהחקלאי לצרכן`

    if (e.target.className.includes('home') !== false) {
      document.getElementById('WelcomeImg').style.display = 'block';
      document.getElementById('myNav').style.display = 'block';

    }

    else {
      document.getElementById('WelcomeImg').style.display = 'none';
      document.getElementById('myNav').style.display = 'block';
      // document.getElementById('shopingCart').style.display = 'none';
      document.getElementById('myNav').style.background =
        'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,121,59,1) 68%, rgba(0,212,255,1) 100%)';
      document.getElementById('myNav').style.marginBottom = '50%';
    }
  }

  render() {
    return (
      <div className="container-fluid footer">
        <div className="mt-5 pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xs-12 about-company">

                <h3 onClick={(e) => this.setPageToTop(e)}><Link className='text-white home' to={`/`}>משק אלמוג </Link></h3>
                <div className="col copyright" style={{ paddingRight: '0' }}>
                  <p><small className="text-white-50">כל הזכויות שמורות למשק אלמוג © {new Date().getFullYear()}</small>
                    {/* <br />
                    <small className="text-white-50"><a target='_blank' href='https://my-portfolio-asaf.netlify.app/'>אסף אלמוג</a></small>&nbsp;|&nbsp;
                    <small className="text-white-50"><a target='_blank' href='https://www.linkedin.com/in/nir-almog-9a4202151/'>ניר אלמוג</a></small> */}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-xs-12 links">
                <h4 className="mt-lg-0 mt-sm-3">קישורים</h4>
                <div id='myLiLinks'>
                  <ul id='listOfPages' className="m-0 p-0" onClick={(e) => this.setPageToTop(e)}>

                    <li><Link className='white-text home' to={`/`}>בית</Link></li>
                    <li><Link className='white-text' to={`/about`}>אודות</Link></li>
                    <li><Link className='white-text' to={`/vegeatbles`}>העבודה במשק</Link></li>
                    <li><Link className='white-text' to={`/reservation`}>הזמנה</Link></li>
                    <li><Link className='white-text' to={`/gallery`}>גלריה</Link></li>
                    <li><Link className='white-text' to={`/contact`}>צור קשר</Link></li>
                    {/* <li><Link className='white-text' to={`/about_me`}>על המפתח</Link></li> */}

                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-xs-12 location">
                <br className="mobile-break" />
                <h4 className="mt-lg-0 mt-sm-4">מיקום</h4>
                <p>מושב אחיטוב</p>
                <div className='contentFooter'>
                  <p className="mb-0"><i className="fa fa-phone mr-3"></i><a href='tel:972-50-282-0402'>(972) 50-282-0402</a> </p>
                  <p><i className="fas fa-envelope"></i> <a href="mailto:meshekalmog@gmail.com">meshekalmog@gmail.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Footer
