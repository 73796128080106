import React, { useState, useEffect, Fragment } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer, MDBInput, MDBCollapse, MDBCollapseHeader } from 'mdbreact';

import { useDispatch } from 'react-redux'
import MyModal from '../../../components/MyModal'
import GetProducts from '../../../components/GetProducts'
import { useHistory } from "react-router-dom";
import EditorInput from '../../../components/EditorInput'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroups } from "../../../redux/groups/groups-actions";
import { getGroupDeleveriesByCode, addGroupDelivery, getLastGroupDeliveryByGroupCode } from "../../../redux/deliveries/deliveries-actions";

const CreateDelivery = (props) => {

    const [eventDate, setEventDate] = useState('')
    const [eventHour, setEventHour] = useState('')
    const [eventDuration, setEventDuration] = useState('')
    const [eventMinimumTotalPrice, setMinimumTotalPrice] = useState(0)
    const [eventPayBoxLink, setEventPayBoxLink] = useState('')
    const [groupCode, setGroupCode] = useState('')
    const [copyMessage, setCopyMessage] = useState('')
    const [modalShow, setModalShow] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [productsInDelivery, setProductsInDelivery] = useState([])
    const [isCopyForm, setIsCopyForm] = useState(false)
    const [showCopyBtn, setShowCopyBtn] = useState(false)
    const [productsFromDelivery, setProductsFromDelivery] = useState([])
    const [htmlHeader, setHtmlHeader] = useState(null)
    const [htmlFooter, setHtmlFooter] = useState(null)
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [collapseID, setCollapseID] = useState("collapseHeader")
    const [collapseIDFooter, setCollapseIDFooter] = useState("footerCollapse")
    const [isOnlyPaybox, setIsOnlyPaybox] = useState(false)
    const [arrowIcon, setArrowIcon] = useState("fa fa-angle-down rotate-icon")
    const [arrowFooterIcon, setArrowFooterIcon] = useState("fa fa-angle-down rotate-icon")

    const history = useHistory()
    const [inputFields, setInputFields] = useState([
        { Address: '', Hour: '', Duration: '' }
    ]);

    const dispatch = useDispatch()

    useEffect(() => {

        props.getGroups()

        setEventDuration(60)
        setMinimumTotalPrice(10)
        let groupEventDate = new Date(new Date()).toISOString().slice(0, 10)
        let groupEventHour = new Date(new Date()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

        setEventDate(groupEventDate)
        setEventHour(groupEventHour)

        if (inputFields.length > 0)
            setDisabledBtn("disabled")

    }, []);


    const toggleCollapse = newCollapseID => () => {

        if (collapseID !== newCollapseID) {
            setCollapseID(newCollapseID)
            setArrowIcon("fa fa-angle-up rotate-icon")
        }
        else {
            setCollapseID("")
            setArrowIcon("fa fa-angle-down rotate-icon")
        }
    }

    const toggleCollapseFooter = newCollapseID => () => {

        if (collapseIDFooter !== newCollapseID) {
            setCollapseIDFooter(newCollapseID)
            setArrowFooterIcon("fa fa-angle-up rotate-icon")
        }
        else {
            setCollapseIDFooter("")
            setArrowFooterIcon("fa fa-angle-down rotate-icon")
        }
    }

    const postEvent = () => {

        inputFields.map(field => {
            if (field.Hour != null)
                field.Hour = new Date(`${eventDate} ${field.Hour}`);
        })

        // console.log('inputFields', inputFields)
        const objectPost = {
            "GroupCode": groupCode,
            "DeliveryDateTime": new Date(eventDate),
            "Places": inputFields,
            "MinimumTotalPrice": eventMinimumTotalPrice,
            "PayboxGroupLink": eventPayBoxLink ? eventPayBoxLink : null,
            "Products": productsInDelivery,
            "IsOnlyPaybox": isOnlyPaybox,
            "IsActive": true,
            "Header": htmlHeader,
            "Footer": htmlFooter
        }

        // console.log('objectPost', objectPost)

        props.addGroupDelivery(objectPost).then(() => {
            history.push("/admin/deliveries")
            // window.location.replace("http://localhost:3000/admin/deliveries")
        })
    }


    const loadGroups = props.groups.map(group => {
        {
            return group.IsActive &&
                <option key={group.Code} value={group.Code}>{group.Name}</option>
        }
    })

    const handleCallback = (childData) => {

        // console.log('childData', childData)
        setProductsInDelivery(childData)
    }

    const parentCallbackOfHtmlHeaderInput = (childData) => {

        // console.log('header', childData)
        setHtmlHeader(childData)
    }

    const parentCallbackOfHtmlFooterInput = (childData) => {

        // console.log('footer', childData)
        setHtmlFooter(childData)
    }

    const setGroupCodeAndShowLastDeliveryBtn = (e) => {
        dispatch(getLastGroupDeliveryByGroupCode(e.target.value))
        setGroupCode(e.target.value)
        if (e.target.value > 0) {
            setShowCopyBtn(true)
        }
        else {
            setShowCopyBtn(false)
            setCopyMessage('')
        }
    }

    const showLastDeliveryOfgroup = () => {
        dispatch(getLastGroupDeliveryByGroupCode(groupCode)).then(() => {

            try {

                // setProductsFromDelivery(props.lastDelivery.Products)
                setIsCopyForm(true)
                const dateWeekLaterThenPrevius = new Date(new Date(props.lastDelivery.DeliveryDateTime).setDate(new Date(props.lastDelivery.DeliveryDateTime).getDate() + 7));
                setEventDate(dateWeekLaterThenPrevius.toISOString().slice(0, 10))
                parentCallbackOfHtmlHeaderInput(props.lastDelivery.Header)
                setEventPayBoxLink(props.lastDelivery.PayboxGroupLink)
                setMinimumTotalPrice(props.lastDelivery.MinimumTotalPrice)
                {
                    props.lastDelivery.Header !== undefined &&
                        setCollapseID(true)
                }
                parentCallbackOfHtmlFooterInput(props.lastDelivery.Footer)
                {
                    props.lastDelivery.Footer !== undefined &&
                        setCollapseIDFooter(true)
                }
                // setHtmlFooter(props.lastDelivery.Footer)

                // { address: '', hour: '', duration: '' }

                //reset array of places
                while (inputFields.length > 1) {
                    inputFields.pop();
                }

                //initial array of places
                setInputFields([{ Address: '', Hour: '', Duration: '' }])

                const values = [...inputFields];

                props.lastDelivery.Places.map((place, index) => {

                    if (index === 0) {
                        values[index].Address = place.Address
                        values[index].Hour = new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                        values[index].Duration = place.Duration
                    }
                    else if (index > 0) {
                        values.push({
                            Address: place.Address,
                            Hour: new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                            Duration: place.Duration
                        });
                        setDisabledBtn("")
                    }
                })

                setInputFields(values);
                setCopyMessage('נתוני הטופס הקודם הועתקו לטופס זה')

            } catch (error) {
                alert('לא קיימים טפסים לקבוצה הנבחרת')
            }

        })
    }

    const handleAddFields = () => {
        const values = [...inputFields];
        values.push({ Address: '', Hour: '', Duration: '' });
        setInputFields(values);
        setDisabledBtn("")
    };

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);

        //   console.log('inputFields.length', inputFields.length)
        if (inputFields.length === 2)
            setDisabledBtn("disabled")
    };

    const handleInputChange = (index, event) => {

        // { address: '', hour: '', duration: '' }

        const values = [...inputFields];
        if (event.target.name === "Address") {
            values[index].Address = event.target.value;
        }
        else if (event.target.name === 'Hour') {
            values[index].Hour = event.target.value
        }
        else if (event.target.name === 'Duration') {
            values[index].Duration = event.target.value;
        }

        setInputFields(values);
    };

    const loadProducts = () => {
        return <GetProducts
            isCreateForm={true}
            isCreateOrUpdateForm={true}
            parentCallback={handleCallback}
            productsFromDelivery={productsFromDelivery}
        // isCopyForm = {isCopyForm}
        />
    }
    return (
        <div className='container-fluid'>
            <MDBContainer id='formContainer' className='firstDivAfterNav'>
                <MDBRow>
                    <MDBCol md="12" style={{ margin: 'auto' }}>
                        <MDBCard>
                            <MDBCardBody>

                                <form style={{ background: 'white' }}>

                                    <h2 className='text-center font-weight-bold'>יצירת טופס חדש </h2>

                                    <div className="black-text">

                                        <div className='col-sm-8' style={{ margin: 'auto' }}>
                                            <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>
                                            <h3>* קבוצה</h3>
                                            <select className="browser-default custom-select" onChange={e => setGroupCodeAndShowLastDeliveryBtn(e)}>
                                                <option value='0'>* בחר/י קבוצה</option>
                                                {loadGroups}

                                            </select>
                                            {
                                                showCopyBtn ?
                                                    <div className='col-sm-12 text-center animated fadeIn '>
                                                        <MDBBtn rounded outline color="secondary"
                                                            onClick={() => showLastDeliveryOfgroup()}
                                                        ><i className="far fa-copy fa-x"></i>&nbsp;
                                                            העתק טופס קודם
                                                        </MDBBtn>
                                                    </div>
                                                    : ''
                                            }

                                            <h5 className='text-center'>{copyMessage}</h5>
                                            <hr />
                                            <h3>* תאריך החלוקה</h3>
                                            <MDBInput label="* " value={eventDate} onChange={e => setEventDate(e.target.value)}
                                                required icon="hour" group type="date" validate
                                                error="wrong"
                                                success="right" />
                                            <hr />

                                            <h3>* מיקום</h3>
                                            {inputFields.map((inputField, index) => (
                                                <Fragment key={`${inputField}~${index}`}>
                                                    <div >


                                                        <div className="form-group col-sm-12">
                                                            <label htmlFor="Address">כתובת החלוקה</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Address"
                                                                name="Address"
                                                                value={inputField.Address}
                                                                onChange={event => handleInputChange(index, event)}
                                                            />
                                                        </div>

                                                        <div className="form-group col-sm-12">
                                                            <label htmlFor="Hour">שעה</label>
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                id="Hour"
                                                                name="Hour"
                                                                value={inputField.Hour}
                                                                onChange={event => handleInputChange(index, event)}
                                                            />
                                                        </div>

                                                        <div className="form-group col-sm-12">
                                                            <label htmlFor="Duration">זמן החלוקה בדקות</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                id="Duration"
                                                                name="Duration"
                                                                value={inputField.Duration}
                                                                onChange={event => handleInputChange(index, event)}
                                                            />
                                                        </div>

                                                        <div >
                                                            <button className='rounded-button'
                                                                type="button"
                                                                onClick={() => handleAddFields()}
                                                            >
                                                                +
                                                            </button>&nbsp;

                                                            <button className='rounded-button' disabled={disabledBtn}
                                                                type="button"
                                                                onClick={() => handleRemoveFields(index)}
                                                            >
                                                                -
                                                            </button>

                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))}
                                            <hr />

                                            <h3 style={{ cursor: 'pointer' }} onClick={toggleCollapse("basicCollapse")}
                                            >טקסט עליון&nbsp;
                                                <i className={arrowIcon}></i>
                                            </h3>
                                            <span> (אופציונאלי)</span>
                                            <MDBCollapse id="basicCollapse" isOpen={collapseID}>
                                                <EditorInput
                                                    initialValue={htmlHeader}
                                                    parentCallback={parentCallbackOfHtmlHeaderInput}
                                                />
                                            </MDBCollapse>
                                            <hr />

                                            <h3>מינימום ש"ח להזמנה</h3>
                                            {/* <input type = "number" min = "0"></input> */}
                                            <MDBInput label="* " value={eventMinimumTotalPrice}
                                                onChange={e => setMinimumTotalPrice(e.target.value)}
                                                required icon="hour" group type="number" validate
                                                error="wrong"
                                                min="10"
                                                success="right" />
                                            <hr />
                                            <br />

                                            <h3>לינק ל PayBox</h3>
                                            <MDBInput value={eventPayBoxLink} onChange={e => setEventPayBoxLink(e.target.value)}
                                                icon='money-bill' group type="link" validate
                                                error="wrong"
                                                success="right"
                                            />
                                            <hr />

                                            <h3>אופן תשלום</h3>
                                            <span> (אופציונאלי)</span>
                                            <br />
                                            <label className='myCheckbox'>
                                                <input
                                                    type='checkbox'
                                                    checked={isOnlyPaybox}
                                                    onChange={() => setIsOnlyPaybox(prev => !prev)}
                                                />
                                                &nbsp;הגדרת תשלום רק בפייבוקס
                                            </label>

                                        </div>
                                        <hr />

                                        <MDBRow >
                                            <MDBCol>

                                                {loadProducts()}
                                                <div className='col-sm-8' style={{ margin: 'auto' }}>
                                                    <h3 style={{ cursor: 'pointer' }} onClick={toggleCollapseFooter("basicCollapse2")}
                                                    >טקסט תחתון&nbsp;
                                                        <i className={arrowFooterIcon}></i>
                                                    </h3>
                                                    <span> (אופציונאלי)</span>

                                                    <hr />

                                                    <MDBCollapse id="basicCollapse2" isOpen={collapseIDFooter}>
                                                        <EditorInput
                                                            parentCallback={parentCallbackOfHtmlFooterInput}
                                                            initialValue={htmlFooter}
                                                        />
                                                    </MDBCollapse>


                                                </div>
                                                <div className=' col-sm-12 text-center'>

                                                    <p className='text-center'>{errorMessage}</p>
                                                    <MDBBtn rounded outline color="secondary"
                                                        variant="primary" type="" value="Send"
                                                        className='' validate
                                                        style={{ margin: 'auto' }}

                                                        onClick={() =>
                                                            groupCode != '' && eventDate != ''
                                                                //  && eventAdrress != '' && eventHour != '' && eventDuration != ''
                                                                && inputFields[0].Address != '' && inputFields[0].Hour != '' && inputFields[0].Duration != ''
                                                                && productsInDelivery.length > 0 && eventMinimumTotalPrice >= 10 ?
                                                                setModalShow(true)
                                                                : setErrorMessage('מלא/י את כל שדות החובה')}

                                                    > יצירת טופס

                                                    </MDBBtn>
                                                </div>

                                            </MDBCol>
                                        </MDBRow >

                                        <MyModal />

                                        <MyModal
                                            headlineText='האם ברצונך ליצור את הטופס?'
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            ButtonText='ביטול'
                                            ButtonDeleteText='אישור'
                                            ButtonDinamicFunction={() => postEvent()}
                                        />

                                    </div>

                                </form>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer >
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
        deliveries: state.deliveriesReducer.deliveries,
        lastDelivery: state.deliveriesReducer.lastDelivery
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroups,
            getGroupDeleveriesByCode,
            addGroupDelivery,
            getLastGroupDeliveryByGroupCode
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDelivery);
