import React, { Fragment } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer, MDBInput, MDBCollapse } from 'mdbreact';

//components
import GetProducts from '../../../components/GetProducts'
import EditorInput from '../../../components/EditorInput'
import MyModal from '../../../components/MyModal'
import MyModal2 from '../../../components/MyModal'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetGroupDeliveryById, UpdateGroupDeliveryById, DeleteGroupDeliveryById } from "../../../redux/deliveries/deliveries-actions";
import { getGroupByCode } from "../../../redux/groups/groups-actions";

class UpdateDelivery extends React.Component {

    state = {
        deliveryIdFromCLinet: '',
        groupCode: '',
        deliveryAddress: '',
        deliveryDate: '',
        deliveryHour: '',
        deliveryDuratuin: '',
        eventMinimumTotalPrice: 0,
        deliveryPayBox: '',
        deliveryInActiveProducts: [],
        deliveryIsActive: '',
        checked: false,
        status: '',
        modalShowDelete: false,
        modalShowUpdate: false,
        errorMessage: '',
        updatedInActiveProducts: [],
        disabledBtn: false,
        isOnlyPaybox: false,
        header: '',
        footer: '',
        collapseID: "collapse1",
        collapseFooterID: "collapse3",
        arrowIcon: "fa fa-angle-down rotate-icon",
        arrowFooterIcon: "fa fa-angle-down rotate-icon",
        inputFields: ([
            { Address: '', Hour: '', Duration: '' }
        ])
    }
    postUpdatedDelivery = () => {

        let date = document.getElementById('date').value

        // console.log('updatedInActiveProducts', this.state.updatedInActiveProducts)

        this.state.inputFields.map(field => {

            if (field.Hour.length < 6)
                field.Hour = new Date(`${date} ${field.Hour}`);
        })

        const objectPost = {
            "GroupCode": this.state.groupCode,
            // "Address": "בדיקה",
            "DeliveryDateTime": new Date(date),
            // "Duration": 60,
            "Places": this.state.inputFields,
            "MinimumTotalPrice": this.state.eventMinimumTotalPrice,
            "PayboxGroupLink": this.state.deliveryPayBox ? this.state.deliveryPayBox : document.getElementById('payBox').value,
            "Products": this.state.updatedInActiveProducts,
            "InActiveProducts": [],
            "IsActive": this.state.deliveryIsActive ? this.state.deliveryIsActive : document.getElementById('status').value,
            "IsOnlyPaybox": this.state.isOnlyPaybox,
            "Header": this.state.header,
            "Footer": this.state.footer
        }

        console.log('objectPost', objectPost)
        // console.log('deliveryIdFromCLinet', this.state.deliveryIdFromCLinet)

        this.props.UpdateGroupDeliveryById(this.state.deliveryIdFromCLinet, objectPost).then(() => {
            // window.location.replace(`/admin/deliveries/groups-orders/${this.state.groupCode}/${this.state.deliveryIdFromCLinet}`)
            this.props.history.push(`/admin/deliveries/groups-orders/${this.state.groupCode}/${this.state.deliveryIdFromCLinet}`)

        })

    }

    changeFunction = (event) => {

        this.setState({
            // checked: !this.state.checked,
            deliveryIsActive: event.target.value,
        });
    }
    deleteDelivery = () => {

        this.props.DeleteGroupDeliveryById(this.state.deliveryIdFromCLinet).then(() => {
            this.props.history.push(`/admin/deliveries`)
        })
    }

    handleCallback = (childData) => {

        // console.log('childData', childData)
        this.setState({ updatedInActiveProducts: childData })
    }

    // ******************************
    handleAddFields = () => {
        const values = [...this.state.inputFields];
        values.push({ Address: '', Hour: '', Duration: '' });
        this.setState({ inputFields: values })
        this.setState({ disabledBtn: '' })
    };

    handleRemoveFields = index => {
        const values = [...this.state.inputFields];
        values.splice(index, 1);
        this.setState({ inputFields: values })

        //   console.log('inputFields.length', inputFields.length)
        if (this.state.inputFields.length === 2)
            this.setState({ disabledBtn: 'disabled' })
    };

    handleInputChange = (index, event) => {

        // { address: '', hour: '', duration: '' }

        const values = [...this.state.inputFields];
        if (event.target.name === "Address") {
            values[index].Address = event.target.value;
        }
        else if (event.target.name === 'Hour') {
            values[index].Hour = event.target.value
        }
        else if (event.target.name === 'Duration') {
            values[index].Duration = event.target.value;
        }

        // setInputFields(values);
        this.setState({ inputFields: values })
    };

    parentCallbackOfHtmlHeaderInput = (childData) => {

        // console.log('header', childData)
        this.setState({ header: childData })

    }

    parentCallbackOfHtmlFooterInput = (childData) => {

        // console.log('footer', childData)
        this.setState({ footer: childData })

    }

    toggleCollapse = newCollapseID => () => {
        if (this.state.collapseID !== newCollapseID) {
            this.setState({ collapseID: newCollapseID, arrowIcon: "fa fa-angle-up rotate-icon" })
        }
        else {
            this.setState({ collapseID: "", arrowIcon: "fa fa-angle-down rotate-icon" })
        }
    }

    toggleCollapseFooter = newCollapseID => () => {

        if (this.state.collapseFooterID !== newCollapseID) {
            this.setState({ collapseFooterID: newCollapseID, arrowFooterIcon: "fa fa-angle-up rotate-icon" })
        }
        else {
            this.setState({ collapseFooterID: "", arrowFooterIcon: "fa fa-angle-down rotate-icon" })
        }
    }

    render() {

        return (
            <div className='container-fluid firstDivAfterNav'>
                <MDBContainer id='formContainer'>
                    <MDBRow>
                        <MDBCol md="12" style={{ margin: 'auto' }}>
                            <MDBCard>
                                <MDBCardBody>

                                    <form>
                                        <h2 className='text-center font-weight-bold'>עריכת טופס</h2>

                                        {this.props.deliveries.length > 0 && this.props.deliveries.map(delivery => {
                                            let groupEventHour = new Date(delivery.DeliveryDateTime).toLocaleTimeString('en-US', { hour12: false })
                                            let groupEventDate = new Date(delivery.DeliveryDateTime).toISOString().slice(0, 10)
                                            let hour = ''
                                            return (
                                                <div className="black-text text-right" key={delivery._id}>
                                                    <div className='col-sm-8' style={{ margin: 'auto' }}>
                                                        <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>
                                                        <br />

                                                        <h3>סטאטוס</h3>
                                                        <select className="form-control" onChange={this.changeFunction} id="status">
                                                            {
                                                                delivery.IsActive ?

                                                                    <>
                                                                        <option value={true} selected={true}>פעיל</option>
                                                                        <option value={false}>לא פעיל</option>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <option value={true} >פעיל</option>
                                                                        <option value={false} selected={true}>לא פעיל</option>
                                                                    </>

                                                            }
                                                        </select>
                                                        <hr />
                                                        <br />

                                                        {this.props.groups.map(group => {
                                                            return (
                                                                <>
                                                                    <h3>* קבוצה</h3>

                                                                    <MDBInput key={group.Code} value={group.Name}
                                                                        required icon="home" group type="text" validate disabled
                                                                        error="wrong"
                                                                        success="right" />
                                                                </>
                                                            )
                                                        })}
                                                        <hr />

                                                        <h3>* תאריך החלוקה</h3>

                                                        <MDBInput valueDefault={groupEventDate}
                                                            id='date'
                                                            required icon="hour" group type="date" validate
                                                            onChange={e => this.setState({ deliveryDate: e.target.value })}
                                                            error="wrong"
                                                            success="right" />
                                                        <hr />

                                                        <h3 style={{ cursor: 'pointer' }} onClick={this.toggleCollapse("basicCollapse")}
                                                        >טקסט עליון&nbsp;
                                                            <i className={this.state.arrowIcon}></i>
                                                        </h3>
                                                        <span> (אופציונאלי)</span>

                                                        <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
                                                            <EditorInput
                                                                parentCallback={this.parentCallbackOfHtmlHeaderInput}
                                                                initialValue={delivery.Header}

                                                            />
                                                        </MDBCollapse>
                                                        <hr />
                                                        <h3>* מיקום</h3>
                                                        {this.state.inputFields.map((inputField, index) => {

                                                            //first rendered - set as date. if update - set as string
                                                            inputField.Hour.length < 20 ?
                                                                hour = inputField.Hour
                                                                : hour = new Date(inputField.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

                                                            return (
                                                                <Fragment key={`${inputField}~${index}`}>
                                                                    <div >
                                                                        <div className="form-group col-sm-12">
                                                                            <label htmlFor="Address">כתובת החלוקה</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="Address"
                                                                                name="Address"
                                                                                value={inputField.Address}
                                                                                onChange={event => this.handleInputChange(index, event)}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group col-sm-12">
                                                                            <label htmlFor="Hour">שעה</label>
                                                                            <input
                                                                                type="time"
                                                                                className="form-control"
                                                                                id="Hour"
                                                                                name="Hour"
                                                                                // values={inputField.Hour}
                                                                                // value={new Date(inputField.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                                value={hour}
                                                                                onChange={event => this.handleInputChange(index, event)}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group col-sm-12">
                                                                            <label htmlFor="Duration">זמן החלוקה בדקות</label>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="Duration"
                                                                                name="Duration"
                                                                                value={inputField.Duration}
                                                                                onChange={event => this.handleInputChange(index, event)}
                                                                            />
                                                                        </div>

                                                                        <div >
                                                                            <button className='rounded-button'
                                                                                type="button"
                                                                                onClick={() => this.handleAddFields()}
                                                                            >
                                                                                +
                                                                            </button>&nbsp;
                                                                            <button className='rounded-button' disabled={this.state.disabledBtn}
                                                                                type="button"
                                                                                onClick={() => this.handleRemoveFields(index)}
                                                                            >
                                                                                -
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })}

                                                        <h3>* מינימום ש"ח להזמנה</h3>
                                                        {/* <input type = "number" min = "0"></input> */}
                                                        <MDBInput label="* " value={this.state.eventMinimumTotalPrice}
                                                            onChange={e => this.setState({ eventMinimumTotalPrice: e.target.value })}
                                                            required icon="hour" group type="number" validate
                                                            error="wrong"
                                                            min="0"
                                                            success="right" />
                                                        <hr />
                                                        <br />

                                                        <h3> לינק ל PayBox</h3>
                                                        <MDBInput label="" valueDefault={delivery.PayboxGroupLink}
                                                            icon='money-bill' group type="link" validate
                                                            id="payBox"
                                                            onChange={e => { this.setState({ deliveryPayBox: e.target.value }) }}
                                                            error="wrong"
                                                            success="right"
                                                        />

                                                        <h3>אופן תשלום</h3>
                                                        <span> (אופציונאלי)</span>
                                                        <br />
                                                        <label className='myCheckbox'>
                                                            <input
                                                                type='checkbox'
                                                                checked={this.state.isOnlyPaybox}
                                                                onChange={() =>
                                                                    this.setState(prevState => {
                                                                        return {
                                                                            isOnlyPaybox: !prevState.isOnlyPaybox
                                                                        }

                                                                    })}
                                                            />
                                                            &nbsp;הגדרת תשלום רק בפייבוקס
                                                        </label>

                                                    </div>
                                                    <hr />

                                                    <MDBRow >
                                                        <MDBCol>

                                                            <GetProducts

                                                                isUpdateForm={true}
                                                                isCreateOrUpdateForm={true}
                                                                parentCallback={this.handleCallback}
                                                                inActiveProducts={delivery.InActiveProducts}
                                                                productsFromDelivery={delivery.Products}

                                                            />
                                                            <div className='col-sm-8' style={{ margin: 'auto' }}>
                                                                <h3 style={{ cursor: 'pointer' }} onClick={this.toggleCollapseFooter("footerCollapse")}
                                                                >טקסט תחתון&nbsp;
                                                                    <i className={this.state.arrowFooterIcon}></i>
                                                                </h3>
                                                                <span> (אופציונאלי)</span>
                                                                <MDBCollapse id="footerCollapse" isOpen={this.state.collapseFooterID}>
                                                                    <EditorInput
                                                                        parentCallback={this.parentCallbackOfHtmlFooterInput}
                                                                        initialValue={delivery.Footer}
                                                                    />
                                                                </MDBCollapse>
                                                                <hr />
                                                            </div>

                                                            <div className=' col-sm-12 text-center'>

                                                                <MDBBtn
                                                                    rounded outline color="secondary"
                                                                    variant="primary" type="" value="Send" validate
                                                                    onClick={() =>
                                                                        document.getElementById('Duration').value > 0
                                                                            ?
                                                                            this.setState({ modalShowUpdate: true })
                                                                            : this.setState({ errorMessage: 'אנא מלא/י את כל שדות החובה' })
                                                                    }
                                                                > עדכון טופס</MDBBtn>

                                                                <MDBBtn
                                                                    rounded outline color="secondary"
                                                                    variant="primary" type="" value="Send" validate
                                                                    onClick={() => this.setState({ modalShowDelete: true })}
                                                                > מחיקת טופס
                                                                </MDBBtn>
                                                            </div>

                                                            <p>{this.state.errorMessage}</p>

                                                        </MDBCol>
                                                    </MDBRow >

                                                    <MyModal2 />
                                                    <MyModal2
                                                        headlineText='האם ברצונך לעדכן את הטופס?'
                                                        show={this.state.modalShowUpdate}
                                                        onHide={() => this.setState({ modalShowUpdate: false })}
                                                        ButtonText='ביטול'
                                                        ButtonDeleteText='אישור'
                                                        ButtonDinamicFunction={() => this.postUpdatedDelivery()}
                                                    />

                                                    <MyModal />

                                                    <MyModal
                                                        headlineText='האם ברצונך למחוק את הטופס?'
                                                        headlineBody='לתשומך ליבך - הטופס וההזמנות הקשורות אליו ימחקו ללא אפשרות שיחזור'
                                                        show={this.state.modalShowDelete}
                                                        onHide={() => this.setState({ modalShowDelete: false })}
                                                        ButtonText='ביטול'
                                                        ButtonDeleteText='אישור'
                                                        ButtonDinamicFunction={() => this.deleteDelivery()}
                                                    />

                                                </div>

                                            )

                                        })}

                                    </form>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer >
            </div >
        )
    }

    componentDidMount() {

        document.title = "משק אלמוג - עריכת טופס"
        window.scroll(0, 0)

        const { match } = this.props;
        let inputPlaces = []

        this.props.GetGroupDeliveryById(match.params.deliveryId).then(() => {
            // console.log('deliver', this.props.deliveries)

            this.props.getGroupByCode(match.params.groupCode)
            this.setState({
                deliveryIdFromCLinet: match.params.deliveryId,
                groupCode: match.params.groupCode
            })

            this.props.deliveries.map((delivery, index) => {
                // console.log("delivery", delivery)
                this.setState({ eventMinimumTotalPrice: delivery.MinimumTotalPrice, isOnlyPaybox: delivery.IsOnlyPaybox })
                delivery.Places.map(place => {
                    inputPlaces.push({
                        "Address": place.Address,
                        "Hour": place.Hour,
                        // "Hour":new Date( place.Hour),
                        "Duration": place.Duration
                    })
                })
                if (delivery.Header !== undefined && delivery.Header !== null)
                    this.setState({ header: delivery.Header, collapseID: true, arrowIcon: "fa fa-angle-up rotate-icon" })

                if (delivery.Footer !== undefined && delivery.Footer !== null)
                    this.setState({ footer: delivery.Footer, collapseFooterID: true, arrowFooterIcon: "fa fa-angle-up rotate-icon" })

            })

            // console.log('inputPlaces', inputPlaces)

            this.setState({ groupCode: match.params.groupCode, inputFields: [...inputPlaces], disabledBtn: 'disabled' })
            // console.log('groups', this.props.groups)
        })

    }
}

const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
        deliveries: state.deliveriesReducer.deliveries

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroupByCode,
            GetGroupDeliveryById,
            UpdateGroupDeliveryById,
            DeleteGroupDeliveryById
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDelivery);
