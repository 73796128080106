import React, { Component } from "react";
import MyModal from '../../../../../components/MyModal'
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupOrdersByGroupDeliveryId, updateGroupOrderPaidStatus } from "../../../../../redux/groupOrders/group-orders-action"
import { GetGroupDeliveryById, getGroupDeliveryTotalsById } from "../../../../../redux/deliveries/deliveries-actions";
import { getProducts } from "../../../../../redux/products/products-actions";
import { getGroupByCode } from "../../../../../redux/groups/groups-actions";
// import { CSVLink, CSVDownload } from "react-csv";

import "../groupOrdersSummary.css";
import moment from "moment";
import ManagerShowDeliveryOrders from "./ManagerShowDeliveryOrders";

class ManagerLoadDeliveryOrders extends Component {
  state = {
    csvData: [],
    productsIndexInTable: [],
    columns: [
      {
        Header: "שם מלא",
        accessor: "שם מלא",
      },
      // {
      //   Header: "מספר הזמנה",
      //   accessor: "מספר הזמנה",
      // },
      {
        Header: "תאריך הזמנה",
        accessor: "תאריך הזמנה",
      },
      // {
      //   Header: "Email",
      //   accessor: "Email",
      // },
      {
        Header: "טלפון",
        accessor: "טלפון",
      },

      {
        Header: "נקודת חלוקה",
        accessor: "נקודת חלוקה",
      },
      {
        Header: "הערות",
        accessor: "הערות",
      },
    ],
    data: [],
    // totalAmounts: [],
    groupName: "",
    delivery: [],
    modalShow: false,
    token: localStorage.getItem("token") != null ? "admin" : "manager"
    // hasOrders: true,
  };

  render() {
    return (
      <div className="container-fluid" style={{ backgroundColor: "white" }}>
        {/* <CSVLink data={this.state.csvData}>Download me</CSVLink>; */}
        <ManagerShowDeliveryOrders
          csvData={this.state.csvData}
          groupName={this.state.groupName}
          groupCode={this.props.match.params.groupCode}
          columns={this.state.columns}
          data={this.state.data}
          updateData={this.updateData}
          delivery={this.props.deliveries[0]}
        />

        <>
          <MyModal />

          <MyModal
            headlineText="סטאטוס התשלום עודכן"
            // headlineBody='לתשומך ליבך - לא ניתן יהיה לשחזר לאחר המחיקה'
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
          // ButtonText='ביטול'
          // ButtonDeleteText='אישור'
          // ButtonDinamicFunction={() => this.deleteDelivery()}
          />
        </>
      </div>
    );
  }

  componentDidMount() {
    document.title = "משק אלמוג - הזמנות - תצוגת מנהלים";

    const { match } = this.props;

    this.props.getGroupDeliveryTotalsById(match.params.deliveryId);

    this.props.getGroupByCode(match.params.groupCode).then(() => {
      this.setState({ groupName: this.props.groups[0].InternalName });
    });
    this.props
      .getGroupOrdersByGroupDeliveryId(match.params.deliveryId)
      .then(() => {
        // console.log("delivberies", this.props.deliveries)
        this.setState({ delivery: this.props.deliveries[0] });

        this.props.getProducts().then(() => {
          // console.log("orders: ", this.props.orders);
          try {
            this.prepColumns();
            this.perpData();
            this.prepCsvFile();
          } catch (error) { }
        });
        // .catch((err) => console.log(err));
      });
    this.props.GetGroupDeliveryById(match.params.deliveryId).then(() => {
      // console.log("Group Deliveries: ", this.props.deliveries);
    });
    // .catch((err) => console.log(err));

    // console.log('has', this.state.hasOrders)
  }

  prepColumns = () => {
    this.props.deliveries[0].Products.map((dp) => {
      this.setState(
        {
          productsIndexInTable: [...this.state.productsIndexInTable, dp.Code],
        },
        () => {
          // console.log("indexes: ", this.state.productsIndexInTable);
        }
      );
      //console.log("Products: ", this.props.products);
      this.setState(
        {
          columns: [
            ...this.state.columns,
            {
              Header: this.props.products.filter((p) => p.Code === dp.Code)[0]
                .ShortName,
              accessor: this.props.products.filter((p) => p.Code === dp.Code)[0]
                .ShortName,
            },
          ],
        },
        () => {
          // console.log("columns: ", this.state.columns);
        }
      );
    });
    const arr = [
      {
        Header: "שיטת תשלום",
        accessor: "שיטת תשלום",
      },
      // {
      //   Header: "הערות פנימיות",
      //   accessor: "הערות פנימיות",
      // },
      {
        Header: 'סה"כ לתשלום',
        accessor: 'סה"כ לתשלום',
      },
      {
        Header: "האם שולם",
        accessor: "האם שולם",
      },
    ];

    this.setState(
      {
        columns: [...this.state.columns, ...arr],
      },
      () => {
        // console.log("columns2: ", this.state.columns);
      }
    ); //another array
  };

  perpData = () => {
    // console.log("perpData");
    const newData = [];
    // const name = this.props.orders;
    this.setState({ data: [] });
    this.props.orders.map((order) => {
      const personOrder = {};

      personOrder["Id"] = order.Id;

      personOrder["האם שולם"] = order.IsPaid && order.PaymentMethod === "PayBox" ? (
        <label>
          שולם
        </label>
      ) : order.PaymentMethod === "PayBox" ? (
        ''
      ) : ''

      personOrder["שם מלא"] =
        order.PersonInfo.FirstName + " " + order.PersonInfo.LastName;
      // personOrder["מספר הזמנה"] = order.OrderNumber;
      // personOrder["Email"] = order.PersonInfo.Email;
      personOrder["תאריך הזמנה"] = moment(order.OrderDate).format(
        "DD/MM/YYYY HH:mm"
      );
      personOrder["טלפון"] = order.PersonInfo.Phone;
      personOrder["נקודת חלוקה"] = order.Place

      personOrder["הערות"] = order.Comment;
      personOrder["orderNumber"] = order.OrderNumber;
      this.state.productsIndexInTable.map((code, i) => {
        if (order.Products.filter((p) => p.Code === code).length > 0) {
          const amount = order.Products.filter((p) => p.Code === code)[0]
            .Amount;
          personOrder[this.state.columns[i + 5].Header] = amount;
        } else {
          personOrder[this.state.columns[i + 5].Header] = "";
        }
      });
      // personOrder["תגובה"] = order.IsPaid ? "שולם" : "";
      personOrder["שיטת תשלום"] = order.PaymentMethod;
      if (this.state.token === "admin")
        personOrder['סה"כ לתשלום'] = order.TotalPrice + " ₪"
      else personOrder['סה"כ לתשלום'] = ""
      // personOrder["הערות פנימיות"] = order.InternalComment;

      const setPaymentStatus = (OrderNumber) => {
        const { match } = this.props;

        const objectPost = {
          GroupDeliveryId: match.params.deliveryId,
          OrderNumber: OrderNumber,
        };
        // console.log('objectPost', objectPost)
        this.props.updateGroupOrderPaidStatus(objectPost).then(() => {
          // window.location.reload()

          // console.log('res2', res)
          // this.setState({ modalShow: true })

          this.updateData();
        });
      };

      // console.log("person: ", personOrder);
      newData.push(personOrder);
      // this.setState(
      //   {
      //     data: [...this.state.data, personOrder],
      //   },
      //   () => {
      //     console.log("data:", this.state.data);
      //   }
      // );
    });
    this.setState({ data: newData }, () => {
      // console.log("new data: ", this.state.data);
      this.prepTotals();
    });
  };
  prepTotals = () => {
    const totalsData = [];
    const length = this.state.productsIndexInTable.length + 6;
    for (var i = 0; i < length; i++) {
      if (i === 0) {
        totalsData.push('סה"כ');
      } else if (i === 1 || i === 2 || i === 3 || i === 4) {
        totalsData.push("");
      } else if (i === length - 1) {
        totalsData.push(100);
      } else {
        totalsData.push(this.getTotalsRow(this.state.columns[i]));
      }
    }
    this.setState(
      {
        data: [...this.state.data, totalsData],
      },
      () => {
        // console.log("data with totals:", this.state.data);
      }
    );
  };

  getTotalsRow = (key) => {
    let total = 0;
    this.state.data.forEach((item) => {
      if (item[key.Header] !== "") {
        total += parseInt(item[key.Header]);
      }
    });
    // console.log("total: ", total);
    return total;
  };

  updateData = () => {
    const { match } = this.props;
    this.props.getGroupDeliveryTotalsById(match.params.deliveryId);
    this.props
      .getGroupOrdersByGroupDeliveryId(match.params.deliveryId)
      .then(() => {
        this.perpData();
        this.prepCsvFile();
      });
  };

  prepCsvFile = () => {
    let csvHeadersAndData = [];
    let headers = [];
    this.state.columns.map((cul) => {
      headers.push(cul.Header);
    });
    csvHeadersAndData.push(headers);
    let csvData = [];
    this.state.data.map((d, i) => {
      if (i != this.state.data.length - 1) {
        const current = this.props.orders.filter(
          (order) => order.OrderNumber === d.orderNumber
        )[0];
        // console.log("current: ", current);
        // if (i !== 0 || i == this.state.data.length - 1) {
        // console.log("d: ", d)
        // console.log("delivey", this.state.delivery.GroupCode)

        let arr = Object.keys(d).map((k) => d[k]);
        // //  arr.push(isPaid);
        // arr.push("שולם");
        arr.splice(1, 1);
        arr.splice(0, 1);
        arr.splice(5, 1);

        // if it's Nes Tsiona - splice place 17
        if (this.props.match.params.groupCode == 3) {
          arr.splice(17, 1)
        }
        else {
          // arr.splice(19, 1)
          // arr.splice(17, 1)
          // arr.splice(23, 1)
          // arr.splice(21, 1)

        }
        if (arr[25] !== undefined) {
          arr[25] = arr[25].replace("₪", "");
        }
        // console.log("arr: ", arr);
        if (current == undefined) {
          arr.push("");
        } else if (current.IsPaid === true) {
          arr.push("שולם");
        } else {
          arr.push("");
        }
        console.log('arr', arr);

        csvHeadersAndData.push(arr);
        // }
      }
    });

    //csvHeadersAndData.push(csvData)
    this.setState({ csvData: csvHeadersAndData }, () => {
      // console.log("csv data: ", this.state.csvData)
    });
  };
}

const mapStateToProps = (state) => {
  return {
    orders: state.groupOrdersReducer.orders,
    deliveries: state.deliveriesReducer.deliveries,
    totalPricesForDelivery: state.deliveriesReducer.totalPricesForDelivery,
    products: state.productReducer.products,
    groups: state.groupReducer.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGroupOrdersByGroupDeliveryId,
      GetGroupDeliveryById,
      getProducts,
      getGroupByCode,
      updateGroupOrderPaidStatus,
      getGroupDeliveryTotalsById,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerLoadDeliveryOrders);

