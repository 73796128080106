import { MDBBtn } from 'mdbreact';
import React, { useState } from 'react';
import Modal from "react-bootstrap/esm/Modal";
import '../preparation.scss';

const PreparationOrderNameList = ({ modalShow, setModalShow, sortedOrders, setPlace }) => {

    const [search, setSearch] = useState("")

    const handleClick = (index) => {
        setPlace(index + 1)
        setModalShow(false)
    }

    const handleCancel = () => {
        setSearch("")
        setModalShow(false)
    }

    return (
        <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='preparation-order-name-list-modal'
            style={{ height: "100vh", padding: "0 15px" }}
        >
            <i className='fas fa-times' onClick={handleCancel} />
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    רשימת מזמינים
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    className='form-control'
                    type='search'
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder="חיפוש..."
                />
                <div className="flex">
                    {sortedOrders
                        .filter(o => {
                            if (search != "") {
                                if (o.PersonInfo.FirstName.includes(search) | o.PersonInfo.LastName.includes(search))
                                    return o
                            }
                            else
                                return o
                        })
                        .map(order => {
                            return <React.Fragment key={order.Id}>
                                <p onClick={() => handleClick(order.index)}>{order.PersonInfo.FirstName}&nbsp;{order.PersonInfo.LastName}</p>
                            </React.Fragment>
                        })}

                </div>
            </Modal.Body>
            <MDBBtn
                rounded outline color="secondary"
                variant="primary" type="" value="Send" validate
                onClick={handleCancel}
            > ביטול</MDBBtn>
        </Modal>
    )
}

export default PreparationOrderNameList