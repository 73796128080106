import React, { useState, useEffect } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBCard } from 'mdbreact'
import CopyToClipBoard from '../../../components/CopyToClipBoard'
import { useParams } from "react-router-dom";
import moment from 'moment-timezone'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetGroupDeliveryById } from "../../../redux/deliveries/deliveries-actions";
import { getGroupByCode } from "../../../redux/groups/groups-actions";

const SendMessege = (props) => {
    const [textForWhatssApp, setTextForWhatssApp] = useState(null)
    const [newDateObj, setNewDateObj] = useState('')
    const [visibility, setVisibility] = useState('hidden')
    const [messageType, setMessageType] = useState(0)
    const [selectedType, setSelectedType] = useState(0)
    const initialMessageText = `*🍅 שרי תמר וויטני מתוק 🍅 שרי תמר לובלו מתוק מאוד 🧡 שרי כתום מתוק 🖤 שרי ליקופן ("מנומר") חמוץ-מתוק 🥒 מלפפון 🍅 עגבניות 🌶️  פלפלונים 🍄 פטריות 🍠 בטטה 🥬 חסה 🫒 זיתים `

    const [messageText, setMessageText] = useState(initialMessageText)
    const [weekText, setWeekText] = useState("")
    let { groupCode, deliveryId } = useParams();

    useEffect(() => {
        props.GetGroupDeliveryById(deliveryId);
        props.getGroupByCode(groupCode);

    }, [])

    useEffect(() => {
        if (props.deliveries.length > 0 && props.groups.length > 0) {

            if (props.groups[0].Code == 1) {
                setSelectedType(2)
                setMessageTypeMethod(2)
            }
            else if (props.groups[0].Code == 2) {
                setSelectedType(3)
                setMessageTypeMethod(3)
            }

        }

    }, [props.groups, props.deliveries])

    useEffect(() => {
        if (messageType != 0) {

            let whatssAppOfGroup = ''
            if (props.groups.WhatsappGroupLink != "" && props.groups.WhatsappGroupLink != undefined)
                whatssAppOfGroup = ` 
                לינק להצטרפות לקבוצת הווטסאפ:
                ${props.groups.WhatsappGroupLink}
                `
            let hours = ''
            let oldOateObject = (new Date())
            let dateObject = (new Date())


            //set beggining hour
            props.deliveries[0].Places.map((place, index) => {
                if (props.deliveries[0].Places.length === 1) {
                    hours = moment(new Date(place.Hour)).tz("Asia/Jerusalem").format('HH:mm')
                    oldOateObject = new Date(place.Hour)
                    dateObject.setTime(oldOateObject.getTime() + (place.Duration * 60 * 1000));
                    setNewDateObj(moment(dateObject).tz("Asia/Jerusalem").format('HH:mm'))
                }
            })
            setVisibility('initial')
            let dateForMessege = moment(dateObject).tz("Asia/Jerusalem").format('HH:mm')

            //'when user selected default OR הודעת פרסום OR הודעת תזכורת יום לפני
            let combineText = groupCode != 2 ? `${hours}-${dateForMessege} - ${props.deliveries[0].Places[0].Address}` : ``
            if (messageType != 3) {
                //UPDATE WHATSSAPP MESSAGE
                setTextForWhatssApp(
                    `${weekText}
${combineText}
${messageText}    

✔️לינק לרישום:
https://meshekalmog.co.il/groups/${groupCode}/${deliveryId}
    
✔️לינק לתשלום בפייבוקס:
${props.deliveries[0].PayboxGroupLink}
${whatssAppOfGroup}
*משק אלמוג*`)

            }
            //when user selected רשימת מוצרים ומחירים
            else if (messageType == 3) {
                //UPDATE WHATSSAPP MESSAGE
                setTextForWhatssApp(
                    `
*🥒 מלפפונים 1.5 ק"ג ב-10 ש"ח
*🍅 שרי תמר מזן "לובלו"- כ-1 ק"ג ב-20 ש"ח
*🍅 שרי תמר מזן "וויטני"- כ-1 ק"ג ב-15 ש"ח
*🧡 שרי כתום - 500 גרם ב-10 ש"ח
*🖤 שרי ליקופן מנומר - 500 גרם ב-10 ש"ח
*🍅 עגבניות חממה - כ- 1 ק"ג ב-10 ש"ח 
*🥒 מלפפון בייבי - 500 גרם ב-10 ש"ח
*🍄 זוג פטריות - ב-15 ש"ח
*🌶 🌶2 פלפלונים ב-15 ש"ח
🍠 *בייבי בטטה ב- 10 ש"ח
🥔 *בייבי תפוחי אדמה ב-10 ש"ח`)
            }
        }

    }, [messageType, messageText])

    const setMessageTypeMethod = (value) => {

        let text = ``
        let weekTextToUpdate = ''
        let dayOfWeek = ''

        switch (new Date(props.deliveries[0].DeliveryDateTime).getDay('he-IL', { hour12: false })) {
            case 0:
                dayOfWeek = "ראשון";
                break;
            case 1:
                dayOfWeek = "שני";
                break;
            case 2:
                dayOfWeek = "שלישי";
                break;
            case 3:
                dayOfWeek = "רביעי";
                break;
            case 4:
                dayOfWeek = "חמישי";
                break;
            case 5:
                dayOfWeek = "שישי";
                break;
            case 6:
                dayOfWeek = "שבת";
                break;
            default:
                break;
        }

        if (value == 1) {
            text = initialMessageText
            weekTextToUpdate = `🍅🥒 משק אלמוג מגיעים ל${props.groups[0].Name} ${messageType == 2 ? 'מחר' : `*ביום ${dayOfWeek}*`}`

        } else if (value == 2) {
            text = `
🍅 שרי תמר וויטני מתוק 
🍅 שרי תמר לובלו מתוק מאוד
🧡 שרי כתום מתוק
🖤 שרי ליקופן חמוץ - מתוק
🥒 מלפפון
🍅 עגבניות
🌶️ פלפלונים
🍄 פטריות
🍠 בטטה
🥔 תפו"א
🥦 ברוקולי`
            weekTextToUpdate = `           
🍅🥒  משק אלמוג מגיעים ל${props.groups[0].Name} ${messageType == 2 ? 'מחר' : `*ביום ${dayOfWeek}.*`}
*מינימום הזמנה בטופס - 80 ש"ח.*
*ההזמנות יחכו לכם בשוק.*
* תשלום מראש בפייבוקס בלבד *
`
        }
        else if (value == 3) {
            // text = `*🍅 שרי תמר וויטני מתוק 🍅 שרי תמר לובלו מתוק מאוד 🧡 שרי כתום מתוק 🖤 שרי ליקופן("מנומר") חמוץ - מתוק 🥒 מלפפון 🍅 עגבניות 🌶️  פלפלונים 🍄 פטריות 🍠 בטטה 🥬 חסה 🫒 זיתים`
            text = `
🍅 שרי תמר וויטני מתוק 
🍅 שרי תמר לובלו מתוק מאוד
🧡 שרי כתום מתוק
🖤 שרי ליקופן חמוץ - מתוק
🥒 מלפפון
🍅 עגבניות
🌶️ פלפלונים
🍄 פטריות
🍠 בטטה
🥔 תפו"א
🥬 חסה
🥦 ברוקולי`

            let hours = ''
            let oldOateObject = (new Date())
            let dateObject = (new Date())
            //set beggining hour
            props.deliveries[0].Places.map((place, index) => {
                if (props.deliveries[0].Places.length === 1) {
                    hours = moment(new Date(place.Hour)).tz("Asia/Jerusalem").format('HH:mm')
                    oldOateObject = new Date(place.Hour)
                    dateObject.setTime(oldOateObject.getTime() + (place.Duration * 60 * 1000));
                    setNewDateObj(moment(dateObject).tz("Asia/Jerusalem").format('HH:mm'))
                }
            })
            let dateForMessege = moment(dateObject).tz("Asia/Jerusalem").format('HH:mm')

            weekTextToUpdate = `🍅🥒 משק אלמוג מגיעים ל${props.groups[0].Name} פטאי 12 ${messageType == 2 ? 'מחר' : `*ביום ${dayOfWeek}*`}
${hours} - ${dateForMessege} למשלמים במזומן
16:30 - 18:00 למשלמים בפייבוקס`
        }
        setMessageText(text)
        setWeekText(weekTextToUpdate)
    }

    return (
        <div className='container-fluid firstDivAfterNav'>
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="12" >
                        <MDBCard className='col-sm-12'>
                            <h2 className='text-center font-weight-bold'>שליחת הודעה </h2>

                            <h4>אופן השליחה</h4>
                            <select id="optionOfSendingMessage" className="form-control">
                                <option value="1">WhatssApp</option>
                            </select>
                            <br />

                            <h4>בחירת הודעה</h4>
                            <select onChange={(e) => setMessageType(e.target.value)} className="form-control">
                                <option value="0">בחר/י</option>
                                <option value="1">הודעת פרסום</option>
                                <option value="2">הודעת תזכורת יום לפני</option>
                                <option value="3">רשימת מוצרים ומחירים</option>
                            </select>
                            <br />

                            {
                                (messageType == 1 || messageType == 2) &&
                                <>
                                    <h5>נוסח ההודעה</h5>
                                    <select onChange={(e) => setMessageTypeMethod(e.target.value)} className="form-control" defaultValue={selectedType}>
                                        <option value={0}>בחר/י</option>
                                        <option value="1">הודעה כללית</option>
                                        <option value="2">שכונה למד</option>
                                        <option value="3">רמת אביב</option>
                                    </select>
                                </>
                            }
                            <br />

                            <textarea value={textForWhatssApp} rows="12" className="md-textarea form-control"></textarea>

                            <div className="col-sm-12 text-center" style={{ visibility: visibility }}>
                                <CopyToClipBoard
                                    text={textForWhatssApp}
                                // link={`https://meshekalmog.co.il/groups/${groupCode}/${deliveryId}`}
                                />
                            </div >

                        </MDBCard >
                    </MDBCol >
                </MDBRow >
            </MDBContainer >
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        deliveries: state.deliveriesReducer.deliveries,
        groups: state.groupReducer.groups,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            GetGroupDeliveryById,
            getGroupByCode
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMessege)

