import React from 'react'
import './LeftSideMenu.scss'
import MakingOrders from './MakingOrders'
import { MDBAnimation } from 'mdbreact'
const LeftSideMenu = () => {
    return (
        <div className='left-side-menu'>
            <MDBAnimation type="fadeInLeft" delay="0.2s">
                <div className='left-side-menu-wrapper'>
                    <MakingOrders />
                </div>
            </MDBAnimation >
        </div >
    )
}

export default LeftSideMenu