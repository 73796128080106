import React, { useState, useEffect } from 'react'
import { MDBBtn, MDBCard, MDBRow, MDBCol, MDBContainer, MDBInput } from 'mdbreact';
import MyModal from '../../../components/MyModal'
import { useHistory } from 'react-router-dom';
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createGroup, getGroups } from "../../../redux/groups/groups-actions";

const CreateGroup = (props) => {

    const [groupName, setGroupName] = useState('')
    const [groupInternalName, setGroupInternalName] = useState('')
    const [groupWhatssApp, setGroupWhatssApp] = useState('')
    const [modalShow, setModalShow] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const history = useHistory()

    useEffect(() => {

        props.getGroups()

    }, []);

    const postGroup = () => {
        let lastEventIndex = props.groups.length + 1
        // console.log('rops.groups.length', props.groups.length)

        const objectPost = {
            "Name": groupName,
            "InternalName": groupInternalName,
            "Code": lastEventIndex,
            "WhatsappGroupLink": groupWhatssApp
        }

        // console.log('objectPost', objectPost)
        props.createGroup(objectPost).then(() => {
            history.push('/admin/groups')
        })
    }

    return (
        <div className=' container-fluid'>
            <MDBContainer id='formContainer' className='firstDivAfterNav'>
                <MDBRow>
                    <MDBCol md="12" style={{ margin: 'auto' }}>
                        <MDBCard>
                            <form className='col-sm-8'>
                                <h2 className='text-center font-weight-bold'>יצירת קבוצה חדשה </h2>

                                <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>

                                <div className="black-text">

                                    <MDBInput label="* שם ציבורי לקבוצה" value={groupName}
                                        onChange={e => setGroupName(e.target.value)}
                                        required icon="user" group type="text" validate
                                        error="wrong"
                                        success="right" />

                                    <MDBInput label="* שם פנימי לקבוצה (לדוחות)" value={groupInternalName}
                                        onChange={e => setGroupInternalName(e.target.value)}
                                        required icon="user" group type="text" validate
                                        error="wrong"
                                        success="right" />


                                    <MDBInput label="לינק לווטסאפ" value={groupWhatssApp} onChange={e => setGroupWhatssApp(e.target.value)}
                                        icon="phone" group type="text" validate
                                        error="wrong"
                                        success="right" />

                                    <div className=' col-sm-12 text-center'>
                                        <MDBBtn style={{ margin: 'auto' }}
                                            rounded outline color="secondary"
                                            variant="primary" type="" value="Send" validate
                                            onClick={() =>
                                                groupName != '' && groupInternalName != '' ? setModalShow(true)
                                                    : setErrorMessage('מלא את כל שדות החובה')}

                                        > יצירה</MDBBtn>
                                    </div>
                                    <p>{errorMessage}</p>

                                    <MyModal />

                                    <MyModal
                                        headlineText='האם ברצונך ליצור את הקבוצה?'
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        ButtonText='ביטול'
                                        ButtonDeleteText='אישור'
                                        ButtonDinamicFunction={() => postGroup()}
                                    />


                                </div>

                            </form>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer >
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroups,
            createGroup
        },
        dispatch
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup)