import React from 'react';
import ReactToPrint from 'react-to-print';
// import { MDBBtn } from 'mdbreact';
import RoundedButton from './RoundedButton/RoundedButton';

const Print = (props) => {

  return (

    <ReactToPrint
      documentTitle={props.titleToPrint}
      trigger={() =>
        <RoundedButton
          disabled={props.disabled}
          icon="fas fa-print"
        />
      }
      content={() => props.componentRef}
    />

  );
};
export default Print