// import axios from "axios";
import Api from '../../utils/Api'
let token = "";

if (localStorage.getItem("token")) token = localStorage.getItem("token");
else if (sessionStorage.getItem("token"))
  token = sessionStorage.getItem("token");

export const getProducts = () => {
  return (dispatch) => {
    return Api
      .get("products")
      .then((res) => {
        // console.log('res.data.Payload', res.data.Payload)
        // res.data.Payload.sort((a, b) => a.Code - b.Code);
        if (res.data) {
          dispatch({
            type: "GET_PRODUCTS",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const getActiveProducts = () => {
  return (dispatch) => {
    return Api
      .get("products/active")
      .then((res) => {
        // console.log('res.data.Payload', res.data.Payload)
        // res.data.Payload.sort((a, b) => a.Code - b.Code);
        if (res.data) {
          dispatch({
            type: "GET_ACTIVE_PRODUCTS",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const createProduct = (objectPost) => {
  return (dispatch) => {
    return Api
      .post(`products/add`, objectPost, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.request.status == 200) {
          // alert("המוצר נוסף");
          dispatch({
            type: "CREATE_PRODUCT",
          });
        }
      })
      .catch((e) => {
        alert("לא נוסף");
        console.log("e", e);
      });
  };
};

export const deleteProductByCode = (groupCode) => {
  return (dispatch) => {
    return Api
      .delete(`products/${groupCode}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("res deleted", res);
        if (res.request.status == 200) {
          // alert("המוצר נמחק");
          dispatch({
            type: "DELETE_PRODUCT",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        alert("שגיאה");
      });
  };
};

export const GetProductByCodeFromLink = (productCode) => {
  return (dispatch) => {
    return Api
      .get(`products/${productCode}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // alert('success')
        if (res.data) {
          dispatch({
            type: "GET_PRODUCT_BY_CODE_FROM_LINK",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        alert("faile");
        console.log(e);
      });
  };
};

export const updateProductByCode = (productCode, objectPost) => {
  // console.log('objectPost', objectPost)
  // alert('in')
  return (dispatch) => {
    return Api
      .post(
        `products/update/${productCode}`,
        objectPost,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        // alert("המוצר עודכן");
        if (res.data) {
          dispatch({
            type: "UPDATE_PRODUCT_BY_CODE",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        alert("failed");
        // console.log(e);
      });
  };
};

export const changeProductStockStatus = (productCode) => {
  console.log('addProductToStock', productCode)
  // alert('in')
  return (dispatch) => {
    return Api
      .post(
        `products/update/in-stock/${productCode}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        // alert("סטאטוס המוצר השתנה");
        if (res.data) {
          dispatch({
            type: "ADD_PRODUCT_TO_STOCK",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        alert("failed");
        // console.log(e);
      });
  };
};


export const getProductByCode = (code) => {
  return {
    type: "GET_PRODUCT_BY_CODE",
    payload: { code },
  };
};

export const getInActiveProducts = (code) => {
  return {
    type: "GET_INACTIVE_PRODUCTS",
  };
};
