import React, { useEffect, useState } from 'react'
import moment from "moment";
import { Link, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOnlineOrderByGroupDeliveryIdAndOrderNumber } from '../../../../../redux/onlineOrders/online-orders-action';
import { MDBCard, MDBCol, MDBContainer, MDBRow, MDBCardImage } from 'mdbreact';

const LastPage = (props) => {
    const [load, isLoad] = useState(true)
    let { deliveryId, groupCode, orderNumber } = useParams();
    useEffect(() => {

        props.getOnlineOrderByGroupDeliveryIdAndOrderNumber(deliveryId, orderNumber).then(() => {
            isLoad(false)
        })

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById('myNav').style.display = 'block'
        document.getElementById('myNav').style.background = `
        linear-gradient(
            90deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(9, 121, 59, 1) 68%,
            rgba(0, 212, 255, 1) 100%
          )`;
        document.getElementById('WelcomeImg').style.display = 'none';
    })
    return (
        <div className='container-fluid'>
            <MDBContainer className='firstDivAfterNav'>
                <MDBRow>
                    <MDBCol sm='12' lg='8' className='text-center' style={{ margin: 'auto' }}>
                        <MDBCard>
                            <h3 className='text-center'>תודה על ההזמנה ממשק אלמוג!</h3>
                            {/* {console.log('onlineOrders', props.onlineOrders)} */}
                            {
                                !load ?
                                    <div className='col-lg-8 col-sm-12 animated fadeIn' style={{ margin: 'auto' }}>
                                        <p>
                                            שלום {props.onlineOrders[0].PersonInfo.FirstName},
                                            <br />
                                            מספר הזמנתך הוא: {props.onlineOrders[0].OrderNumber}
                                            <br />
                                            מועד החלוקה:&nbsp;
                                            {moment(props.onlineOrders[0].DeliveryDate).format("DD/MM/YYYY")},&nbsp;
                                            בין השעות:&nbsp;
                                            {props.onlineOrders[0].DeliveryTime}
                                        </p>
                                        <p>בדקות הקרובות ישלח אליך מייל עם הפרטים המלאים</p>
                                        <a href={`/online/orders/userUpdate/999/${deliveryId}/${orderNumber}`} >מעבר לעריכת ההזמנה</a>
                                        <p>תודה,
                                            <br />
                                            משק אלמוג
                                        </p>
                                        <Link to='/'>
                                            <MDBCardImage
                                                style={{ width: '20%', margin: 'auto' }}
                                                hover
                                                overlay='white-light'
                                                className='card-img-top'
                                                src={require('../../../../../components//img/almog.PNG')}
                                                alt='man'
                                            />
                                        </Link>
                                    </div>

                                    : ''
                            }

                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div >
    )
}
const mapStateToProps = (state) => {
    return {

        onlineOrders: state.onlineOrdersReducer.onlineOrders,
        cart: state.cartReducer.cart
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOnlineOrderByGroupDeliveryIdAndOrderNumber
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LastPage);

