
import React from 'react';
import './SumChart.scss'
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    AreaSeries,
    LineSeries,
    VerticalBarSeries
} from 'react-vis';


const SumChart = ({ data }) => {

    // const data = [
    //     { x: 1, y: 13000 },
    //     { x: 2, y: 16500 },
    //     { x: 3, y: 14250 },
    //     { x: 4, y: 19000 },
    //     { x: 5, y: 20000 },
    //     { x: 6, y: 21000 },
    //     { x: 7, y: 22000 },
    //     { x: 8, y: 23000 },

    // ];

    const style = {
        overFlowX: "auto",
        width: "100%"
    }
    return (
        <div className='sum-charts'>
            <XYPlot
                xType='ordinal'
                style={style}
                width={1000}
                height={500}>
                <HorizontalGridLines />
                <VerticalGridLines />
                <XAxis title="תאריך" />
                <YAxis title="סהכ הכנסות" />
                <AreaSeries
                    // className="area-series-example"
                    // curve="curveNatural"
                    data={data} />

            </XYPlot>
        </div>

        // <XYPlot width={700} height={300}>
        //     <VerticalGridLines />
        //     <HorizontalGridLines />
        //     <XAxis />
        //     <YAxis />
        //     <AreaSeries
        //         className="area-series-example"
        //         curve="curveNatural"
        //         data={data}

        //     />
        // </XYPlot>
    );
}
export default SumChart