import React, { Component } from "react";
import MyModal from "../../../../components/MyModal";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupOrdersByGroupDeliveryId, updateGroupOrderPaidStatus, } from "../../../../redux/groupOrders/group-orders-action";
import { getOnlineOrdersByGroupDeliveryId } from "../../../../redux/onlineOrders/online-orders-action";

import { getOnlineDeliveryById, getOnlineDeliveryTotalsById } from "../../../../redux/onlineDeliveries/onlineDeliveries-actions";


import { getProducts } from "../../../../redux/products/products-actions";
import { getGroupByCode } from "../../../../redux/groups/groups-actions";
// import { CSVLink, CSVDownload } from "react-csv";

import "../../orders/show_orders/groupOrdersSummary.css";
import moment from "moment";
import OrdersShow from "./OrdersShow";

class OrdersLoad extends Component {
    state = {
        csvData: [],
        productsIndexInTable: [],
        orderTotalPrice: 0,
        columns: [
            {
                Header: "שם מלא",
                accessor: "שם מלא",
            },
            {
                Header: "האם שולם",
                accessor: "האם שולם",
            },
            {
                Header: 'סה"כ לתשלום',
                accessor: 'סה"כ לתשלום',
            },
            // {
            //   Header: "מספר הזמנה",
            //   accessor: "מספר הזמנה",
            // },
            {
                Header: "תאריך הזמנה",
                accessor: "תאריך הזמנה",
            },
            // {
            //   Header: "Email",
            //   accessor: "Email",
            // },
            {
                Header: "טלפון",
                accessor: "טלפון",
            },
            {
                Header: "נקודת חלוקה",
                accessor: "נקודת חלוקה",
            },
            {
                Header: "הערות",
                accessor: "הערות",
            },
            {
                Header: "שעת המשלוח",
                accessor: "שעת המשלוח",
            },
        ],
        data: [],
        // totalAmounts: [],
        groupName: "",
        delivery: [],
        modalShow: false,
        // hasOrders: true,
    };

    render() {
        return (
            <div className="container-fluid" style={{ backgroundColor: "white" }}>
                {/* <CSVLink data={this.state.csvData}>Download me</CSVLink>; */}
                <OrdersShow
                    csvData={this.state.csvData}
                    groupName={this.state.groupName}
                    groupCode={this.props.match.params.groupCode}
                    columns={this.state.columns}
                    data={this.state.data}
                    updateData={this.updateData}
                    delivery={this.props.onlineDeliveries[0]}
                    updateGroupOrderPaidStatus={this.props.updateGroupOrderPaidStatus}
                />

                <>
                    <MyModal />

                    <MyModal
                        headlineText="סטאטוס התשלום עודכן"
                        // headlineBody='לתשומך ליבך - לא ניתן יהיה לשחזר לאחר המחיקה'
                        show={this.state.modalShow}
                        onHide={() => this.setState({ modalShow: false })}
                    // ButtonText='ביטול'
                    // ButtonDeleteText='אישור'
                    // ButtonDinamicFunction={() => this.deleteDelivery()}
                    />
                </>
            </div>
        );
    }

    componentDidMount() {
        document.title = "משק אלמוג - הזמנות";

        const { match } = this.props;

        this.props.getOnlineDeliveryTotalsById(match.params.deliveryId);

        // this.props.getGroupByCode(match.params.groupCode).then(() => {
        //     this.setState({ groupName: this.props.groups[0].InternalName });
        // });
        this.props.getOnlineOrdersByGroupDeliveryId(match.params.deliveryId)
            .then(() => {

                console.log('onlineOrders', this.props.onlineOrders)
                this.setState({ delivery: this.props.onlineDeliveries[0] });

                this.props.getProducts().then(() => {
                    // console.log("orders: ", this.props.orders);
                    try {
                        this.prepColumns();
                        this.perpData();
                        this.prepCsvFile();
                    } catch (error) { }
                });
                // .catch((err) => console.log(err));
            });
        this.props.getOnlineDeliveryById(match.params.deliveryId).then(() => {
            // console.log("Group onlineDeliveries: ", this.props.onlineDeliveries);
        });
        // .catch((err) => console.log(err));

        // console.log('has', this.state.hasOrders)
    }

    prepColumns = () => {
        this.props.onlineDeliveries[0].Products.map((dp) => {
            this.setState(
                {
                    productsIndexInTable: [...this.state.productsIndexInTable, dp.Code],
                },
                () => {
                    // console.log("indexes: ", this.state.productsIndexInTable);
                }
            );
            //console.log("Products: ", this.props.products);
            this.setState(
                {
                    columns: [
                        ...this.state.columns,
                        {
                            Header: this.props.products.filter((p) => p.Code === dp.Code)[0]
                                .ShortName,
                            accessor: this.props.products.filter((p) => p.Code === dp.Code)[0]
                                .ShortName,
                        },
                    ],
                },
                () => {
                    // console.log("columns: ", this.state.columns);
                }
            );
        });
        const arr = [
            {
                Header: "שיטת תשלום",
                accessor: "שיטת תשלום",
            },
            {
                Header: "הערות פנימיות",
                accessor: "הערות פנימיות",
            },
        ];

        this.setState(
            {
                columns: [...this.state.columns, ...arr],
            },
            () => {
                // console.log("columns2: ", this.state.columns);
            }
        ); //another array
    };

    perpData = () => {
        // console.log("perpData");
        const newData = [];
        // const name = this.props.orders;
        this.setState({ data: [] });
        this.props.onlineOrders.map((order) => {

            console.log('order', order)
            const personOrder = {};

            personOrder["Id"] = order._id;

            personOrder["האם שולם"] = order.IsPaid && order.PaymentMethod === "PayBox" ? (
                <label className="myCheckbox">
                    <input
                        style={{ display: 'block', margin: 'auto' }}
                        type="checkbox"
                        value="שולם"
                        checked={true}
                        onChange={() => setPaymentStatus(order.OrderNumber)}
                    ></input>
                    &nbsp; שולם
                </label>
            ) : order.PaymentMethod === "PayBox" ? (
                <label className="myCheckbox" style={{ fontSize: 'x-small' }}>
                    <input
                        style={{ display: 'block', margin: 'auto' }}
                        type="checkbox"
                        value="לא שולם"
                        onChange={() => setPaymentStatus(order.OrderNumber)}
                    ></input>
                    &nbsp; לא שולם
                </label>
            ) : ''

            personOrder["שם מלא"] = <span className="font-weight-bold">{order.PersonInfo.FirstName + " " + order.PersonInfo.LastName}</span>
            // personOrder["מספר הזמנה"] = order.OrderNumber;
            // personOrder["Email"] = order.PersonInfo.Email;
            personOrder["תאריך הזמנה"] = moment(order.OrderDate).format(
                "DD/MM/YYYY HH:mm"
            );
            personOrder["טלפון"] = order.PersonInfo.Phone;
            personOrder["נקודת חלוקה"] = order.PersonInfo.City + ", " + order.PersonInfo.Address;
            personOrder["הערות"] = order.Comment;
            personOrder["שעת המשלוח"] = order.DeliveryTime;
            personOrder["orderNumber"] = order.OrderNumber;
            this.state.productsIndexInTable.map((code, i) => {
                if (order.Products.filter((p) => p.Code === code).length > 0) {
                    const amount = order.Products.filter((p) => p.Code === code)[0]
                        .Amount;
                    personOrder[this.state.columns[i + 7].Header] = amount;
                } else {
                    personOrder[this.state.columns[i + 7].Header] = "";
                }
            });
            personOrder["תגובה"] = order.Comment;
            personOrder["שיטת תשלום"] = order.PaymentMethod;
            personOrder["הערות פנימיות"] = order.InternalComment;

            this.setState({ orderTotalPrice: order.TotalPrice })
            personOrder['סה"כ לתשלום'] = <p className="font-weight-bold" style={{ fontSize: 'larger' }}>{this.state.orderTotalPrice + " ₪"}</p>

            const setPaymentStatus = (OrderNumber) => {
                const { match } = this.props;

                const objectPost = {
                    GroupDeliveryId: match.params.deliveryId,
                    OrderNumber: OrderNumber,
                };
                // console.log('objectPost', objectPost)
                this.props.updateGroupOrderPaidStatus(objectPost).then(() => {
                    // window.location.reload()

                    // console.log('res2', res)
                    // this.setState({ modalShow: true })

                    this.updateData();
                });
            };

            // console.log("person: ", personOrder);
            newData.push(personOrder);
            // this.setState(
            //   {
            //     data: [...this.state.data, personOrder],
            //   },
            //   () => {
            //     console.log("data:", this.state.data);
            //   }
            // );
        });
        this.setState({ data: newData }, () => {
            // console.log("new data: ", this.state.data);
            this.prepTotals();
        });
    };
    prepTotals = () => {
        const totalsData = [];
        const length = this.state.productsIndexInTable.length + 7;
        for (var i = 0; i < length; i++) {
            if (i === 0) {
                totalsData.push('סה"כ');
            } else if (i === 1 || i === 2 || i === 3 || i === 4) {
                totalsData.push("");
            } else if (i === length - 1) {
                totalsData.push(100);
            } else {
                totalsData.push(this.getTotalsRow(this.state.columns[i]));
            }
        }
        this.setState(
            {
                data: [...this.state.data, totalsData],
            },
            () => {
                // console.log("data with totals:", this.state.data);
            }
        );
    };

    getTotalsRow = (key) => {
        let total = 0;
        this.state.data.forEach((item) => {
            if (item[key.Header] !== "") {
                total += parseInt(item[key.Header]);
            }
        });
        // console.log("total: ", total);
        return total;
    };

    updateData = () => {
        const { match } = this.props;
        this.props.getOnlineDeliveryTotalsById(match.params.deliveryId);
        this.props.getOnlineOrdersByGroupDeliveryId(match.params.deliveryId)
            .then(() => {
                this.perpData();
                this.prepCsvFile();
            });
    };

    prepCsvFile = () => {
        let csvHeadersAndData = [];
        let headers = [];
        this.state.columns.map((cul) => {
            headers.push(cul.Header);
        });
        csvHeadersAndData.push(headers);
        let csvData = [];
        this.state.data.map((d, i) => {
            if (i != this.state.data.length - 1) {
                const current = this.props.onlineOrders.filter(
                    (order) => order.OrderNumber === d.orderNumber
                )[0];
                // console.log("current: ", current);
                // if (i !== 0 || i == this.state.data.length - 1) {
                // console.log("d: ", d)
                let arr = Object.keys(d).map((k) => d[k]);
                // //  arr.push(isPaid);

                // arr.push("שולם");

                // console.log("arr", arr)

                // arr.splice(1, 1);
                arr.splice(0, 1);
                // arr.splice(2, 1);
                // arr.splice(6, 1);
                arr.splice(23, 1);
                if (arr[25] !== undefined) {
                    // arr[25] = arr[25].replace("₪", "");
                }
                // console.log("arr: ", arr);
                if (current == undefined) {
                    arr.push("");
                } else if (current.IsPaid === true) {
                    arr.push("שולם");
                } else {
                    arr.push("");
                }

                csvHeadersAndData.push(arr);
                // }
            }
        });

        //csvHeadersAndData.push(csvData)
        this.setState({ csvData: csvHeadersAndData }, () => {
            // console.log("csv data: ", this.state.csvData)
        });
    };
}

const mapStateToProps = (state) => {
    return {
        // orders: state.groupOrdersReducer.orders,

        // deliveries: state.deliveriesReducer.deliveries,
        onlineDeliveries: state.onlineDeliveriesReducer.onlineDeliveries,


        // totalPricesForDelivery: state.deliveriesReducer.totalPricesForDelivery,
        onlineTotalPricesForDelivery: state.onlineDeliveriesReducer.onlineTotalPricesForDelivery,

        products: state.productReducer.products,
        groups: state.groupReducer.groups,
        onlineOrders: state.onlineOrdersReducer.onlineOrders,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOnlineOrdersByGroupDeliveryId,
            getOnlineDeliveryById,
            getOnlineDeliveryTotalsById,

            getProducts,
            getGroupByCode,
            updateGroupOrderPaidStatus

        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(OrdersLoad);
