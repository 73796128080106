import React from 'react'
import { MDBCard, MDBCardBody, MDBRow, MDBCol } from "mdbreact";
import GedDayOfWeek from '../../../components/GedDayOfWeek'
import GetLocalDayAndHour from '../../../components/GetLocalDayAndHour'
import moment from "moment";
// import _ from "lodash";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getWeekSummary, getGroupDeliveriesByWeekNumber, setGroupDeliveryRevenue } from "../../../redux/deliveries/deliveries-actions";
import { getActiveProducts } from '../../../redux/products/products-actions';
// import Spinner from '../../../components/Spinner/Spinner';


class WeekOrdersSummary extends React.Component {
    constructor() {
        super()
        this.state = {
            newInActiveProducts: [],
            indexOfProduct: [],
            weekHeadline: '',
            // weekDates: '',
            totalPrice: 0,
            weekNum: 0,
            currentPage: 0,
            startDate: null,
            endDate: null,
            currentWeek: 'שבוע נוכחי',
            weekDates: '',
            // totalPrice: 0,
            ordersOfCurrentWeek: [],
            daysSummary: [],
            lastDayDate: "",
            revenues: [],
            token: null
        }
    }

    getAllWeek = () => {
        const date = new Date();
        // console.log('today', date)

        let day = date.getDay();
        let array = []
        for (let i = 0; i < 7; i++) {
            if (i - day != 0) {
                let days = i - day;
                let newDate = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));

                array.push(newDate);
            }
            else
                array.push(date);
        }

        // console.log('array', array)
        const myWeek = array.map((mydate, index) => {
            // console.log('index', index)
            if (index === 0) {
                return <span key={index}>
                    <GetLocalDayAndHour
                        dateOfDelivery={mydate}
                    />
                </span>
            }

            else if (index === array.length - 1) {
                return <span> -&nbsp;
                    <GetLocalDayAndHour
                        dateOfDelivery={mydate}
                    />
                </span>
            }
        })
        this.setState({ weekDates: myWeek })
        return myWeek

    }

    // updateRevenue = (id, e, i) => {
    //     // console.log('id', id)
    //     this.debouncedUpdateRevenue(id, e.target.value)
    //     let items = [...this.state.revenues];
    //     let item = { ...items[i] };
    //     item = e.target.value
    //     items[i] = item;

    //     // console.log('  items[i]', items[i])
    //     this.setState({ revenues: items });

    //     // console.log('revenues', this.state.revenues)

    // }

    // debouncedUpdateRevenue = _.debounce((id, revenue) => {
    //     // console.log('id to r', id)
    //     // console.log('revenue to r', revenue)
    //     if (revenue > 0) {
    //         this.props.setGroupDeliveryRevenue({ "Id": id, "Revenue": revenue })
    //             .then(() => {
    //                 this.initialRevenues(this.state.weekNum);
    //             })
    //             .catch((e) => console.log(e))
    //     }
    // }, 500);

    loadTotalRevenuesToHeadline = () => {

        let totalRevenues = 0
        for (let index = 0; index < this.state.revenues.length; index++) {
            totalRevenues += this.state.revenues[index]
        }
        return <>{totalRevenues} <i className="fas fa-shekel-sign fa"></i></>
    }

    render() {

        if (this.state.token === "manager") {

            if (this.state.endDate !== null && this.state.revenues !== []) {
                return (
                    <div className='container-fluid firstDivAfterNav' >


                        {/* <table >
                        <tr>
                            <td>
                                <h2 id='weekHeadline' className='text-center'>
                                    {this.state.weekHeadline}
                                </h2>

                                <h3 id='weekDates' className='text-center font-italic'>{this.state.endDate} - {this.state.startDate}
                                </h3>
                            </td>
                            <td className='text-center'>
                                <h3 id='totalH3' style={{ direction: 'rtl' }}> סה"כ:    {this.state.totalPrice}  <i class="fas fa-shekel-sign fa"></i></h3>
                                <button>                                <i className="fas fa-sync-alt text-center" onClick={() => window.location.reload()}></i>
                                </button>

                            </td>
                        </tr>
                    </table> */}

                        <MDBRow>
                            <MDBCol md='4' style={{ textAlign: 'center', margin: 'auto' }}>
                                <MDBCard>
                                    <MDBCardBody>
                                        <button className='rounded-button' style={{ float: 'right' }} onClick={() => this.rowClicked('/admin', true)}>
                                            <i className="fas fa-chevron-circle-right"></i>
                                        </button>

                                        <h2 id='weekHeadline' className='text-center font-weight-bold' style={{ display: 'inline' }}>
                                            {this.state.weekHeadline}&nbsp; {this.state.endDate} - {this.state.startDate}
                                        </h2>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>

                            <MDBCol md='4' style={{ textAlign: 'center', margin: 'auto' }}>
                                <MDBCard>
                                    <MDBCardBody>
                                        <h3>סה"כ הכנסות: {this.loadTotalRevenuesToHeadline()}</h3>
                                        <h4 id='totalH3' style={{ direction: 'rtl' }}> סה"כ הזמנות:    {this.state.totalPrice}  <i className="fas fa-shopping-cart"></i></h4>

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>


                        {/* <div className='col-sm-12'> */}
                        {/* <div className='col-sm-8 text-right' style={{ display: 'inline' }}>
                            <h2 id='weekHeadline' className='text-center font-weight-bold' style={{ display: 'inline' }}>
                                {this.state.weekHeadline} | {this.state.endDate} - {this.state.startDate}
                            </h2>
                        </div> */}


                        {/* <div className='col-sm-4 text-left' style={{ display: 'inline' }}>
                            {/* <h3 id='totalH3' style={{ direction: 'rtl' }}> סה"כ הזמנות:    {this.state.totalPrice}  <i class="fas fa-shekel-sign fa"></i></h3>

                            <h4>סה"כ בפועל: {this.loadTotalRevenuesToHeadline()}</h4>
                        </div> */}

                        {/* </div> * /} */}

                        {/* <SerachInout /> */}
                        <h5 className='font-weight-bold text-center'>{this.state.currentWeek}</h5>
                        <div className='table-responsive'>
                            <table id="summaryTable" className="table table-striped col-sm-12 text-center tableOfOrders" style={{ margin: 'auto' }} ref={el => (this.componentRef = el)}>
                                <thead className="thead-dark">
                                    {this.props.deliveries.map((delivery, index) => {
                                        // console.log(delivery)
                                        if (index == 0)
                                            return <tr style={{ backgroundColor: "#dddddd" }}>
                                                <td colspan={4} className="sticky-column">
                                                    <h4>
                                                        <GedDayOfWeek
                                                            dayOfWeek={delivery.DeliveryDateTime}
                                                        />
                                                    </h4>
                                                </td>
                                                {
                                                    delivery.ProductsSummary != undefined &&
                                                    <td colspan={5 + Number(delivery.ProductsSummary.length)}>
                                                    </td>
                                                }

                                            </tr>
                                    })
                                    }
                                    <tr className="header">

                                        {/* <th>יום</th> */}
                                        <th>שעה</th>
                                        <th>קבוצה</th>
                                        <th>סה"כ הזמנות</th>
                                        <th className="sumOfRevenuesTd">סה"כ הכנסות</th>

                                        {
                                            this.props.prodcuts.map(product => {
                                                return (
                                                    <th className='myCell' key={product.Code}> {product.ShortName}</th>
                                                )
                                            })
                                        }

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.ordersOfCurrentWeek}
                                </tbody >
                            </table>

                            <div className='col-sm-8 text-center' style={{ margin: 'auto' }}>
                                <button className="btn text-center almogBtn"
                                    onClick={() => this.changeWeekNum(this.state.weekNum + 1)}
                                >
                                    <i className="fas fa-arrow-alt-circle-right fa-2x"></i>
                                    הקודם&nbsp;
                                </button>
                                <button className="btn text-center almogBtn"
                                    disabled={this.state.currentPage == -1}
                                    onClick={() => this.changeWeekNum(this.state.weekNum - 1)}
                                >הבא&nbsp;
                                    <i className="fas fa-arrow-alt-circle-left fa-2x"></i>

                                </button>

                            </div>
                        </div>
                    </div >

                )
            }
            else {

                return <div className='firstDivAfterNav text-center'>
                    <img width='15%' src={require('../../../components/img/almog.PNG')}></img>
                    <br />
                    <h2 id='weekHeadline' className='text-center'>
                        {
                            this.props.deliveries[0] != undefined ?
                                'טוען...'
                                : 'לא קיימים נתונים בשבוע זה'
                        }
                    </h2>
                    {/* <Spinner
                        display={'block'}
                        background={'linear-gradient(90deg, #020024, #09793b 68%, #00d4ff)'}
                    /> */}
                </div >
            }

        }
        else {
            return <div className='firstDivAfterNav'>
                <h2 className='text-center font-weight-bold' style={{ paddingTop: '150px', paddingBottom: '150px' }}>
                    אין לך הרשאה לצפות בעמוד זה</h2>
            </div>
        }

    }
    // moveLeft = (e) => {
    //     // e.preventDefault()
    //     document.getElementById("dataTable").scrollIntoView = 100
    //     // window.scrollTo(500, 100)
    //     alert('das')
    // }
    rowClicked = (link, isBackBtn) => {
        if (isBackBtn)
            this.props.history.push(link)
        else window.open(link)

    }

    setClickableRow = (e, link) => {
        // console.log('e.target', e.target.tagName)
        if (e.target.tagName !== 'INPUT')
            this.rowClicked(link)
    }

    loadOrdersOfCurrentWeek = () => {

        let currentDate = new Date(this.props.deliveries[0].DeliveryDateTime).toDateString()
        let dayNum = 0
        const newOrdersOfCurrentWeek = this.props.deliveries.map((delivery, index) => {

            // console.log('delivery', delivery)
            let color = '#dddddd'
            let deliveryDay = new Date(delivery.DeliveryDateTime).getDay('he-IL', { hour12: false })
            if (deliveryDay % 2 === 0)
                color = 'rgba(0,0,0,.05)'
            if (currentDate != new Date(delivery.DeliveryDateTime).toDateString()) {
                currentDate = new Date(delivery.DeliveryDateTime).toDateString();
                let curDayNum = dayNum
                dayNum = dayNum + 1;
                return (
                    <>
                        <tr className='summaryTableSumRow'>
                            {/* <td style={{ fontWeight: 'bold' }}>
                                <GedDayOfWeek
                                    dayOfWeek={delivery.DeliveryDateTime}
                                    sumDayOfWeek={true}
                                />
                            </td> */}
                            <td colSpan={2}></td>
                            {/* {this.state.daysSummary[curDayNum].map((p, i) => {
                                if (i < (this.state.daysSummary[curDayNum].length - 2))
                                    return (
                                        <td style={{ fontWeight: 'bold' }}>{p}</td>
                                    )
                                else
                                    return (
                                        <td style={{ fontWeight: 'bold' }}>{p} ₪</td>
                                    )
                            })} */}

                            {this.state.daysSummary[curDayNum].map((p, i) => {
                                if (i == this.state.daysSummary[curDayNum].length - 2)
                                    return <td className="text-primary font-weight-bold" style={{ fontSize: 'large' }}>{p} ₪</td>
                                else if (i == this.state.daysSummary[curDayNum].length - 1)
                                    return (
                                        <td className="sumOfRevenuesTd" style={{ fontSize: 'large' }}>{p} ₪</td>
                                    )
                            })}
                            {this.state.daysSummary[curDayNum].map((p, i) => {
                                if (i < (this.state.daysSummary[curDayNum].length - 2))

                                    return (
                                        <td className="font-weight-bold">
                                            {p}
                                            <br />
                                            {
                                                i == 0 ?
                                                    ''
                                                    : i < 4 ?
                                                        <div className='boxDiv'>
                                                            <div className='boxSpanDiv'><span>  {Math.ceil(p / 6)}</span></div>
                                                            <img width='25' height='25' src='../img/box3.png' />
                                                        </div>
                                                        : i < 6 ?
                                                            <div className='boxDiv'>
                                                                <div className='boxSpanDiv'><span>  {Math.ceil(p / 8)}</span></div>
                                                                <img width='25' height='25' src='../img/box3.png' />
                                                            </div>
                                                            : ''
                                            }
                                        </td>
                                    )

                            })}
                            {/* <td style={{ fontWeight: 'bold' }}>
                                {this.state.revenues[index - 1]} ₪
                                </td> */}

                        </tr>
                        <tr className='separatedRow'></tr>
                        <tr style={{ backgroundColor: "#dddddd" }}>
                            <td colspan={4} className="sticky-column">
                                <h4>
                                    <GedDayOfWeek
                                        dayOfWeek={delivery.DeliveryDateTime}
                                    />
                                </h4>
                            </td>
                            <td colspan={5 + Number(delivery.ProductsSummary.length)}>
                            </td>
                        </tr>
                        <tr className="header" style={{ background: '#343a40', color: 'white' }}>

                            {/* <td>יום</td> */}
                            <td>שעה</td>
                            <td>קבוצה</td>
                            <td>סה"כ הזמנות</td>
                            <td className="sumOfRevenuesTd">סה"כ הכנסות</td>
                            {
                                this.props.prodcuts.map(product => {
                                    return (

                                        <td style={{ color: 'white' }} className='myCell'> {product.ShortName}</td>
                                    )
                                })
                            }
                        </tr>
                        <tr className="thead-dark summaryTableDatalRow" id={`tr${delivery.GroupCode}`}
                            // onClick={() => window.location.replace(`/admin/deliveries/groups-orders/${delivery.GroupCode}/${delivery.ID}`)}
                            onClick={(e) => this.setClickableRow(e, `/admin/deliveries/groups-orders/${delivery.GroupCode}/${delivery.ID}`)}

                            // onClick={() => this.rowClicked(`/admin/deliveries/groups-orders/${delivery.GroupCode}/${delivery.ID}`)}

                            style={{ backgroundColor: color }}>
                            {/* <button id='floatedBtnOnTable' ><i class="fas fa-angle-double-left" onClick={(e) => this.moveLeft(e)}></i></button> */}

                            {/* <td>
                                <GedDayOfWeek
                                    dayOfWeek={delivery.DeliveryDateTime}
                                />
                            </td> */}
                            <td >
                                {/* <GetLocalDayAndHour
                                    hourOfDelivery={delivery.DeliveryDateTime}
                                /> */}
                                {
                                    delivery.Places.map((place, index) => {
                                        return <span>
                                            {
                                                (delivery.Places.length !== index && index !== 0) &&
                                                ', '
                                            }
                                            <GetLocalDayAndHour
                                                hourOfDelivery={place.Hour}
                                            />
                                        </span>
                                    })
                                }
                            </td>
                            <td className="sticky-column">
                                {delivery.GroupName}
                                <span> ({delivery.TotalOrders})</span>
                            </td>
                            {<td className="text-primary font-weight-bold">{(delivery.TotalPrice !== undefined ? ` ${delivery.TotalPrice} ₪` : '0 ₪')} </td>}
                            <td className="sumOfRevenuesTd">
                                {this.state.revenues[index]} ₪
                                {/* <input className='inputOfRevenue'
                                    onChange={(e) => this.updateRevenue(delivery.ID, e, index)}
                                    type='number' value={this.state.revenues[index]}></input><span></span> */}
                            </td>
                            {
                                this.props.prodcuts.map((product, i) => {
                                    // console.log("delivery.ProductsSummary: ", delivery.ProductsSummary)
                                    // console.log("product: ", product)
                                    // res.data.Payload.sort((a, b) => a.Code - b.Code);
                                    try {
                                        for (let index = 0; index < delivery.ProductsSummary.length; index++) {
                                            if (delivery.ProductsSummary[index].Code == product.Code) {
                                                // console.log("order: ", o.Code)
                                                // console.log("product: ", product.Code)
                                                return <td key={product.Code} className='myCell'>{delivery.ProductsSummary[index].Amount}</td>
                                            }
                                        }
                                    } catch (error) {

                                    }

                                    return <td>-</td>

                                })
                            }
                            {/* {<td>{delivery.GroupCode == 11 ? '-' : ` ${delivery.TotalPrice} ₪`}</td>} */}
                            {/* {<td>{delivery.GroupCode !== 1 ? (delivery.TotalPrice !== undefined ? ` ${delivery.TotalPrice} ₪` : '0') : '-'} </td>} */}

                        </tr >
                    </>
                )
            }
            else {

                return (

                    <tr className="thead-dark summaryTableDatalRow" id={`tr${delivery.GroupCode}`}
                        onClick={(e) => this.setClickableRow(e, `/admin/deliveries/groups-orders/${delivery.GroupCode}/${delivery.ID}`)}

                        // onClick={() => window.location.replace(`/admin/deliveries/groups-orders/${delivery.GroupCode}/${delivery.ID}`)}
                        style={{ backgroundColor: color }}>
                        {/* 
                        <td>

                            <GedDayOfWeek
                                dayOfWeek={delivery.DeliveryDateTime}
                            />

                        </td> */}
                        <td >
                            {
                                delivery.Places.map((place, index) => {
                                    return <span>
                                        {
                                            (delivery.Places.length !== index && index !== 0) &&
                                            ', '
                                        }
                                        <GetLocalDayAndHour
                                            hourOfDelivery={place.Hour}
                                        />
                                    </span>
                                })
                            }
                        </td>
                        <td className="sticky-column">
                            {delivery.GroupName}
                            <span> ({delivery.TotalOrders})</span>
                        </td>
                        {<td className="text-primary font-weight-bold">{delivery.TotalPrice !== undefined ? ` ${delivery.TotalPrice} ₪` : '0 ₪'} </td>}
                        <td className="sumOfRevenuesTd">
                            {this.state.revenues[index]} ₪
                            {/* <input className='inputOfRevenue'
                                onChange={(e) => this.updateRevenue(delivery.ID, e, index)}
                                type='number' value={this.state.revenues[index]}></input> */}
                        </td>

                        {
                            this.props.prodcuts.map((product, i) => {
                                // console.log("delivery.ProductsSummary: ", delivery.ProductsSummary)
                                // console.log("product: ", product)
                                try {
                                    for (let index = 0; index < delivery.ProductsSummary.length; index++) {
                                        if (delivery.ProductsSummary[index].Code == product.Code) {
                                            // console.log("order: ", o.Code)
                                            // console.log("product: ", product.Code)
                                            return <td key={product.Code} className='myCell'>{delivery.ProductsSummary[index].Amount}</td>
                                        }
                                    }
                                } catch (error) {

                                }

                                return <td>-</td>

                            })
                        }
                        {/* {<td>{delivery.GroupCode !== 1 ? (delivery.TotalPrice !== undefined ? ` ${delivery.TotalPrice} ₪` : '0') : '-'} </td>} */}
                        {/* {<td>{delivery.GroupCode == 11 ? '-' : ` ${delivery.TotalPrice} ₪`}</td>} */}

                    </tr >
                )
            }
        })
        newOrdersOfCurrentWeek.push(
            <tr className='summaryTableSumRow'>
                <td className="font-weight-bold">
                    {/* <GetLocalDayAndHour
                        dateOfDelivery={this.state.lastDayDate}
                    /> */}
                    {/* <GedDayOfWeek
                        dayOfWeek={this.state.lastDayDate}
                    /> */}
                </td>
                <td></td>
                {this.state.daysSummary[dayNum] && this.state.daysSummary[dayNum].length > 0 && this.state.daysSummary[dayNum].map((p, i) => {
                    if (i == this.state.daysSummary[dayNum].length - 2)
                        return <td className="text-primary font-weight-bold" style={{ fontSize: 'large' }}>{p} ₪</td>
                    else if (i == this.state.daysSummary[dayNum].length - 1)
                        return (
                            <td className="sumOfRevenuesTd" style={{ fontSize: 'large' }}>{p} ₪</td>
                        )
                })}
                {this.state.daysSummary[dayNum].map((p, i) => {
                    if (i < (this.state.daysSummary[dayNum].length - 2))
                        return (
                            <td className="font-weight-bold">
                                {p}
                                <br />
                                {
                                    i == 0 ?
                                        ''
                                        : i < 4 ?
                                            <div className='boxDiv'>
                                                <div className='boxSpanDiv'><span>  {Math.ceil(p / 6)}</span></div>
                                                <img width='25' height='25' src='../img/box3.png' />
                                            </div>
                                            : i < 6 ?
                                                <div className='boxDiv'>
                                                    <div className='boxSpanDiv'><span>  {Math.ceil(p / 8)}</span></div>
                                                    <img width='25' height='25' src='../img/box3.png' />
                                                </div>
                                                : ''
                                }
                            </td>
                        )
                })}
                {/* <td style={{ fontWeight: 'bold' }}>{this.state.revenues[this.state.revenues.length - 1]} ₪</td> */}
            </tr>
        )
        this.setState({ ordersOfCurrentWeek: newOrdersOfCurrentWeek })
    }


    componentDidMount() {

        document.title = "משק אלמוג - סיכום שבועי"

        if (localStorage.getItem("token") || sessionStorage.getItem("token")) {

            this.setState({ token: "manager" })
            // console.log('revenues', this.state.revenues)
            // document.getElementById('myNav').style.display = 'none'
            let price = 0
            this.props.getWeekSummary(this.state.weekNum).then(() => {
                this.props.deliveries.map(delivery => {
                    // console.log('first loaded', delivery)
                    if (delivery.TotalPrice == null)
                        price += 0
                    else
                        price += delivery.TotalPrice
                })
                if (this.props.deliveries[0] != undefined) {
                    this.getDaySum();
                    this.loadOrdersOfCurrentWeek()
                    this.setState({ totalPrice: price })
                    this.getDeliveriesByWeekNum(this.state.weekNum)
                }

            })

            this.props.getActiveProducts()
            this.setState({ weekHeadline: 'סיכום שבועי' })
            this.getAllWeek()

            this.initialRevenues(this.state.weekNum)
        }

    }


    changeWeekNum = (weekNumber) => {

        this.setState({
            weekNum: weekNumber
        }, () => {
            this.loadWeekSummary(this.state.weekNum)
            this.initialRevenues(this.state.weekNum)
        })
    }

    initialRevenues = (weekNum) => {

        let revenuesArray = []

        this.props.getGroupDeliveriesByWeekNumber(weekNum).then(() => {
            this.props.deliveries.map(delivery => {
                // console.log('delivery', delivery)
                revenuesArray.push(delivery.Revenue)
            })
            this.setState({ revenues: revenuesArray })
        })

        this.loadTotalRevenuesToHeadline()
    }


    loadWeekSummary = (newWeekNum) => {
        // console.log('this.state.weekNum', this.state.weekNum)
        // if (newWeekNum == -2) {
        //     alert("לא קיימים נתונים עבור השבוע המבוקש")
        // }
        // else {
        let price = 0
        this.props.getWeekSummary(newWeekNum).then(() => {
            this.props.deliveries.map(delivery => {
                // console.log('delivery.TotalPrice', delivery.TotalPrice)
                if (delivery.TotalPrice > 0)
                    price += delivery.TotalPrice
                else
                    price += 0
            })
            this.setState({ totalPrice: price, weekNum: newWeekNum })
            try {
                this.getDaySum();
                this.loadOrdersOfCurrentWeek()
                this.getDeliveriesByWeekNum(newWeekNum)
            } catch (error) {
                alert("לא קיימים נתונים עבור השבוע המבוקש")
            }

        })
        // }

    }

    getDeliveriesByWeekNum = (weekAgo) => {

        let weekText = ''
        const week = weekAgo
        if (weekAgo != 0) {
            weekAgo = -weekAgo * 7;
            weekText = ''
        }
        else
            weekText = 'שבוע נוכחי'
        this.setState({
            currentPage: week,
            startDate: moment().weekday(weekAgo).format("DD/MM"),
            endDate: moment().weekday(weekAgo + 6).format("DD/MM"),
            currentWeek: weekText
        })

    }

    getDaySum = () => {
        // console.log("deliveirs: ", this.props.deliveries)
        let currentDate = new Date(this.props.deliveries[0].DeliveryDateTime).toDateString()
        let currentdaySum = Array(this.props.prodcuts.length).fill(0);
        let dayNum = 0;
        this.props.deliveries.map((delivery) => {
            this.props.prodcuts.map((product, i) => {
                if (currentDate != new Date(delivery.DeliveryDateTime).toDateString()) {
                    let newDaysSummary = [...this.state.daysSummary]
                    newDaysSummary[dayNum] = currentdaySum
                    this.setState({ daysSummary: newDaysSummary })
                    dayNum += 1;
                    currentdaySum = Array(this.props.prodcuts.length).fill(0);
                    currentDate = new Date(delivery.DeliveryDateTime).toDateString()
                }
                for (let index = 0; index < delivery.ProductsSummary.length; index++) {
                    if (delivery.ProductsSummary[index].Code == product.Code) {
                        currentdaySum[i] += parseInt(delivery.ProductsSummary[index].Amount);
                    }
                }
            })
            // currentDate = new Date(delivery.DeliveryDateTime).toDateString();
            // for (let index = 0; index < delivery.length; {
            //     if (currentDate != new Date(delivery.DeliveryDateTime).toDateString()) {
            //         currentdaySum[this.props.prodcuts.length] = totalPrice
            //         totalPrice = 0;
            //     }
            //     totalPrice = totalPrice + parseInt(delivery.TotalPrice)
            // }

            let newDaysSummary = [...this.state.daysSummary]
            newDaysSummary[dayNum] = currentdaySum
            this.setState({ daysSummary: newDaysSummary },
                () => {
                    //   console.log("daysSummary: ", this.state.daysSummary)
                })
        })

        currentDate = new Date(this.props.deliveries[0].DeliveryDateTime).toDateString()
        this.setState({ lastDayDate: currentDate })
        dayNum = 0;
        let totalDayPrice = 0;
        let totalOrdersPrice = 0;
        this.props.deliveries.map((delivery, i) => {
            // console.log("current date: ", currentDate)
            if (currentDate != new Date(delivery.DeliveryDateTime).toDateString()) {
                currentDate = new Date(delivery.DeliveryDateTime).toDateString();
                let newDaysSummary = [...this.state.daysSummary]
                // console.log("newDaysSummary[dayNum]1: ", newDaysSummary[dayNum])
                if (newDaysSummary[dayNum] != undefined) {
                    // console.log("totalDayPrice: ", totalDayPrice)
                    newDaysSummary[dayNum].push(totalDayPrice)
                    newDaysSummary[dayNum].push(totalOrdersPrice)
                    this.setState({ daysSummary: newDaysSummary })
                    dayNum += 1;
                    totalDayPrice = 0;
                    totalOrdersPrice = 0
                }
            }
            if (delivery.TotalPrice != undefined) {
                // console.log("total price: ", delivery.TotalPrice)
                totalDayPrice += parseInt(delivery.TotalPrice);
            }
            if (delivery.TotalOrdersPrice != undefined) {
                totalOrdersPrice += parseInt(delivery.TotalOrdersPrice);
            }

        })
        this.setState({ lastDayDate: new Date(this.props.deliveries[this.props.deliveries.length - 1].DeliveryDateTime).toDateString() })
        let newDaysSummary = [...this.state.daysSummary]
        if (newDaysSummary[dayNum] != undefined) {
            // console.log("newDaysSummary[dayNum]2: ", newDaysSummary[dayNum])
            newDaysSummary[dayNum].push(totalDayPrice)
            newDaysSummary[dayNum].push(totalOrdersPrice)
            // newDaysSummary[dayNum].push(del.TotalOrdersPrice)
            this.setState({ daysSummary: newDaysSummary })
        }


    }

    calculateRows = () => {

    }
}

const mapStateToProps = (state) => {
    return {
        prodcuts: state.productReducer.products.sort((a, b) => a.TypeCode - b.TypeCode),
        deliveries: state.deliveriesReducer.deliveries


    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getWeekSummary,
            getActiveProducts,
            getGroupDeliveriesByWeekNumber,
            setGroupDeliveryRevenue
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WeekOrdersSummary);
