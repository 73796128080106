import React, { useState, useEffect } from 'react'
import moment from 'moment'

const GetLocalDayAndHour = (props) => {

    const [date, setDate] = useState('')
    const [hour, setHour] = useState('')


    useEffect(() => {

        // console.log('props.hourOfDelivery', props)

        if (props.dateOfDelivery != null) {
            let deliveryDate = new Date(props.dateOfDelivery).toLocaleDateString('he-IL', { hour12: false })
            setDate(deliveryDate)
        }
        if (props.hourOfDelivery != null) {
            let deliveryHour = moment(props.hourOfDelivery).format('HH:mm')
            // let deliveryDHour = new Date(props.hourOfDelivery).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            setHour(deliveryHour)
        }
    }, [props]);

    return (
        <>
            {date.toString()}
            {hour.toString()}
        </>
    )
}
export default GetLocalDayAndHour