const initialState = {
    onlineDeliveries: [],
    onlineLastDelivery: {},
    onlineTotalPricesForDelivery: []
};

export default function (state = initialState, action) {

    switch (action.type) {
        case "ADD_ONLINE_DELIVERY":
            return { ...state };

        case "UPDATE_ONLINE_DELIVERY_BY_ID":
            return { ...state };

        case "GET_ONLINE_DELIVERY_BY_DELIVERY_ID":
            return { ...state, onlineDeliveries: action.payload };

        case "DELETE_ONLINE_DELIVERY_BY_ID":
            return { ...state };

        case "GET_ONLINE_DELIVERIES":
            return { ...state, onlineDeliveries: action.payload };

        case "GET_ONLINE_DELIVERIES_TOTALS_BY_DELIVERY_ID":
            // console.log(action.payload[0])
            return { ...state, onlineTotalPricesForDelivery: action.payload[0] };



        case "GET_GROUPS_DELIVERIES_BY_WEEK_NUMBER":
            return { ...state, onlineDeliveries: action.payload };
        case "GET_DELIVERIES_BY_GROUP_CODE":
            return { ...state, onlineDeliveries: action.payload };
        case "GET_WEEK_SUMMARY":
            return { ...state, onlineDeliveries: action.payload };


        case "GET_LAST_DELIVERY_BY_GROUP_CODE":
            return { ...state, onlineLastDelivery: action.payload };

        case "SET_GROUP_DELIVERY_REVENUE":
            return { ...state }

        default:
            return state;
    }
}
