// export const validateEmail = (email) => {
//     const re =
//       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     return re.test(String(email).toLowerCase());
//   };

export const validatePhone = (phone) => {
    // const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const re = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
    return re.test(String(phone).toLowerCase());
};

//   export const validateTz = (tz) => {
//     return tz.length === 9
//   };


export const validateHebrew = (text) => {

    // First choose the required validation

    // const HebrewChars = new RegExp("^[\u0590-\u05FF]+$");
    // Regex to match Hebrew characters, spaces, hyphens, and apostrophes
    const HebrewCharsWithSpecials = new RegExp("^[\u0590-\u05FF '-]+$");
    // AlphaNumericChars = new RegExp("^[a-zA-Z0-9\-]+$");
    // EnglishChars = new RegExp("^[a-zA-Z\-]+$");
    // LegalChars = new RegExp("^[a-zA-Z\-\u0590-\u05FF ]+$"); //Note that this one allows space 

    // Then use it

    if (!HebrewCharsWithSpecials.test(text)) {
        return false;
    } else
        return true;

}
