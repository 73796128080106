import React, { useEffect } from 'react'
import './css/serachTable.css'

const SerachInout = (props) => {

    useEffect(() => {

        doSearch()

    }, [props])

    const doSearch = () => {

        // console.log('in')
        try {
            let input, filter, found, table, tr, td, i, j;
            input = document.getElementById("searchTerm");
            filter = input.value.toUpperCase();
            table = document.getElementById("dataTable");
            tr = table.getElementsByTagName("tr");
            for (i = 1; i < tr.length; i++) {
                td = tr[i].getElementsByTagName("td");
                for (j = 0; j < td.length; j++) {
                    if (td[j].innerText.toUpperCase().indexOf(filter) > -1) {
                        found = true;
                    }
                }
                if (found) {
                    tr[i].style.display = "";
                    found = false;
                } else {
                    tr[i].style.display = "none";
                    table.style.height = 'max-content'
                }
            }
        } catch (error) {

        }
    }

    return (
        <div className="col-sm-8" style={{ margin: 'auto', textAlign: 'center' }} >
            <input type="text"
                autoComplete='off'
                id="searchTerm"
                className="form-control light-table-filter input-search"
                onChange={() => doSearch()}
                placeholder="חיפוש"
                title="הקלד/י לחיפוש.."
                style={{ textAlign: 'center', marginBottom: '2%' }}
            />
        </div >
    )
}
export default SerachInout