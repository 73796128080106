// import axios from "axios";
import Api from '../../utils/Api'
let token = ''

if (localStorage.getItem('token'))
    token = localStorage.getItem('token')
else if (sessionStorage.getItem('token'))
    token = sessionStorage.getItem('token')



export const getGroups = () => {

    return (dispatch) => {
        return Api.get("groups", { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                // alert('Success')
                if (res.data) {
                    res.data.Payload.sort(
                        (a, b) => a.Code - b.Code
                    );

                    dispatch({
                        type: "GET_GROUPS",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                // alert('error')
                console.log(e);
            });
    };
}

export const getGroupByCode = (groupCode) => {

    return (dispatch) => {
        // console.log('groupCode',groupCode)
        return Api.get(`groups/${groupCode}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                if (res.data) {
                    res.data.Payload.sort(
                        (a, b) => a.Code - b.Code
                    );

                    // alert('in group')
                    // console.log('res.data', res.data)
                    dispatch({

                        type: "GET_GROUP_BY_CODE",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
}

export const updateGroupByCode = (groupCode, objectPost) => {

    console.log('groupCode', groupCode)
    console.log('objectPost', objectPost)
    return (dispatch) => {
        return Api.post(`groups/update/${groupCode}`, objectPost, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                if (res.request.status == 200) {
                    // alert('הקבוצה עודכנה')
                    dispatch({
                        type: "UPDATE_GROUP",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert('שגיאה')
            });
    };
};

export const createGroup = (objectPost) => {
    return (dispatch) => {
        return Api.post(`groups/add/`, objectPost, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                if (res.request.status == 200) {
                    // alert('הקבוצה נוספה')
                    dispatch({
                        type: "ADD_GROUPS",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                // alert('שגיאה')
            });
    };
};


export const deleteGroupByCode = (groupCode) => {

    // console.log('groupCode', groupCode)
    // console.log('objectPost', objectPost)
    return (dispatch) => {
        return Api.delete(`groups/${groupCode}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then((res) => {
                console.log('res deleted', res)
                if (res.request.status == 200) {
                    // alert('הקבוצה נמחקה')
                    dispatch({
                        type: "DELETE_GROUP"
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert('שגיאה')
            });
    };
};


export const createUser = (user) => {
    return (dispatch) => {
        return Api.post("AdminDashboard/CreateUser", user)
            .then((res) => {
                if (res.data.Success) {
                    dispatch({
                        type: "CREATE_USER",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};

export const editUser = (user) => {
    return (dispatch) => {
        return Api.post("AdminDashboard/ManageUser", {
            UserId: user.Id,
            Email: user.Email,
            Telephone: user.Telephone,
            IdNumber: user.IdNumber,
            Firstname: user.FirstName,
            Lastname: user.LastName,
            Site: user.Site,
            Role: user.Role,
            Active: true,
            DirectManagerId: user.DirectManagerId,
        })
            .then((res) => {
                if (res.data.Success) {
                    dispatch({
                        type: "EDIT_USER",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};

export const deleteUser = (user) => {
    return (dispatch) => {
        return Api.post("AdminDashboard/ManageUser", {
            ...user,
            UserId: user.Id,
        })
            .then((res) => {
                if (res.data.Success) {
                    dispatch({
                        type: "EDIT_USER",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};

export const changeUserPasswordReq = (user) => {
    return (dispatch) => {
        return Api.post("AdminDashboard/ChangePasswordReq", {
            Domain:
                window.location.hostname === "localhost"
                    ? "lms.hi-teach.co.il"
                    : window.location.hostname,
            OrgId: null,
            By: 1,
            Email: user.Email,
        })
            .then((res) => {
                if (res.data.Success) {
                    dispatch({
                        type: "CHANGE_USER_PASSWORD",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};

export const getAdminEmails = () => {
    return {
        type: "GET_ADMIN_EMAILS",
    };
};
export const addAdminEmails = () => {
    return {
        type: "ADD_ADMIN_EMAILS",
    };
};

export const changeAdminEmail = (index, newEmail) => {
    return {
        type: "CHANGE_ADMIN_EMAIL",
        payload: { index, newEmail },
    };
};

export const deleteAdminEmail = (index) => {
    return {
        type: "DELETE_ADMIN_EMAIL",
        payload: index,
    };
};

export const getAdminPhones = () => {
    return {
        type: "GET_ADMIN_PHONES",
    };
};
export const addAdminPhones = () => {
    return {
        type: "ADD_ADMIN_PHONES",
    };
};

export const changeAdminPhone = (index, newPhone) => {
    return {
        type: "CHANGE_ADMIN_PHONE",
        payload: { index, newPhone },
    };
};

export const deleteAdminPhone = (index) => {
    return {
        type: "DELETE_ADMIN_PHONE",
        payload: index,
    };
};

export const sendFirstMessageCheck = () => {
    return (dispatch) => {
        return Api.get("AdminDashboard/SendFirstMessageCheck")
            .then((res) => {
                if (res.data.Success) {
                    dispatch({
                        type: "SEND_FIRST_MESSAGE_CHECK",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};

export const sendFirstMessage = (adminEmails, adminPhones) => {
    return (dispatch) => {
        return Api.post("AdminDashboard/SendFirstMessage", {
            adminEmails,
            adminPhones,
        })
            .then((res) => {
                if (res.data.Success) {
                    dispatch({
                        type: "SEND_FIRST_MESSAGE",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};

export const getChosenCsvFile = () => {
    return {
        type: "GET_CHOSEN_CSV_FILE",
    };
};

export const changeChosenCsvFile = (newChosenCsvFile) => {
    return {
        type: "CHANGE_CHOSEN_CSV_FILE",
        payload: { newChosenCsvFile },
    };
};

export const resetChosenCsvFile = () => {
    return {
        type: "RESET_CHOSEN_CSV_FILE",
    };
};
