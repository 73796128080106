import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'

const GetHourByDuration = (props) => {

    const [newDateObj, setNewDateObj] = useState('')

    useEffect(() => {

        let oldOateObject = (new Date())
        oldOateObject = (new Date(props.hour))

        let dateObject = (new Date())
        dateObject.setTime(oldOateObject.getTime() + (props.duration * 60 * 1000));

        // setNewDateObj(new Date(dateObject).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))

        setNewDateObj(moment(dateObject).tz("Asia/Jerusalem").format('HH:mm'))
    }, [props]);

    return (
        <>
            { newDateObj}
        </>
    )
}
export default GetHourByDuration