// import axios from "axios";
import Api from '../../utils/Api'
let token = "";

if (localStorage.getItem("token"))
    token = localStorage.getItem("token");
else if (sessionStorage.getItem("token"))
    token = sessionStorage.getItem("token");

export const getUseresList = (postObject) => {

    return (dispatch) => {

        return Api.post("user/login", postObject)
            .then((res) => {


                if (res.request.status == 200) {
                    // alert('success')
                    console.log(' res.data', res.data)
                    dispatch({
                        type: "GET_USERS_LIST",
                        payload: res.data
                    });
                }
            })
            .catch((e) => {
                // alert('error')
                console.log(e);
            });
    };
};

export const registerUser = (objectPost) => {
    return (dispatch) => {
        return Api
            .post(`user/register`, objectPost, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.request.status == 200) {
                    // alert("המשתמש נוסף");
                    dispatch({
                        type: "REGISTER_USER",
                    });
                }
            })
            .catch((e) => {
                alert("לא נוסף");
                console.log("e", e);
            });
    };
};

export const createContact = (objectPost) => {
    return (dispatch) => {
        return Api
            .post(`user/contact`, objectPost)
            .then((res) => {
                if (res.request.status == 200) {
                    // alert("המשתמש נוסף");
                    dispatch({
                        type: "CREATE_CONTACT",
                    });
                }
            })
            .catch((e) => {
                // alert("לא נוסף");
                console.log("e", e);
            });
    };
};



export const getProductByCode = (code) => {
    return {
        type: "GET_PRODUCT_BY_CODE",
        payload: { code },
    };
};

export const getInActiveProducts = (code) => {
    return {
        type: "GET_INACTIVE_PRODUCTS"
    };
};



