import React from 'react';
import { useLocation } from "react-router";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const FormStatistics = (props) => {
    let loaction = useLocation();
    let { groupCode, deliveryId } = useParams();
    const history = useHistory()
    return (
        <div className='container-fluid '>
            <div className='firstDivAfterNav'>
                <h2 className='text-center'>סטטיסטיקות</h2>

                <button className='rounded-button' style={{ float: 'right', margin: "10px" }}
                    onClick={() => history.push(`/admin/deliveries/groups-orders/${groupCode}/${deliveryId}`)}>
                    <i className="fas fa-chevron-circle-right"></i>
                </button>

                {loaction.state.formURL &&
                    <div className="col-sm-8" style={{ margin: "auto" }}>
                        <iframe width="100%" height="800"
                            src={`https://datastudio.google.com/embed/reporting/2a92bcc5-c2aa-4060-a438-31b8cd697d35/page/YMauC?params=${loaction.state.formURL}`}>
                        </iframe>
                    </div>
                }
            </div>
        </div>
    )
}

export default FormStatistics