import React from 'react'

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProducts } from "../redux/products/products-actions";
import { MDBBtn } from 'mdbreact'

class GetProducts extends React.Component {
    state = {
        newProductsFromDeliveryConverted: [],
        newProductsFromDelivery: [],
        indexOfProduct: [],
        isCheked: false,
        displayAllProductsBtn: 'inline',
        productsBtnText: "הוספת כל המוצרים",
        fontOfProducts: "fas fa-fill fa-2x"
    }

    setProdpuctOutFromDelivery = (event) => {

        //catch the place of the qunatity of the product (1 / 2 / 3.....)
        let productCode = event.target.id

        // // update array of qunatities
        let myDinamicArray = this.state.newProductsFromDeliveryConverted

        myDinamicArray.map((product, index) => {
            // console.log('product', product)
            // console.log('productCode', productCode)
            if (product.Code == parseInt(productCode) || product === parseInt(productCode)) {

                //remove value by index we founded
                if (index > -1) {

                    this.props.isUpdateForm ?
                        myDinamicArray[index] = { "Code": parseInt(product.Code), "IsActive": false }
                        : myDinamicArray.splice(index, 1)

                }
            }
            // console.log('myDinamicArray', myDinamicArray)
        })

        //updating sate
        this.setState({
            newProductsFromDeliveryConverted: myDinamicArray
        });

        // console.log('newProductsFromDelivery', this.state.newProductsFromDelivery)

        this.props.parentCallback(this.state.newProductsFromDeliveryConverted);
        event.preventDefault();
    }

    setProdpuctInDelivery = (event) => {

        //catch the productCode of the qunatity of the product (1 / 2 / 3.....)
        let productCode = event.target.id

        //UnShadow the chosen row
        document.getElementById('tr' + event.target.id).style.backgroundColor = 'white'

        // update array of qunatities
        let myDinamicArray = this.state.newProductsFromDeliveryConverted
        let isExist = false;

        this.props.prodcuts.map((product, index) => {
            myDinamicArray.map((productFromArray, index) => {
                // console.log('product.Code', productCode)
                if (productFromArray.Code === parseInt(productCode)) {
                    productFromArray.IsActive = true
                    isExist = true
                }

            });
            if (!isExist) {

                //if product chosen - push it to array
                if (product.Code == productCode) {
                    myDinamicArray.indexOf(productCode) === -1 ?
                        this.props.isUpdateForm ?
                            myDinamicArray.push({ "Code": parseInt(product.Code), "IsActive": true })
                            : myDinamicArray.push(product.Code)
                        : console.log("This item already exists");
                }
            }
        })

        // console.log('myDinamicArray', myDinamicArray)

        //updating sate
        this.setState({
            newProductsFromDeliveryConverted: myDinamicArray
        });

        // console.log('newProductsFromDelivery', this.state.newProductsFromDelivery)

        this.props.parentCallback(this.state.newProductsFromDeliveryConverted);
        // console.log('   this.props.parentCallback', this.props.parentCallback)
        event.preventDefault();

    }

    addAllProducts = (event) => {

        if (this.state.productsBtnText === "הוספת כל המוצרים") {

            //remove all current products from array
            while (this.state.newProductsFromDeliveryConverted.length) {
                this.state.newProductsFromDeliveryConverted.pop();
            }

            // console.log('newProductsFromDeliveryConverted', this.state.newProductsFromDeliveryConverted)

            // update array of qunatities
            let myDinamicArray = this.state.newProductsFromDeliveryConverted

            //add all products to the dinamic array
            this.props.prodcuts.map(product => {
                if (product.InStock)
                    this.props.isUpdateForm ? myDinamicArray.push({ "Code": product.Code, "IsActive": true })
                        : myDinamicArray.push(product.Code)
            })

            // console.log('myDinamicArray', myDinamicArray)

            //updating sate
            this.setState({
                newProductsFromDeliveryConverted: myDinamicArray
            });

            this.props.parentCallback(this.state.newProductsFromDeliveryConverted);
            // console.log('   this.props.parentCallback', this.props.parentCallback)
            event.preventDefault();

            // // event.target.style.display = 'none'
            this.setState({ productsBtnText: 'הסרת כל המוצרים', fontOfProducts: "fas fa-eraser fa-2x" })

        }
        else {

            //remove all current products from array
            while (this.state.newProductsFromDeliveryConverted.length) {
                this.state.newProductsFromDeliveryConverted.pop();
            }
            // console.log('products', this.state.newProductsFromDeliveryConverted)

            this.setState({ productsBtnText: 'הוספת כל המוצרים', fontOfProducts: "fas fa-fill fa-2x" })

        }

    }
    render() {

        return (
            <>
                {
                    this.props.isCreateOrUpdateForm ?
                        <div className='text-center col-sm-12' style={{ margin: 'auto', textAlign: 'center' }}>
                            <h3 className="col-sm-6" style={{ display: 'inline' }}>* מוצרים</h3>

                            <MDBBtn
                                style={{ display: this.state.displayAllProductsBtn }}
                                id='addAllProductsBtn'
                                className="col-sm-2"
                                rounded outline color="secondary"
                                variant="primary" type="" value="Send"
                                onClick={this.addAllProducts}
                            >
                                <i className={this.state.fontOfProducts}></i>{this.state.productsBtnText}</MDBBtn>
                        </div>
                        : ''
                }
                <div className='table-responsive'>
                    < table id="dataTableForForm" className="table table-striped col-sm-12 text-center" >
                        <thead className="thead-dark">
                            <tr>
                                <th>הוספה לטופס</th>
                                <th colSpan='2'>מוצר</th>
                                <th>מחיר</th>
                                <th>סטאטוס</th>
                                <th>הסרה מהטופס</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.loadMyProducts()}
                        </tbody>
                    </table >
                </div>
            </>
        )
    }

    loadMyProducts = () => this.props.prodcuts.map(product => {
        if (!product.InStock)
            return;

        let myImage = ''
        {
            this.props.isUpdateForm || this.props.isCopyForm ?
                myImage = `../../../${product.ReservationImage.toString()}`
                : myImage = `../${product.ReservationImage.toString()}`
        }

        let prodprice = product.Price + ' ש"ח'

        // let background = 'white'
        let myClass = 'notInForm'
        let status = 'לא בטופס'
        let displayOfOut = ''
        let displayOfIn = 'none'

        this.state.newProductsFromDeliveryConverted.map(productInDelivery => {
            // console.log('productInDelivery', productInDelivery)
            // console.log('product.Code', product.Code)
            if (this.props.isUpdateForm) {
                if (productInDelivery.Code == product.Code && productInDelivery.IsActive) {
                    myClass = 'inForm'
                    status = 'בטופס'
                    displayOfOut = 'none'
                    displayOfIn = ''
                }
            }
            else if (productInDelivery == product.Code || this.props.isCopyForm) {
                myClass = 'inForm'
                status = 'בטופס'
                displayOfOut = 'none'
                displayOfIn = ''
            }

        })

        return (

            < tr className={`thead-dark ${myClass}`} id={`tr${product.Code}`}   >
                <td>
                    <button onClick={(event) => event.preventDefault()}
                        className='rounded-button' style={{ display: displayOfOut }}>
                        <i id={product.Code} className="fa fa-plus" style={{ width: '100%', height: '100%' }}
                            onClick={this.setProdpuctInDelivery} aria-hidden="true"></i>
                    </button>
                </td>
                <td>
                    <img className="img-responsive cellHiddenInMobile" src={myImage} alt="prewiew" width={120} height={80}></img>
                </td>
                <td>
                    <h4 id='prodName' className="product-name"><b>{product.Name}</b></h4>
                    <h4>
                        <small id='productDescriptionForAdmin'>{product.DescriptionForRes}</small>
                    </h4>
                </td>
                <td>
                    <h6 style={{ display: 'inline-block' }}><strong> {prodprice} </strong></h6>
                </td>
                <td>
                    {status}
                </td>
                <td>
                    <button onClick={(event) => event.preventDefault()}
                        className='rounded-button' style={{ display: displayOfIn }} >
                        <i id={product.Code} className="fa fa-minus" style={{ width: '100%', height: '100%' }}
                            onClick={this.setProdpuctOutFromDelivery} aria-hidden="true"></i>
                    </button>
                </td>
            </tr >
        )
    })

    // componentDidUpdate() {

    //     while (this.state.newProductsFromDeliveryConverted.length > 0) {
    //         this.state.newProductsFromDeliveryConverted.pop();
    //     }

    //     if (this.props.productsFromDelivery != null) {
    //         this.props.productsFromDelivery.map(product => {
    //             this.state.newProductsFromDeliveryConverted.push(product.Code)
    //         })
    //     }
    // }
    componentDidMount() {
        this.props.getProducts().then(() => {

            let myArrayForQunatities = []

            if (this.props.productsFromDelivery != null) {
                this.props.productsFromDelivery.map(product => {
                    if (this.props.isUpdateForm || this.props.isCopyForm)
                        this.state.newProductsFromDeliveryConverted.push({ "Code": product.Code, "IsActive": product.IsActive })
                })
            }

            this.setState({
                newProductsFromDelivery: this.props.productsFromDelivery,
                newInActiveProducts: this.props.inActiveProducts,
                indexOfProduct: [...myArrayForQunatities]
            })

            // console.log('newProductsFromDelivery', this.props.newProductsFromDeliveryConverted)
            this.props.parentCallback(this.state.newProductsFromDeliveryConverted);
        })
    }
}

const mapStateToProps = (state) => {
    return {
        prodcuts: state.productReducer.products

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProducts
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GetProducts);
