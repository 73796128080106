import React, { Fragment } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer, MDBInput, MDBCollapse } from 'mdbreact';
import moment from 'moment-timezone'
//components
import GetProducts from '../../../components/GetProducts'
import EditorInput from '../../../components/EditorInput'
import MyModal from '../../../components/MyModal'
import MyModal2 from '../../../components/MyModal'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupByCode } from '../../../redux/groups/groups-actions';

import { updateOnlineDeliveryById, getOnlineDeliveryById, deleteOnlineDeliveryById } from '../../../redux/onlineDeliveries/onlineDeliveries-actions';

// import { GetGroupDeliveryById, UpdateGroupDeliveryById, DeleteGroupDeliveryById } from "../../../redux/deliveries/deliveries-actions";


class UpdateOnlineDelivery extends React.Component {

    state = {
        deliveryIdFromCLinet: '',
        groupCode: '',
        deliveryAddress: '',
        deliveryDate: '',
        deliveryHour: '',
        deliveryDuration: '',
        eventMinimumTotalPrice: 0,
        deliveryPayBox: '',
        deliveryInActiveProducts: [],
        deliveryIsActive: '',
        checked: false,
        status: '',
        modalShowDelete: false,
        modalShowUpdate: false,
        errorMessage: '',
        updatedInActiveProducts: [],
        disabledBtn: false,
        header: '',
        footer: '',
        collapseID: "collapse1",
        collapseFooterID: "collapse3",
        arrowIcon: "fa fa-angle-down rotate-icon",
        arrowFooterIcon: "fa fa-angle-down rotate-icon",
        inputFields: ([
            { DeliveryDate: '', DeliveryTime: '', Duration: '' }

        ])
    }
    postUpdatedDelivery = () => {

        let date = document.getElementById('date').value
        // console.log('date', date)
        // let hour = document.getElementById('hour').value
        // let addressForPost = document.getElementById('address').value
        // let duration = document.getElementById('time').value


        // console.log('parentCallback', this.props.parentCallback)
        // if (this.props.parentCallback == null) {
        //     alert('in')
        //     this.handleCallback()
        // }

        // console.log('updatedInActiveProducts', this.state.updatedInActiveProducts)

        this.state.inputFields.map(field => {
            // console.log('length', field.Hour.length)
            // console.log('date1', date)
            // console.log('field.Hour before', field.Hour)

            if (field.DeliveryTime.length < 6)
                field.DeliveryTime = new Date(`${date} ${field.DeliveryTime}`);

            // console.log('field.Hour after', field.Hour)
        })




        const objectPost =

        {
            "GroupCode": this.state.groupCode,
            // "Address": "בדיקה",
            "DeliveryDateTime": new Date(date),
            // "Duration": 60,
            "Places": this.state.inputFields,
            "MinimumTotalPrice": this.state.eventMinimumTotalPrice,
            "PayboxGroupLink": this.state.deliveryPayBox ? this.state.deliveryPayBox : document.getElementById('payBox').value,
            "Products": this.state.updatedInActiveProducts,
            "InActiveProducts": [],
            "IsActive": this.state.deliveryIsActive ? this.state.deliveryIsActive : document.getElementById('status').value,
            "Header": this.state.header,
            "Footer": this.state.footer
        }

        // console.log('objectPost', objectPost)
        // console.log('deliveryIdFromCLinet', this.state.deliveryIdFromCLinet)

        this.props.updateOnlineDeliveryById(this.state.deliveryIdFromCLinet, objectPost).then(() => {
            // window.location.replace(`/admin/deliveries/groups-orders/${this.state.groupCode}/${this.state.deliveryIdFromCLinet}`)
            this.props.history.push(`/admin/deliveries/groups-orders/${this.state.groupCode}/${this.state.deliveryIdFromCLinet}`)

        })

    }

    changeFunction = (event) => {

        this.setState({
            // checked: !this.state.checked,
            deliveryIsActive: event.target.value,
        });
    }
    deleteDelivery = () => {

        this.props.deleteOnlineDeliveryById(this.state.deliveryIdFromCLinet).then(() => {
            // window.location.replace(`/admin/deliveries/groups-orders/${this.state.groupCode}/${this.state.deliveryIdFromCLinet}`)
            this.props.history.push(`/admin/deliveries`)
        })
    }

    handleCallback = (childData) => {

        // console.log('childData', childData)
        this.setState({ updatedInActiveProducts: childData })
    }

    // ******************************
    handleAddFields = () => {
        const values = [...this.state.inputFields];
        values.push({ DeliveryDate: '', DeliveryTime: '', Duration: '' });
        this.setState({ inputFields: values })
        this.setState({ disabledBtn: '' })
    };

    handleRemoveFields = index => {
        const values = [...this.state.inputFields];
        values.splice(index, 1);
        this.setState({ inputFields: values })

        //   console.log('inputFields.length', inputFields.length)
        if (this.state.inputFields.length === 2)
            this.setState({ disabledBtn: 'disabled' })
    };

    handleInputChange = (index, event) => {

        //  { DeliveryDate: '', DeliveryTime: '', Duration: ''}

        const values = [...this.state.inputFields];
        if (event.target.name === "DeliveryDate") {
            values[index].DeliveryDate = event.target.value;
        }
        else if (event.target.name === 'DeliveryTime') {
            values[index].DeliveryTime = event.target.value
        }
        else if (event.target.name === 'Duration') {
            values[index].Duration = event.target.value;
        }
        // setInputFields(values);
        this.setState({ inputFields: values })
    };
    // ******************************

    parentCallbackOfHtmlHeaderInput = (childData) => {

        // console.log('header', childData)
        this.setState({ header: childData })
        // console.log(this.state.header)
    }
    parentCallbackOfHtmlFooterInput = (childData) => {

        // console.log('footer', childData)
        this.setState({ footer: childData })
        // console.log(this.state.footer)
    }


    toggleCollapse = newCollapseID => () => {
        if (this.state.collapseID !== newCollapseID) {
            this.setState({ collapseID: newCollapseID, arrowIcon: "fa fa-angle-up rotate-icon" })
        }
        else {
            this.setState({ collapseID: "", arrowIcon: "fa fa-angle-down rotate-icon" })
        }
    }

    toggleCollapseFooter = newCollapseID => () => {

        if (this.state.collapseFooterID !== newCollapseID) {
            this.setState({ collapseFooterID: newCollapseID, arrowFooterIcon: "fa fa-angle-up rotate-icon" })
        }
        else {
            this.setState({ collapseFooterID: "", arrowFooterIcon: "fa fa-angle-down rotate-icon" })
        }
    }

    render() {


        return (
            <div className=' container-fluid'>
                <MDBContainer id='formContainer' className='firstDivAfterNav'>
                    <MDBRow>
                        <MDBCol md="12" style={{ margin: 'auto' }}>
                            <MDBCard>
                                <MDBCardBody>

                                    <form>
                                        <h2 className='text-center font-weight-bold'>עריכת חלוקה</h2>

                                        {this.props.onlineDeliveries.length > 0 ? this.props.onlineDeliveries.map(delivery => {
                                            // let groupEventHour = new Date(delivery.DeliveryDateTime).toLocaleTimeString('en-US', { hour12: false })
                                            // let groupEventDate = new Date(delivery.DeliveryDateTime).toISOString().slice(0, 10)
                                            let hour = ''
                                            return (
                                                <div className="black-text text-right" key={delivery._id}>
                                                    <div className='col-sm-8' style={{ margin: 'auto' }}>
                                                        <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>
                                                        <br />

                                                        <h3>סטאטוס</h3>
                                                        <select className="form-control" onChange={this.changeFunction} id="status">
                                                            {
                                                                delivery.IsActive ?

                                                                    <>
                                                                        {/* <p className='true' id='status'>הטופס פעיל</p> */}
                                                                        <option value={true} selected={true}>פעיל</option>
                                                                        <option value={false}>לא פעיל</option>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {/* <p className='true' id='status'>הטופס פעיל</p> */}
                                                                        <option value={true} >פעיל</option>
                                                                        <option value={false} selected={true}>לא פעיל</option>
                                                                    </>

                                                            }
                                                        </select>

                                                        <div className="form-group col-sm-12">
                                                            <label htmlFor="DeliveryDate">תאריך החלוקה</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="DeliveryDate"
                                                                name="DeliveryDate"
                                                                value={this.state.deliveryDate}
                                                                onChange={event => this.setState({ deliveryDate: event.target.value })}
                                                            />
                                                        </div>

                                                        <div className="form-group col-sm-12">
                                                            <label htmlFor="DeliveryTime">שעה</label>
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                id="DeliveryTime"
                                                                name="DeliveryTime"
                                                                value={this.state.deliveryHour}
                                                                onChange={event => this.setState({ deliveryHour: event.target.value })}
                                                            />
                                                        </div>

                                                        <div className="form-group col-sm-12">
                                                            <label htmlFor="Duration">זמן החלוקה בדקות</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                id="Duration"
                                                                name="Duration"
                                                                min="10"
                                                                value={this.state.deliveryDuration}
                                                                onChange={event => this.setState({ deliveryDuration: event.target.value })}
                                                            />
                                                        </div>

                                                        <hr />

                                                        {/* <h3>* חלוקה</h3> */}
                                                        {/* {
                                                            this.state.inputFields.map((inputField, index) => {

                                                                inputField.DeliveryTime.length < 20 ?
                                                                    hour = inputField.DeliveryTime
                                                                    : hour = new Date(inputField.DeliveryTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })


                                                                return (
                                                                    <Fragment key={`${inputField}~${index}`}>
                                                                        <div >
                                                                            <div className="form-group col-sm-12">
                                                                                <label htmlFor="DeliveryDate">תאריך החלוקה</label>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    id="DeliveryDate"
                                                                                    name="DeliveryDate"
                                                                                    value={inputField.DeliveryDate}
                                                                                    onChange={event => this.handleInputChange(index, event)}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group col-sm-12">
                                                                                <label htmlFor="DeliveryTime">שעה</label>
                                                                                <input
                                                                                    type="time"
                                                                                    className="form-control"
                                                                                    id="DeliveryTime"
                                                                                    name="DeliveryTime"
                                                                                    value={inputField.DeliveryTime}
                                                                                    onChange={event => this.handleInputChange(index, event)}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group col-sm-12">
                                                                                <label htmlFor="Duration">זמן החלוקה בדקות</label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    id="Duration"
                                                                                    name="Duration"
                                                                                    min="10"
                                                                                    value={inputField.Duration}
                                                                                    onChange={event => this.handleInputChange(index, event)}
                                                                                />
                                                                            </div>

                                                                            <div >
                                                                                <button className='rounded-button'
                                                                                    type="button"
                                                                                    onClick={() => this.handleAddFields()}
                                                                                >
                                                                                    +
                                                                                </button>&nbsp;

                                                                                <button className='rounded-button' disabled={this.state.disabledBtn}
                                                                                    type="button"
                                                                                    onClick={() => this.handleRemoveFields(index)}
                                                                                >
                                                                                    -
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            })}
                                                        <hr /> */}

                                                        <h3>מינימום ש"ח להזמנה</h3>
                                                        <MDBInput label="* " value={this.state.eventMinimumTotalPrice}
                                                            onChange={e => this.setState({ eventMinimumTotalPrice: e.target.value })}
                                                            required icon="hour" group type="number" validate
                                                            error="wrong"
                                                            min="10"
                                                            success="right" />
                                                        <br />




                                                        <h3 style={{ cursor: 'pointer' }} onClick={this.toggleCollapse("basicCollapse")}
                                                        >טקסט עליון&nbsp;
                                                            <i className={this.state.arrowIcon}></i>
                                                        </h3>
                                                        <span> (אופציונאלי)</span>

                                                        <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
                                                            <EditorInput
                                                                parentCallback={this.parentCallbackOfHtmlHeaderInput}
                                                                initialValue={delivery.Header}

                                                            />
                                                        </MDBCollapse>
                                                        <hr />

                                                        <h3> לינק ל PayBox</h3>
                                                        <MDBInput label="" valueDefault={delivery.PayboxGroupLink}
                                                            icon='money-bill' group type="link" validate
                                                            id="payBox"
                                                            onChange={e => { this.setState({ deliveryPayBox: e.target.value }) }}
                                                            error="wrong"
                                                            success="right"
                                                        />
                                                        {/* <InActiveProducts
                                            products={delivery}
                                        /> */}
                                                    </div>
                                                    <hr />

                                                    <MDBRow >
                                                        <MDBCol>

                                                            <GetProducts

                                                                isUpdateForm={true}
                                                                isCreateOrUpdateForm={true}
                                                                parentCallback={this.handleCallback}
                                                                inActiveProducts={delivery.InActiveProducts}
                                                                productsFromDelivery={delivery.Products}

                                                            />
                                                            <div className='col-sm-8' style={{ margin: 'auto' }}>
                                                                <h3 style={{ cursor: 'pointer' }} onClick={this.toggleCollapseFooter("footerCollapse")}
                                                                >טקסט תחתון&nbsp;
                                                                    <i className={this.state.arrowFooterIcon}></i>
                                                                </h3>
                                                                <span> (אופציונאלי)</span>
                                                                <MDBCollapse id="footerCollapse" isOpen={this.state.collapseFooterID}>
                                                                    <EditorInput
                                                                        parentCallback={this.parentCallbackOfHtmlFooterInput}
                                                                        initialValue={delivery.Footer}
                                                                    />
                                                                </MDBCollapse>
                                                                <hr />
                                                            </div>

                                                            <div className=' col-sm-12 text-center'>

                                                                <MDBBtn
                                                                    rounded outline color="secondary"
                                                                    variant="primary" type="" value="Send" validate
                                                                    onClick={() =>
                                                                        // document.getElementById('Address').value != ''
                                                                        //     // && document.getElementById('date').value != ''
                                                                        //     && document.getElementById('Hour ').value != ''
                                                                        document.getElementById('Duration').value > 0
                                                                            ?
                                                                            this.setState({ modalShowUpdate: true })
                                                                            : this.setState({ errorMessage: 'אנא מלא/י את כל שדות החובה' })
                                                                    }
                                                                > עדכון חלוקה</MDBBtn>
                                                                {/* <MDBBtn rounded outline color="secondary"
                                                     variant="primary" type="" value="Send" */}
                                                                <MDBBtn
                                                                    rounded outline color="secondary"
                                                                    variant="primary" type="" value="Send" validate
                                                                    onClick={() => this.setState({ modalShowDelete: true })}
                                                                > מחיקת טופס
                                                                </MDBBtn>
                                                            </div>




                                                            <p>{this.state.errorMessage}</p>

                                                        </MDBCol>
                                                    </MDBRow >



                                                    <MyModal2 />
                                                    <MyModal2
                                                        headlineText='האם ברצונך לעדכן את הטופס?'
                                                        show={this.state.modalShowUpdate}
                                                        onHide={() => this.setState({ modalShowUpdate: false })}
                                                        ButtonText='ביטול'
                                                        ButtonDeleteText='אישור'
                                                        ButtonDinamicFunction={() => this.postUpdatedDelivery()}
                                                    />

                                                    <MyModal />

                                                    <MyModal
                                                        headlineText='האם ברצונך למחוק את הטופס?'
                                                        headlineBody='לתשומך ליבך - הטופס וההזמנות הקשורות אליו ימחקו ללא אפשרות שיחזור'
                                                        show={this.state.modalShowDelete}
                                                        onHide={() => this.setState({ modalShowDelete: false })}
                                                        ButtonText='ביטול'
                                                        ButtonDeleteText='אישור'
                                                        ButtonDinamicFunction={() => this.deleteDelivery()}
                                                    />

                                                </div>

                                            )

                                        })
                                            : <h2>אין נתונים</h2>
                                        }



                                    </form>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer >
            </div >
        )
    }



    componentDidMount() {
        console.log('in update 1')
        document.title = "משק אלמוג - עריכת טופס אונליין"
        window.scroll(0, 0)

        const { match } = this.props;
        let inputPlaces = []


        this.props.getOnlineDeliveryById(match.params.deliveryId).then(() => {
            console.log('onlineDeliveries', this.props.onlineDeliveries)

            this.props.getGroupByCode(match.params.groupCode)
            this.setState({
                deliveryIdFromCLinet: match.params.deliveryId,
                groupCode: match.params.groupCode
            })

            this.props.onlineDeliveries.map((delivery, index) => {

                let groupEventHour = moment(new Date(delivery.DeliveryDate)).format('HH:mm')
                let groupEventDate = new Date(delivery.DeliveryDate).toISOString().slice(0, 10)

                this.setState({
                    eventMinimumTotalPrice: delivery.MinimumTotalPrice,
                    deliveryDate: groupEventDate,
                    deliveryHour: groupEventHour,
                    deliveryDuration: delivery.Duration

                })

                //set day
                // delivery.Days.map(day => {
                //     inputPlaces.push({
                //         "DeliveryDate": day.DeliveryDate,
                //         "DeliveryTime": day.DeliveryTime,
                //         "Duration": day.Duration
                //     })
                // })

                //fake day - DELETE
                inputPlaces.push({
                    "DeliveryDate": '20/08/2021',
                    "DeliveryTime": '08:00-13:00',
                    "Duration": 300
                })
                console.log('inputPlaces', inputPlaces)

                if (delivery.Header !== undefined && delivery.Header !== null)
                    this.setState({ header: delivery.Header, collapseID: true, arrowIcon: "fa fa-angle-up rotate-icon" })

                if (delivery.Footer !== undefined && delivery.Footer !== null)
                    this.setState({ footer: delivery.Footer, collapseFooterID: true, arrowFooterIcon: "fa fa-angle-up rotate-icon" })

            })

            // console.log('inputPlaces', inputPlaces)

            this.setState({ groupCode: match.params.groupCode, inputFields: [...inputPlaces], disabledBtn: 'disabled' })
            // console.log('groups', this.props.groups)
        })

    }
}

const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
        // deliveries: state.deliveriesReducer.deliveries
        onlineDeliveries: state.onlineDeliveriesReducer.onlineDeliveries,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroupByCode,

            getOnlineDeliveryById,
            // GetGroupDeliveryById,

            updateOnlineDeliveryById,
            // UpdateGroupDeliveryById,

            deleteOnlineDeliveryById,
            // DeleteGroupDeliveryById
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOnlineDelivery);
