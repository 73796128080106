import React from "react";
import { RWebShare } from "react-web-share";

const CopyToClipBoard = (props) => {
    return (
        <div>
            <RWebShare
                data={{
                    text: props.text,
                    url: props.link,
                    title: "משק אלמוג",
                }}
            >
                <button id='clipBoard' className='rounded-button' color='tw' style={{ display: 'inline' }}>
                    <i className="fas fa-share-alt"></i>
                </button>

            </RWebShare>
        </div>
    );
};

export default CopyToClipBoard;