import { MDBCard } from 'mdbreact'
import React from 'react'
const Reports = () => {
    return (
        <div className='container-fluid firstDivAfterNav'>
            <div className='container'>
                <MDBCard>
                    <iframe width="100%" height="1900" src="https://datastudio.google.com/embed/reporting/a2a0f795-c436-4c52-859c-4fe924a7d563/page/4VDGB"
                    ></iframe>
                </MDBCard>
            </div>
        </div>
    )
}
export default Reports