
import React from "react";
import UpdateBtn from '../../../components/UpdateBtn'
import GedDayOfWeek from '../../../components/GedDayOfWeek'
import GetLocalDayAndHour from '../../../components/GetLocalDayAndHour'
import MyContextMenu from '../../../components/ContextMenu/MyContextMenu'
import { ContextMenuTrigger } from "react-contextmenu";


// import SerachInout from '../../../components/SerachInout'
import moment from "moment";
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import '../../../dist/css/admin.css'

import "../orders/show_orders/groupOrdersSummary.css";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupDeliveriesByWeekNumber, setGroupDeliveryRevenue } from "../../../redux/deliveries/deliveries-actions";
import { getGroups } from "../../../redux/groups/groups-actions";
import _ from "lodash";

class GetOnlineDelivery extends React.Component {
    state = {
        revenues: [],
        groupNames: [],
        active: '',
        items: [],
        currentPage: 0,
        startDate: null,
        endDate: null,
        currentWeek: 'שבוע נוכחי',
        isDisplayed: "none",
        floatedMenuDelivery: null,
        floatedMenuUpdate: null,
        floatedMenuForm: null,
        floatedMenuManagerView: null,
        floatedMenuGroupPage: null,
        floatedMenuMessegePage: null
    };

    getDeliveriesByWeekNum = (weekAgo) => {

        // console.log('weekNum', weekAgo)

        //reset revenues array
        let revenuesArray = []
        this.props.getGroupDeliveriesByWeekNumber(weekAgo).then(() => {
            this.props.deliveries.map(delivery => {
                // console.log('delivery', delivery)
                revenuesArray.push(delivery.Revenue)
            })
            this.setState({ revenues: revenuesArray })
        })

        // console.log('window', window)



        let weekText = ''
        const week = weekAgo
        if (weekAgo != 0) {
            weekAgo = -weekAgo * 7;
            weekText = ''
        }
        else
            weekText = 'שבוע נוכחי'

        this.props.getGroupDeliveriesByWeekNumber(week)
            .then(() => {
                this.setState({
                    currentPage: week,
                    startDate: moment().weekday(weekAgo).format("DD/MM"),
                    endDate: moment().weekday(weekAgo + 6).format("DD/MM"),
                    currentWeek: weekText
                })

            })
            .catch((e) => console.log(e));
    }

    render() {

        return (
            <div className='container-fluid'>
                <MDBContainer className='firstDivAfterNav'>
                    <MDBRow>
                        <MDBCol md="12" style={{ margin: 'auto' }}>
                            <MDBCard>
                                <MDBCardBody>

                                    <h2 className='text-center font-weight-bold'>הזמנות אונליין</h2>

                                    <UpdateBtn
                                        headlineText='חלוקה חדשה'
                                        linkPath='/admin/online/create-delivery'
                                    />

                                    <MyContextMenu
                                        delivery={this.state.floatedMenuDelivery}
                                        update={this.state.floatedMenuUpdate}
                                        form={this.state.floatedMenuForm}
                                        managerView={this.state.floatedMenuManagerView}
                                        groupPage={this.state.floatedMenuGroupPage}
                                        messegePage={this.state.floatedMenuMessegePage}
                                        isDeliveriesPage={true}
                                    />

                                    {/* <SerachInout /> */}

                                    <div className='text-center'>
                                        <h5 className='font-weight-bold'>{this.state.currentWeek}</h5>
                                        <span className='font-weight-bold font-italic'>{this.state.endDate}</span> - <span className='font-weight-bold font-italic'>{this.state.startDate}</span>
                                    </div>
                                    <div className='table-responsive '>
                                        <table id="dataTable"
                                            className="table table-striped col-sm-12 text-center"
                                            border='1' style={{ margin: 'auto', tableLayout: 'fixed' }}>
                                            <thead className="thead-dark">
                                                <tr className="header">
                                                    {/* <th></th> */}
                                                    {/* <th>יום</th> */}
                                                    {/* <th>תאריך</th> */}
                                                    <th>קבוצה</th>
                                                    <th>שעה</th>
                                                    {/* <th>כתובת</th> */}
                                                    <th>סטאטוס</th>
                                                    <th>סה"כ הזמנות</th>
                                                    <th>סה"כ הכנסות</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.loadDeliveries()}
                                                <tr id='sumRow' style={{ background: 'none', borderTop: '3px solid' }}>
                                                    <td colSpan='3'></td>
                                                    <td className="font-weight-bold">
                                                        {this.loadDeliveriesSum()} ₪
                                                    </td>
                                                    <td className="font-weight-bold">
                                                        {this.loadDeliveriesSumOfTotal()} ₪
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                    <div className='col-sm-8 text-center' style={{ margin: 'auto' }}>
                                        <button className="btn text-center almogBtn"
                                            onClick={() => this.getDeliveriesByWeekNum(this.state.currentPage + 1)}
                                        >
                                            <i className="fas fa-arrow-alt-circle-right fa-2x"></i>
                                            הקודם&nbsp;
                                        </button>
                                        <button className="btn text-center almogBtn"
                                            disabled={this.state.currentPage == -1}
                                            onClick={() => this.getDeliveriesByWeekNum(this.state.currentPage - 1)}
                                        >הבא&nbsp;
                                            <i className="fas fa-arrow-alt-circle-left fa-2x"></i>

                                        </button>

                                    </div>

                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div >
        );
    }
    rowClicked = (link) => {
        this.props.history.push(link)
    }

    updateRevenue = (id, e, i) => {
        this.debouncedUpdateRevenue(id, e.target.value)
        let items = [...this.state.revenues];
        let item = { ...items[i] };
        item = e.target.value
        items[i] = item;
        this.setState({ revenues: items });
    }

    debouncedUpdateRevenue = _.debounce((id, revenue) => {
        if (revenue != null) {
            this.props.setGroupDeliveryRevenue({ "Id": id, "Revenue": revenue })
                .then(() => {
                    this.loadGroupDeliveries(this.state.currentPage);
                })
                .catch((e) => console.log(e))
        }
    }, 500);

    setClickableRow = (e, link) => {
        // console.log('e.target', e.target.tagName)
        if (e.target.tagName !== 'INPUT')
            this.rowClicked(link)
    }

    loadDeliveriesSum = () => {
        let totalPrices = 0
        for (let index = 0; index < this.props.deliveries.length; index++) {
            totalPrices += this.props.deliveries[index].TotalOrdersPrice
        }
        return totalPrices
    }

    loadDeliveriesSumOfTotal = () => {
        let totalPricesOfTotal = 0
        for (let index = 0; index < this.state.revenues.length; index++) {
            totalPricesOfTotal += this.state.revenues[index]
        }
        return totalPricesOfTotal
    }

    loadDeliveries = () => this.props.deliveries.sort((a, b) =>
        b.dateOfDelivery - a.dateOfDelivery).map((delivery, index) => {
            // console.log('delivery', delivery)

            let name = ''
            let count = 0

            while (count < this.state.groupNames.length) {
                count++
                switch (delivery.GroupCode) {
                    case count:
                        name = this.state.groupNames[count - 1]
                    default:
                        break;
                }
            }

            let IsActive = ''
            let activeText = ''
            if (delivery.IsActive) {
                activeText = 'פעיל'

            }
            else {
                activeText = 'סגור'
                IsActive = 'isNotActive'
            }

            // console.log('delivery', index)

            let color = '#dddddd'
            let deliveryDay = new Date(delivery.DeliveryDateTime).getDay('he-IL', { hour12: false })
            // let day = < GedDayOfWeek
            //     dayOfWeek={delivery.DeliveryDateTime}
            // />

            // let prevDay = < GedDayOfWeek
            //     dayOfWeek={this.props.deliveries[index - 1]}
            // />

            let day = new Date(delivery.DeliveryDateTime).getDay();
            let prevDay;
            if (index > 0)
                prevDay = new Date(this.props.deliveries[index - 1].DeliveryDateTime).getDay();

            // console.log('day', day)
            // console.log('prevDay', prevDay)

            // console.log('day', deliveryDay)
            if (deliveryDay % 2 === 0)
                // color = 'rgba(0,0,0,.05)'
                color = 'white'
            else
                color = 'white'

            // counter++
            return (
                <>

                    {
                        day != prevDay ?
                            <tr>
                                <td colSpan='5' className='bg-light headlineRow h3'>
                                    < GedDayOfWeek
                                        dayOfWeek={delivery.DeliveryDateTime}
                                    />
                                </td>
                            </tr>
                            : ''
                    }



                    <ContextMenuTrigger id="some_unique_identifier" key={delivery._id}>
                        <tr className={`thead-dark ${IsActive}`} id={delivery._id} style={{ backgroundColor: color }}
                            onClick={(e) => this.setClickableRow(e, `/admin/online/orders/${delivery.GroupCode}/${delivery._id}`)}
                            onContextMenu={() =>
                                this.setState({
                                    floatedMenuDelivery: `/admin/deliveries/groups-orders/${delivery.GroupCode}/${delivery._id}`,
                                    floatedMenuUpdate: `/admin/deliveries/edit/${delivery.GroupCode}/${delivery._id}`,
                                    floatedMenuForm: `/groups/${delivery.GroupCode}/${delivery._id}`,
                                    floatedMenuManagerView: `/admin/deliveries/groups-orders/manager/${delivery.GroupCode}/${delivery._id}`,
                                    floatedMenuGroupPage: `/admin/groups/groups-orders/${delivery.GroupCode}`,
                                    floatedMenuMessegePage: `/admin/deliveries/send-messege/${delivery.GroupCode}/${delivery._id}`

                                })
                            }

                        >
                            {/* <td>
                                < GedDayOfWeek
                                    dayOfWeek={delivery.DeliveryDateTime}
                                />
                            </td> */}
                            <td>{name}</td>

                            <td>
                                {
                                    delivery.Places != null &&
                                    delivery.Places.map((place, index) => {
                                        return <span key={index}>
                                            {
                                                (delivery.Places.length !== index && index !== 0) &&
                                                ', '
                                            }
                                            <GetLocalDayAndHour
                                                hourOfDelivery={place.Hour}
                                            />
                                        </span>
                                    })
                                }
                            </td>
                            <td>
                                {activeText}
                            </td>
                            <td>{delivery.TotalOrdersPrice} ₪
                            </td>
                            <td>
                                <input className='inputOfRevenue' onChange={(e) => this.updateRevenue(delivery._id, e, index)}
                                    type='number' value={this.state.revenues[index] == 0 ? null : this.state.revenues[index]}>
                                </input>
                                <span><b> ₪</b></span>
                            </td>
                        </tr >
                    </ContextMenuTrigger >
                </>

            );
            // }
        })

    loadGroupDeliveries = (weekNum) => {
        // console.log('weekNum', weekNum)
        this.props.getGroupDeliveriesByWeekNumber(weekNum).then(() => {
            this.props.deliveries.map(delivery => {
                // console.log('delivery', delivery)
                revenuesArray.push(delivery.Revenue)
            })
            this.setState({ revenues: revenuesArray })
        })

        // console.log('window', window)
        let myArrayForQunatities = []
        let revenuesArray = []
        this.props.getGroups()
            .then(() => {

                for (let index = 0; index < this.props.groups.length; index++) {
                    myArrayForQunatities.push(this.props.groups[index].InternalName)
                }
                // console.log('myArrayForQunatities1:', myArrayForQunatities)
                this.setState({
                    groupNames: [...myArrayForQunatities],
                    active: 1
                })
            })
            .catch((e) => {
                console.log(e);
            });



        this.setState({
            startDate: moment().weekday(0).format("DD/MM"),
            endDate: moment().weekday(6).format("DD/MM")
        })

    }
    componentDidMount() {
        document.title = "משק אלמוג - טפסים"
        this.loadGroupDeliveries(this.state.currentPage);

    }
}



const mapStateToProps = (state) => {
    return {
        deliveries: state.deliveriesReducer.deliveries,
        groups: state.groupReducer.groups,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            // GetGroupsDeleveries,
            getGroupDeliveriesByWeekNumber,
            getGroups,
            setGroupDeliveryRevenue
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GetOnlineDelivery);
