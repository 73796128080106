import React, { useEffect, useState } from 'react'
import { MDBRow, MDBCol } from 'mdbreact'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addOnlineOrder } from '../../redux/onlineOrders/online-orders-action';
import { useDispatch, useSelector } from "react-redux";

const CartStep3 = (props) => {
    const [collapseID, setCollapseID] = useState('')
    const [upIcon, setUpIcon] = useState('fas fa-chevron-up')
    const [upIcon2, setUpIcon2] = useState('fas fa-chevron-up')
    const [display, setDisplay] = useState('')
    const [display2, setDisplay2] = useState('')
    const dispatch = useDispatch();
    const currentOrderNum = useSelector((state) => state.onlineOrdersReducer.currentOnlineOrderNum);

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const toggleCollapse = (e) => {
        e.preventDefault()
        if (display == '') {
            setDisplay('none')
            setUpIcon('fas fa-chevron-down')
        }
        else {
            setDisplay('')
            setUpIcon('fas fa-chevron-up')
        }
    }
    const toggleCollapse2 = (e) => {
        e.preventDefault()
        if (display2 == '') {
            setDisplay2('none')
            setUpIcon2('fas fa-chevron-down')
        }
        else {
            setDisplay2('')
            setUpIcon2('fas fa-chevron-up')
        }
    }

    const sendReservation = () => {

        const { firstName, lastName, email, phone, additionalPhone, city, street, notes, payment, approved, hour } = props.personData[0]

        let Products = []
        props.cartData.map(c => {
            Products.push({
                "Code": c.Code,
                "Name": c.ShortName,
                "Price": c.Price,
                "Weight": c.Weight,
                "Amount": c.Quantity,
                "Description": c.DescriptionForRes,
            })
        })


        console.log('props.deliveryId', props.deliveryId)
        let objectOfreservation = {
            "OnlineDeliveryId": props.deliveryId,
            "DeliveryDate": "2021-08-24T00:00:00.000Z",
            "DeliveryTime": hour.toString(),
            "TotalPrice": props.toalOfAll,
            "Comment": notes,
            "InternalComment": "",
            "PaymentMethod": payment,
            "Approved": approved,

            "PersonInfo": {
                "FirstName": firstName,
                "LastName": lastName,
                "Phone": phone,
                "Email": email,
                "Phone": phone,
                "AdditionalPhone": additionalPhone,
                "City": city,
                "Address": street
            },
            Products

        }
        console.log('objectOfreservation', objectOfreservation)

        addOnlineOrder(objectOfreservation).then((data) => {
            dispatch(data);
            // console.log('data is', data)
            // console.log('currentOrderNum', currentOrderNum)
            props.setOrderNumber(data.payload[0].OrderNumber)
            props.setcheckedStep3(true)
            props.setActiveStep(3)



        })

    }
    return (
        <>
            {/* <div className='col-sm-12 stepHeadline'>
                <div className='col-lg-4 col-sm-12' style={{ margin: 'auto' }}>
                    <h3 className='text-center'>סיכום ושליחת הזמנה</h3>
                    <p>אנא ודאי/י כי פרטיך נכונים.</p>
                    <p>לסיום ושליחת ההזמנה לחיצה על <b>"סיום"</b> מטה</p>
                </div>
            </div> */}
            <MDBRow className='stepHeadline'>
                <MDBCol lg='3' sm='6' className='text-right' style={{ margin: 'auto' }}>
                    <button className="btn text-center almogBtn cartBtnRight"
                        type='button'
                        onClick={() => props.setActiveStep(1)}
                    >
                        <i className="fas fa-arrow-alt-circle-right fa-2x"></i>
                        הקודם&nbsp;
                    </button>
                </MDBCol>
                <MDBCol lg='6' sm='12' className='text-center'>
                    <h3 className='text-center'>סיכום ושליחת הזמנה</h3>
                    <p>אנא ודאי/י כי פרטיך נכונים.</p>
                    <p>לסיום ושליחת ההזמנה לחיצה על <b>"סיום"</b></p>
                </MDBCol>
                <MDBCol lg='3' sm='6' className='text-left' style={{ margin: 'auto' }}>
                    <button className="btn text-center almogBtn cartBtnLeft"
                        onClick={() => sendReservation()}
                        type='submit'
                    >סיום&nbsp;
                        <i className="fas fa-paper-plane fa-2x"></i>
                    </button>
                </MDBCol>
            </MDBRow>


            {/* <!-- Buttons trigger collapse --> */}

            {/* <h4 className='text-center'>מוצרים</h4> */}
            {/* {loadCart}   */}
            {/* <h5 className='text-center'>סה"כ לתשלום: {props.toalOfAll} ₪ </h5> */}
            {/* <hr /> */}
            {/* {loadDetails} */}
            <div className='col-sm-12 text-center' style={{ margin: 'auto' }}>

                <table id='dataTableForForm' className='col-sm-12 reservationTable cartTable' >
                    {/* <tr>
                        <td colSpan='6'>
                            <span> הזמנה מספר {order.OrderNumber}</span>
                        </td>
                    </tr> */}
                    <tr className='firstTd'>
                        {/* <td colSpan='6'>
                            <span id='dateAandHour'>
                                <GetLocalDayAndHour
                                    style={{ display: 'inline' }}
                                    hourOfDelivery={order.OrderDate}
                                /> |    <GetLocalDayAndHour
                                    style={{ display: 'inline' }}
                                    dateOfDelivery={order.OrderDate}
                                />
                            </span>
                        </td> */}
                    </tr>
                    {
                        props.personData.map(person => {
                            return <React.Fragment key={person.phone}>
                                <tr className='firstTd'>
                                    <td colSpan='6'>&nbsp;</td>
                                </tr>
                                <tr className='headlineTr'>
                                    <td colSpan='6'>
                                        <h4 className='text-center'>פרטים אישיים&nbsp;
                                            <small onClick={(e) => toggleCollapse(e)} style={{ cursor: 'pointer' }}>
                                                <i className={`${upIcon}`}></i>
                                            </small>
                                        </h4>
                                    </td>
                                </tr>
                                {/* <MDBCollapse id={person.phone} isOpen={collapseID}> */}

                                <tr className='notHasHoverAttributes' style={{ display }}>
                                    <td colSpan='3'><span>{person.firstName} {person.lastName} </span></td>
                                    <td colSpan='3'><span>{person.email}</span></td>
                                </tr>
                                <tr className='firstTd' style={{ display }}>
                                    <td colSpan='3'><span>{person.phone}</span> </td>
                                    <td colSpan='3'><span>תשלום ב{person.payment}</span> </td>
                                </tr>
                                {
                                    person.notes.length > 0 ?
                                        <tr className='firstTd' style={{ display }}>
                                            <td colSpan='6'>   {person.notes}</td>
                                        </tr>
                                        : ''
                                }
                                <tr className='firstTd' style={{ display }}>
                                    <td colSpan='3'>{person.city}</td>
                                    <td colSpan='3'>{person.street}</td>
                                </tr>
                                <tr className='firstTd' style={{ display }}>
                                    <td colSpan='6'>שעות המשלוח: {person.hour}</td>
                                </tr>


                                <tr className='firstTd'>
                                    <td colSpan='6'>&nbsp;</td>
                                </tr>
                                {/* </MDBCollapse> */}
                                <tr className='headlineTr'>
                                    <td colSpan='6'><h4 className='text-center'>מוצרים&nbsp;
                                        <small onClick={(e) => toggleCollapse2(e)} style={{ cursor: 'pointer' }}>
                                            <i className={`${upIcon2}`}></i>
                                        </small>
                                    </h4>
                                    </td>
                                </tr>
                                <tr style={{ display: display2 }}>
                                    {/* <th></th> */}
                                    <th className='myCell'>מוצר</th>
                                    <th className='myCell'>כמות</th>
                                    <th className='myCell'>פירוט</th>
                                    <th className='myCell'>מחיר ליחידה</th>
                                    <th className='myCell'>סה"כ</th>
                                </tr>

                            </React.Fragment>
                        })
                    }
                    {
                        props.cartData.map(product => {

                            return (
                                <tr key={product.Code} style={{ display: display2 }}>
                                    {/* <td className='imgCell'><img height='50px' src={`../${product.ReservationImage}`} alt='Preview'></img></td> */}
                                    <td className='myCell'> {product.ShortName}</td>
                                    <td className='myCell'> {product.Quantity} </td>
                                    <td className='myCell'> {product.DescriptionForRes}</td>
                                    <td className='myCell'>
                                        {
                                            product.Code === 10 ?
                                                <> {product.Price} ₪   (ל - 2 יחידות)</>
                                                : <> {product.Price} ₪ </>
                                        }

                                        {/* {product.Price} ₪   */}
                                    </td>
                                    <td className='myCell'>{

                                        product.Code === 10 ?
                                            (product.Price / 2) * product.Quantity
                                            : product.Price * product.Quantity
                                    } ₪</td>

                                </tr>
                            )




                        })
                    }
                    <tr className='firstTd' style={{ display: display2 }}>
                        <td colSpan='6'> <span>סה"כ סכום ההזמנה: <b>{props.toalOfAll} ₪</b></span></td>
                    </tr>
                </table>
                <button className="btn text-center almogBtn BottomBtnCart"
                    type='button'
                    onClick={() => props.setActiveStep(1)}
                >
                    <i className="fas fa-arrow-alt-circle-right fa-2x"></i>
                    הקודם&nbsp;
                </button>
                <button className="btn text-center almogBtn BottomBtnCart"
                    type='submit'
                    onClick={() => {
                        sendReservation()
                    }}
                >סיום&nbsp;
                    <i className="fas fa-paper-plane fa-2x"></i>
                </button>
            </div>
        </>
    )
}
// const mapStateToProps = (state) => {
//     return {

//         currentOnlineOrderNum: state.onlineOrdersReducer.currentOnlineOrderNum,
//         onlineOrders: state.onlineOrdersReducer.onlineOrders,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators(
//         {
//             addOnlineOrder
//         },
//         dispatch
//     );
// };

export default CartStep3
