import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

class EditorInput extends React.Component {
    handleEditorChange = (content, editor) => {
        // console.log('Content was updated:', content);
        this.props.parentCallback(content);

    }

    render() {
        return (
            <Editor
                apiKey="4q8odasrmx10v95i62d39px7gmtkzx9gg5n1auq0tq2hcupj"
                initialValue={this.props.initialValue !== "" ? this.props.initialValue : "<p></p>"}
                init={{
                    height: 500,
                    directionality: 'rtl',
                    language: 'he_IL',
                    menubar: true,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help | styleselect  | forecolor  | | link image | code'
                }
                }
                onEditorChange={this.handleEditorChange}
            />
        );
    }
}

export default EditorInput;
