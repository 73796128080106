import React from 'react'
import { MDBContainer, MDBCard } from 'mdbreact'
import { useParams } from "react-router-dom";
const UsersUnSubscribe = () => {
    let { emailId } = useParams();
    return (
        <div className='container-fluid'>

            <MDBContainer className="firstDivAfterNav text-center">
                <MDBCard style={{ height: '220px' }}>
                    <h2 className="text-center font-weight-bold">הסרה מרשימת תפוצה</h2>
                    <p>המייל <b>{emailId}</b> הוסר מרשימת התפוצה</p>
                </MDBCard>
            </MDBContainer>
        </div>
    )
}
export default UsersUnSubscribe