import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBInput, MDBIcon } from 'mdbreact';
import Button from 'react-bootstrap/Button';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUseresList } from "../../redux/users/useres-actions";

class Login extends React.Component {
    constructor() {
        super()
        this.state = {
            user: '',
            password: '',
            rememberMe: false,
            token: '',
            // users: [],
            errorMessage: '',
            name: '',
            passwordShown: false,
            display: 'none'
        }
    }

    componentDidMount() {

        document.title = "משק אלמוג - התחברות"

        const rememberMe = localStorage.getItem('rememberMe') === 'true';
        const user = rememberMe ? localStorage.getItem('user') : '';
        const password = rememberMe ? localStorage.getItem('password') : '';
        const name = rememberMe ? localStorage.getItem('name') : '';

        if (localStorage.getItem('name') == true) {
            this.props.history.push('/login/admin/')
            // window.location = `/login/admin/}`;
        }
        if (sessionStorage.getItem('name') == true)
            this.props.history.push('/login/admin/')

        this.setState({ user, rememberMe, password, name });
    }

    handleChange = (event) => {

        this.setState({
            display: 'block',
            password: event.target.value
        });

    };

    togglePasswordVisiblity = () => {
        this.setState(prevStae => {
            return {
                passwordShown: !prevStae.passwordShown
            }
        })
    }

    handleFormSubmit = (e) => {
        e.preventDefault()

        const { user, rememberMe, password, token } = this.state;

        const postObject = {
            "Email": user,
            "Password": password
        }

        // console.log('postObject', postObject)

        let data = {}

        this.props.getUseresList(postObject).then(() => {

            let token = this.props.users.token

            // console.log('token', token)
            data = {
                email: this.props.users.Email,
                name: this.props.users.Name,
                token: token,
                role: this.props.users.Role
            }

            // console.log('data', this.props.users)

            if (token != null) {
                if (this.state.rememberMe) {
                    // Save data to localStorage
                    localStorage.setItem('rememberMe', rememberMe);
                    localStorage.setItem('name', rememberMe ? this.props.users.Name : '');
                    localStorage.setItem('email', rememberMe ? this.props.users.Email : '');
                    localStorage.setItem('token', rememberMe ? token : '');
                    localStorage.setItem('role', rememberMe ? this.props.users.Role : '');
                }
                else {
                    // Save data to sessionStorage
                    sessionStorage.setItem('name', this.props.users.Name);
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('role', this.props.users.Role);
                }

                window.location.replace('/admin')
                // this.props.history.push('/admin')
            }

            else {
                this.setState({
                    errorMessage: 'שם משתמש או סיסמה שגויים'
                })
            }
        })
    };

    render() {
        return (
            <div className='container-fluid'>
                <MDBContainer className='firstDivAfterNavOfLogin animated fadeIn'>
                    <MDBRow>
                        <MDBCol md="6" style={{ margin: 'auto', direction: 'ltr' }}>

                            <MDBCard className='col-sm-12'>

                                <h2 className='text-center font-weight-bold'>התחברות </h2>

                                <MDBCardImage
                                    style={{ width: '50%', margin: 'auto' }}
                                    hover
                                    overlay='white-light'
                                    className='card-img-top'
                                    src={require('../../components/img/almog.PNG')}
                                    alt='man'
                                />

                                <form>
                                    <MDBInput id='email' label="* שם משתמש"
                                        style={{ marginTop: '0' }}
                                        value={this.state.user}
                                        onChange={(e) => this.setState({ user: e.target.value })}
                                        required
                                        group type="text"
                                        error="wrong"
                                        icon="user"
                                        success="right"
                                        className="form-control loginFields"
                                    />

                                    <MDBInput id='password' label="* סיסמה"
                                        className='form-control loginFields'
                                        value={this.state.password}
                                        onChange={e => this.handleChange(e)}
                                        required
                                        icon="lock"
                                        group
                                        error="wrong" name="user_password"
                                        success="right"
                                        type={this.state.passwordShown ? "text" : "password"}
                                    >
                                        {
                                            this.state.password ?
                                                <i style={{ display: this.state.display }}
                                                    className="fas fa-eye passwordIcon"
                                                    onClick={this.togglePasswordVisiblity}>
                                                </i>
                                                : ''
                                        }
                                    </MDBInput>

                                    <div className='col-sm-12 text-center'>
                                        <p>{this.state.errorMessage}</p>
                                        <label style={{ cursor: 'pointer' }} >
                                            <input name="rememberMe" checked={this.state.rememberMe}
                                                onChange={e => this.setState(prevStae => { return { rememberMe: !prevStae.rememberMe } })}
                                                type="checkbox" /> זכור אותי
                                        </label>
                                    </div>

                                    <div className="text-center mt-4">
                                        <Button variant="primary"
                                            onClick={e =>
                                                this.state.password != '' && this.state.user != '' ?
                                                    this.handleFormSubmit(e)
                                                    : ''
                                            }
                                            className='submitBtn ml-1' type="submit" value="Send">
                                            התחבר <MDBIcon far icon="paper-plane" className="ml-1" />
                                        </Button>
                                    </div>

                                </form>
                            </MDBCard>

                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.usersReducer.users
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUseresList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
