import React, { useState, useEffect } from "react";
import { MDBContainer, MDBCol, MDBBtn, MDBCard, MDBCardBody, MDBCardImage } from 'mdbreact';
import { useParams, useHistory } from "react-router-dom";
import '../../../dist/css/editProducts.css'
import MyModal from '../../../components/MyModal'
import MyModal2 from '../../../components/MyModal'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetProductByCodeFromLink, updateProductByCode, deleteProductByCode } from "../../../redux/products/products-actions";

const UpdateProductByID = (props) => {

    const [productReservationImage, setProductReservationImage] = useState('')
    const [modalShow, setModalSHow] = useState(false)
    const [modalShowDelete, setModalShowDelete] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [imageHelperText, setImageHelperText] = useState('')
    let { productCode } = useParams();
    const history = useHistory()

    useEffect(() => {

        props.GetProductByCodeFromLink(productCode)

    }, [productCode]);

    const deleteProduct = () => {
        props.deleteProductByCode(productCode).then(() => {
            history.push("/admin/products")
        })
    }

    const loadFileForRes = (event) => {
        setImageHelperText('**לתשומתך ליבך - את התמונה צריך לשמור בנוסף בתיקיית public/img/Res')
        setProductReservationImage(`../img/Res/${event.target.files[0].name}`)
        let image = document.getElementById('ReservationImage');
        image.src = URL.createObjectURL(event.target.files[0]);
    }

    const postUpdatedProduct = () => {

        //if we didn't uplaod a new reservation img - create a src for the former img
        let pathOfReservationImg = ''
        if (productReservationImage == '') {
            let url2 = document.getElementById('ReservationImage').src;
            pathOfReservationImg = `..${new URL(url2).pathname}`
        }
        else {
            pathOfReservationImg = productReservationImage
        }


        const objectPost =
        {
            "Name": document.getElementById('Name').value,
            "ShortName": document.getElementById('ShortName').value,
            "Code": productCode,
            "TypeCode": parseInt(document.getElementById("typeCode").value),
            "Description": document.getElementById('Description').value,
            "DescriptionForRes": document.getElementById('DescriptionForRes').value,
            "Price": document.getElementById('Price').value,
            "Weight": document.getElementById('Weight').value,
            // "Image": pathOfReservationImg,
            "ReservationImage": pathOfReservationImg,
            "InStock": document.getElementById('isActive').value === 'false' || document.getElementById('isActive').value === false ? false : true
        }

        // console.log('objectPost', objectPost)

        props.updateProductByCode(productCode, objectPost).then(() => {
            history.push("/admin/products")
        })


    }
    const loadProducts = () => {
        if (props.product.length > 0) {

            return (
                <div className='container-fluid firstDivAfterNav'>
                    <MDBContainer id='formContainer'>
                        <MDBCol md="12" style={{ margin: 'auto' }}>
                            <MDBCard>
                                <MDBCardBody>

                                    <br />
                                    <div className=' col-sm-8' style={{ margin: 'auto' }}>

                                        {
                                            props.product.map(prod => {
                                                // console.log(prod)
                                                let status = prod.InStock
                                                if (prod.InStock == true)
                                                    status = true
                                                else
                                                    status = false

                                                return (
                                                    <div key={prod._id}>
                                                        <div className='text-center'>
                                                            <h2 className='text-center font-weight-bold'>עריכת {prod.ShortName}</h2>
                                                            {/* <h6 >* סטאטוס</h6> */}&nbsp;

                                                            <select className="form-select" id="isActive" name="paymentMethod">
                                                                {
                                                                    status ?
                                                                        <>
                                                                            <option value={false}> לא במלאי</option>
                                                                            <option selected={true} value={true}>במלאי</option>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <option selected={true} value={false}>לא במלאי</option>
                                                                            <option value={true}> במלאי</option>
                                                                        </>
                                                                }
                                                            </select>
                                                        </div>
                                                        <br />
                                                        <form className='form-inline' key={prod._id}>


                                                            <span className="col-sm-5" >
                                                                <i data-test="fa" className="fas fa-key fa-lg"> </i>
                                                                <label htmlFor="code" className="black-text">
                                                                    &nbsp;קוד מוצר
                                                                </label>
                                                            </span>
                                                            <input name="typeCode"
                                                                disabled
                                                                min="1"
                                                                required
                                                                type="number"
                                                                value={prod.Code}
                                                                id="code" className="col-sm-7 form-control"
                                                            />
                                                            <br />

                                                            <span className="col-sm-5" >
                                                                <i data-test="fa" className="fas fa-sort-numeric-up-alt fa-lg"> </i>
                                                                <label htmlFor="typeCode" className="black-text">
                                                                    &nbsp;קדימות
                                                                </label>
                                                            </span>
                                                            <input name="typeCode"
                                                                min="1"
                                                                required
                                                                type="number"
                                                                defaultValue={prod.TypeCode}
                                                                id="typeCode" className="col-sm-7 form-control"
                                                            />
                                                            <br />

                                                            <br />
                                                            <span className="col-sm-5" >
                                                                <i data-test="fa" className="fa fa-user fa-lg"> </i>
                                                                <label htmlFor="Name" className="black-text">
                                                                    &nbsp;שם
                                                                </label>
                                                            </span>
                                                            <input name="Name"
                                                                required
                                                                type="text"
                                                                defaultValue={prod.Name}
                                                                id="Name" className="col-sm-7 form-control"
                                                            />
                                                            <br />

                                                            <span className="col-sm-5" >
                                                                <i data-test="fa" className="fa fa-user fa-lg"> </i>
                                                                <label htmlFor="Name" className="black-text">
                                                                    &nbsp;שם מקוצר להזמנות
                                                                </label>
                                                            </span>
                                                            <input name="ShortName"
                                                                required
                                                                type="text"
                                                                defaultValue={prod.ShortName}
                                                                id="ShortName" className="col-sm-7 form-control"
                                                            />
                                                            <br />

                                                            <span className="col-sm-5">
                                                                <i data-test="fa" className="fas fa-info fa-lg"></i>
                                                                <label htmlFor="ProductDescription" className="black-text">
                                                                    &nbsp; תיאור המוצר
                                                                </label>
                                                            </span>
                                                            <textarea name="ProductDescription"
                                                                rows="5"
                                                                required
                                                                type="text"
                                                                defaultValue={prod.Description}
                                                                id="Description" className="col-sm-7 form-control"
                                                            />
                                                            <br />

                                                            <span className="col-sm-5">
                                                                <i data-test="fa" className="fas fa-info fa-lg"></i>
                                                                <label htmlFor="DescriptionForRes" className="black-text">
                                                                    &nbsp; תיאור מקוצר להזמנות
                                                                </label>
                                                            </span>
                                                            <input name="DescriptionForRes"

                                                                required
                                                                type="text"
                                                                defaultValue={prod.DescriptionForRes}
                                                                id="DescriptionForRes" className="col-sm-7 form-control"
                                                            />
                                                            <br />

                                                            <span className="col-sm-5">
                                                                <i data-test="fa" className="fas fa-shekel-sign fa-lg"></i>
                                                                <label htmlFor="Price" className="black-text">
                                                                    &nbsp; מחיר בש"ח
                                                                </label>
                                                            </span>
                                                            <input name="DescriptionForRes"

                                                                required
                                                                type="text"
                                                                defaultValue={prod.Price}
                                                                id="Price" className="col-sm-7 form-control"
                                                            />
                                                            <br />

                                                            <span className="col-sm-5">
                                                                <i data-test="fa" className="fas fa-weight fa-lg"></i>
                                                                <label htmlFor="Weight" className="black-text">
                                                                    &nbsp; משקל
                                                                </label>
                                                            </span>
                                                            <input name="Weight"

                                                                required
                                                                type="text"
                                                                defaultValue={prod.Weight}
                                                                id="Weight" className="col-sm-7 form-control"
                                                            />
                                                            <br />

                                                            <MDBCol md='6' style={{ margin: 'auto', textAlign: 'center' }}>
                                                                <MDBCard>
                                                                    <MDBCardBody>
                                                                        <i className="fas fa-image fa-lg"></i>
                                                                        <label htmlFor="Image" className="black-text font-weight-bold">
                                                                            &nbsp; תמונה
                                                                        </label>
                                                                        <p className="font-weight-normal">גודל מומלץ: 240/160px</p>
                                                                        <p>{imageHelperText}</p>
                                                                        <br />
                                                                        <input type="file" id="newImgForRes" accept="image/*" name="image" id="file"
                                                                            onChange={e => loadFileForRes(e)} />


                                                                        <MDBCardImage
                                                                            style={{ margin: 'auto' }}
                                                                            id='ReservationImage'
                                                                            hover
                                                                            overlay='white-light'
                                                                            className='card-img-top col-sm-8'
                                                                            src={`../../../${prod.ReservationImage}`}
                                                                            alt='product'
                                                                        />
                                                                    </MDBCardBody>
                                                                </MDBCard>
                                                            </MDBCol>

                                                            <p className='col-sm-12'>{errorMessage}</p>

                                                            <div className=' col-sm-12 text-center'>

                                                                <MDBBtn
                                                                    rounded outline color="secondary"
                                                                    variant="primary" type="" value="Send" validate
                                                                    onClick={() => document.getElementById('Name').value != ''
                                                                        && document.getElementById('ShortName').value != ''
                                                                        && document.getElementById('Description').value != ''
                                                                        && document.getElementById('DescriptionForRes').value != ''
                                                                        && document.getElementById('Price').value != ''
                                                                        && document.getElementById('Weight').value != ''
                                                                        // && document.getElementById('CarouselImage').src != ''
                                                                        && document.getElementById('ReservationImage').src != '' ?
                                                                        setModalSHow(true)
                                                                        : setErrorMessage('מלא/י את כל שדות החובה')
                                                                    }
                                                                > עדכון</MDBBtn>

                                                                <MDBBtn rounded outline color="secondary"
                                                                    variant="primary" type="" value="Send" validate
                                                                    onClick={() => setModalShowDelete(true)}

                                                                > מחיקת מוצר</MDBBtn>
                                                            </div>
                                                            <MyModal />

                                                            <MyModal
                                                                headlineText='האם ברצונך לעדכן את המוצר'
                                                                show={modalShow}
                                                                onHide={() => setModalSHow(false)}
                                                                ButtonDeleteText='אישור'
                                                                ButtonText='סגירה'
                                                                ButtonDinamicFunction={() => postUpdatedProduct()}
                                                            />

                                                            <MyModal2 />
                                                            <MyModal2
                                                                headlineText='האם ברצונך למחוק את המוצר?'
                                                                headlineBody='לתשומך ליבך - לא ניתן יהיה לשחזר לאחר המחיקה'
                                                                show={modalShowDelete}
                                                                onHide={() => setModalShowDelete(false)}
                                                                ButtonText='ביטול'
                                                                ButtonDeleteText='מחיקה'
                                                                ButtonDinamicFunction={() => deleteProduct()}
                                                            />
                                                        </form>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBContainer>
                </div >
            )

        }
        else
            return ''

    }
    return (
        <>
            {loadProducts()}

        </>
    );
};
const mapStateToProps = (state) => {
    return {
        product: state.productReducer.product
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            GetProductByCodeFromLink,
            updateProductByCode,
            deleteProductByCode
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProductByID);