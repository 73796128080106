import React from 'react'
import { PureComponent } from 'react'
import '../../dist/css/reservation.css'
import { Button } from 'react-bootstrap/Button';
import ImageModal from '../../components/ImageModal/ImageModal'
import { MDBCol, MDBCollapse, MDBRow } from "mdbreact"
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProducts, getProductByCode } from '../../redux/products/products-actions';
import { add_to_cart, get_cart, update_cart_by_id, delete_cart_by_id, delete_cart, get_cart_by_id } from '../../redux/cart/cart-actions'
import { times } from 'lodash'
import { Link } from 'react-router-dom';

class CartStep1 extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            quantity: [],
            peoductChosen: [],
            totalPrice: '0',
            products: [],
            InActiveProducts: [],
            productsFromDelivery: [],
            collapseID: ""

        }
    }


    handleClick = (event) => {

        //catch the amount
        const pricePlus = event.target.name


        // document.getElementById('trashBtn' + event.target.id).style.display = 'inline-block'
        document.getElementById('trashBtn' + event.target.id).classList.add('activeTrashBtn')

        //shadow the chosen row
        document.getElementById('productRow' + event.target.id).classList.add('activeProduct')

        //add 1 to the amount
        document.getElementById('number' + event.target.id).value++

        //catch the place of the qunatity of the product (1 / 2 / 3.....)
        let place = event.target.id

        // update array of qunatities
        let myDinamicArray = this.state.quantity
        //if peppers - change count to 2
        if (parseInt(place) === 10) {
            myDinamicArray[place - 1] += 2
            document.getElementById('number' + event.target.id).value++
        }
        else {
            myDinamicArray[place - 1] += 1
        }

        // console.log('myDinamicArray', myDinamicArray)
        //updating sate
        this.setState(prevState => (
            {
                totalPrice: parseInt(prevState.totalPrice) + parseInt(pricePlus),
                quantity: myDinamicArray
            }
        ));
        // console.log('place', place)

        //filter to the object of chosen product
        let myCartProduct = this.state.products.filter(p => p.Code == place)

        //find if this product in cart
        let checkIFInCart = this.props.cart.some(c => c.Code == place)

        //in cart - update
        if (checkIFInCart) {
            this.props.update_cart_by_id({
                ...myCartProduct[0],
                "Quantity": myDinamicArray[place - 1]
            }).then(() => {
                console.log('cart is updtated', this.props.cart)
            })
        }
        //not in cart - add
        else {
            this.props.add_to_cart(
                {
                    ...myCartProduct[0],
                    "Quantity": myDinamicArray[place - 1]
                }
            ).then(() => {
                console.log('cart is added', this.props.cart)
            })
        }

        // console.log('quantity', this.state.quantity)

    }

    handleClickMinus = (event) => {

        //if the amount is bigger than 0, go into the function
        if (document.getElementById('number' + event.target.id).value > 0) {

            //catch the price of product
            const priceMinus = event.target.name

            //if product amount is bigger than 0, remove it by one
            if (document.getElementById('number' + event.target.id).value > 0) {
                document.getElementById('number' + event.target.id).value--

                //catch the place of the qunatity of the product (1 / 2 / 3.....)
                let place = event.target.id
                console.log(place)

                // update array of qunatities
                let myDinamicArray = this.state.quantity


                //if peppers - change count to 2
                if (parseInt(place) === 10) {
                    myDinamicArray[place - 1] -= 2
                    document.getElementById('number' + event.target.id).value--
                }
                //if other veg - change count to 1
                else {
                    console.log('in elser')
                    myDinamicArray[place - 1] -= 1
                }
                console.log('myDinamicArray[place - 1] - 1', myDinamicArray[place - 1])

                //filter to the object of chosen product
                let myCartProduct = this.state.products.filter(p => p.Code == place)
                console.log('myCartProduct', myCartProduct)
                //find if this product in cart
                let checkIFInCart = this.props.cart.some(c => c.Code == place)
                console.log('checkIFInCart', checkIFInCart)

                let copiedArray;
                let idOfProduct
                if (checkIFInCart) {
                    idOfProduct = myCartProduct[0].Code;
                    console.log('in', idOfProduct)
                    this.props.get_cart_by_id(idOfProduct).then(() => {
                        copiedArray = [...this.props.cart]
                        // console.log('copiedArray', copiedArray[0].Quantity)
                        copiedArray.map(c => c.Code == idOfProduct ? c.Quantity = myDinamicArray[place - 1] : c)
                        // c.Quantity = myDinamicArray[place - 1]
                        console.log('copiedArray[0]', copiedArray[0])

                        this.props.update_cart_by_id({
                            ...copiedArray[0]
                        })
                    })
                }

                //updating state if total price is bigger than 0
                if (this.state.totalPrice > 0) {
                    this.setState(prevState => (
                        {
                            totalPrice: parseInt(prevState.totalPrice) - parseInt(priceMinus),
                            quantity: myDinamicArray

                        }
                    ));
                }
                // console.log('this.state.quantity', this.state.quantity)


            }
            //if product amount is smaller than 1, remove the row's shadow and the trash
            if (document.getElementById('number' + event.target.id).value < 1) {
                document.getElementById('productRow' + event.target.id).classList.remove('activeProduct')
                document.getElementById('trashBtn' + event.target.id).classList.remove('activeTrashBtn')

                this.props.delete_cart_by_id(event.target.id).then(() => {
                    console.log('cart is ', this.props.cart)
                })
            }
        }
    }

    handleCancel = (event) => {

        //catch the price of product
        const priceCancel = event.target.name

        //remove the trash and the row's shadow
        try {
            document.getElementById('trashBtn' + event.target.id).classList.remove('activeTrashBtn')
            document.getElementById('productRow' + event.target.id).classList.remove('activeProduct')
        } catch (error) {

        }

        //catch the place of the qunatity of the product (1 / 2 / 3.....)
        let place = event.target.id

        //catch the value from the amount of the product
        let myValue = null

        //if peppers - delete half of calculated amount
        if (parseInt(place) === 10) {
            myValue = document.getElementById('number' + event.target.id).value / 2
        }
        else {
            try {
                myValue = document.getElementById('number' + event.target.id).value

            } catch (error) {
                myValue = 0
            }
        }

        // update array of qunatities
        let myDinamicArray = this.state.quantity

        //if peppers - change count to 2
        if (parseInt(place) === 10) {
            myDinamicArray[place - 1] = 0
            document.getElementById('number' + event.target.id).value--
        }
        //if other veg - change count to 1
        else {
            myDinamicArray[place - 1] = 0
        }

        //update state:
        // 1. totalPrice to - (totalPrice - amount of the product()
        // 2. quantity in the place of current product to -(0)
        this.setState(prevState => (
            {
                totalPrice: parseInt(prevState.totalPrice) - (parseInt(priceCancel) * myValue),
                // quantity: parseInt(prevState.quantity[place]) - parseInt(1)
                quantity: myDinamicArray
            }
        ));
        // console.log('this.state.quantity', this.state.quantity)

        //update the amount of the quantity of the product to 0
        try {
            document.getElementById('number' + place).value = 0
        } catch (error) {

        }
        this.props.delete_cart_by_id(event.target.id).then(() => {
            console.log('cart is ', this.props.cart)
        })

    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    loadMyProducts = () => this.state.products.map((product, index) => {


        let myImage = product.ReservationImage.toString()

        let display = 'none'
        let quantity = 0
        let activeProduct = null
        let visibility = 'hidden'
        if (product.InStock) {
            // typeOfProduct = 'invalidProduct'
            // prodprice = 'לא זמין במלאי'

            this.props.cart.map(c => {
                // console.log('deliveryProduct', deliveryProduct)
                if (c.Code === product.Code) {
                    display = 'block'
                    quantity = c.Quantity
                    // this.state.quantity[product.Code - 1] = c.Quantity
                    activeProduct = 'activeProduct'
                    visibility = ''
                }
                else {
                    quantity = this.state.quantity[product.Code - 1]
                }

            })


            //set product prices by qunatitiy selected
            let prodprice = product.Price
            let prodSumPrice = (product.Price * (this.state.quantity[product.Code - 1]))

            if (product.Code == 10) //if it's peppers set the price different
                prodSumPrice > 0 ? prodprice = (prodSumPrice / 2) : prodprice = product.Price
            else
                prodSumPrice > 0 ? prodprice = prodSumPrice : prodprice = product.Price

            //set price size by qunatitiy selected
            let fontSize = 'larger'

            if (this.state.quantity[product.Code - 1] > 0) {
                fontSize = "x-large"
            }




            return (
                <div id={'productRow' + product.Code} key={product.Code} style={{ display: display }} className={activeProduct}>

                    {/* PRODUCT */}
                    <div className="text-center row myProduct">
                        <div className="col-lg-2 col-md-12 col-sm-12">
                            <a className="lightbox" href={`#myImage${product.Code}`}>
                                <img className="img-responsive" src={`../${myImage}`} alt="prewiew" width={120} height={80}></img>

                            </a>
                            {/* //modal img */}
                            <ImageModal
                                imageNumber={`myImage${product.Code}`}
                                imageSrc={`../${myImage}`}
                                closedHref={'#productRow' + (product.Code - 1)}
                            />


                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 text-md-center">
                            <h4 id='prodName' className="product-name"><b>{product.Name}</b></h4>
                            <h4>
                                <small onClick={this.toggleCollapse(product.Code)} style={{ cursor: 'pointer' }}>{product.DescriptionForRes}&nbsp;
                                    {
                                        product.Code === 11 ?
                                            <>
                                                <br />
                                                <small> (במידה ויהיה מחסור בפורטבלו יסופקו זוג מארזי שמפיניון)</small>&nbsp;
                                            </>
                                            : ''
                                    }
                                    <i className="fa fa-angle-down rotate-icon" ></i>
                                </small>
                                <>
                                    <MDBCollapse id={product.Code} isOpen={this.state.collapseID}>
                                        <small className="font-italic" style={{ fontSize: 'medium' }}>{product.Description}</small>
                                    </MDBCollapse>
                                </>
                            </h4>

                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 text-sm-center text-md-right row">
                            <div className="propertyOfProduct">
                                {/* <span id = 'multiplySign' className="text-muted col-3 col-sm-12 col-md-6 text-md-center"> x </span> */}
                                <h6 className="animated fadeIn">
                                    {
                                        prodprice !== product.Price ?
                                            <strong className="font-italic font-weight-bold animated fadeInRight" style={{ fontSize }}>
                                                {prodprice} ₪
                                            </strong>
                                            : <strong className="font-italic font-weight-bold animated fadeInLeft " style={{ fontSize }}>
                                                {prodprice} ₪
                                            </strong>
                                    }

                                </h6>
                            </div>

                            {product.InStock ?
                                <div className="col-lg-8 col-md-12 col-sm-12 text-md-center quantityContainer" style={{ margin: 'auto', zIndex: 0 }}>
                                    <div className="quantity col-lg-12 col-sm-12 text-md-center">

                                        <button className="rounded-button fa fa-plus"
                                            onClick={this.handleClick} name={product.Price} id={product.Code}
                                        >
                                        </button>

                                        <input className='propertyOfProduct' type="number" step={1} max={99} min={0} id={'number' + product.Code}
                                            style={{ fontSize: fontSize }}
                                            // value={quantity != null ? quantity : this.state.quantity[product.Code - 1]}
                                            value={this.state.quantity[product.Code - 1]}
                                            title="Qty" className="qty" name={product.Code} disabled />

                                        <button className="rounded-button fas fa-minus"
                                            onClick={this.handleClickMinus} name={product.Price} id={product.Code}
                                        >
                                        </button>

                                    </div>
                                </div>
                                : ''
                            }
                            <div className="propertyOfProduct col-lg-2 col-md-12 col-sm-12 text-center"
                                id={'trashBtn' + product.Code} name={product.Price}
                                //  style={{ display: 'none' }}
                                style={{ visibility }}
                            >
                                <button type="button" className="animated fadeInRight btn btn-outline-danger btn-xs fa fa-trash dinamicTrashBtn"
                                    name={product.Price} onClick={this.handleCancel} id={product.Code}>
                                    <i className="fa fa-trash fa-2x myFaFaTrash" aria-hidden="true" style={{ pointerEvents: 'none', margin: 'auto' }} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {/* END PRODUCT */}
                    {/* <button onClick={() => this.props.setActiveStep(1)}>הקודם</button> */}
                </div >

            )
        }
    });

    componentDidMount() {


        let myArrayForQunatities = []

        this.props.get_cart().then(() => {
            this.props.getProducts()
                .then(() => {
                    // this.props.getProductByCode(2);
                    this.setState({
                        products: this.props.products,
                        // InActiveProducts: this.props.InActiveProductsFromDelivery,
                        productsFromDelivery: this.props.productsFromDelivery
                    })

                    for (let index = 0; index < this.props.products.length; index++) {
                        myArrayForQunatities.push(0)
                        this.props.cart.map(c => {
                            this.props.products[index].Code == c.Code ?
                                myArrayForQunatities[c.Code - 1] = c.Quantity
                                : myArrayForQunatities.push(0)
                        })



                    }
                    // console.log('InActiveProductsFromDelivery:', this.props.InActiveProductsFromDelivery)
                    this.setState({
                        quantity: [...myArrayForQunatities],
                    })
                })
                .catch((e) => {
                    console.log(e);
                });
        })

    }

    render() {

        return (
            <div>
                {
                    this.props.cart.length > 0 &&
                    <>
                        {/* <MDBRow className='stepHeadline'>
                            <MDBCol lg='7' sm='12' className='text-center'>
                                <h3 className='text-center'>המוצרים שלך</h3>
                                <p>אלה המוצרים אותם בחרת.</p>
                                <p>לחצו על<b>"הבא"</b> להמשך.</p>
                            </MDBCol>
                            <MDBCol lg='5' sm='12' className='text-left'>
                                <button className="btn text-center almogBtn cartBtn"
                                    onClick={() => this.props.setActiveStep(1)}
                                    type='submit'
                                >הבא&nbsp;
                                    <i className="fas fa-arrow-alt-circle-left fa-2x"></i>
                                </button>
                            </MDBCol>
                        </MDBRow> */}

                        <MDBRow className='stepHeadline'>
                            <MDBCol lg='3' sm='12' className='text-right' style={{ margin: 'auto' }}>
                                <button className="btn text-center almogBtn cartBtnRight"
                                    type='button'
                                    disabled
                                > <i className="fas fa-arrow-alt-circle-right fa-2x"></i>
                                    הקודם&nbsp;
                                </button>
                            </MDBCol>
                            <MDBCol lg='6' sm='12' className='text-center'>
                                <h3 className='text-center'>המוצרים שלך</h3>
                                <p>אלה המוצרים אותם בחרת.</p>
                                <p>לחצו על<b>"הבא"</b> להמשך.</p>
                            </MDBCol>
                            <MDBCol lg='3' sm='12' className='text-left' style={{ margin: 'auto' }}>
                                <button className="btn text-center almogBtn cartBtnLeft"
                                    onClick={() => this.props.setActiveStep(1)}
                                    type='submit'
                                >הבא&nbsp;
                                    <i className="fas fa-arrow-alt-circle-left fa-2x"></i>
                                </button>
                            </MDBCol>
                        </MDBRow>

                        {this.loadMyProducts()}
                        <div className='col-sm-4 text-center' style={{ margin: 'auto' }}>
                            <p>להוספת מוצרים נוספים <Link to='/reservation'>לחץ/י כאן</Link>.</p>

                            {
                                <h3 className='text-dark'>סה"כ לתשלום: {this.props.toalOfAll} ₪ </h3>

                            }
                            <button className="btn almogBtn BottomBtnCart"
                                onClick={() => this.props.setActiveStep(1)}
                            >הבא&nbsp;
                                <i className="fas fa-arrow-alt-circle-left fa-2x"></i>
                            </button>
                        </div>

                    </>
                }

            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {

        products: state.productReducer.products,
        product: state.productReducer.product,
        cart: state.cartReducer.cart
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProducts,
            getProductByCode,
            add_to_cart,
            get_cart,
            get_cart_by_id,
            update_cart_by_id,
            delete_cart_by_id,
            delete_cart
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CartStep1);