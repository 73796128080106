import React, { PureComponent } from 'react'
import { MDBIcon, MDBBtn, MDBCollapse } from 'mdbreact';
import Button from 'react-bootstrap/Button';
import MyModal from '../../../../../components/MyModal'
import MyModal2 from '../../../../../components/MyModal'

import { withRouter } from 'react-router';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProducts, getProductByCode } from '../../../../../redux//products/products-actions'
import { updateGroupOrderByGroupDeliveryIdAndOrderNumber, deleteGroupOrderByOrderId } from "../../../../../redux/groupOrders/group-orders-action";

import { updateOnlineOrderByDeliveryIdAndOrderNumber, deleteOnlinerderByOrderId } from "../../../../../redux/onlineOrders/online-orders-action";

class UpdateOrderProduducts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: [],
            peoductChosen: [],
            totalPrice: '0',
            products: [],
            InActiveProducts: [],
            productsFromDelivery: [],
            newQuantity: [],
            errorMessage: '',
            modalShow: false,
            modalShowDelete: false,
            collapseID: ""
        }
    }


    handleClick = (event) => {

        //catch the amount
        const pricePlus = event.target.name

        // document.getElementById('trashBtn' + event.target.id).style.display = 'inline-block'
        document.getElementById('trashBtn' + event.target.id).classList.add('activeTrashBtn')

        //shadow the chosen row
        document.getElementById('productRow' + event.target.id).classList.add('activeProduct')

        //add 1 to the amount
        document.getElementById('number' + event.target.id).value++

        //catch the place of the qunatity of the product (1 / 2 / 3.....)
        let place = event.target.id

        // update array of qunatities
        let myDinamicArray = this.state.newQuantity

        //if peppers - change count to 2
        if (parseInt(place) === 10) {
            myDinamicArray[place - 1] += 2
            document.getElementById('number' + event.target.id).value++
            //set price of product calculated by quantity
            document.getElementById("prodprice" + event.target.id).innerHTML = ((pricePlus * myDinamicArray[place - 1]) / 2)
        }
        //if other veg - change count to 1
        else {
            myDinamicArray[place - 1] += 1
            //set price of product calculated by quantity
            document.getElementById("prodprice" + event.target.id).innerHTML = (pricePlus * myDinamicArray[place - 1])
            document.getElementById("prodprice" + event.target.id).classList.add('font-weight-bold')

        }

        //updating sate
        this.setState(prevState => (
            {
                totalPrice: parseInt(prevState.totalPrice) + parseInt(pricePlus),
                newQuantity: myDinamicArray
            }
        ));

        // console.log("amount", document.getElementById('number' + event.target.id).value)
        // console.log('newQuantity', this.state.newQuantity)

    }

    handleClickMinus = (event) => {

        //if the amount is bigger than 0, go into the function
        if (document.getElementById('number' + event.target.id).value > 0) {

            //catch the price of product
            const priceMinus = event.target.name

            //catch the product row
            // const divStyle = document.getElementById('productRow' + event.target.id).style

            //if product amount is bigger than 0, remove it by one
            if (document.getElementById('number' + event.target.id).value > 0) {
                document.getElementById('number' + event.target.id).value--

                //catch the place of the qunatity of the product (1 / 2 / 3.....)
                let place = event.target.id

                // update array of qunatities
                let myDinamicArray = this.state.newQuantity

                //if peppers - change count to 2
                if (parseInt(place) === 10) {
                    myDinamicArray[place - 1] -= 2
                    document.getElementById('number' + event.target.id).value--
                    //set price of product calculated by quantity
                    document.getElementById("prodprice" + event.target.id).innerHTML = ((priceMinus * myDinamicArray[place - 1]) / 2)

                }
                //if other veg - change count to 1
                else {
                    myDinamicArray[place - 1] -= 1
                    //set price of product calculated by quantity
                    document.getElementById("prodprice" + event.target.id).innerHTML = (priceMinus * myDinamicArray[place - 1])
                }
                //if qunatity is 0 set the price to initial
                if (document.getElementById("prodprice" + event.target.id).innerHTML == 0)
                    document.getElementById("prodprice" + event.target.id).innerHTML = (priceMinus * 1)

                //updating state if total price is bigger than 0
                if (this.state.totalPrice > 0) {
                    this.setState(prevState => (
                        {
                            totalPrice: parseInt(prevState.totalPrice) - parseInt(priceMinus),
                            newQuantity: myDinamicArray

                        }
                    ));
                }
                // console.log("amount", document.getElementById('number' + event.target.id).value)
                // console.log('this.state.newQuantity', this.state.newQuantity)

            }
            //if product amount is smaller than 1, remove the row's shadow and the trash
            if (document.getElementById('number' + event.target.id).value < 1) {
                document.getElementById('productRow' + event.target.id).classList.remove('activeProduct')
                document.getElementById("prodprice" + event.target.id).classList.remove('font-weight-bold')
                // divStyle.boxShadow = 'none'
                // document.getElementById('trashBtn' + event.target.id).style.display = 'none'
                document.getElementById('trashBtn' + event.target.id).classList.remove('activeTrashBtn')
            }



        }
    }
    handleCancel = (event) => {

        //catch the price of product
        const priceCancel = event.target.name

        //remove the trash and the row's shadow
        try {
            document.getElementById('trashBtn' + event.target.id).classList.remove('activeTrashBtn')
            document.getElementById('productRow' + event.target.id).classList.remove('activeProduct')
            document.getElementById("prodprice" + event.target.id).classList.remove('font-weight-bold')
        } catch (error) {

        }

        //catch the place of the qunatity of the product (1 / 2 / 3.....)
        let place = event.target.id

        //catch the value from the amount of the product
        let myValue = null

        //if peppers - delete half of calculated amount
        if (parseInt(place) === 10) {
            myValue = document.getElementById('number' + event.target.id).value / 2
        }
        else {
            try {
                myValue = document.getElementById('number' + event.target.id).value

            } catch (error) {
                myValue = 0
            }
        }

        // update array of qunatities
        let myDinamicArray = this.state.newQuantity
        myDinamicArray[place - 1] = 0

        //set price of product calculated by quantity
        document.getElementById("prodprice" + event.target.id).innerHTML = (priceCancel * 1)


        //update state:
        // 1. totalPrice to - (totalPrice - amount of the product()
        // 2. quantity in the place of current product to -(0)
        this.setState(prevState => (
            {
                totalPrice: parseInt(prevState.totalPrice) - (parseInt(priceCancel) * myValue),
                // quantity: parseInt(prevState.quantity[place]) - parseInt(1)
                newQuantity: myDinamicArray
            }
        ));
        // console.log("amount", document.getElementById('number' + event.target.id).value)
        // console.log('this.state.newQuantity', this.state.newQuantity)

        //update the amount of the quantity of the product to 0
        try {
            document.getElementById('number' + place).value = 0
        } catch (error) {

        }
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    loadMyProducts = () => this.state.products.map((product, index) => {


        // console.log("product.ReservationImage: ", this.state.quantity)
        // console.log('product:', product)

        // let myImage = product.ReservationImage
        let myImage = product.ReservationImage.toString()

        // console.log('myImage', myImage)

        // let typeOfProduct = ''
        // let prodprice = product.Price + ' ₪'
        //set product prices by qunatitiy selected
        let prodprice = product.Price
        let fontSize = 'larger'

        let display = 'none'
        let disabled = ""
        let classOfUpdateProduct = null
        let isActiveProductHasAmount = ''
        let trashClass = ''
        if (product.InStock) {


            this.state.productsFromDelivery.map(deliveryProduct => {
                // console.log('deliveryProduct', deliveryProduct)
                // if (deliveryProduct.Code === product.Code) {
                //     if (deliveryProduct.IsActive)
                //         display = 'block'
                //     else {
                //         display = 'none'
                //         disabled = "disabled"
                //     }

                // }
                // else if (deliveryProduct.Code === product.Code && !deliveryProduct.IsActive) {
                //     display = 'none'
                //     disabled = "disabled"
                // }

                if (this.props.productsFromOrder != null) {

                    this.props.productsFromOrder.map(productFromOrder => {
                        // console.log('productFromOrder', productFromOrder)
                        // console.log('product.Code', product.Code)

                        if (deliveryProduct.Code === product.Code) {
                            if (deliveryProduct.IsActive)
                                display = 'block'
                            else {

                                if (productFromOrder.Code === product.Code) {
                                    display = 'block'
                                    this.props.isUserUpdate ? disabled = "disabled" : disabled = ''
                                    // disabled = "disabled"

                                    isActiveProductHasAmount = 'המוצר אזל מהמלאי אך הזמנתך נקלטה ותסופק'
                                }
                                else {
                                    // display = 'none'
                                    // disabled = ""

                                }

                            }

                        }


                        if (product.Code === productFromOrder.Code) {
                            trashClass = 'activeTrashBtn'
                            let prodSumPrice = (product.Price * (this.state.quantity[product.Code - 1]))

                            if (product.Code == 10) // if the product is peppers
                                prodSumPrice > 0 ? prodprice = (prodSumPrice / 2) : prodprice = product.Price
                            else
                                prodSumPrice > 0 ? prodprice = prodSumPrice : prodprice = product.Price

                            classOfUpdateProduct = 'activeProduct'
                            for (let index = 0; index < this.state.quantity.length; index++) {
                                // console.log('index', index)
                                // console.log('productFromOrder.Code', productFromOrder.Code)

                                if (productFromOrder.Code === index) {
                                    this.state.quantity[index - 1] = productFromOrder.Amount
                                }
                            }
                            // console.log('this.state.quantity:', this.state.quantity)
                        }

                    })

                }


            })



            // console.log(this.props)
            return (




                < div className={classOfUpdateProduct} id={'productRow' + product.Code} key={product.Code} style={{ display: display }
                }>

                    {/* PRODUCT */}
                    < div className="text-center row myProduct" >
                        <div className="col-lg-2 col-md-12 col-sm-12">


                            <img className="img-responsive" src={`../../../../../../${myImage}`} alt="prewiew" width={120} height={80}></img>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 text-md-center">
                            <h4 id='prodName' className="product-name"><b>{product.Name}</b></h4>
                            <h4>
                                <small onClick={this.toggleCollapse(product.Code)} style={{ cursor: 'pointer' }}>{product.DescriptionForRes}&nbsp;
                                    <i className="fa fa-angle-down rotate-icon" ></i>
                                </small>
                                <>
                                    <MDBCollapse id={product.Code} isOpen={this.state.collapseID}>
                                        <small className="font-italic" style={{ fontSize: 'medium' }}>{product.Description}</small>
                                    </MDBCollapse>
                                </>
                            </h4>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 text-sm-center text-md-right row">
                            <div className="propertyOfProduct">
                                {/* <span id = 'multiplySign' className="text-muted col-3 col-sm-12 col-md-6 text-md-center"> x </span> */}
                                <h6 style={{ display: 'inline-block', fontSize: fontSize }} className="font-italic"><strong id={`prodprice${product.Code}`}> {prodprice} </strong> ₪</h6>
                            </div>

                            {product.InStock ?

                                <div className="col-lg-8 col-md-12 col-sm-12 text-md-center quantityContainer" style={{ margin: 'auto', zIndex: 0 }}>
                                    <div className="quantity col-lg-12 col-sm-12 text-md-center">


                                        {/* <input type="button" defaultValue="+" className="plus"
                                            onClick={this.handleClick} name={product.Price} id={product.Code} /> */}

                                        <button className="rounded-button fa fa-plus"
                                            disabled={disabled}
                                            onClick={this.handleClick} name={product.Price} id={product.Code}
                                        >
                                        </button>

                                        <input className='propertyOfProduct col-lg-2 col-md-12 col-sm-12 text-center' type="number" step={1} max={99} min={0} id={'number' + product.Code}
                                            defaultValue={this.state.quantity[product.Code - 1]} title="Qty" className="qty" name={product.Code} disabled />

                                        {/* <input type="button" defaultValue="-" className="minus"
                                            onClick={this.handleClickMinus} name={product.Price} id={product.Code} /> */}

                                        <button className="rounded-button fas fa-minus" disabled={disabled}
                                            onClick={this.handleClickMinus} name={product.Price} id={product.Code}
                                        >
                                        </button>
                                        <br />

                                    </div>

                                </div>

                                : ''
                            }
                            <div className={`propertyOfProduct col-2 col-sm-12 col-md-2 text-center  ${trashClass}`}
                                id={'trashBtn' + product.Code} name={product.Price}
                                style={{ visibility: 'hidden' }}
                            // style={{ display: 'none', padding: '0' }}
                            >
                                <button type="button"
                                    className="animated fadeInRight btn btn-outline-danger btn-xs fa fa-trash dinamicTrashBtn"
                                    disabled={disabled}
                                    name={product.Price} onClick={this.handleCancel} id={product.Code}>
                                    <i className="fa fa-trash myFaFaTrash" aria-hidden="true" style={{ pointerEvents: 'none' }} />
                                </button>
                            </div>
                            <span className="col-lg-12 col-sm-12 text-md-center" style={{ fontSize: 'small' }}>{isActiveProductHasAmount}</span>

                        </div>
                    </div >
                    <hr />
                    {/* END PRODUCT */}
                </div >
            )
        }
    });

    loadCurrentQunatities = () => this.state.products.map((product, index) => {

        if (product.InStock) {


            if (this.props.productsFromOrder != null) {

                this.props.productsFromOrder.map(productFromOrder => {
                    // console.log('productFromOrder', productFromOrder.Code)
                    // console.log('product.Code', product.Code)



                    if (product.Code === productFromOrder.Code) {

                        for (let index = 0; index < this.state.newQuantity.length; index++) {
                            // console.log('index', index)
                            // console.log('productFromOrder.Code', productFromOrder)

                            if (productFromOrder.Code === index) {
                                this.state.newQuantity[index - 1] = productFromOrder.Amount
                            }
                        }
                        // console.log('this.state.quantity:', this.state.quantity)
                    }

                })

            }
        }
    })

    componentDidMount() {

        // console.log('this.props.productsFromOrder', this.props.totalPrice)

        document.getElementById('WelcomeImg').style.display = 'none';

        this.setState({ totalPrice: this.props.totalPrice })

        let myArrayForQunatities = []

        this.props.getProducts()
            .then(() => {
                // this.props.getProductByCode(2);
                this.setState({
                    products: this.props.products,
                    // InActiveProducts: this.props.InActiveProductsFromDelivery,
                    productsFromDelivery: this.props.productsFromDelivery
                })

                for (let index = 0; index < this.props.products.length + 1; index++) {
                    myArrayForQunatities.push(0)
                }
                // console.log('InActiveProductsFromDelivery:', this.props.InActiveProductsFromDelivery)
                this.setState({
                    quantity: [...myArrayForQunatities],
                    newQuantity: [...myArrayForQunatities]
                })
                this.loadCurrentQunatities()
            })
            .catch((e) => {
                console.log(e);
            });
    }



    postReservation = () => {



        const { groupName, isUserUpdate, deliveryDateTime, payBoxLink, city, address, firstName, lastName, email, phone, additionalPhone,
            comment, paymentChecked, hour, isPaid, place, groupCode, deliveryId, orderNumber, internalComments, deliveryPlaces } = this.props

        // console.log('GroupCode', groupCode)
        // console.log('deliveryId', deliveryId)

        //set array of qunatities of products

        let myArray = []
        let myObj = {}
        this.state.newQuantity.map((qnt, index) => {
            this.state.products.map(products => {
                if (qnt > 0 && products.Code === index + 1) {
                    myArray.push(
                        myObj = {
                            "Code": index + 1,
                            "Name": products.Name,
                            "Price": products.Price,
                            "Weight": products.Weight,
                            "Amount": qnt,
                            "Description": products.DescriptionForRes,
                        }
                    )

                }
            })
        })



        // console.log('myArray', myArray)
        // const objectPost =

        // {
        //     "GroupName": groupName,
        //     "GroupCode": groupCode,
        //     "GroupDeliveryId": deliveryId,
        //     "payBoxLink": payBoxLink,
        //     "DeliveryPlaces": deliveryPlaces,
        //     "DateOfDelivery": new Date(deliveryDateTime),
        //     "Place": place,
        //     "PersonInfo": {
        //         "FirstName": firstName,
        //         "LastName": lastName,
        //         "Email": email,
        //         "Phone": phone,
        //         "UserId": null
        //     },
        //     "Products": myArray,
        //     "TotalPrice": this.state.totalPrice,
        //     "Comment": comment,
        //     "InternalComment": internalComments,
        //     "PaymentMethod": paymentChecked,
        //     "IsPaid": isPaid === 'false' || isPaid === false ? false : true,
        //     "IsUser": isUserUpdate
        // }

        let objectPost = {
            "OnlineDeliveryId": deliveryId,
            "DeliveryDate": "2021-08-24T00:00:00.000Z",
            "DeliveryTime": hour,
            "TotalPrice": this.state.totalPrice,
            "Comment": comment,
            "InternalComment": internalComments,
            "PaymentMethod": paymentChecked,
            // "Approved": approved,
            "IsPaid": isPaid === 'false' || isPaid === false ? false : true,
            "PersonInfo": {
                "FirstName": firstName,
                "LastName": lastName,
                "Phone": phone,
                "Email": email,
                "Phone": phone,
                "AdditionalPhone": additionalPhone,
                "City": city,
                "Address": address
            },
            "Products": myArray

        }
        console.log('objectPost', objectPost)

        this.props.updateOnlineOrderByDeliveryIdAndOrderNumber(deliveryId, orderNumber, objectPost)
            .then(data => {
                if (isUserUpdate) { //user updating his order
                    // console.log('data', data)
                    // alert('in')
                    // window.location.replace(`http://localhost:3000/groups/${groupCode}/${deliveryId}/${orderNumber}/thanks`)
                    this.props.history.push(`/online/orders/${groupCode}/${deliveryId}/${orderNumber}/thanks`)

                }
                else { //admin updating user's order
                    // alert('out')
                    // window.location.replace(`http://localhost:3000/admin/deliveries/groups-orders/${groupCode}/${deliveryId}`)
                    this.props.history.push(`/admin/online/orders/${groupCode}/${deliveryId}`)
                }

            })

    }

    errorOfSubmit = () => {
        // setErrorMessage('אנא מלא את כל שדות החובה')

        const { firstName, lastName, email, phone, paymentChecked, place } = this.props

        // console.log(' this.props', this.props)

        let nameOfElement = ''
        let nameOfSpan = ''
        if (firstName === '')
            nameOfElement = 'firstName'
        else if (lastName === '')
            nameOfElement = 'lastName'
        else if (email === '')
            nameOfElement = 'email'
        else if (phone === '')
            nameOfElement = 'phone'
        else if (paymentChecked === '')
            nameOfElement = 'payment'
        else if (place === '')
            nameOfElement = 'place'

        // console.log('nameOfElement', nameOfElement)
        try {
            let myElement = document.getElementById(nameOfElement)
            myElement.scrollIntoView();
            myElement.focus();
        } catch (error) {
            this.setState({
                errorMessage: `אנא בחר/י מוצרים בשווי מינימלי בסך ${this.props.minimumTotalPrice} ₪`
            })
        }

        // document.getElementById('formHeadline').innerText = 'אנא מלא/י את כל שדות החובה'
        document.getElementById('formSecondHeadline').innerText = 'אנא מלא/י את כל שדות החובה'


    }

    updateOrder = () => {
        this.postReservation()
    }

    deleteOrder = () => {

        const { OrderId, groupCode, deliveryId } = this.props

        // console.log('OrderId', OrderId)

        this.props.deleteOnlinerderByOrderId(OrderId).then(() => {
            this.props.history.push(`/admin/online/orders/${groupCode}/${deliveryId}`)
        })

    }

    render() {
        const passState = this.state
        const passProps = this.props
        return (
            <div>
                {this.loadMyProducts()}
                {/* <FormSummary /> */}

                {
                    this.props.isUserUpdate ?
                        <h5 className='col-sm-12'>  סה"כ סכום ההזמנה: <b>{this.state.totalPrice} ₪</b></h5>
                        :
                        <div className="text-center">
                            <h5 className='col-sm-12'>  סה"כ סכום ההזמנה: </h5>
                            <input type="text" className="form-control col-sm-4" id="totalPriceInput" value={this.state.totalPrice}
                                style={{ textAlign: 'center' }}
                                onChange={e => this.setState({ totalPrice: e.target.value })}>
                            </input>
                            <b style={{ display: 'inline-block' }}> ₪</b>
                        </div>
                }
                <p>{this.state.errorMessage}</p>

                {this.props.isUserUpdate &&

                    this.props.footer !== undefined && this.props.footer !== "undefined" &&
                    this.props.footer !== null && this.props.footer !== "null" && this.props.footer.length > 0 ?
                    <div className="col-12 text-center" id="headerOfForm">
                        < hr />
                        {this.props.footer}
                        < hr />
                    </div>
                    : ''

                }

                {
                    !this.props.isUserUpdate ?
                        <MDBBtn rounded outline color="secondary"
                            variant="primary" type="" value="Send" validate
                            onClick={() =>
                                this.props.isUserUpdate ?
                                    this.props.name !== '' && this.props.email !== ''
                                        && this.props.phone !== '' && this.props.paymentChecked !== '' && this.props.place !== ''
                                        && this.state.totalPrice > 0 ?
                                        // onClick={() => props.productsDetails.totalPrice > 0 ?
                                        // setModalShow(true)
                                        this.setState({ modalShow: true })
                                        : this.errorOfSubmit()

                                    : this.setState({ modalShow: true })
                            }
                        >עדכון</MDBBtn>
                        :
                        <Button variant="primary" type="submit" value="Send"
                            onClick={() => this.props.name !== '' && this.props.email !== ''
                                && this.props.phone !== '' && this.props.paymentChecked !== '' && this.props.place !== ''
                                && this.state.totalPrice >= this.props.minimumTotalPrice ?
                                // onClick={() => props.productsDetails.totalPrice > 0 ?
                                // setModalShow(true)
                                this.setState({ modalShow: true })
                                : this.errorOfSubmit()
                            }
                            className='submitBtn'>
                            עדכון <MDBIcon far icon="paper-plane" className="ml-1" />
                        </Button>
                }



                {
                    !this.props.isUserUpdate ?
                        <MDBBtn rounded outline color="secondary"
                            variant="primary" type="" value="Send" validate
                            onClick={() => this.setState({ modalShowDelete: true })}

                        >מחיקה</MDBBtn>
                        : ''
                }

                <MyModal />
                <MyModal
                    headlineText='האם ברצונך לעדכן את ההזמנה?'
                    show={this.state.modalShow}
                    onHide={() => this.setState({ modalShow: false })}
                    ButtonText='ביטול'
                    ButtonDeleteText='עדכון'
                    ButtonDinamicFunction={() => {
                        this.updateOrder()
                    }
                    }
                />

                <MyModal2 />
                <MyModal2
                    headlineText={`האם ברצונך למחוק את ההזמנה של ${this.props.firstName} ${this.props.lastName}?`}
                    headlineBody='לתשומך ליבך - לא ניתן יהיה לשחזר לאחר המחיקה'
                    show={this.state.modalShowDelete}
                    onHide={() => this.setState({ modalShowDelete: false })}
                    ButtonText='ביטול'
                    ButtonDeleteText='מחיקה'
                    ButtonDinamicFunction={() => this.deleteOrder()}
                />



            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        products: state.productReducer.products,
        product: state.productReducer.product,
        orders: state.groupOrdersReducer.orders,
        onlineOrders: state.onlineOrdersReducer.onlineOrders,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateOnlineOrderByDeliveryIdAndOrderNumber,
            deleteOnlinerderByOrderId,
            getProducts,
            getProductByCode,
            // updateGroupOrderByGroupDeliveryIdAndOrderNumber,
            deleteGroupOrderByOrderId
        },
        dispatch
    );
};
// export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrderProduducts);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateOrderProduducts))