
import React from "react";
import SerachInout from '../../../components/SerachInout'
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroups } from "../../../redux/groups/groups-actions";
import UpdateBtn from '../../../components/UpdateBtn'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { Link } from "react-router-dom";
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer } from 'mdbreact';

class GroupsList extends React.Component {

    loadMyGroups = () => this.props.groups.map((group, index) => {
        let typeOfProduct = ''

        if (!group.IsActive)
            typeOfProduct = 'isNotActive'

        return (
            <tr key={index} className={typeOfProduct}
                onClick={(e) => this.setClickableRow(e, `/admin/groups/groups-orders/${group.Code}`)}

            >
                <td>
                    {/* <Tooltip text="צפייה בטפסים">
                        <Link to={`/admin/groups/groups-orders/${group.Code}`}>
                            <button className='rounded-button'>
                                <i className="fas fa-search-plus"></i>
                            </button>
                        </Link>
                    </Tooltip>&nbsp; */}
                    <Link to={`/admin/groups/edit/${group.Code}`}>
                        <button className='rounded-button'>
                            <i className="fas fa-edit"></i>
                        </button>
                    </Link>
                    <Link to={`/admin/groups/groups-orders/statistics/${group.Code}`} className="mx-2">
                        <button className='rounded-button'>
                            <i className="fas fa-solid fa-chart-line"></i>
                        </button>
                    </Link>
                </td>
                <td>{group.Code}</td>
                {/* <td>{group.Name}</td> */}
                <td>{group.InternalName}</td>
                <td>
                    {
                        group.WhatsappGroupLink != "" ?
                            <i className="fab fa-whatsapp fa-2x"
                                title={group.WhatsappGroupLink}
                                onClick={() => window.open(group.WhatsappGroupLink)}
                            >
                            </i>
                            : ''
                    }</td>
                <td>{group.IsActive ? 'פעילה' : 'לא פעילה'}</td>
            </tr>
        )
    })

    setClickableRow = (e, link) => {
        // console.log('e.target', e.target.tagName)
        if (e.target.tagName !== 'I' && e.target.target !== 'BUTTON')
            this.rowClicked(link)
    }
    rowClicked = (link) => {
        this.props.history.push(link)
    }

    render() {
        return (
            <div className='container-fluid firstDivAfterNav'>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="12">
                            <MDBCard>
                                <MDBCardBody>
                                    <h2 className='text-center font-weight-bold'>רשימת קבוצות</h2>

                                    <div className='col-sm-6' style={{ margin: 'auto' }}>
                                        <UpdateBtn
                                            className='col-sm-4'
                                            headlineText='קבוצה חדשה'
                                            linkPath='/admin/groups/create-group'
                                        />
                                    </div>
                                    <SerachInout />

                                    <div className='table-responsive'>
                                        <table id="dataTable" className="table table-striped col-sm-12 text-center" style={{ margin: 'auto' }}>
                                            <thead className="thead-dark">
                                                <tr className="header">
                                                    {/* <th className='cellHiddenInMobile'></th> */}
                                                    <th></th>
                                                    <th>קוד</th>
                                                    {/* <th>שם ציבורי</th> */}
                                                    <th>שם הקבוצה</th>
                                                    <th>לינק לווטסאפ</th>
                                                    <th>סטאטוס</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.loadMyGroups()}
                                            </tbody>
                                        </table>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div >
        );
    }

    componentDidMount() {

        document.title = "משק אלמוג - קבוצות"
        this.props.getGroups()

    }

}

const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroups,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsList);
