import { MDBCard, MDBCol } from 'mdbreact';
import React, { Component } from 'react';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { Jumbotron, Col, Input, InputGroup, InputGroupAddon, FormGroup, Label, Button, Fade, FormFeedback, Container, Card } from 'reactstrap';
import { MDBContainer } from 'mdbreact'

class ImportUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            person: [],
            isOpen: false,
            dataLoaded: false,
            isFormInvalid: false,
            rows: null,
            cols: null
        }
        this.fileHandler = this.fileHandler.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openFileBrowser = this.openFileBrowser.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.fileInput = React.createRef();
    }

    renderFile = (fileObj) => {
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                this.setState({
                    dataLoaded: true,
                    cols: resp.cols,
                    rows: resp.rows
                }, () => {
                    let names = []
                    let emails = []
                    let dinamicArray = []
                    for (let index = 1; index < resp.rows.length; index++) {
                        console.log('resp.rows[0]', resp.rows[index][0])
                        // names.push(resp.rows[index][0]).filter(item => item !== undefined) // התא בו נמצאים השמות
                        // emails.push(resp.rows[index][2]).filter(item => item !== undefined) //התא בו נמצאים המיילים

                        // this.state.person[index].name = resp.rows[index][0]
                        // this.state.person[index].email = resp.rows[index][2]
                        if (resp.rows[index][0] != undefined && resp.rows[index][2] != undefined) {
                            let dinamicObj = {}
                            dinamicObj.name = resp.rows[index][0]
                            dinamicObj.email = resp.rows[index][2]

                            dinamicArray.push(dinamicObj)

                        }

                    }
                    // console.log('dinamicArray', dinamicArray)

                    this.setState({
                        person: dinamicArray
                    }, () => {
                        console.log('person', this.state.person)

                    })

                    // this.props.setEmails(emails)
                    // while (i < resp.rows.length) {
                    //     this.props.setUsersObject({
                    //         person: {
                    //             names: names[i],
                    //             emails: emails[i]
                    //         }
                    //     })
                    //     i++;
                    // }
                });
            }
        });
    }

    fileHandler = (event) => {
        if (event.target.files.length) {
            let fileObj = event.target.files[0];
            let fileName = fileObj.name;


            //check for file extension and pass only if it is .xlsx and display error message otherwise
            if (fileName.slice(fileName.lastIndexOf('.') + 1) === "xlsx") {
                this.setState({
                    uploadedFileName: fileName,
                    isFormInvalid: false
                });
                this.renderFile(fileObj)
            }
            else {
                this.setState({
                    isFormInvalid: true,
                    uploadedFileName: ""
                })
            }
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    openFileBrowser = () => {
        this.fileInput.current.click();
    }
    render() {
        return (
            <div className='container-fluid'>
                <MDBContainer className="firstDivAfterNav text-center">
                    <Container>
                        <form>
                            <MDBCard className='col-lg-12'>

                                <h2 className="text-center font-weight-bold">ייבוא משתמשים</h2>
                                <FormGroup row>
                                    <Label for="exampleFile" xs={6} sm={4} lg={2} size="lg">העלאת קובץ</Label>
                                    <MDBCol sm='12'>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <Button color="info" style={{ color: "white", zIndex: 0 }} onClick={this.openFileBrowser.bind(this)}><i className="cui-file"></i> Browse&hellip;</Button>
                                                <input type="file" hidden onChange={this.fileHandler.bind(this)} ref={this.fileInput} onClick={(event) => { event.target.value = null }} style={{ "padding": "10px" }} />
                                            </InputGroupAddon>
                                            <Input type="text" className="form-control" value={this.state.uploadedFileName} readOnly invalid={this.state.isFormInvalid} />
                                            <FormFeedback>
                                                <Fade in={this.state.isFormInvalid} tag="h6" style={{ fontStyle: "italic" }}>
                                                    Please select a .xlsx file only !
                                                </Fade>
                                            </FormFeedback>
                                        </InputGroup>
                                    </MDBCol>
                                </FormGroup>
                            </MDBCard>

                        </form>

                        {this.state.dataLoaded &&
                            <div>
                                <Card body outline color="secondary" className="restrict-card">

                                    <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="" tableHeaderRowClass="heading" />

                                </Card>
                            </div>}
                    </Container>
                </MDBContainer>
            </div>
        );
    }
}

export default ImportUsers;