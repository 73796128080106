import React from 'react'
import UpdateBtn from '../../../components/UpdateBtn'
import SerachInout from '../../../components/SerachInout'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { PureComponent } from 'react';
import { Link } from "react-router-dom";
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer } from 'mdbreact';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProducts, changeProductStockStatus } from "../../../redux/products/products-actions";


class ProductList extends PureComponent {
    constructor() {
        super()
        this.state = {
            newInActiveProducts: [],
            indexOfProduct: [],
            token: null
        }
    }

    render() {
        if (this.state.token == "manager") {

            return (
                <div className='container-fluid firstDivAfterNav'>
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBCard>
                                    <MDBCardBody>
                                        <h2 className='text-center font-weight-bold'>ניהול מוצרים</h2>
                                        <div className='col-sm-6' style={{ margin: 'auto' }}>
                                            <UpdateBtn
                                                className='col-sm-4'
                                                headlineText='מוצר חדש'
                                                linkPath='/admin/products/create-product'
                                            />
                                        </div>
                                        <SerachInout />

                                        <div className='table-responsive'>
                                            <table id="dataTable" className="table table-striped col-sm-12 text-center" style={{ margin: 'auto' }}>
                                                <thead className="thead-dark">
                                                    <tr className="header">
                                                        {/* <th>קוד</th> */}
                                                        <th>קדימות</th>
                                                        <th colSpan="2"></th>
                                                        <th>מוצר</th>
                                                        {/* <th>תיאור</th> */}
                                                        <th>מחיר</th>

                                                        <th>סטאטוס</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.loadMyProducts()}
                                                </tbody>
                                            </table>
                                        </div>

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>

            )
        }
        else {
            return <div className='firstDivAfterNav'>
                <h2 className='text-center font-weight-bold' style={{ paddingTop: '150px', paddingBottom: '150px' }}>
                    אין לך הרשאה לצפות בעמוד זה</h2>
            </div>
        }
    }

    setProdpuctOutFromDelivery = (e) => {
        e.preventDefault();
        document.getElementById(`tr` + e.target.id).style.background = 'red'
        document.getElementById(e.target.id).style = 'none'

        let newElement = parseInt(e.target.id)

        this.setState(prevState => {
            return {
                newInActiveProducts: [...prevState.newInActiveProducts, newElement]
            }
        })
    }

    setProdpuctInDelivery = (e) => {
        e.preventDefault();

        document.getElementById(`tr` + e.target.id).style.background = 'none'
        this.state.newInActiveProducts.splice(e.target.index, 1)
        // console.log('newInActiveProducts', this.state.newInActiveProducts)

    }
    loadMyProducts = () => this.props.prodcuts.map((product, index) => {

        let myImage = product.ReservationImage.toString()
        let prodprice = product.Price + ' ש"ח'
        let stock = ''
        let typeOfProduct = ''

        if (product.InStock) {
            // stock = 'במלאי'
            typeOfProduct = 'activeProductInAdminProducts'
            stock = <>
                <option value={false}> לא במלאי</option>
                <option selected={true} value={true}>במלאי</option>
            </>
        }

        else {
            // stock = 'אין במלאי'
            typeOfProduct = 'invalidProduct isNotActive isNotActiveInAdmin'

            stock = <>
                <option selected={true} value={false}> לא במלאי</option>
                <option value={true}> במלאי</option>
            </>

        }

        return (
            <tr className={`thead-dark ${typeOfProduct}`} id={`tr${product.Code}`} key={product.Code}>

                <td style={{ textDecoration: 'none' }}>{product.TypeCode}

                </td>
                <td id={product.Code} className=''>
                    {/* <a href={`/products/${product.Code}`} > <i className="fas fa-edit"></i> עריכה</a> */}

                    <Tooltip text="עריכה">
                        <Link to={`/admin/products/edit/${product.Code}`}>
                            <button className='rounded-button'>
                                <i className="fas fa-edit"></i>
                            </button>
                        </Link>
                    </Tooltip>

                </td>
                <td>
                    <img className="img-responsive cellHiddenInMobile" src={`../${myImage}`} alt="prewiew" width={120} height={80}></img>
                </td>
                <td>
                    <h4 id='prodName' className="product-name"><b>{product.Name}</b></h4>

                </td>
                {/* <td>{product.DescriptionForRes}</td> */}
                <td>
                    <h6 style={{ display: 'inline-block' }}><strong> {prodprice} </strong></h6>

                </td>

                <td>
                    <select onChange={e => this.setStockStatus(product.Code)}>
                        {stock}
                    </select>
                </td>
            </tr >

        )
    })

    setStockStatus = (code) => {

        this.props.changeProductStockStatus(code).then(() => {
            this.props.getProducts()
        })
    }

    componentDidMount() {
        document.title = "משק אלמוג - מוצרים"
        if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
            this.setState({ token: "manager" })
            this.props.getProducts()
        }
    }
}

const mapStateToProps = (state) => {
    return {
        prodcuts: state.productReducer.products
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProducts,
            changeProductStockStatus
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
