import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBCard, MDBCardBody, MDBCardImage } from 'mdbreact';
import { useHistory } from "react-router-dom";
import '../../../dist/css/editProducts.css'
import MyModal from '../../../components/MyModal'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createProduct, getProducts } from "../../../redux/products/products-actions";

import './products.scss'

const CreateProduct = (props) => {

    const [productName, setProductName] = useState('')
    const [productShortName, setProductShortName] = useState('')
    const [productTypeCode, setProductTypeCode] = useState('')
    const [productDescription, setProductDescription] = useState('')
    const [productDescriptionForRes, setProductDescriptionForRes] = useState('')
    const [productPrice, setProductPrice] = useState('')
    const [producWeight, setProductWeight] = useState('')
    const [productReservationImage, setProductReservationImage] = useState('')
    const [modalShow, setModalShow] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [imageHelperText, setImageHelperText] = useState('')
    const history = useHistory()

    useEffect(() => {

        props.getProducts()

    }, []);

    const loadFileForRes = (event) => {

        try {
            setImageHelperText('לתשומתך ליבך - את התמונה צריך לשמור בנוסף בתיקיית public/img/Res')

            setProductReservationImage(`../img/Res/${event.target.files[0].name}`)
            let image = document.getElementById('ReservationImage');
            image.src = URL.createObjectURL(event.target.files[0]);
        }
        catch (error) {
        }
    }

    const postUpdatedProduct = () => {

        let newProductCode = props.products.length + 1;

        const objectPost =
        {
            "Code": parseInt(newProductCode),
            "TypeCode": parseInt(productTypeCode),
            "Name": productName,
            "ShortName": productShortName,
            "Description": productDescription,
            "DescriptionForRes": productDescriptionForRes,
            "Price": productPrice,
            "Weight": producWeight,
            // "Image": productReservationImage,
            "ReservationImage": productReservationImage,
            "InStock": true
        }

        // console.log('objectPost', objectPost)

        props.createProduct(objectPost).then(() => {
            history.push('/admin/products')
        })
    }

    const setModalShowToShow = (e) => {
        e.preventDefault();
        setModalShow(true)
    }

    return (
        <>
            return (
            <div className='container-fluid firstDivAfterNav'>
                <MDBContainer id='formContainer'>
                    <MDBRow>
                        <MDBCol md="12" style={{ margin: 'auto' }}>
                            <MDBCard>
                                <MDBCardBody>

                                    <form style={{ background: 'white' }}>
                                        <div className='col-sm-8' style={{ margin: 'auto' }}>
                                            <h2 className='text-center font-weight-bold'>הוספת מוצר</h2>
                                            <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>

                                            <MDBInput label="* שם המוצר"
                                                onChange={e => setProductName(e.target.value)}
                                                id="Name"
                                                name="Name"
                                                required icon="user" group type="text" validate
                                                error="wrong"
                                                success="right" />

                                            <MDBInput label="* שם מקוצר להזמנות"
                                                onChange={e => setProductShortName(e.target.value)}
                                                name="ShortName"
                                                id="ShortName"
                                                required icon="user" group type="text" validate
                                                error="wrong"
                                                success="right" />

                                            <MDBInput label="* קדימות"
                                                onChange={e => setProductTypeCode(e.target.value)}
                                                name="typeCode"
                                                id="typeCode"
                                                min="1"
                                                required icon="sort-numeric-up-alt" group type="number" validate
                                                error="wrong"
                                                success="right" />

                                            <MDBInput label="* תיאור המוצר"
                                                onChange={e => setProductDescription(e.target.value)}
                                                name="ProductDescription"
                                                id="ProductDescription"
                                                required icon="info" group type="text" validate
                                                error="wrong"
                                                success="right" />

                                            <MDBInput label="* תיאור מקוצר להזמנות"
                                                onChange={e => setProductDescriptionForRes(e.target.value)}
                                                name="DescriptionForRes"
                                                id="DescriptionForRes"
                                                required icon="info" group type="text" validate
                                                error="wrong"
                                                success="right" />

                                            <MDBInput label={`* מחיר בש"ח`}
                                                onChange={e => setProductPrice(e.target.value)}
                                                name="Price"
                                                id="Price"
                                                required icon="shekel-sign" group type="number" validate
                                                error="wrong"
                                                success="right" />

                                            <MDBInput label={`* משקל`}
                                                onChange={e => setProductWeight(e.target.value)}
                                                name="Weight"
                                                id="Weight"
                                                required icon="weight" group type="text" validate
                                                error="wrong"
                                                success="right" />

                                            <br />
                                            <h4 className='col-sm-12'>תמונות</h4>

                                            <MDBCol md='12' sm='6' style={{ float: 'right', textAlign: 'center' }}   >
                                                <MDBCard>
                                                    <MDBCardBody>
                                                        <i className="fas fa-image fa-lg"></i>
                                                        <label htmlFor="Image" className="black-text">
                                                            &nbsp; תמונה
                                                        </label>
                                                        <p>{imageHelperText}</p>
                                                        <br />
                                                        <input type="file" id="newImgForRes" accept="image/*"
                                                            required
                                                            name="image"
                                                            onChange={e => loadFileForRes(e)} />

                                                        <MDBCardImage
                                                            id='ReservationImage'
                                                            hover
                                                            overlay='white-light'
                                                            className='card-img-top'
                                                            alt=''
                                                        />

                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                            <br /><br /><br /><br /><br />
                                            <p className='col-sm-12 text-center'>{errorMessage}</p>
                                            <div className='col-sm-12 text-center'>
                                            </div>

                                            <MDBBtn
                                                rounded outline color="secondary"
                                                variant="primary" type="submit" value="Send" validate
                                                style={{ display: 'block', margin: 'auto' }}
                                                onClick={(e) => productName != ''
                                                    && productName != ''
                                                    && productShortName != ''
                                                    && productShortName != ''
                                                    && productDescription != ''
                                                    && productDescriptionForRes != ''
                                                    && productPrice != ''
                                                    && producWeight != ''
                                                    && document.getElementById('ReservationImage').src != '' ?
                                                    setModalShowToShow(e)
                                                    : setErrorMessage('מלא/י את כל שדות החובה')
                                                }
                                            > הוספה</MDBBtn>

                                            {/* <MyModal /> */}
                                            <MyModal
                                                headlineText='האם ברצונך להוסיף את המוצר?'
                                                show={modalShow}
                                                onHide={() => setModalShow(false)}
                                                ButtonDeleteText='הוספה'
                                                ButtonText='סגירה'
                                                ButtonDinamicFunction={() => postUpdatedProduct()}
                                            />

                                        </div>
                                    </form>

                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        products: state.productReducer.products
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProducts,
            createProduct

        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);