const initialState = {
  products: [],
  product: {},
  inActiveProducts: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_PRODUCTS":
      return {
        ...state,
        products: action.payload.map((a => {
          return ({
            ...a,
            ShortNameForPreperation:
              a.ShortName.includes("שרי") ?
                a.ShortName.replace("שרי", "") :
                a.ShortName.includes("מיני") ?
                  a.ShortName.replace("מיני", "") :
                  a.ShortName.includes("בייבי בטטה") ?
                    a.ShortName.replace("בייבי בטטה", "") :
                    a.ShortName.includes("מלפפון בייבי") ?
                      a.ShortName.replace("מלפפון בייבי", "בייבי") :
                      a.ShortName.includes("תפוח אדמה בייבי") ?
                        a.ShortName.replace("תפוח אדמה בייבי", 'תפוא') :
                        a.ShortName.includes("חסה לבבות") ?
                          a.ShortName.replace("חסה לבבות", 'חסה') :
                          a.ShortName.includes("חסה סלנובה") ?
                            a.ShortName.replace("חסה סלנובה", 'חסה') : a.ShortName
          })
        }
        ))
      };

    case "GET_ACTIVE_PRODUCTS":
      return { ...state, products: action.payload };

    case "GET_PRODUCT_BY_CODE_FROM_LINK":
      return { ...state, product: action.payload };

    case "CREATE_PRODUCT":
      return { ...state };

    case "DELETE_PRODUCT":
      return { ...state };

    case "UPDATE_PRODUCT_BY_CODE":
      return { ...state };

    case "ADD_PRODUCT_TO_STOCK":
      return { ...state };

    case "REMOVE_PRODUCT_FROM_STOCK":
      return { ...state };

    case "GET_PRODUCT_BY_CODE":
      return {
        ...state,
        product: state.products.filter(
          (product) => product.Code == action.payload.code
        ),
      };

    case "GET_INACTIVE_PRODUCTS":
      return { ...state, inActiveProducts: state.products.InActiveProducts };

    case "CHANGE_USER":
      return { ...state, products: action.payload };

    case "CREATE_USER":
      return { ...state };

    case "EDIT_USER":
      return { ...state };

    case "DELETE_USER":
      return { ...state, users: action.payload };

    case "CHANGE_USER_PASSWORD":
      return { ...state };

    case "GET_ADMIN_EMAILS":
      return { ...state, adminEmails: action.payload };

    case "ADD_ADMIN_EMAILS":
      state.adminEmails = [...state.adminEmails, ""];
      return { ...state };

    case "CHANGE_ADMIN_EMAIL":
      return {
        ...state,
        adminEmails: state.adminEmails.map((email, index) => {
          return index == action.payload.index
            ? action.payload.newEmail
            : email;
        }),
      };

    case "DELETE_ADMIN_EMAIL":
      return {
        ...state,
        adminEmails: state.adminEmails.filter((e, i) => i !== action.payload),
      };

    case "GET_ADMIN_PHONES":
      return { ...state, adminPhones: action.payload };

    case "ADD_ADMIN_PHONES":
      state.adminPhones = [...state.adminPhones, ""];
      return { ...state };

    case "CHANGE_ADMIN_PHONE":
      return {
        ...state,
        adminPhones: state.adminPhones.map((phone, index) => {
          return index == action.payload.index
            ? action.payload.newPhone
            : phone;
        }),
      };

    case "DELETE_ADMIN_PHONE":
      return {
        ...state,
        adminPhones: state.adminPhones.filter((e, i) => i !== action.payload),
      };

    case "SEND_FIRST_MESSAGE_CHECK":
      return { ...state, usersFirstMessageCount: action.payload };

    case "SEND_FIRST_MESSAGE":
      return { ...state, usersFirstMessageCount: action.payload };

    case "GET_CHOSEN_CSV_FILE":
      return { ...state, chosenCsvFile: action.payload };

    case "CHANGE_CHOSEN_CSV_FILE":
      return { ...state, chosenCsvFile: action.payload.newChosenCsvFile };

    case "RESET_CHOSEN_CSV_FILE": {
      return { ...state, chosenCsvFile: "" };
    }

    default:
      return state;
  }
}
