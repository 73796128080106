import React, { Component } from "react";
import moment from "moment";

//components
import ShowDeliveryOrders from "./ShowDeliveryOrders";
import MyModal from "../../../../../components/MyModal";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupOrdersByGroupDeliveryId, updateGroupOrderPaidStatus, } from "../../../../../redux/groupOrders/group-orders-action";
import { GetGroupDeliveryById, getGroupDeliveryTotalsById, } from "../../../../../redux/deliveries/deliveries-actions";
import { getProducts } from "../../../../../redux/products/products-actions";
import { getGroupByCode } from "../../../../../redux/groups/groups-actions";

//css
import "../groupOrdersSummary.css";

class LoadDeliveryOrders extends Component {
  state = {
    csvData: [],
    productsIndexInTable: [],
    orderTotalPrice: 0,
    columns: [
      {
        Header: "שם מלא",
        accessor: "שם מלא",
      },
      {
        Header: "האם שולם",
        accessor: "האם שולם",
      },
      {
        Header: 'סה"כ לתשלום',
        accessor: 'סה"כ לתשלום',
      },
      // {
      //   Header: "מספר הזמנה",
      //   accessor: "מספר הזמנה",
      // },
      {
        Header: "תאריך הזמנה",
        accessor: "תאריך הזמנה",
      },
      // {
      //   Header: "Email",
      //   accessor: "Email",
      // },
      {
        Header: "טלפון",
        accessor: "טלפון",
      },
      // {
      //   Header: "נקודת חלוקה",
      //   accessor: "נקודת חלוקה",
      // },
      {
        Header: "הערות",
        accessor: "הערות",
      },
    ],
    data: [],
    // totalAmounts: [],
    groupName: "",
    delivery: [],
    modalShow: false,
    // hasOrders: true,
  };

  render() {
    return (
      <div className="container-fluid" style={{ backgroundColor: "white" }}>
        {/* <CSVLink data={this.state.csvData}>Download me</CSVLink>; */}
        <ShowDeliveryOrders
          csvData={this.state.csvData}
          groupName={this.state.groupName}
          groupCode={this.props.match.params.groupCode}
          columns={this.state.columns}
          data={this.state.data}
          updateData={this.updateData}
          delivery={this.props.deliveries[0]}
          updateGroupOrderPaidStatus={this.props.updateGroupOrderPaidStatus}
        />

        <>
          <MyModal />

          <MyModal
            headlineText="סטאטוס התשלום עודכן"
            // headlineBody='לתשומך ליבך - לא ניתן יהיה לשחזר לאחר המחיקה'
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
          // ButtonText='ביטול'
          // ButtonDeleteText='אישור'
          // ButtonDinamicFunction={() => this.deleteDelivery()}
          />
        </>
      </div>
    );
  }

  componentDidMount() {
    document.title = "משק אלמוג - הזמנות";

    const { match } = this.props;

    this.props.getGroupDeliveryTotalsById(match.params.deliveryId);

    this.props.getGroupByCode(match.params.groupCode).then(() => {
      this.setState({ groupName: this.props.groups[0].InternalName });
    });
    this.props
      .getGroupOrdersByGroupDeliveryId(match.params.deliveryId)
      .then(() => {
        // console.log("delivberies", this.props.deliveries)
        this.setState({ delivery: this.props.deliveries[0] });

        this.props.getProducts().then(() => {
          // console.log("orders: ", this.props.orders);
          try {
            this.prepColumns();
            this.perpData();
            this.prepCsvFile();
          } catch (error) { }
        });
        // .catch((err) => console.log(err));
      });
    this.props.GetGroupDeliveryById(match.params.deliveryId).then(() => {
      // console.log("Group Deliveries: ", this.props.deliveries);
    });
    // .catch((err) => console.log(err));

    // console.log('has', this.state.hasOrders)
  }

  prepColumns = () => {
    this.props.deliveries[0].Products.map((dp) => {
      this.setState(
        {
          productsIndexInTable: [...this.state.productsIndexInTable, dp.Code],
        },
        () => {
          // console.log("indexes: ", this.state.productsIndexInTable);
        }
      );
      //console.log("Products: ", this.props.products);
      this.setState(
        {
          columns: [
            ...this.state.columns,
            {
              Header: this.props.products.filter((p) => p.Code === dp.Code)[0]
                .ShortName,
              accessor: this.props.products.filter((p) => p.Code === dp.Code)[0]
                .ShortName,
            },
          ],
        },
        () => {
          // console.log("columns: ", this.state.columns);
        }
      );
    });
    const arr = [
      {
        Header: "שיטת תשלום",
        accessor: "שיטת תשלום",
      },
      {
        Header: "הערות פנימיות",
        accessor: "הערות פנימיות",
      },
    ];

    this.setState(
      {
        columns: [...this.state.columns, ...arr],
      },
      () => {
        // console.log("columns2: ", this.state.columns);
      }
    ); //another array
  };

  perpData = () => {
    // console.log("perpData");
    const newData = [];
    // const name = this.props.orders;
    this.setState({ data: [] });
    this.props.orders.map((order) => {
      const personOrder = {};

      personOrder["Id"] = order.Id;

      personOrder["האם שולם"] = order.IsPaid && order.PaymentMethod === "PayBox" ? (
        <label className="myCheckbox">
          <input
            style={{ display: 'block', margin: 'auto' }}
            type="checkbox"
            value="שולם"
            checked={true}
            onChange={() => setPaymentStatus(order.OrderNumber)}
          ></input>
          &nbsp; שולם
        </label>
      ) : order.PaymentMethod === "PayBox" ? (
        <label className="myCheckbox" style={{ fontSize: 'x-small' }}>
          <input
            style={{ display: 'block', margin: 'auto' }}
            type="checkbox"
            value="לא שולם"
            onChange={() => setPaymentStatus(order.OrderNumber)}
          ></input>
          &nbsp; לא שולם
        </label>
      ) : ''

      personOrder["שם מלא"] = <span className="font-weight-bold">{order.PersonInfo.FirstName + " " + order.PersonInfo.LastName}</span>
      // personOrder["מספר הזמנה"] = order.OrderNumber;
      // personOrder["Email"] = order.PersonInfo.Email;
      personOrder["תאריך הזמנה"] = moment(order.OrderDate).format(
        "DD/MM/YYYY HH:mm"
      );
      personOrder["טלפון"] = order.PersonInfo.Phone;
      // personOrder["נקודת חלוקה"] = order.Place;
      personOrder["הערות"] = order.Comment;
      personOrder["orderNumber"] = order.OrderNumber;
      this.state.productsIndexInTable.map((code, i) => {
        if (order.Products.filter((p) => p.Code === code).length > 0) {
          const amount = order.Products.filter((p) => p.Code === code)[0]
            .Amount;
          personOrder[this.state.columns[i + 6].Header] = amount;
        } else {
          personOrder[this.state.columns[i + 6].Header] = "";
        }
      });
      personOrder["תגובה"] = order.Comment;
      personOrder["שיטת תשלום"] = order.PaymentMethod;
      personOrder["הערות פנימיות"] = order.InternalComment;

      this.setState({ orderTotalPrice: order.TotalPrice })
      personOrder['סה"כ לתשלום'] = <p className="font-weight-bold" style={{ fontSize: 'larger' }}>{this.state.orderTotalPrice + " ₪"}</p>

      const setPaymentStatus = (OrderNumber) => {
        const { match } = this.props;

        const objectPost = {
          GroupDeliveryId: match.params.deliveryId,
          OrderNumber: OrderNumber,
        };
        // console.log('objectPost', objectPost)
        this.props.updateGroupOrderPaidStatus(objectPost).then(() => {
          // window.location.reload()

          // console.log('res2', res)
          // this.setState({ modalShow: true })

          this.updateData();
        });
      };

      // console.log("person: ", personOrder);
      newData.push(personOrder);
      // this.setState(
      //   {
      //     data: [...this.state.data, personOrder],
      //   },
      //   () => {
      //     console.log("data:", this.state.data);
      //   }
      // );
    });
    this.setState({ data: newData }, () => {
      // console.log("new data: ", this.state.data);
      this.prepTotals();
    });
  };
  prepTotals = () => {
    const totalsData = [];
    const length = this.state.productsIndexInTable.length + 6;
    for (var i = 0; i < length; i++) {
      if (i === 0) {
        totalsData.push('סה"כ');
      } else if (i === 1 || i === 2 || i === 3 || i === 4) {
        totalsData.push("");
      } else if (i === length - 1) {
        totalsData.push(100);
      } else {
        totalsData.push(this.getTotalsRow(this.state.columns[i]));
      }
    }
    this.setState(
      {
        data: [...this.state.data, totalsData],
      },
      () => {
        // console.log("data with totals:", this.state.data);
      }
    );
  };

  getTotalsRow = (key) => {
    let total = 0;
    this.state.data.forEach((item) => {
      if (item[key.Header] !== "") {
        total += parseInt(item[key.Header]);
      }
    });
    // console.log("total: ", total);
    return total;
  };

  updateData = () => {
    const { match } = this.props;
    this.props.getGroupDeliveryTotalsById(match.params.deliveryId);
    this.props
      .getGroupOrdersByGroupDeliveryId(match.params.deliveryId)
      .then(() => {
        this.perpData();
        this.prepCsvFile();
      });
  };

  prepCsvFile = () => {
    let csvHeadersAndData = [];
    let headers = [];
    this.state.columns.map((cul) => {
      headers.push(cul.Header);
    });
    csvHeadersAndData.push(headers);
    let csvData = [];
    this.state.data.map((d, i) => {
      if (i != this.state.data.length - 1) {
        const current = this.props.orders.filter(
          (order) => order.OrderNumber === d.orderNumber
        )[0];
        // console.log("current: ", current);
        // if (i !== 0 || i == this.state.data.length - 1) {
        // console.log("d: ", d)
        let arr = Object.keys(d).map((k) => d[k]);
        // //  arr.push(isPaid);

        // arr.push("שולם");

        // console.log("arr", arr)

        // arr.splice(1, 1);
        arr.splice(0, 1);
        // arr.splice(2, 1);
        // arr.splice(6, 1);
        arr.splice(23, 1);
        if (arr[25] !== undefined) {
          arr[25] = arr[25].replace("₪", "");
        }
        // console.log("arr: ", arr);
        if (current == undefined) {
          arr.push("");
        } else if (current.IsPaid === true) {
          arr.push("שולם");
        } else {
          arr.push("");
        }

        csvHeadersAndData.push(arr);
        // }
      }
    });

    //csvHeadersAndData.push(csvData)
    this.setState({ csvData: csvHeadersAndData }, () => {
      // console.log("csv data: ", this.state.csvData)
    });
  };
}

const mapStateToProps = (state) => {
  return {
    orders: state.groupOrdersReducer.orders,
    deliveries: state.deliveriesReducer.deliveries,
    totalPricesForDelivery: state.deliveriesReducer.totalPricesForDelivery,
    products: state.productReducer.products,
    groups: state.groupReducer.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGroupOrdersByGroupDeliveryId,
      GetGroupDeliveryById,
      getProducts,
      getGroupByCode,
      updateGroupOrderPaidStatus,
      getGroupDeliveryTotalsById,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadDeliveryOrders);
