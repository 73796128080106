import React from 'react';
import { CSVLink } from "react-csv";
import RoundedButton from './RoundedButton/RoundedButton';

const DownloadBtn = (props) => {

  return (
    <CSVLink data={props.csvData}
      filename={`${props.name}.csv`}
    >
      <RoundedButton
        icon="fas fa-download"
      />
    </CSVLink>
  );
};
export default DownloadBtn

// import React from "react";
// import { CSVLink } from "react-csv";
// import RoundedButton from "./RoundedButton/RoundedButton";
// import XLSX from "sheetjs-style";
// const DownloadBtn = (props) => {
//   const downloadExcel = () => {
//     var data = [...props.csvData];
//     // remvoe "תאריך" column
//     for (var i = 0; i < data.length; i++) {
//       data[i].splice(1, 1);
//     }
//     // remvoe "נקודת חלוקה" column
//     for (var i = 0; i < data.length; i++) {
//       data[i].splice(2, 1);
//     }
//     // remvoe "הערות" column
//     for (var i = 0; i < data.length; i++) {
//       data[i].splice(2, 1);
//     }
//     // remvoe "שיטת תשלום" column
//     for (var i = 0; i < data.length; i++) {
//       data[i].splice(15, 1);
//     }
//     // remvoe "₪" from "סהכ" column
//     for (var i = 0; i < data.length; i++) {
//       data[i][15] = data[i][15] ? data[i][15].replace("₪", "") : data[i][15];
//     }
//     // data[0].splice(4, 1);

//     // for (var i = 1; i < data.length; i++) {
//     //   data[i][0] = data[i][1].props.children;
//     //   data[i][1] = data[i][3];
//     // }

//     /* make the worksheet */
//     var ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });

//     // styling
//     for (var i in ws) {
//       if (typeof ws[i] != "object") continue;
//       let cell = XLSX.utils.decode_cell(i);

//       ws[i].s = {
//         // styling for all cells
//         font: {
//           sz: 10,
//           name: "arial",
//         },
//         alignment: {
//           vertical: "center",
//           horizontal: "center",
//           wrapText: "1", // any truthy value here
//         },
//         border: {
//           right: {
//             style: "thin",
//             color: "000000",
//           },
//           left: {
//             style: "thin",
//             color: "000000",
//           },
//         },
//       };

//       //   if (cell.c == 0) {
//       //     // first column
//       //     ws[i].s.numFmt = "DD/MM/YYYY HH:MM"; // for dates
//       //     ws[i].z = "DD/MM/YYYY HH:MM";
//       //   } else {
//       //     ws[i].s.numFmt = "00.00"; // other numbers
//       //   }

//       if (cell.r == 0) {
//         // first row
//         ws[i].s.fill = {
//           patternType: "solid",
//           fgColor: { rgb: "343a40" },
//           bgColor: { rgb: "343a40" },
//         };
//         ws[i].s.font = {
//           sz: 10,
//           bold: true,
//           color: { rgb: "FFFFFF" },
//         };
//       }

//       if (cell.r % 2) {
//         // every other row
//         ws[i].s.fill = {
//           // background color
//           patternType: "solid",
//           fgColor: { rgb: "b2b2b2" },
//           bgColor: { rgb: "b2b2b2" },
//         };
//       }
//     }

//     // ws["!pageSetup"] = {
//     //   scale: "100",
//     //   orientation: "landscape",
//     // };

//     // columns width
//     var wscols = [];
//     let width = 0;
//     for (var i = 0; i < data[0].length; i++) {
//       //   if (["שם מלא", "נקודת חלוקה", "טלפון"].includes(data[0][i])) {
//       //     width = 20;
//       //   } else {
//       //     width = 10;
//       //   }
//       //   wscols.push({ wch: width });
//       console.log("data[0][i]", data[0][i]);
//       if (
//         data[0][i] &&
//         (data[0][i].includes("שרי") || data[0][i].includes("בייבי"))
//       ) {
//         width = 5;
//       } else {
//         width = 7;
//       }
//       wscols.push({ wch: width });
//     }
//     ws["!cols"] = wscols;

//     /* add to workbook */
//     var wb = {
//       Workbook: {
//         Views: [{ RTL: true }],
//       },
//       Sheets: { ws },
//       SheetNames: [],
//     };

//     XLSX.utils.book_append_sheet(wb, ws, "nir");

//     /* generate an XLSX file */
//     XLSX.writeFile(wb, "sheetjs.xlsx");
//   };

//   return (
//     // <CSVLink data={data}
//     //     filename={`${props.name}.csv`}
//     // >
//     //     <RoundedButton
//     //         icon="fas fa-download"
//     //     />
//     // </CSVLink>
//     <span>
//       <RoundedButton icon="fas fa-download" onClick={downloadExcel} />
//     </span>
//   );
// };
// export default DownloadBtn;
