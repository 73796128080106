// import axios from "axios";
import Api from '../../utils/Api'
let token = "";

if (localStorage.getItem("token")) token = localStorage.getItem("token");
else if (sessionStorage.getItem("token"))
    token = sessionStorage.getItem("token");

export const addOnlineDelivery = (objectPost) => {
    // alert('in')
    return (dispatch) => {
        return Api
            .post(`onlineDeliveries/add/`, objectPost, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                console.log("res from server", res.request.status);

                if (res.request.status == 200) {
                    // alert("הטופס נוסף");
                    dispatch({
                        type: "ADD_ONLINE_DELIVERY",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert("failed");
            });
    };
};

export const updateOnlineDeliveryById = (deliveryIdFromCLinet, objectPost) => {
    // console.log('deliveryIdFromCLinet', deliveryIdFromCLinet)
    // console.log('objectPost', objectPost)
    return (dispatch) => {
        return Api
            .post(
                `onlineDeliveries/update/${deliveryIdFromCLinet}`,
                objectPost,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
                if (res.request.status == 200) {
                    // alert("הטופס עודכן");
                    dispatch({
                        type: "UPDATE_ONLINE_DELIVERY_BY_ID",
                    });
                }
            })
            .catch((e) => {
                alert('לא עודכן')
                console.log(e);
            });
    };
};

export const getOnlineDeliveryById = (deliveryId) => {
    // alert('in')
    return (dispatch) => {
        return Api
            .get(`onlineDeliveries/${deliveryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data) {
                    // alert('success')
                    console.log('res.data', res.data.Payload)
                    dispatch({
                        type: "GET_ONLINE_DELIVERY_BY_DELIVERY_ID",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                //alert('failed')
            });
    };
};

export const deleteOnlineDeliveryById = (deliveryId) => {
    console.log("deliveryId", deliveryId);

    return (dispatch) => {
        //יש בעיה בניתוב הזה - (זהה לעדכון. לבדוק עם ניר)
        return Api
            .delete(`groupDeliveries/${deliveryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                console.log("res", res);
                if (res.request.status == 200) {
                    // alert("הטופס נמחק");
                    dispatch({
                        type: "DELETE_ONLINE_DELIVERY_BY_ID",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert("failed");
            });
    };
};

export const getOnlineDeleveries = () => {
    return (dispatch) => {
        // alert('in')
        return Api
            .get(`onlineDeliveries`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data) {
                    // alert('success')
                    // sort to show first active forms

                    res.data.Payload.sort((a, b) =>
                        new Date(b.DeliveryDateTime) - new Date(a.DeliveryDateTime)
                    );

                    // console.log('res', res.data.Payload)
                    dispatch({
                        type: "GET_ONLINE_DELIVERIES",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                // alert('failed')
            });
    };
};

export const getOnlineDeliveryTotalsById = (deliveryId) => {
    // alert('in')
    return (dispatch) => {
        return Api
            .get(`onlineDeliveries/totals/${deliveryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data) {
                    // alert('success')
                    // console.log('es.data', [res.data.Payload])
                    dispatch({
                        type: "GET_ONLINE_DELIVERIES_TOTALS_BY_DELIVERY_ID",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                //alert('failed')
            });
    };
};










export const getGroupDeliveriesByWeekNumber = (weeksAgo) => {
    return (dispatch) => {
        // alert('in')
        return Api
            .get(`groupDeliveries/week/${weeksAgo}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data) {
                    // alert('success')
                    // sort to show first active forms

                    res.data.Payload.sort((a, b) =>
                        new Date(a.DeliveryDateTime) - new Date(b.DeliveryDateTime)
                    );
                    // // console.log('res', res.data.Payload)
                    dispatch({
                        type: "GET_GROUPS_DELIVERIES_BY_WEEK_NUMBER",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                // alert('failed')
            });
    };
};


export const getGroupDeleveriesByCode = (groupCode) => {
    // console.log("groupCode", groupCode);

    return (dispatch) => {
        return Api
            .get(`groups/deliveries/${groupCode}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data) {
                    // alert('success')
                    // sort to show first active forms
                    res.data.Payload.sort(
                        (a, b) => a.DeliveryDateTime - b.DeliveryDateTime
                    );
                    // res.data.Payload.sort((a, b) => b.DeliveryDateTime - a.DeliveryDateTime);

                    dispatch({
                        type: "GET_DELIVERIES_BY_GROUP_CODE",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                // alert('failed')
            });
    };
};


export const getLastGroupDeliveryByGroupCode = (groupCode) => {
    console.log("groupCode", groupCode);

    return (dispatch) => {
        return Api
            .get(`groups/deliveries/last/${groupCode}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data) {
                    console.log('res data', res.data)
                    // alert('success')
                    dispatch({
                        type: "GET_LAST_DELIVERY_BY_GROUP_CODE",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert('failed')
            });
    };
};




export const getWeekSummary = (weekNum) => {
    // alert('in')
    return (dispatch) => {
        return Api
            .get(`groupDeliveries/week-summary/${weekNum}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data) {
                    // alert('success')
                    // console.log('es.data', [res.data.Payload])
                    res.data.Payload.sort((a, b) =>
                        new Date(a.DeliveryDateTime) - new Date(b.DeliveryDateTime)
                    )
                    dispatch({
                        type: "GET_WEEK_SUMMARY",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                //alert('failed')
            });
    };
};


export const setGroupDeliveryRevenue = (objectPost) => {
    // console.log('data:', deliveryId, orderNumber)
    return (dispatch) => {
        return Api
            .post(`/groupDeliveries/update-revenue`, objectPost, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                // console.log("res from server", res.request.status);
                if (res.request.status == 200) {
                    // alert("הרווח בפועל עודכן");
                    dispatch({
                        type: "SET_GROUP_DELIVERY_REVENUE",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert("failed");
            });
    };
};


