import React, { useEffect, useState } from "react";
import { MDBBtn, MDBCard, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { WhatsappIcon } from "react-share";
// Redux
import { GetGroupDeliveryById } from "../../../../../redux/deliveries/deliveries-actions";
import { addGroupOrder, getGroupOrdersByGroupDeliveryId, updateGroupOrderAsReady, updateGroupOrderPaidStatus, updateGroupOrdersToOnlyNotReady } from "../../../../../redux/groupOrders/group-orders-action";
import { getGroupByCode } from "../../../../../redux/groups/groups-actions";
import { getProducts } from "../../../../../redux/products/products-actions";
//components
import Spinner from "../../../../../components/Spinner/Spinner";
import Switch from "../../../../../components/Switch/Switch";
import PreparationOrderNameList from "./PreparationOrderNameList";
import SpontaneousInvitation from "./SpontaneousInvitation";
import GedDayOfWeek from "../../../../../components/GedDayOfWeek";
import GetLocalDayAndHour from "../../../../../components/GetLocalDayAndHour";
import '../preparation.scss';

const PreparationOrder = () => {
    const dispatch = useDispatch();
    let { groupCode, deliveryId, index } = useParams();
    //redux states
    const history = useHistory()
    const groups = useSelector((state) => state.groupReducer.groups);
    const sortedOrders = useSelector((state) => state.groupOrdersReducer.sortedOrders);
    const initialedSortedOrders = useSelector((state) => state.groupOrdersReducer.initialedSortedOrders);
    const deliveries = useSelector((state) => state.deliveriesReducer.deliveries);
    const products = useSelector((state) => state.productReducer.products);
    //local states
    const [productListOfOrderClass, setProductListOfOrderClass] = useState("")
    const [currentOrder, setCurrentOrder] = useState([])
    const [loading, setLoading] = useState(false)
    const [isReadyArray, setIsReadyArray] = useState([])
    const [place, setPlace] = useState(null)
    const [seconds, setSeconds] = useState(6)
    const [displayDropDownMenue, setDisplayDropDownMenue] = useState("none")
    const [modalShow, setModalShow] = useState(false)
    const [modalShowNameList, setModalShowNameList] = useState(false)
    const [totalPrice, setTotalPrice] = useState(null)
    const [internalComment, setInternalComment] = useState("")
    const [totalPriceNewRes, setTotalPriceNewRes] = useState(null)
    const [internalCommentNewRes, setInternalCommentNewRes] = useState("")
    const [showOnlyNotReadyOrders, setShowOnlyNotReadyOrders] = useState(false)

    //scroll
    useEffect(() => {
        //hide navbar and welcome img of site
        document.getElementById('myNav').style.display = 'none'
        const section1 = document.getElementById(`orderContent`);
        if (section1) {
            const yOffset = 0;
            const y = section1.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, [])

    //initial INTERVAL TIME and update SECOND varibale
    useEffect(() => {
        if (seconds <= 5) {
            const interval = setInterval(() => {
                setSeconds(prevSecond => {
                    if (prevSecond > 0 && prevSecond <= 60)
                        return prevSecond - 1
                    else
                        return 59

                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [seconds])

    useEffect(() => {
        if (seconds == 0) handleNext()
    }, [seconds])

    //call APIs
    useEffect(() => {
        document.title = "משק אלמוג - הכנת הזמנות";
        if (groupCode && deliveryId) {
            dispatch(getGroupByCode(groupCode));
            dispatch(getGroupOrdersByGroupDeliveryId(deliveryId));
            dispatch(GetGroupDeliveryById(deliveryId))
            dispatch(getProducts())
        }
    }, [groupCode, deliveryId]);

    useEffect(() => {
        if (index) setPlace(index)
    }, [index])

    //setCurrentOrder
    useEffect(() => {
        if (sortedOrders.length > 0 && place) {
            const filterToCurrent = sortedOrders.filter(o => o.Id == sortedOrders[place - 1].Id)

            setCurrentOrder(filterToCurrent)
            setTotalPrice(filterToCurrent[0].TotalPrice)
            setInternalComment(filterToCurrent[0].InternalComment)
            setProductListOfOrderClass(Array(sortedOrders[place - 1].Products.length).fill(sortedOrders[place - 1].IsReady ? "active" : "not-active"))
        }
    }, [sortedOrders, place]);


    //update IsReadyArray
    useEffect(() => {
        if (sortedOrders.length > 0) {
            let updateIsReady = []
            updateIsReady = sortedOrders.map((r, i) => ({
                IsReady: r.IsReady,
                indexLetter: `${r.PersonInfo.FirstName.charAt(0)}${r.PersonInfo.LastName.charAt(0)}`
            }))
            setIsReadyArray(updateIsReady)
        }
    }, [sortedOrders])


    const handleClickProduct = (code, index) => {

        const currentProductListOfOrderClass = [...productListOfOrderClass]

        const activeList = currentProductListOfOrderClass.filter(c => c == "active")
        if (activeList.length == currentOrder[0].Products.length - 1 && !currentOrder[0].IsReady)
            handleOrderIsReadyToggle()
        else {
            let updated = [];
            updated = currentProductListOfOrderClass.map((c, i) => {
                return (
                    i == index ?
                        c == "not-active" ? "active" : "not-active"
                        : c
                )
            })
            setProductListOfOrderClass(updated)
        }
    }

    const handleChangePaidStatus = () => {
        setLoading(true)
        dispatch(updateGroupOrderPaidStatus({
            GroupDeliveryId: deliveryId,
            OrderNumber: currentOrder[0].OrderNumber,
        })).then(() => {
            setLoading(false)
        })
    }

    const handleNext = () => {
        setSeconds(6)

        if (place == sortedOrders.length)
            history.push(`/admin/deliveries/preparation/${groupCode}/${deliveryId}`)
        else
            setPlace(prev => Number(prev) + Number(1))
    }

    const handleChangeInternalComment = (e) => {
        setLoading(true)

        const objectPost = {
            GroupDeliveryId: deliveryId,
            GroupOrderId: currentOrder[0].Id,
            InternalComment: internalComment,
            TotalPrice: Number(totalPrice)
        };
        const updatedOrder = [...currentOrder]

        dispatch(updateGroupOrderAsReady({ objectPost, currentOrder: updatedOrder[0] })).then(() => {
            const copiedCurrentOrder = [...currentOrder]
            copiedCurrentOrder[0].InternalComment = internalComment
            copiedCurrentOrder[0].TotalPrice = Number(totalPrice)
            const newCurrentOrder = copiedCurrentOrder
            setCurrentOrder(newCurrentOrder)
            setLoading(false)
        })
    }

    const handleOrderIsReadyToggle = () => {

        setLoading(true)

        const objectPost = {
            GroupDeliveryId: deliveryId,
            GroupOrderId: currentOrder[0].Id,
            InternalComment: currentOrder[0].InternalComment,
            TotalPrice: Number(totalPrice)
        };
        const updatedOrder = [...currentOrder]
        updatedOrder[0].IsReady = !updatedOrder[0].IsReady

        dispatch(updateGroupOrderAsReady({ objectPost, currentOrder: updatedOrder[0] })).then(() => {
            setLoading(false)
            const section1 = document.getElementById(`orderContent`);
            if (section1) {
                const y = section1.getBoundingClientRect().top + 110;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }

            if (updatedOrder[0].IsReady) {
                setProductListOfOrderClass(Array(sortedOrders[place - 1].Products.length).fill("active"))
                setSeconds(5)
            }
            else {
                setProductListOfOrderClass(Array(sortedOrders[place - 1].Products.length).fill("not-active"))
                setSeconds(6)
            }
        })
    }

    const handleSaveNewReservation = () => {
        setLoading(true);
        const objectPost = {
            "GroupName": groups[0].Name,
            "DeliveryPlaces": deliveries[0].Places,
            "DateOfDelivery": deliveries[0].DeliveryDateTime,
            "payBoxLink": deliveries[0].PayboxGroupLink,
            "Place": deliveries[0].Places[0].Address,
            "GroupCode": groupCode,
            "GroupDeliveryId": deliveryId,
            "PersonInfo": {
                "FirstName": "1-רכישה",
                "LastName": "1-ספונטנית",
                "Email": "meshekalmog@gmail.com",
                "Phone": "0502820405",
                "UserId": null
            },
            "Products": [
                {
                    "Code": 1,
                    "Name": "מלפפונים",
                    "Price": 10,
                    "Weight": "1.5  ק\"ג",
                    "Amount": 1
                }
            ],
            "TotalPrice": totalPriceNewRes,
            "Comment": "",
            "PaymentMethod": "PayBox",
            "InternalComment": internalCommentNewRes,
            "IsReady": true,
            "IsPaid": true
        }

        console.log('objectPost', objectPost)

        addGroupOrder(objectPost).then(() => {
            dispatch(getGroupOrdersByGroupDeliveryId(deliveryId)).then(() => {
                setLoading(false);
                alert("ההזמנה נשמרה!")
                setPlace(prev => Number(prev) + Number(1))
                setModalShow(false)
                setInternalCommentNewRes("")
                setTotalPriceNewRes(null)
            });

        }).catch(() => {
            setLoading(false);
            alert('אירעה שגיאה')
        })
    }

    return (
        <div className='container-fluid' style={{ overflow: "initial" }}>
            <MDBContainer className='animated fadeIn preparation-order'>

                <h2 className="text-center font-weight-bold">
                    {groups.length > 0 ? (
                        <span> {groups[0].Name}</span>
                    ) : (
                        <span>...טוען</span>
                    )}
                </h2>
                {
                    deliveries.length > 0 &&
                    <>
                        <div className="text-center flex-row">
                            <div style={{ flex: 1 }}>
                                <button className='rounded-button' style={{ float: 'right' }}
                                    onClick={() => history.push(`/admin/deliveries/preparation/${groupCode}/${deliveryId}`)}>
                                    <i className="fas fa-chevron-circle-right"></i>
                                </button>
                            </div>

                            {deliveries[0].Places.length > 0 &&
                                <div style={{ flex: 11 }}>
                                    <h4 className="text-center">
                                        {moment(new Date(deliveries[0].DeliveryDateTime)).format("DD/MM/YYYY")},&nbsp;
                                        <GedDayOfWeek
                                            dayOfWeek={deliveries[0].DeliveryDateTime}
                                        />,
                                        בשעה&nbsp;
                                        <GetLocalDayAndHour
                                            style={{ display: 'inline' }}
                                            hourOfDelivery={deliveries[0].Places[0].Hour}
                                        />
                                    </h4>
                                </div>

                            }

                            <button className='rounded-button' onClick={() => setModalShow(true)}>
                                <i class="fas fa-plus-square"></i>
                            </button>

                            <button className='rounded-button' onClick={() => setModalShowNameList(true)}>
                                <i class="fas fa-solid fa-users"></i>
                            </button>
                        </div>
                        {
                            currentOrder && currentOrder.length > 0 &&
                            <Switch
                                defaultChecked={showOnlyNotReadyOrders}
                                func={() => {

                                    if (showOnlyNotReadyOrders) {
                                        setShowOnlyNotReadyOrders(false)
                                        setPlace(1)
                                        let updateIsReady = []
                                        updateIsReady = initialedSortedOrders.map((r, i) => ({
                                            IsReady: r.IsReady,
                                            indexLetter: `${r.PersonInfo.FirstName.charAt(0)}${r.PersonInfo.LastName.charAt(0)}`
                                        }))
                                        setIsReadyArray(updateIsReady)
                                        dispatch(updateGroupOrdersToOnlyNotReady(false))

                                    }
                                    else {
                                        setShowOnlyNotReadyOrders(true)
                                        setPlace(1)
                                        const copiedIsReadyArray = [...isReadyArray]
                                        const updatedIsReadyArray = copiedIsReadyArray.filter(c => !c.IsReady)
                                        setIsReadyArray(updatedIsReadyArray)
                                        dispatch(updateGroupOrdersToOnlyNotReady(true))
                                    }

                                }}
                                label={"הזמנות לא מוכנות"}
                            />
                        }
                    </>

                }


                {/* CIRCLES */}
                <div className='circles-wrapper' id="orderContent">
                    {
                        isReadyArray.length > 0 &&
                        isReadyArray.map((item, index) => {
                            return <React.Fragment key={index}>

                                <div className={index == place - 1 ? "active-circle" : "circle"}
                                    onClick={() => setPlace(index + 1)}
                                    style={{
                                        background: (index == place - 1) ? "#D6D6D6"
                                            : item.IsReady ? '#25D03C' : '#d12829'
                                    }}>

                                    {sortedOrders[index].IsPaid && <p><i className="fas fa-check small-checked"></i> </p>}

                                    <span>
                                        {/* {index + 1} */}
                                        {item.indexLetter}
                                    </span>
                                </div>

                            </React.Fragment>
                        })
                    }
                </div>

                {sortedOrders.length > 0 && currentOrder.length > 0 && products.length > 0 &&
                    <MDBRow>
                        <MDBCol sm="12">
                            <MDBCard style={{ opacity: loading ? 0.5 : 1 }}>

                                <div className="text-center">
                                    <div className="header">
                                        {/* BUTTONS */}
                                        <div className='flex-row'>
                                            <button className="btn text-center almogBtn"
                                                disabled={place == 1}
                                                onClick={() => setPlace(prev => Number(prev) + Number(-1))}
                                            >
                                                <i className="fas fa-arrow-alt-circle-right fa-2x"></i>
                                                הקודם&nbsp;
                                            </button>
                                            {place && <p className="text-center part-of-order">{place}/{sortedOrders.length}</p>}
                                            <button className="btn text-center almogBtn"
                                                onClick={handleNext}
                                            >הבא&nbsp;
                                                <i className="fas fa-arrow-alt-circle-left fa-2x"></i>
                                            </button>
                                        </div>
                                        {/* TITLES */}
                                        <>
                                            <h3
                                                className={currentOrder[0].IsPaid ? "font-weight-bold" : "text-danger font-weight-bold"}
                                                onClick={() => setDisplayDropDownMenue(displayDropDownMenue == "none" ? "flex" : "none")}
                                            >
                                                {currentOrder[0].InternalComment != "" && <i className="fa fa-solid fa-quote-right"></i>}&nbsp;
                                                {currentOrder[0].PersonInfo.FirstName == "1-רכישה" ? "רכישה" : currentOrder[0].PersonInfo.FirstName}
                                                &nbsp;{currentOrder[0].PersonInfo.LastName == "1-ספונטנית" ? "ספונטנית" : currentOrder[0].PersonInfo.LastName}
                                            </h3>

                                            <div className="dropdown-content" style={{ display: displayDropDownMenue }}>
                                                <i className="fas fa-times" onClick={() => setDisplayDropDownMenue("none")} />
                                                <div>
                                                    <p>פעולות:</p>
                                                    <button className='rounded-button' aria-label="Right Align" style={{ width: '32px', height: '32px' }}>
                                                        <a href={`tel: 972-${currentOrder[0].PersonInfo.Phone}`}>
                                                            <MDBIcon icon="phone" className='reactShareIcons' />
                                                        </a>
                                                    </button>
                                                    {
                                                        deliveries.length > 0 &&
                                                        <button className='rounded-button' aria-label="Center Align" style={{ width: '32px', height: '32px', padding: '0' }}>
                                                            <a href={`https://wa.me/972${currentOrder[0].PersonInfo.Phone}?text=לינק לפייבוקס - ${encodeURIComponent(deliveries[0].PayboxGroupLink)}`}
                                                                target="_blank" rel="noopener noreferrer">
                                                                <WhatsappIcon className='reactShareIcons' size={32} round={true} />
                                                            </a>
                                                        </button>
                                                    }
                                                </div>
                                            </div>


                                            <p>{currentOrder[0].PaymentMethod == "PayBox" ?
                                                <img src='../../../../../img/Paybox.png' style={{ width: '20px' }} alt="payBox"></img>
                                                : <i className="fas fa-money-bill"></i>
                                            }

                                                <div className="flex-row">
                                                    <input type="number" className="form-control col-sm-4" id="totalPriceInput" value={totalPrice}
                                                        autoComplete="off"
                                                        style={{ textAlign: 'center' }}
                                                        onChange={e => setTotalPrice(e.target.value)}
                                                    >
                                                    </input>&nbsp;
                                                    <b style={{ display: 'inline-block' }}> ₪</b>
                                                </div>
                                                {
                                                    currentOrder[0].TotalPrice != totalPrice &&
                                                    <>
                                                        <MDBBtn
                                                            rounded outline color="primary"
                                                            variant="primary" type="" value="Send" validate
                                                            onClick={handleChangeInternalComment}
                                                        > עדכון סכום</MDBBtn>
                                                        <MDBBtn
                                                            rounded outline color="primary"
                                                            variant="primary" type="" value="Send" validate
                                                            onClick={() => setTotalPrice(currentOrder[0].TotalPrice)}
                                                        > ביטול</MDBBtn>
                                                    </>
                                                }
                                            </p>

                                            <Switch
                                                defaultChecked={currentOrder[0].IsReady}
                                                func={handleOrderIsReadyToggle}
                                                label={"הזמנה מוכנה"}
                                            />

                                            <Switch
                                                defaultChecked={currentOrder[0].IsPaid}
                                                func={handleChangePaidStatus}
                                                label={"הזמנה שולמה"}
                                            />
                                        </>
                                    </div>

                                    {/* PRODCUTS */}
                                    {
                                        currentOrder[0].Products.map((p, i) => {
                                            return <div key={p.Code} className={`product-wrapper mt-2`} onClick={() => handleClickProduct(p.Code, i)}>
                                                <div className={`flex-row ${productListOfOrderClass[i]}`}>
                                                    <img src={`../../../../${products.find(product => product.Code == p.Code).ReservationImage}`} width={"inherit"} />
                                                    <p><b>{p.Amount}</b> {products.find(product => product.Code == p.Code).ShortNameForPreperation}</p>
                                                </div>
                                            </div>
                                        })
                                    }
                                    <br />

                                    <MDBInput type="textarea" rows="2" label="הערות פנימיות" icon="pen"
                                        value={internalComment}
                                        onChange={e => setInternalComment(e.target.value)}

                                        // onBlur={handleChangeInternalComment}
                                        name='manager_notes' />

                                    {
                                        currentOrder[0].InternalComment != internalComment &&

                                        <>
                                            <MDBBtn
                                                rounded outline color="primary"
                                                variant="primary" type="" value="Send" validate
                                                onClick={handleChangeInternalComment}
                                            > עדכון הערה</MDBBtn>

                                            <MDBBtn
                                                rounded outline color="primary"
                                                variant="primary" type="" value="Send" validate
                                                onClick={() => setInternalComment(currentOrder[0].InternalComment)}
                                            > ביטול</MDBBtn>
                                        </>
                                    }

                                    {
                                        seconds <= 5 &&
                                        <div className="order-ready-wrapper">
                                            {/* BUTTONS */}
                                            <div className='flex-row'>
                                                <button className="btn text-center almogBtn"
                                                    // disabled={place == 1}
                                                    onClick={() => setSeconds(6)}
                                                >
                                                    <i className="fas fa-times fa-2x"></i>&nbsp;
                                                    ביטול&nbsp;
                                                </button>
                                                <button className="btn text-center almogBtn"
                                                    onClick={handleNext}
                                                >הזמנה הבאה&nbsp;
                                                    <span id="seconds"> {seconds}</span>
                                                    &nbsp;&nbsp;
                                                    <i className="fas fa-arrow-alt-circle-left fa-2x"></i>
                                                </button>
                                            </div>
                                        </div>
                                    }

                                </div>

                            </MDBCard>

                        </MDBCol>
                    </MDBRow>
                }

                <SpontaneousInvitation
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    totalPrice={totalPriceNewRes}
                    setTotalPrice={setTotalPriceNewRes}
                    internalComment={internalCommentNewRes}
                    setInternalComment={setInternalCommentNewRes}
                    handleSaveNewReservation={handleSaveNewReservation}
                />
                <PreparationOrderNameList
                    modalShow={modalShowNameList}
                    setModalShow={setModalShowNameList}
                    sortedOrders={sortedOrders.map((s, i) => ({
                        ...s,
                        PersonInfo: {
                            ...s.PersonInfo,
                            FirstName: s.PersonInfo.FirstName == "1-רכישה" ? "רכישה" : s.PersonInfo.FirstName,
                            LastName: s.PersonInfo.LastName == "1-ספונטנית" ? "ספונטנית" : s.PersonInfo.LastName
                        },
                        index: i
                    })
                    )}
                    setPlace={setPlace}
                />

                <Spinner
                    display={loading ? "flex" : "none"}
                />
            </MDBContainer>

        </div >
    );
};

export default PreparationOrder;
