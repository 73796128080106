import React, { useState, useEffect } from 'react'

const GedDayOfWeek = (props) => {

    const [day, setDay] = useState('')


    useEffect(() => {

        // console.log('props.dayOfWeek', props.dayOfWeek)

        let myDay = ''
        let deliveryDay = new Date(props.dayOfWeek).getDay('he-IL', { hour12: false })

        if (props.sumDayOfWeek) {
            deliveryDay = deliveryDay - 1
            if (deliveryDay == 1)
                deliveryDay = 0
        }

        // console.log("deliveryDay", deliveryDay)

        switch (deliveryDay) {
            case 0:
                myDay = "ראשון";
                break;
            case 1:
                myDay = "שני";
                break;
            case 2:
                myDay = "שלישי";
                break;
            case 3:
                myDay = "רביעי";
                break;
            case 4:
                myDay = "חמישי";
                break;
            case 5:
                myDay = "שישי";
                break;
            case 6:
                myDay = "שבת";
                break;
            default:
                break;
        }

        setDay(myDay)

    }, [props]);

    return (
        <>
            {day}
        </>
    )
}
export default GedDayOfWeek