import React, { useEffect, useState, useRef } from "react";
// import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import RoundedButton from "../../../../../components/RoundedButton/RoundedButton";
import { MDBCardBody, MDBRow, MDBCol } from 'mdbreact';
import SerachInout from '../../../../../components/SerachInout'
import Print from '../../../../../components/Print'
// import MyModal from '../../../../components/MyModal'
import DownloadBtn from '../../../../../components/DownloadBtn'
import Tooltip from '../../../../../components/Tooltip/Tooltip'
import GetHourByDuration from '../../../../../components/GetHourByDuration'
import { useHistory } from 'react-router-dom';
import "../groupOrdersSummary.css";
import moment from 'moment-timezone'

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { changeGroupDeliveryStatus } from "../../../../../redux/groupOrders/group-orders-action";
import { deleteGroupOrderByOrderId } from "../../../../../redux/groupOrders/group-orders-action";

// const mapStateToProps = (state) => {
//   return {
//     // orders: state.groupOrdersReducer.orders,
//     // deliveries: state.deliveriesReducer.deliveries,
//     // products: state.productReducer.products,
//   };
// };

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            deleteGroupOrderByOrderId,
            // getGroupOrdersByGroupDeliveryId,
            changeGroupDeliveryStatus
        },
        dispatch
    );
};

function ManagerShowDeliveryOrders({ columns, data, groupName, delivery, csvData }) {
    const [deliveryDate, setDeliveryDate] = useState('')
    const [deliveryHour, setDeliveryHour] = useState('')
    const [activeStatus, setActiveStatus] = useState(null)
    const [statusText, setStatusText] = useState(null)
    const [token, setToken] = useState(null)

    const componentRef = useRef();

    let myRef = React.createRef()
    const dispatch = useDispatch();

    const totals = useSelector((state) => state.deliveriesReducer.totalPricesForDelivery)
    useEffect(() => {
        // console.log('delivery Date', delivery.DeliveryDateTime)

        if (localStorage.getItem("token"))
            setToken("manager")
        //  token = localStorage.getItem("token");
        else if (sessionStorage.getItem("token"))
            setToken("manager")
        // token = sessionStorage.getItem("token");

        try {
            setDeliveryDate(new Date(delivery.DeliveryDateTime).toLocaleDateString('he-IL', { hour12: false }))
            setActiveStatus(delivery.IsActive)
            { delivery.IsActive ? setStatusText("פתוח") : setStatusText("סגור") }
            // setDeliveryHour(new Date(delivery.DeliveryDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))
            setDeliveryHour(moment(new Date(delivery.DeliveryDateTime).format('HH:mm')))
            document.getElementById('myNav').style.display = 'block';


        } catch (error) {

        }

    }, [delivery])

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    );

    const loadData = () => {
        let count = 0;

        return (

            <div className='firstDivAfterNav' style={{ background: "white" }}>
                <MDBRow key={delivery._id}>
                    <MDBCol md="12" style={{ margin: 'auto' }}>
                        {/* <MDBCard> */}
                        <MDBCardBody>
                            <div className='text-center'>
                                <h2 className='text-center font-weight-bold' style={{ display: 'inline' }}> {groupName} |&nbsp;
                                    {delivery.Places.length > 0 ? '' : delivery.Address + " | "}
                                    {deliveryDate}   |&nbsp;
                                </h2>
                                <h3 className='text-center' style={{ display: 'inline' }}>
                                    <span>
                                        {delivery.IsActive ?
                                            <span className='myCheckbox'> רישום {statusText}</span>
                                            : <span className='myCheckbox'>רישום {statusText}</span>
                                        }
                                    </span>
                                </h3>

                                <br />
                                <div id='test' style={{ display: 'inline-block', textAlign: 'right' }}>

                                    {

                                        delivery.Places.map((place, index) => {
                                            // console.log("place", place)
                                            if (delivery.Places.length === 1) {
                                                return <p key={place._id}>
                                                    <span>
                                                        &nbsp;&nbsp;<span className="font-italic">
                                                            {moment(new Date(place.Hour)).tz("Asia/Jerusalem").format('HH:mm')}
                                                            -{<GetHourByDuration
                                                                hour={place.Hour}
                                                                duration={place.Duration}
                                                            />}</span>
                                                    </span>
                                                    <b> {place.Address}</b>
                                                </p>
                                            }
                                            else {
                                                return <li key={place._id}>
                                                    <p style={{ display: 'inline' }}>
                                                        <span>
                                                            &nbsp;&nbsp;<span className="font-italic">
                                                                {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                -{<GetHourByDuration
                                                                    hour={place.Hour}
                                                                    duration={place.Duration}
                                                                />}</span>
                                                        </span>
                                                        <b> {place.Address}</b>

                                                    </p>
                                                </li>
                                            }

                                        })

                                    }
                                </div>
                                {
                                    token === "manager" && csvData.length > 0 &&
                                    <>
                                        <br />
                                        <Tooltip text="הורדה">
                                            {/* {console.log('csvData', csvData)} */}
                                            <DownloadBtn csvData={
                                                csvData.map((c, i) => i == 0 ?
                                                    c.map(headers => {
                                                        return headers.includes("שרי") ?
                                                            headers.replace("שרי", "") :
                                                            headers.includes("מיני") ?
                                                                headers.replace("מיני", "") :
                                                                headers.includes("בייבי בטטה") ?
                                                                    headers.replace("בייבי בטטה", "") :
                                                                    headers.includes("מלפפון בייבי") ?
                                                                        headers.replace("מלפפון בייבי", "בייבי") :
                                                                        headers.includes("תפוח אדמה בייבי") ?
                                                                            headers.replace("תפוח אדמה בייבי", 'תפוא') :
                                                                            headers.includes("חסה לבבות") ?
                                                                                headers.replace("חסה לבבות", 'חסה') :
                                                                                headers.includes("חסה סלנובה") ?
                                                                                    headers.replace("חסה סלנובה", 'חסה') :
                                                                                    headers.includes(`סה"כ לתשלום`) ?
                                                                                        headers.replace(`סה"כ לתשלום`, "סהכ לתשלום") :
                                                                                        headers
                                                    })
                                                    : c
                                                )
                                            } name={`${groupName} ${deliveryDate}`} className='col-sm-2' />
                                        </Tooltip>
                                    </>
                                }

                            </div >
                            {
                                data.length !== 1 ?
                                    <div>
                                        <SerachInout />

                                        <div className='table-responsive'>

                                            <table id="dataTable" className="ordersSummaryTable table table-striped col-sm-12 text-center tableOfOrders staticTopRowTable"
                                                ref={myRef}
                                                {...getTableProps()}>

                                                <thead className="thead-dark">
                                                    {headerGroups.map((headerGroup) => (
                                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                                            <th></th>
                                                            {

                                                                headerGroup.headers.map((column, i) => {
                                                                    if (column.Header == 'סה"כ לתשלום' && !token)
                                                                        return;
                                                                    else {
                                                                        return (
                                                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} id={`header${i}`}>
                                                                                {column.render("Header")}
                                                                                {/* {console.log('column', column)} */}
                                                                                <span>
                                                                                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                                                                </span>
                                                                            </th>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </tr >
                                                    ))}
                                                </thead >
                                                <tbody {...getTableBodyProps()}>
                                                    {rows.map((row, i) => {
                                                        count++

                                                        if (i == data.length - 1) {
                                                            return;
                                                        }
                                                        const orderNumber = row.original.orderNumber;

                                                        prepareRow(row);
                                                        console.log("data", data)
                                                        return (

                                                            <tr {...row.getRowProps()}
                                                            // onClick={(e) => setClickableRow(e, `/admin/deliveries/groups-orders/update/${groupCode}/${delivery._id}/${orderNumber}`)}
                                                            >
                                                                <td>{data.length - count}</td>
                                                                {row.cells.map((cell, index) => {
                                                                    console.log('cell', cell.column.Header == "האם שולם");

                                                                    if (index === 0) {
                                                                        {
                                                                            return window.screen.width < 800 ?
                                                                                <td className="sticky-columnForOrders" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                                                : <td  {...cell.getCellProps()}>{cell.render("Cell")}</td>;

                                                                        }
                                                                    }
                                                                    else {
                                                                        if (cell.column.Header == 'סה"כ לתשלום' && !token)
                                                                            return;
                                                                        else
                                                                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                                                    }

                                                                })}

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table >
                                        </div>

                                        <br />
                                        {
                                            token === "manager" ?
                                                <>
                                                    <h2 className='text-center font-weight-bold'>סיכום הזמנות</h2>
                                                    <div className='table-responsive'>
                                                        <table id='dataTable' className=" table table-striped col-sm-12 text-center tableOfOrders">
                                                            <thead className="thead-dark">
                                                                {headerGroups.map((headerGroup) => (
                                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                                        <th></th>
                                                                        {totals.Products &&
                                                                            totals.Products.map((p) => {
                                                                                return <th className="font-weight-bold" style={{ width: '70px' }}>{p.Name}</th>
                                                                            })}
                                                                        <th className="font-weight-bold" style={{ width: '70px' }}>סה"כ</th>

                                                                    </tr >
                                                                ))}
                                                            </thead >
                                                            <tbody>

                                                                <tr>
                                                                    <td>סה"כ</td>
                                                                    {totals.Products &&
                                                                        totals.Products.map((p) => {
                                                                            return <td>{p.Amount}</td>
                                                                        })}
                                                                    <td>{totals.TotalPrice} ₪</td>


                                                                </tr>


                                                            </tbody>
                                                        </table>



                                                    </div>
                                                </>
                                                : ''
                                        }
                                    </div>
                                    : <>
                                        <br />
                                        <h4 className='text-center font-weight-bold'>לא קיימים רישומים לטופס זה</h4>
                                    </>
                            }
                        </MDBCardBody>
                        {/* </MDBCard> */}
                    </MDBCol>
                </MDBRow>
            </div >
        )
    }

    return (

        data.length > 0 ?


            delivery != null ?
                delivery.IsActive ?
                    loadData()
                    : token !== "manager" ?
                        <div className='firstDivAfterNav' style={{ background: "white" }}>
                            <h2 className='text-center font-weight-bold' style={{ paddingTop: '150px', paddingBottom: '150px' }}>
                                הטופס נסגר. <br />
                                הנתונים לא זמינים יותר לצפייה</h2>
                        </div>
                        : loadData()
                : ''
            :
            <div className='firstDivAfterNav' style={{ background: "white" }}>
                <h2 className='text-center font-weight-bold' style={{ padding: '150px' }}>טוען...</h2>
            </div>



    );

}
const mapStateToProps = (state) => {
    return {
        totals: state.deliveriesReducer.totals
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerShowDeliveryOrders);
