import React, { useState, useEffect } from 'react'
import { Stepper, Step } from 'react-form-stepper';
import { useHistory } from 'react-router';

const CartStepper = (props) => {
    // const [props.activeStep, setActiveStepState] = useState(null)
    let history = useHistory()

    useEffect(() => {
        // setActiveStepState(props.activeStep)
    }, [])

    // const handleClick = (num) => {
    //     props.stepSertter(num)
    // }
    return (
        <Stepper activeStep={props.activeStep}>
            <Step label="בחירת מוצרים"
                className={props.activeStep == 0 && 'activeStepper'}
            // onClick={() => handleClick(0)}
            >
                {
                    props.toalOfAll > 0 ?
                        <i className="fas fa-check animated fadeIn"></i>
                        : '1'
                }


            </Step>
            <Step label="מילוי פרטים אישיים" disabled={props.activeStep >= 1 ? '' : 'disabled'}
                //  className={props.activeStep == 1 && `bg-dark`}
                className={props.activeStep == 1 && 'activeStepper'}
            // onClick={() => handleClick(1)}
            >
                {
                    props.checkedStep2 ?
                        <i className="fas fa-check animated fadeIn"></i>
                        : 2
                }
            </Step>
            <Step label="סיכום ושליחה" disabled={props.activeStep >= 2 ? '' : 'disabled'}
                // className={props.activeStep == 2 && `bg-dark`}
                className={props.activeStep == 2 && 'activeStepper'}
            // onClick={() => handleClick(2)}
            >
                {
                    props.checkedStep3 ?
                        <i className="fas fa-check animated fadeIn"></i>
                        : 3
                }
            </Step>
            <Step label="סיום" disabled={props.activeStep >= 3 ? '' : 'disabled'}
                // className={props.activeStep == 2 && `bg-dark`}
                className={props.activeStep == 3 && 'activeStepper'}
            // onClick={() => handleClick(2)}
            />
        </Stepper>
    )
}
export default CartStepper