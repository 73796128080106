
// let cartFromStorage = JSON.parse(localStorage.getItem('person'))

const initialState = {
    // person: cartFromStorage != null ? cartFromStorage : [],
    person: []
};

export default function (state = initialState, action) {
    // console.log('person is', state)
    switch (action.type) {
        case "GET_PERSON":
            return { ...state };
        case "GET_PERSON_BY_ID":
            return {
                // Again copy the entire state object
                ...state,
                // This time, we need to make a copy of the old todos array
                person: state.person.map(todo => {
                    // If this isn't the todo item we're looking for, leave it alone
                    if (todo.Code !== action.payload) {
                        return todo
                    }

                    // We've found the todo that has to change. Return a copy:
                    return {
                        ...todo,
                    }
                })
            };

        case "ADD_TO_PERSON":
            return {
                ...state,
                person: state.person.concat(action.payload)
            };
        case "UPDATE_PERSON_BY_ID":
            let myArray = [...state.person]
            //Find index of specific object using findIndex method.    
            let objIndex = state.person.findIndex((obj => obj.email == action.payload.email));
            myArray[objIndex] = action.payload;
            return {
                ...state,
                person: myArray
                // person: state.person.map(item => item.id == action.payload.id ? item = action.payload : item)
            };

        case "DELETE_PERSON_BY_ID":
            return { ...state, person: state.person.filter(c => c.Code != action.payload) };
        case "DELETE_PERSON":
            return { ...state, person: [] };
        default:
            return state;
    }
}
