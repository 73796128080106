const initialState = {
    users: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_USERS_LIST":
            return { ...state, users: action.payload };
        case "REGISTER_USER":
            return { ...state };
        case "CREATE_CONTACT":
            return { ...state };
        default:
            return state;
    }
}
