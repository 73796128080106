import React, { useState, useEffect } from 'react'
import EditorInput from '../../../../../../components/EditorInput'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBInput, MDBBtn } from 'mdbreact'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';

import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetGroupDeliveryById } from "../../../../../../redux/deliveries/deliveries-actions";
import { getGroupByCode } from "../../../../../../redux/groups/groups-actions";
import { getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber, getGroupOrdersByGroupDeliveryId, sendEmailToMuiltipleUsers } from "../../../../../../redux/groupOrders/group-orders-action";

const SendMessageAboutOrder = (props) => {
    const [emailHeadline, setEmailHeadline] = useState("")
    const [htmlTemplate, setHtmlTemplate] = useState("")
    const [textForWhatssApp, setTextForWhatssApp] = useState("")
    const [textFoEmail, setTextFoEmail] = useState("")
    const [payBoxLink, setPayBoxLink] = useState("")
    const [orders, setOrders] = useState([])
    const [emailOfClinet, setEmailOfClinet] = useState("")
    const [phoneOfClinet, setPhoneOfClinet] = useState("")
    const [displayMailTemplates, setDisplayMailTemplates] = useState("")
    const [displayWhatssAppTemplates, setDisplayWhatssAppTemplates] = useState("none")
    const [orderEmails, setOrderEmails] = useState([])
    const [deliveryId, setDeliveryId] = useState([])
    const history = useHistory()
    let border = '1px solid black'
    let center = 'center'

    useEffect(() => {

        setDeliveryId(history.location.state.deliveryId)

        let emails = []

        props.getGroupOrdersByGroupDeliveryId(deliveryId).then(() => {

            props.orders.map(order => {
                history.location.state.orders.forEach(element => {
                    if (order.OrderNumber == element)
                        // console.log('order', order.PersonInfo.Email)
                        emails.push(order.PersonInfo.Email)
                });
            })
            setOrderEmails(emails)
        })


        window.scroll(0, 0)

        props.GetGroupDeliveryById(history.location.state.deliveryId).then(() => {
            // console.log("delivery", props.deliveries)
            props.getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber(props.match.params.deliveryId, history.location.state.orders).then(() => {
                // console.log("order back", props.orders)
                let email = ""
                let comma = ""
                props.orders.map((order, index) => {
                    {
                        index != props.orders.length - 1 ?
                            comma = ", "
                            : comma = ""
                    }
                    if (order.OrderNumber == history.location.state.orders) {
                        setEmailOfClinet(order.PersonInfo.Email)
                        setPhoneOfClinet(`972${order.PersonInfo.Phone}`)
                        email = order.PersonInfo.Email
                        setOrders([order])
                        console.log(order);
                        setTextForWhatssApp(
                            `שלום ${order.PersonInfo.FirstName},\nאנא הסדר/י תשלום בסך *${order.TotalPrice} ₪* עבור הזמנתך ממשק אלמוג בפייבוקס בלינק הבא: ${props.deliveries[0].PayboxGroupLink}\nמעבר לצפייה בהזמנתך: https://meshekalmog.co.il/groups/${props.deliveries[0].GroupCode}/${props.deliveries[0]._id}/${order.OrderNumber}/thanks\n תודה, משק אלמוג`
                        )
                    }
                })
                seMessageMethod(2)

            })
        })

    }, [history.location.state.orders])

    const sendEmail = () => {

        let myObject = {
            'Emails': orderEmails,
            'Subject': emailHeadline,
            'Body': htmlTemplate
        }
        // console.log('myObject', myObject)
        props.sendEmailToMuiltipleUsers(myObject).then(() => {
            // console.log('Sent!')
            alert('המייל נשלח')
        })
            .catch(() => alert('אירעה שגיאה'))
    }
    const setOrderDetails = () => {
        setEmailHeadline("פירוט ההזמנה ממשק אלמוג")

        props.deliveries.map(delivery => {
            // console.log("order In", orders)
            orders.map((order, index) => {
                // console.log("order", order)

                let calculateDuration = delivery.Places.map(place => {
                    // .format("DD/MM/YYYY")
                    let oldDateObj = new Date(place.Hour);
                    let newDateObj = new Date();
                    newDateObj.setTime(oldDateObj.getTime() + (place.Duration * 60 * 1000));
                    // ${new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    // -
                    // ${new Date(newDateObj).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    if (place.Address === order.Place)
                        return `<span>
                      ${moment(newDateObj).tz("Asia/Jerusalem").format('HH:mm')}
                     -
                      ${moment(place.Hour).tz("Asia/Jerusalem").format('HH:mm')}
                      </span>`
                }).join('')

                let paybox =
                    delivery.PayboxGroupLink !== null && delivery.PayboxGroupLink !== '' ?
                        `<br/><a href="${delivery.PayboxGroupLink}">מעבר לתשלום ב PayBox</a>`
                        : ''

                let rowOfTotalPrice =
                    `<tr>
              <td  style = "border: ${border}; text-align: ${center}" colspan="6"> סה"כ סכום ההזמנה: <b>${order.TotalPrice} ₪</b></td>
            </tr>`
                let rowOfOrder = order.Products.map((product, index) => {
                    let background = ''
                    if (index % 2 === 0)
                        background = '#dddddd'
                    return (
                        `<tr>
                    <td  style = "border: ${border}; text-align: ${center}" class="myCell">${product.Name}</td>
                    <td  style = "border: ${border}; text-align: ${center}" class="myCell">${product.Amount} </td>
                    <td  style = "border: ${border}; text-align: ${center}" class="myCell">${product.Weight}</td>
                    <td  style = "border: ${border}; text-align: ${center}" class="myCell">
                    ${product.Code === 10 ?
                            `${product.Price} ₪   (ל - 2 יחידות)`
                            : `${product.Price} ₪`
                        }
                    </td>
                    <td  style = "border: ${border}; text-align: ${center}" class="myCell">
                    ${product.Code === 10 ?
                            `${(parseInt(product.Price) * parseInt(product.Amount) / 2)}`
                            : `${parseInt(product.Price) * parseInt(product.Amount)}`

                        } ₪</td>
                  </tr>
                  `
                    )
                }).join('')

                setHtmlTemplate(
                    `<p>שלום ${order.PersonInfo.FirstName},<br/></p>
                    <p>תודה על ההזמנה ממשק אלמוג לחלוקה ב${history.location.state.groupName}.
                    <br/> החלוקה תתבצע בתאריך ${moment(new Date(delivery.DeliveryDateTime)).format("DD/MM/YYYY")},
                    ${order.Place} בשעות ${calculateDuration}
                  </p>
                  <br/>
                  <h3><u>פרטי ההזמנה:</u></h3>
            
                  <table border = '1'>
                  <tr>
                    <td colspan="6" style = "border: ${border}; text-align: ${center}"><span> הזמנה מספר ${order.OrderNumber}</span></td>
                    </tr>
                    <tr>
                    <td colspan="6" style = "border: ${border}; text-align: ${center}"><span id="dateAandHour">
                    ${moment(new Date(order.OrderDate)).format("DD/MM/YYYY")
                    // .toLocaleDateString('he-IL', { hour12: false })
                    + " | " + moment(order.OrderDate).tz("Asia/Jerusalem").format('HH:mm')}
                    </span></td>
                  </tr>
                  <tr>
                    <td colspan="3" style = "border: ${border}; text-align: ${center}"><span>${order.PersonInfo.FirstName} ${order.PersonInfo.LastName}</span></td>
                    <td colspan="3" style = "border: ${border}; text-align: ${center}"><span>${order.PersonInfo.Email}</span></td>
                  </tr>
                  <tr>
                    <td colspan="3" style = "border: ${border}; text-align: ${center}"><span>${order.PersonInfo.Phone}</span> </td>
                    <td colspan="3" style = "border: ${border}; text-align: ${center}"><span>${order.PersonInfo.PaymentMethod === "Paybox" ? "תשלום ב PayBox " : "תשלום במזומן"}</span> </td>
                  </tr>
                  ${order.Comment.length > 0 ?
                        ` <tr>
                            <td style = "border: ${border}; text-align: ${center}" colSpan='6'>${order.Comment}</td>
                        </tr>`
                        : ''
                    }
 
                     <tr>
                     <th style = "border: ${border}; text-align: ${center}">מוצר</th>
                     <th style = "border: ${border}; text-align: ${center}">כמות</th>
                     <th style = "border: ${border}; text-align: ${center}">פירוט</th>
                     <th style = "border: ${border}; text-align: ${center}">מחיר <br/>ליחידה</th>
                     <th style = "border: ${border}; text-align: ${center}">סה"כ</th>
                   </tr>
                     ${rowOfOrder}
                     ${rowOfTotalPrice}

                     </table>
                     <br/>
                     <a href="https://www.meshekalmog.co.il/groups/update/${delivery.GroupCode}/${delivery._id}/${order.OrderNumber}">מעבר לעריכת ההזמנה</a>
                     <br/>
                    ${paybox} <br/>
                    תודה, משק אלמוג
                    `
                )

            })

        })
    }
    const setPayboxReminder = () => {
        setEmailHeadline("תזכורת לתשלום למשק אלמוג")

        props.deliveries.map(delivery => {

            // console.log("order", orders)
            orders.map((order, index) => {
                setHtmlTemplate(
                    `שלום ${order.PersonInfo.FirstName},<br/><br/>
                    אנא הסדר/י תשלום בסך <b>${order.TotalPrice} ₪</b>  <br/>
                     בפייבוקס <a href = ${delivery.PayboxGroupLink}>בלינק הבא</a><br/><br/>
                     <a href="https://www.meshekalmog.co.il/groups/update/${delivery.GroupCode}/${delivery._id}/${order.OrderNumber}">מעבר לצפייה בהזמנתך</a><br/>

                    תודה, משק אלמוג
                    `
                )

            })

        })
    }
    const setOrderCanceled = () => {
        setEmailHeadline("ביטול חלוקה - משק אלמוג")

        props.deliveries.map(delivery => {

            setHtmlTemplate(
                `<p>שלום,<br/></p>
                    <p>לצערנו החלוקה ב${history.location.state.groupName}
                     בתאריך ${moment(new Date(delivery.DeliveryDateTime)).format("DD/MM/YYYY")}
                    <b>בוטלה</b>.
                  </p>
                  אנו מודים לך על הזמן שהקדשת להזמנה ומתנצלים שנאלצנו לבטל.
                    <br/><br/>
                    תודה,  <br/>
                    <a href="https://www.meshekalmog.co.il"> משק אלמוג</a>
                    `
            )
        })

    }
    const setOrderReminder = () => {
        setEmailHeadline("תזכורת להזמנה ממשק אלמוג")

        props.deliveries.map(delivery => {


            orders.map((order, index) => {

                let calculateDuration = delivery.Places.map(place => {
                    // .format("DD/MM/YYYY")
                    let oldDateObj = new Date(place.Hour);
                    let newDateObj = new Date();
                    newDateObj.setTime(oldDateObj.getTime() + (place.Duration * 60 * 1000));
                    // ${new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    // -
                    // ${new Date(newDateObj).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    if (place.Address === order.Place)
                        return `<span>
                      ${moment(newDateObj).tz("Asia/Jerusalem").format('HH:mm')}
                     -
                      ${moment(place.Hour).tz("Asia/Jerusalem").format('HH:mm')}
                      </span>`
                }).join('')

                let paybox =
                    delivery.PayboxGroupLink !== null && delivery.PayboxGroupLink !== '' ?
                        `<br/><a href="${delivery.PayboxGroupLink}">מעבר לתשלום ב PayBox</a>`
                        : ''

                let rowOfTotalPrice =
                    `<tr>
              <td style = "border: ${border}; text-align: ${center}" colspan="6"> סה"כ סכום ההזמנה: <b>${order.TotalPrice} ₪</b></td>
            </tr>`
                let rowOfOrder = order.Products.map((product, index) => {
                    let background = ''
                    if (index % 2 === 0)
                        background = '#dddddd'
                    return (
                        `<tr>
                    <td style = "border: ${border}; text-align: ${center}" class="myCell">${product.Name}</td>
                    <td style = "border: ${border}; text-align: ${center}" class="myCell">${product.Amount} </td>
                    <td style = "border: ${border}; text-align: ${center}" class="myCell">${product.Weight}</td>
                    <td style = "border: ${border}; text-align: ${center}" class="myCell">
                    ${product.Code === 10 ?
                            `${product.Price} ₪   (ל - 2 יחידות)`
                            : `${product.Price} ₪`
                        }
                    </td>
                    <td  style = "border: ${border}; text-align: ${center}" class="myCell">
                    ${product.Code === 10 ?
                            `${(parseInt(product.Price) * parseInt(product.Amount) / 2)}`
                            : `${parseInt(product.Price) * parseInt(product.Amount)}`

                        } ₪</td>
                  </tr>
                  `
                    )
                }).join('')
                setHtmlTemplate(
                    `<p>שלום ${order.PersonInfo.FirstName},<br/></p>
                    <p>זוהי הודעת תזכורת לגבי הזמנתך ממשק אלמוג לחלוקה ב${history.location.state.groupName}.
                    <br/> החלוקה תתבצע בתאריך ${moment(new Date(delivery.DeliveryDateTime)).format("DD/MM/YYYY")},
                    ${order.Place} בשעות ${calculateDuration}
                  </p>
                  <br/>
                  <h3><u>פרטי ההזמנה:</u></h3>
            
                  <table>
                  <tr>
                    <td style = "border: ${border}; text-align: ${center}" colspan="6"><span> הזמנה מספר ${order.OrderNumber}</span></td>
                    </tr>
                    <tr>
                    <td style = "border: ${border}; text-align: ${center}" colspan="6"><span id="dateAandHour">
                    ${moment(new Date(order.OrderDate)).format("DD/MM/YYYY")
                    // .toLocaleDateString('he-IL', { hour12: false })
                    + " | " + moment(order.OrderDate).tz("Asia/Jerusalem").format('HH:mm')}
                    </span></td>
                  </tr>
                  <tr>
                    <td style = "border: ${border}; text-align: ${center}" colspan="3"><span>${order.PersonInfo.FirstName} ${order.PersonInfo.LastName}</span></td>
                    <td style = "border: ${border}; text-align: ${center}" colspan="3"><span>${order.PersonInfo.Email}</span></td>
                  </tr>
                  <tr>
                    <td style = "border: ${border}; text-align: ${center}" colspan="3"><span>${order.PersonInfo.Phone}</span> </td>
                    <td style = "border: ${border}; text-align: ${center}" colspan="3"><span>${order.PersonInfo.PaymentMethod === "Paybox" ? "תשלום ב PayBox " : "תשלום במזומן"}</span> </td>
                  </tr>
                  ${order.Comment.length > 0 ?
                        ` <tr>
                            <td style = "border: ${border}; text-align: ${center}" colSpan='6'>${order.Comment}</td>
                        </tr>`
                        : ''
                    }
 
                     <tr>
                     <th style = "border: ${border}; text-align: ${center}">מוצר</th>
                     <th style = "border: ${border}; text-align: ${center}">כמות</th>
                     <th style = "border: ${border}; text-align: ${center}">פירוט</th>
                     <th style = "border: ${border}; text-align: ${center}">מחיר <br/>ליחידה</th>
                     <th style = "border: ${border}; text-align: ${center}">סה"כ</th>
                   </tr>
                     ${rowOfOrder}
                     ${rowOfTotalPrice}

                     </table>
                     <br/>
                     <a href="https://www.meshekalmog.co.il/groups/update/${delivery.GroupCode}/${delivery._id}/${order.OrderNumber}">מעבר לעריכת ההזמנה</a>
                     <br/>
                    ${paybox} <br/>
                    תודה, משק אלמוג
                    `
                )

            })

        })
    }
    const parentCallbackOfHtmlHeaderInput = (childData) => {

        // console.log('header', childData)
        setHtmlTemplate(childData)
    }

    const setActionsFromSelected = (e) => {
        if (e.target.value == 0) {
            setHtmlTemplate("")
            setEmailHeadline("")
        }
        else if (e.target.value == 1) {
            setOrderDetails()
        }
        else if (e.target.value == 2) {
            setPayboxReminder()
        }
        else if (e.target.value == 3) {
            setOrderReminder()
        }
        else if (e.target.value == 4) {
            setOrderCanceled()
        }
    }

    const seMessageMethod = (eValue) => {
        if (eValue == 1) {
            setDisplayMailTemplates("block")
            setDisplayWhatssAppTemplates("none")

            // console.log('orders', history.location.state.orders)
            // console.log('deliveryId', deliveryId)
            // props.getGroupOrdersByGroupDeliveryId(deliveryId).then(() => {
            //     console.log('props.orders', props.orders)

            //     props.orders.map(order => {
            //         history.location.state.orders.forEach(element => {
            //             if (order.OrderNumber == element)
            //                 // console.log('order', order.PersonInfo.Email)
            //                 emails.push(order.PersonInfo.Email)
            //         });
            //     })
            //     // setOrderEmails(emails)
            // })

            // console.log('orderEmails', emails)
        }
        else if (eValue == 2) {
            setDisplayMailTemplates("none")
            setDisplayWhatssAppTemplates("block")
            props.deliveries.map(delivery => {
                orders.map((order, index) => {
                    // setHtmlTemplate(
                    //     `שלום ${order.PersonInfo.FirstName},<br/><br/>
                    // אנא הסדר/י תשלום בסך <b>${order.TotalPrice} ₪</b> עבור הזמנתך ממשק אלמוג  <br/>
                    //  בפייבוקס <a href = ${delivery.PayboxGroupLink}>בלינק הבא</a><br/><br/>
                    //  <a href="https://www.meshekalmog.co.il/groups/update/${delivery.GroupCode}/${delivery._id}/${order.OrderNumber}">מעבר לצפייה בהזמנתך</a><br/>

                    // תודה, משק אלמוג
                    // `)
                    setTextForWhatssApp(
                        `שלום ${order.PersonInfo.FirstName},
אנא הסדר/י תשלום בסך *${order.TotalPrice} ₪* עבור הזמנתך ממשק אלמוג בפייבוקס בלינק הבא: ${delivery.PayboxGroupLink}
מעבר לצפייה בהזמנתך: https://www.meshekalmog.co.il/groups/update/${delivery.GroupCode}/${delivery._id}/${order.OrderNumber}
תודה, משק אלמוג`
                    )
                })
            })
        }
    }

    const submitForm = (e) => {
        e.preventDefault()

        if (document.getElementById("optionOfSendingMessage").value == "1") {
            console.log('in send email')
            // sendEmail()
        }
        else if (document.getElementById("optionOfSendingMessage").value == "2") {
            // return <WhatsAppWidget phoneNumber='972502820405' />

        }
    }

    const handleChangeType = e => {
        const { value } = e.target;
        console.log(value);
        if (value == 1) {
            props.deliveries.map(delivery => {
                console.log('delivery', delivery);
                orders.map((order, index) => {
                    console.log(order);
                    setTextForWhatssApp(
                        `שלום ${order.PersonInfo.FirstName},
אנא הסדר/י תשלום בסך *${order.TotalPrice} ₪* עבור הזמנתך ממשק אלמוג בפייבוקס בלינק הבא: ${delivery.PayboxGroupLink}
מעבר לצפייה בהזמנתך: https://www.meshekalmog.co.il/groups/update/${delivery.GroupCode}/${delivery._id}/${order.OrderNumber}
תודה, משק אלמוג`
                    )
                })
            })
        }
        else if (value == 2) {
            setTextForWhatssApp("")
        }
    }

    return (
        <div className='container-fluid'>
            <MDBContainer className='firstDivAfterNav animated fadeIn mt-5'>

                <MDBRow>
                    <MDBCol md="12" style={{ margin: 'auto', direction: 'ltr' }}>
                        <MDBCard className='col-sm-12'>
                            <form className='col-sm-8' onSubmit={e => submitForm(e)}>
                                <h2 className='text-center font-weight-bold'>שליחת הודעה </h2>
                                <h4>אופן השליחה</h4>
                                <select id="optionOfSendingMessage" className='form-control' onChange={(e) => seMessageMethod(e.target.value)}>
                                    <option value="1">מייל</option>
                                    <option selected="selected" value="2">WhatssApp</option>
                                </select>
                                <br /> <br />


                                <div style={{ display: displayMailTemplates }}>
                                    <h4>בחירת הודעה</h4>
                                    <select className='form-control' onChange={(e) => setActionsFromSelected(e)}>
                                        <option value="0">בחר/י</option>
                                        <option value="1">פירוט הזמנה</option>
                                        <option value="2">תזכורת לתשלום</option>
                                        <option value="3">תזכורת להזמנה</option>
                                        <option value="4">ביטול חלוקה</option>
                                    </select>
                                    <br /><br />
                                    <h4>כותרת המייל</h4>
                                    <MDBInput id='emailHeadline' value={emailHeadline} onChange={e => setEmailHeadline(e.target.value)}
                                        required icon="user" group type="text" validate
                                        error="wrong" name="user_firstname"
                                        success="right" />

                                    <h4>כתובת למשלוח</h4>
                                    {/* {console.log('orderEmails', orderEmails)} */}
                                    <MDBInput id='emailHeadline' value={orderEmails[0]} onChange={e => setEmailOfClinet(e.target.value)}
                                        required icon="fa fa-envelope prefix" group type="text" validate
                                        error="wrong" name="user_phone"
                                        success="right" />

                                    <h4>גוף ההודעה</h4>
                                    <EditorInput
                                        id="editorHtml"
                                        initialValue={htmlTemplate}
                                        parentCallback={parentCallbackOfHtmlHeaderInput}
                                    />


                                    <MDBBtn
                                        rounded outline color="secondary"
                                        variant="primary" type="submit" value="Send" validate
                                        onClick={() => sendEmail()}
                                    > שליחה</MDBBtn>
                                </div>
                                <div style={{ display: displayWhatssAppTemplates }}>
                                    <h4>מספר טלפון נייד</h4>
                                    <MDBInput id='emailHeadline' value={phoneOfClinet} onChange={e => setPhoneOfClinet(e.target.value)}
                                        required icon="fa fa-envelope prefix" group type="text" validate
                                        error="wrong" name="user_firstname"
                                        success="right" />

                                    <h4>סוג ההודעה</h4>
                                    <select className='form-control' onChange={handleChangeType}>
                                        <option value={1}>הסדרת תשלום</option>
                                        <option value={2}>הזנת הודעה עצמאית</option>
                                    </select>
                                    <br />

                                    <h4>גוף ההודעה</h4>

                                    {/* <WhatsAppWidget id="whatssAppDiv" phoneNumber='972502820405'
                                    companyName="משק אלמוג"
                                    message='שלום! איך אפשר לעזור לך?' /> */}

                                    <textarea value={textForWhatssApp} rows="7" className="md-textarea form-control" onChange={e => setTextForWhatssApp(e.target.value)}></textarea>

                                    <ReactWhatsapp
                                        className="btn-secondary btn-outline-secondary btn Ripple-parent"
                                        rounded outline color="secondary"
                                        variant="primary" type="submit" value="Send" validate
                                        number={phoneOfClinet} message={textForWhatssApp}>שליחה</ReactWhatsapp>
                                </div>
                            </form>

                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        deliveries: state.deliveriesReducer.deliveries,
        groups: state.groupReducer.groups,
        orders: state.groupOrdersReducer.orders,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            GetGroupDeliveryById,
            sendEmailToMuiltipleUsers,
            getGroupByCode,
            getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber,
            getGroupOrdersByGroupDeliveryId
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMessageAboutOrder);
