import { combineReducers } from "redux";
import groupReducer from "./groups/groups-reducer";
import productReducer from "./products/products-reducer";
import deliveriesReducer from "./deliveries/deliveries-reducer";
import usersReducer from "./users/users-reducer";
import groupOrdersReducer from "./groupOrders/group-orders-reducer";
import cartReducer from "./cart/cart-reducer";
import personReducer from "./person/person-reducer";
import onlineOrdersReducer from "./onlineOrders/online-orders-reducer";
import onlineDeliveriesReducer from "./onlineDeliveries/onlineDeliveries-reducer";


const rootReducer = combineReducers({
  groupReducer,
  productReducer,
  deliveriesReducer,
  usersReducer,
  groupOrdersReducer,
  personReducer,
  cartReducer,
  onlineOrdersReducer,
  onlineDeliveriesReducer
});

export default rootReducer;
