import { MDBBtn, MDBInput } from 'mdbreact'
import React, { useEffect } from 'react'
import Modal from "react-bootstrap/esm/Modal";

const SpontaneousInvitation = ({ modalShow, setModalShow, totalPrice, setTotalPrice, internalComment, setInternalComment, handleSaveNewReservation }) => {

    useEffect(() => {
        if (modalShow) {
            setInternalComment("")
            setTotalPrice(null)
        }
    }, [modalShow])


    const handleCancel = () => {
        setTotalPrice(null)
        setInternalComment("")
        setModalShow(false)
    }

    return (
        <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <i className='fas fa-times' onClick={handleCancel} />

            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    רכישה ספונטנית
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="text-center">
                    <div className="flex-row">
                        <input type="number" className="form-control col-sm-4" id="totalPriceInput" value={totalPrice}
                            placeholder='סכום הרכישה'
                            autoComplete="off"
                            style={{ textAlign: 'center' }}
                            onChange={e => setTotalPrice(e.target.value)}>
                        </input>&nbsp;
                        <b style={{ display: 'inline-block' }}> ₪</b>
                    </div>

                    <MDBInput type="textarea" rows="2" label="הערות פנימיות" icon="pen1"
                        value={internalComment}
                        onChange={e => setInternalComment(e.target.value)}
                        name='manager_notes' />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <MDBBtn
                    rounded outline color="primary"
                    variant="primary" type="" value="Send" validate
                    disabled={totalPrice == null}
                    onClick={handleSaveNewReservation}
                > שמירת הזמנה</MDBBtn>
                <MDBBtn
                    rounded outline color="secondary"
                    variant="primary" type="" value="Send" validate
                    onClick={handleCancel}
                > ביטול</MDBBtn>
            </Modal.Footer>
        </Modal>
    )
}

export default SpontaneousInvitation