const initialState = {
  orders: [],
  sortedOrders: [],
  currentOrderNum: 0
};

export default function (state = initialState, action) {

  let copiedActionPayload = [];
  if (typeof action.payload == "object")
    copiedActionPayload = JSON.parse(JSON.stringify(action.payload))

  switch (action.type) {
    case "GET_GROUP_DELIVERY_ORDERS":
      const data = copiedActionPayload.sort((a, b) => a.PersonInfo.FirstName !== b.PersonInfo.FirstName ? a.PersonInfo.FirstName < b.PersonInfo.FirstName ? -1 : 1 : 0);;
      return {
        ...state,
        orders: action.payload,
        sortedOrders: data,
        initialedSortedOrders: data
      };

    case "GET_GROUP_DELIVERY_BY_GROUP_DELIVERY_ID_ND_ORDER_NUMBER":
      return { ...state, orders: action.payload };

    case "ADD_GROUP_ORDER":
      console.log("reducer: ", action.payload[0].OrderNumber)
      return { ...state, currentOrderNum: action.payload[0].OrderNumber };

    case "UPDATE_GROUP_ORDER_BY_GROUP_DELIVERY_ID_AND_ORDER_NUMBER":
      return { ...state }

    case "UPDATE_GROUP_ORDER_PAID_STATUS":
      return {
        ...state,
        sortedOrders: state.sortedOrders.map(s => s.OrderNumber == action.payload.OrderNumber ? ({ ...s, IsPaid: !s.IsPaid }) : s)
        // sortedOrders: state.sortedOrders.map(s => {
        //   if (s.OrderNumber == action.payload.OrderNumber)
        //     return ({ ...s, IsPaid: !s.IsPaid })
        //   else
        //     return s
        // })

      }

    case "UPDATE_GROUP_ORDER_ISREADY":
      return {
        ...state,
        sortedOrders: state.sortedOrders.map(s => s.Id == action.payload.Id ? action.payload : s)
      }
    case "UPDATE_GROUP_ORDERS_TO_ONLY_NOT_READY":
      const filterToIsNotReady = action.payload;
      return {
        ...state,
        sortedOrders: filterToIsNotReady ? state.sortedOrders.filter(s => !s.IsReady) : state.initialedSortedOrders
      }

    case "CHANGE_GROUP_DELIVERY_STATUS":
      return { ...state }

    case "DELETE_GROUP_ORDER_BY_ID":
      return { ...state };
    case "SEND_GROUP_ORDER_EMAILS_OF_CHOSEN_ORDERS":
      return { ...state };

    default:
      return state;
  }
}
