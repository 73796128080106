import React from 'react'
import RoundedButton from '../RoundedButton/RoundedButton'
import './MakingOrdersBtn.scss'
const MakingOrdersBtn = ({ setShowLeftMenu, showLeftMenu }) => {
    return (
        <>
            {
                localStorage.getItem("token") &&
                <RoundedButton
                    className="making-orders-btn"
                    icon="fa fa-shopping-cart"
                    onClick={() => setShowLeftMenu(!showLeftMenu)}
                >
                </RoundedButton>
            }
        </>

    )
}

export default MakingOrdersBtn