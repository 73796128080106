import React, { useEffect, useState } from "react";
import FormFields from './FormFields'
import { useParams } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupByCode } from "../../../redux/groups/groups-actions";

import './form.scss'
import MyModal from "../../../components/MyModal";

const LoadForm = (props) => {

    let { groupCode, isAdminForm, deliveryId } = useParams();
    const lastReservationOfUser = JSON.parse(localStorage.getItem('lastReservationOfUser'));
    const [isThereAnyActiveReservation, setIsThereAnyActiveReservation] = useState(false)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (deliveryId && lastReservationOfUser && !isThereAnyActiveReservation) {
            if (deliveryId == lastReservationOfUser.GroupDeliveryId) {
                setIsThereAnyActiveReservation((true))
                setShowModal(true)
            }
        }
    }, [deliveryId, lastReservationOfUser])

    useEffect(() => {

        props.getGroupByCode(groupCode)

        //hide navbar and welcome img of site
        document.getElementById('myNav').style.display = 'none'
        document.getElementById('WelcomeImg').style.display = 'none';

        //hide footer of site
        for (let element of document.getElementsByClassName("footer")) {
            element.style.display = "none";
        }

    }, [groupCode]);


    useEffect(() => {
        console.log('isAdminForm', isAdminForm);
        if (isAdminForm == 1) {
            //hide navbar and welcome img of site
            document.getElementById('myNav').style.display = 'block'
        }
    }, [isAdminForm])

    return (
        <>
            {props.groups.length > 0 && (
                props.groups.map(dataOfGroup => {
                    return (
                        <div className='animated fadeIn'>
                            <div key={dataOfGroup.Code} className={`${isAdminForm == 1 ? "admin-form" : ""}`}>

                                <FormFields
                                    dataOfGroup={dataOfGroup}
                                    isThereAnyActiveReservation={isThereAnyActiveReservation}
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                />

                            </div>
                        </div>
                    )

                })
            )
            }
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroupByCode,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadForm);