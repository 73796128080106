const initialState = {
    groups: [],
    message: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_GROUPS":
            return { ...state, groups: action.payload };

        case "GET_GROUP_BY_CODE":
            return { ...state, groups: action.payload };

        case "ADD_GROUPS":
            return { ...state };

        case "UPDATE_GROUP":
            return { ...state };

        case "DELETE_GROUP":
            return { ...state };

        case "CHANGE_USER":
            return { ...state, user: action.payload };

        case "CREATE_USER":
            return { ...state };

        case "EDIT_USER":
            return { ...state };

        case "DELETE_USER":
            return { ...state, users: action.payload };

        case "CHANGE_USER_PASSWORD":
            return { ...state };

        case "GET_ADMIN_EMAILS":
            return { ...state, adminEmails: action.payload };

        case "ADD_ADMIN_EMAILS":
            state.adminEmails = [...state.adminEmails, ""];
            return { ...state };

        case "CHANGE_ADMIN_EMAIL":
            return {
                ...state,
                adminEmails: state.adminEmails.map((email, index) => {
                    return index == action.payload.index
                        ? action.payload.newEmail
                        : email;
                }),
            };

        case "DELETE_ADMIN_EMAIL":
            return {
                ...state,
                adminEmails: state.adminEmails.filter((e, i) => i !== action.payload),
            };

        case "GET_ADMIN_PHONES":
            return { ...state, adminPhones: action.payload };

        case "ADD_ADMIN_PHONES":
            state.adminPhones = [...state.adminPhones, ""];
            return { ...state };

        case "CHANGE_ADMIN_PHONE":
            return {
                ...state,
                adminPhones: state.adminPhones.map((phone, index) => {
                    return index == action.payload.index
                        ? action.payload.newPhone
                        : phone;
                }),
            };

        case "DELETE_ADMIN_PHONE":
            return {
                ...state,
                adminPhones: state.adminPhones.filter((e, i) => i !== action.payload),
            };

        case "SEND_FIRST_MESSAGE_CHECK":
            return { ...state, usersFirstMessageCount: action.payload };

        case "SEND_FIRST_MESSAGE":
            return { ...state, usersFirstMessageCount: action.payload };

        case "GET_CHOSEN_CSV_FILE":
            return { ...state, chosenCsvFile: action.payload };

        case "CHANGE_CHOSEN_CSV_FILE":
            return { ...state, chosenCsvFile: action.payload.newChosenCsvFile };

        case "RESET_CHOSEN_CSV_FILE": {
            return { ...state, chosenCsvFile: "" };
        }

        default:
            return state;
    }
}
