import React, { useEffect, useState } from 'react'
import './imageModal.css'
import '../../dist/css/gallery.css'

const ImageModal = ({ imageNumber, imageSrc, closedHref }) => {

    const [displayModal, setDisplayModal] = useState("block")
    const [imgZoom, setImgZoom] = useState(0)

    useEffect(() => {
        // document.getElementById(`modalImg${imageNumber}`).style.zoom = parseInt(2)
        setImgZoom(2)
        setDisplayModal("block")
    }, []);

    return (
        <div className="lightbox-target" id={imageNumber} style={{ display: displayModal }}>
            <div className='ril-inner ril__inner'>
                < div className="ril-toolbar ril__toolbar" >
                    < ul className="ril-toolbar-left ril__toolbarSide ril__toolbarLeftSide" >
                    </ul >
                    <ul className="ril-toolbar-right ril__toolbarSide ril__toolbarRightSide">
                        <li className=" ril-toolbar__item ril__toolbarItem">
                            {/* <a> */}
                            <i className='fa fa-plus imageModalIcons'
                                onClick={() => setImgZoom(3)}>
                            </i>
                            {/* </a> */}
                        </li>&nbsp;&nbsp;
                        <li className="ril-toolbar__item ril__toolbarItem">
                            {/* <a> */}
                            <i className='fa fa-minus imageModalIcons'
                                onClick={() => setImgZoom(2)}>
                            </i>
                            {/* </a> */}
                        </li>
                    </ul>
                </div >
            </div>
            <img src={imageSrc} className='img-fluid' id={`modalImg${imageNumber}`} style={{ zoom: imgZoom }} alt="vegtable" />
            <a className="lightbox-close" href={''} onClick={(e) => {
                e.preventDefault()
                setDisplayModal("none")
                // document.getElementById(imageNumber).style.display = "none"
            }} ></a>
        </div >
    )
}
export default ImageModal