import React, { } from 'react'
import { Link } from "react-router-dom";
import { MDBBtn } from 'mdbreact';

const UpdateBtn = (props) => {
    return (

        <div className="col-sm-4 text-center font-weight-bold" style={{ margin: 'auto' }}>
            <Link to={props.linkPath}>
                <MDBBtn rounded
                    className="text-center almogBtn"
                    outline color="secondary"
                >
                    <span>{props.headlineText}&nbsp;</span>
                    <i class="fas fa-plus-square fa-2x"></i>
                </MDBBtn>
            </Link >
        </div >

    )
}
export default UpdateBtn