
export const get_cart = () => {
    return (dispatch) => {
        // console.log('in getChart')
        dispatch({
            type: "GET_CART"
        });
        return Promise.resolve();
    }
};
export const get_cart_by_id = (id) => {
    return (dispatch) => {
        dispatch({
            type: "GET_CART_BY_ID",
            payload: id
        });
        return Promise.resolve();
    }
};
export const add_to_cart = (product) => {
    console.log('add', product)
    return (dispatch) => {
        dispatch({
            type: "ADD_TO_CART",
            payload: product
        });
        return Promise.resolve();
    }
};
export const update_cart_by_id = (product) => {
    // console.log('update function', product)
    // alert('Updated successfully')
    return (dispatch) => {
        dispatch({
            type: "UPDATE_CART_BY_ID",
            payload: product
        });
        return Promise.resolve();
    }
};
export const delete_cart_by_id = (id) => {
    // console.log('dalete', id)
    // alert('Deleted from shoping cart')
    return (dispatch) => {
        dispatch({
            type: "DELETE_CART_BY_ID",
            payload: id
        });
        return Promise.resolve();
    }
};
export const delete_cart = (id) => {
    // alert('Deleted all shoping cart')
    return (dispatch) => {
        dispatch({
            type: "DELETE_CART",
        });
        return Promise.resolve();
    }
};




