import React, { lazy, Suspense } from "react";
// import Carousel from './Carousel'
import logo from './img/image.png'
// import logo from './img/__almog.jpg'

import { Link, withRouter } from 'react-router-dom';

import "./css/nav.css";
// import ReservationBtn from './ReservationBtn'

import {
  MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse,
  MDBMask, MDBRow, MDBCol, MDBBtn, MDBView, MDBContainer, MDBAnimation
} from "mdbreact";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get_cart } from '../redux/cart/cart-actions'
import ContactIcons from "../pages/Contact/ContactIcons.js/ContactIcons";

const Carousel = lazy(() => import("./Carousel"));

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      userIslogged: false,
      userName: '',
      loginMessage: '',
      userName2: '',
      displayDropDownMenue: 'none'
    };
  }

  componentDidMount() {

    window.addEventListener("scroll", this.handleScroll);

    this.props.get_cart()
    // Get saved data from sessionStorage
    let data = sessionStorage.getItem('userName')
    this.setState({
      userName: data
    })

    if (localStorage.getItem('name') != null || sessionStorage.getItem('name') != null) {
      this.setState({
        userIslogged: true,
        userName: localStorage.getItem('name'),
        loginMessage: 'התנתק'
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 20) {
      document.getElementById('navLogo').style.width = '60px'
    }
    else {
      document.getElementById('navLogo').style.width = '80px'
    }
  };

  ClearLocalStorage = (e) => {

    // Remove all saved data from sessionStorage and localStorage
    sessionStorage.clear();
    localStorage.clear();

    if (this.state.loginMessage == 'התנתק')
      // this.props.history.push('/login')
      window.location = "/login";
    else
      // this.props.history.push('/login')
      window.location = "/login";

  }

  handleTogglerClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      displayDropDownMenue: 'none'
    });
    // event.target.className ='active'

  };
  closeHandler = (e) => {

    if (window.screen.width < 800) {
      // this.updateDisplayDropDownMenu()
      this.setState({
        collapsed: !this.state.collapsed,
        displayDropDownMenue: 'none'
      });
    }
    else
      this.setState({ displayDropDownMenue: 'none' })


    // console.log('nav Clicked',  document.getElementById('WelcomeImg'))
    window.scrollTo({ top: 0 });

    if (e.target.className.includes('home') !== false) {
      document.getElementById('WelcomeImg').style.display = 'block';
      document.getElementById('myNav').style.background = 'inherit';
    }
    else {

      document.getElementById('WelcomeImg').style.display = 'none';
      // document.getElementById('shopingCart').style.display = 'none';
      document.getElementById('myNav').style.background =
        'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,121,59,1) 68%, rgba(0,212,255,1) 100%)';
      document.getElementById('myNav').style.marginBottom = '50%';
      document.getElementById('myNav').style.boxShadow = 'none';
    }
  }


  updateDisplayDropDownMenu = () => {

    this.state.displayDropDownMenue === 'none' ?
      this.setState({ displayDropDownMenue: 'block' })
      : this.setState({ displayDropDownMenue: 'none' })

  }

  render() {

    const overlay = (
      <div
        id="sidenav-overlay"
        // style={{ backgroundColor: "transparent" }}
        onClick={this.handleTogglerClick}

      />
    );
    return (

      <div id="apppage">

        <div>

          <MDBNavbar
            id='myNav'
            // className='animated fadeIn'
            color='lime darken-4'
            dark
            expand="md"
            fixed="top"
            scrolling
            transparent
          >
            <MDBContainer>
              <MDBNavbarBrand>


                <MDBNavbarNav>
                  <MDBNavItem id='meshekHeadline'>
                    <MDBNavLink onClick={this.closeHandler} className='homeNav' to="/" style={{ fontWeight: 'bold' }}>
                      משק אלמוג
                    </MDBNavLink>
                  </MDBNavItem>

                  <MDBNavItem>
                    <img id='navLogo'
                      src={logo}
                      alt='logo'
                      style={{ transition: 'width 0.5s ease-in-out 0s' }}
                      onClick={() => this.props.history.push('/')}></img>
                  </MDBNavItem>

                  <img id='navLogoForSmallScreen' src={logo} alt='almog'></img>
                </MDBNavbarNav>
              </MDBNavbarBrand>

              {
                !this.state.collapsed ? <MDBNavbarToggler onClick={this.handleTogglerClick} />
                  : <div className='navbar-toggler '>
                    <i className="fas fa-times navCloseBtn" onClick={this.handleTogglerClick}></i>
                  </div>
              }

              <MDBCollapse isOpen={this.state.collapsed} navbar>

                <MDBNavbarNav right className='col-sm-6' style={{ justifyContent: 'center' }}>
                  <MDBNavItem>
                    <MDBNavLink className='navLinkItem homeNav' onClick={this.closeHandler} to="/" >בית
                      <i className="fas fa-angle-left navMobileIcon"></i>
                    </MDBNavLink>
                  </MDBNavItem>
                  <hr className='mobileHr' />

                  <MDBNavItem>
                    <MDBNavLink className='navLinkItem' onClick={this.closeHandler} id="about" to="/about">אודות
                      <i className="fas fa-angle-left navMobileIcon"></i>
                    </MDBNavLink>
                  </MDBNavItem>
                  <hr className='mobileHr' />

                  <MDBNavItem>
                    <MDBNavLink className='navLinkItem' onClick={this.closeHandler} to="/vegeatbles">העבודה במשק
                      <i className="fas fa-angle-left navMobileIcon"></i>
                    </MDBNavLink>
                  </MDBNavItem>
                  <hr className='mobileHr' />

                  <MDBNavItem>
                    <MDBNavLink className='navLinkItem' onClick={this.closeHandler} to="/reservation">הזמנה
                      <i className="fas fa-angle-left navMobileIcon"></i>
                    </MDBNavLink>
                  </MDBNavItem>
                  <hr className='mobileHr' />

                  <MDBNavItem>
                    <MDBNavLink className='navLinkItem' onClick={this.closeHandler} to="/gallery">גלריה
                      <i className="fas fa-angle-left navMobileIcon"></i>
                    </MDBNavLink>
                  </MDBNavItem>
                  <hr className='mobileHr' />

                  <MDBNavItem>
                    <MDBNavLink className='navLinkItem' onClick={this.closeHandler} to="/contact">צור קשר
                      <i className="fas fa-angle-left navMobileIcon"></i>
                    </MDBNavLink>
                  </MDBNavItem>
                  <hr className='mobileHr' />
                  {/* 
                  {
                    sessionStorage.getItem('name') || localStorage.getItem('name') ?
                      <>
                        <MDBNavItem >
                          <MDBNavLink className='navLinkItem' onClick={this.closeHandler} to="/admin">ניהול
                            <i className="fas fa-angle-left navMobileIcon"></i>
                          </MDBNavLink>
                        </MDBNavItem>
                        <hr className='mobileHr' />
                      </>
                      : ''
                  } */}
                  {
                    sessionStorage.getItem('name') || localStorage.getItem('name') ?
                      <div className="dropdown">
                        <MDBNavItem >
                          <MDBNavLink className='navLinkItem dropbtn'
                            onClick={(e) => window.screen.width > 800 ? this.closeHandler(e) : this.updateDisplayDropDownMenu()}
                            onMouseOver={() => window.screen.width > 800 && this.updateDisplayDropDownMenu()}
                            to="/admin">
                            ניהול&nbsp;
                            <i className="fa fa-caret-down"></i>
                            {/* <i className="fas fa-angle-left navMobileIcon"></i> */}
                          </MDBNavLink>
                          <div className="dropdown-content" style={{ display: this.state.displayDropDownMenue }}
                            onMouseLeave={() => window.screen.width > 800 && this.updateDisplayDropDownMenu()}

                          >

                            <Link to='/admin/groups-orders' onClick={this.closeHandler}>סיכום שבועי
                              <i className="fas fa-angle-left navMobileIcon"></i>
                            </Link>
                            <hr className='mobileHrOfDropDown' />

                            <Link to='/admin/deliveries' onClick={this.closeHandler}>טפסים
                              <i className="fas fa-angle-left navMobileIcon"></i>
                            </Link>
                            <hr className='mobileHrOfDropDown' />

                            <Link to='/admin/groups' onClick={this.closeHandler}>קבוצות
                              <i className="fas fa-angle-left navMobileIcon"></i>
                            </Link>
                            <hr className='mobileHrOfDropDown' />

                            <Link to='/admin/products' onClick={this.closeHandler}>מוצרים
                              <i className="fas fa-angle-left navMobileIcon"></i>
                            </Link>
                            <hr className='mobileHrOfDropDown' />

                            <Link to='/admin/reports' onClick={this.closeHandler}>דוחות
                              <i className="fas fa-angle-left navMobileIcon"></i>
                            </Link>

                          </div>
                        </MDBNavItem>
                        <hr className='mobileHr' />
                      </div>
                      : ''
                  }

                </MDBNavbarNav>

                <MDBNavbarNav className='col-sm-4'>
                  <MDBNavItem className='navLinkItem'>
                    {
                      sessionStorage.getItem('name') ?
                        <MDBNavLink id='userNameNav' onClick={this.closeHandler} to="">שלום {sessionStorage.getItem('name')}</MDBNavLink>
                        : localStorage.getItem('name') ?
                          <MDBNavLink id='userNameNav' onClick={this.closeHandler} to="">שלום {localStorage.getItem('name')}</MDBNavLink>
                          : ''
                    }
                  </MDBNavItem>

                </MDBNavbarNav>

                <MDBNavbarNav className='col-sm-2'>
                  <MDBNavItem>
                    <MDBNavLink onClick={this.ClearLocalStorage} to=""
                      style={{ margin: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                      {this.state.userIslogged ?
                        this.state.loginMessage
                        : 'התחבר'
                      }
                    </MDBNavLink>
                  </MDBNavItem>



                  {/* <span className="col-sm-2 navbar-brand" style={{ cursor: 'pointer' }} onClick={(e) => {
                    this.closeHandler(e)
                    this.props.history.push("/cart")

                  }
                  }>
                    <i className="fas fa-shopping-cart navItem"></i>
                    <span className="badge badge-pill red">{this.props.cart.length}</span>
                  </span> */}
                </MDBNavbarNav>

                <MDBNavbarNav className='col-sm-2 navLogoBottom'>
                  <MDBAnimation type="fadeIn" delay="0.5s">
                    <MDBNavItem>
                      <ContactIcons />

                    </MDBNavItem>
                  </MDBAnimation>
                </MDBNavbarNav>

              </MDBCollapse>
            </MDBContainer>
          </MDBNavbar>

          {this.state.collapsed && overlay}
        </div>

        <MDBView className='navHeader' id='WelcomeImg'>
          <MDBMask className="d-flex justify-content-center align-items-center gradient">
            <MDBContainer>
              <MDBRow>
                <MDBCol
                  md="6"
                  className="white-text text-center text-md-right mt-xl-5 mb-5 WelcomeImageCols"
                >
                  <MDBAnimation type={window.screen.width > 800 ? 'fadeInRight' : 'fadeIn'} delay=".3s">
                    <h1 className="h1-responsive font-weight-bold mt-sm-5">
                      משק אלמוג, מושב אחיטוב
                    </h1>
                    <br />
                    <h2 className="text-white"> מהחקלאי ישירות אליך לצלחת!</h2>

                    <hr className="hr-light" />

                    <h3 className="mb-4" style={{ fontSize: 'large', lineHeight: '1.7' }}>
                      הירקות שלנו נקטפים בבוקר ובערב כבר אצל הלקוחות!<br />
                      טרי מהחממה, ממויין וארוז.<br />
                      מלפפונים, עגבניות שרי ועוד..<br />
                      {/* על איכות לא מתפשרים */}
                    </h3>
                    <br /><br />
                    <div className='navButtonContainer'>
                      <MDBBtn outline color="white" className='navButton' href='#introduction'>
                        אודותינו&nbsp;&nbsp;
                        <i className="fas fa-info"></i>
                      </MDBBtn>

                      <MDBBtn color="white" className='navButton' href='#Reservation'>
                        הזמנה&nbsp;&nbsp;
                        <i className="fas fa-shopping-cart"></i>
                      </MDBBtn>
                    </div>

                  </MDBAnimation>
                </MDBCol>

                <MDBCol md="6" xl="5" className="mt-xl-5 WelcomeImageCols">
                  <MDBAnimation type="fadeInLeft" delay=".3s">
                    <Suspense fallback={<div></div>}>
                      <Carousel
                        headline=''
                        width='inherit'
                        height='550px'
                        price=''
                      />
                    </Suspense>
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div >
    );
  }
}
// export default Nav
const mapStateToProps = (state) => {
  return {
    cart: state.cartReducer.cart
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      get_cart,
    },
    dispatch
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav))
// export default (withRouter(Nav))
