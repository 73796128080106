import axios from "axios";

const Api = axios.create({
  // baseURL: "http://localhost:2000/",
  baseURL: "/api"
  // baseURL: "https://meshekalmog.co.il/api"
});

// const checkAuth = (status) => {
//   if (status === 401) {
//     // Auth.deleteAuthenticated();
//     window.location.href =
//       LoginBase +
//       "/#/signin?why=expiredAuth&to=dashboard&redirect=" +
//       history.location.pathname; //this.props.location.search
//   }
// };
// Api.interceptors.request.use(
//   function (config) {
//     if (!checkIEorEdge()) return config;

//     let url = config.url;
//     let parts = url.split("?");
//     config.url =
//       `${parts[0]}?ts=${new Date().getTime()}` +
//       (parts.length > 1 ? `&${parts[1]}` : "");
//     // Do something before request is sent
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// // Do something with response data
// Api.interceptors.response.use(
//   function (response) {
//     // Show errors
//     const { Success, ErrorCode, Message } = response.data;
//     if (!Success) {
//       store.dispatch({ type: "SHOW_ERROR", payload: { ErrorCode, Message } });
//     }
//     return response;
//   },
//   function (error) {
//     // Do something with response error
//     if (error && error.response) checkAuth(error.response.status);
//     return Promise.reject(error);
//   }
// );
export default Api;
