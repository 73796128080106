
import React from "react";
import '../../../dist/css/admin.css'
import SerachInout from '../../../components/SerachInout'
import UpdateBtn from '../../../components/UpdateBtn'
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUseresList } from "../../../redux/users/useres-actions";



class GetUseres extends React.Component {
    state = {
        groupNames: []
    };


    render() {

        return (
            <div className='firstDivAfterNav'>
                <h2 className='text-center'>משתמשים</h2>

                <div className='col-sm-6' style={{ margin: 'auto' }}>

                    <UpdateBtn
                        className='col-sm-4'
                        headlineText='משתמש חדש'
                        linkPath='/admin/users/create-users'
                    />

                </div>


                <SerachInout />

                <table className="table table-striped col-sm-8 text-center" border='1' style={{ margin: 'auto', tableLayout: 'fixed' }}>

                    <thead className="thead-dark">
                        <tr>
                            <th ></th>
                            <th>שם</th>
                            <th>שם משתמש</th>
                            <th>הרשאה</th>
                        </tr>
                    </thead>
                    {/* {this.props.deliveries.map((delivery, index) => {



                        let activeText = ''
                        let closedForm = {}



                        // if (this.state.groupNames[index] == delivery.GroupCode) {
                        let deliveryDate = new Date(delivery.DeliveryDateTime).toLocaleDateString('en-US', { hour12: false })
                        let deliveryDHour = new Date(delivery.DeliveryDateTime).toLocaleTimeString('en-US', { hour12: false })
                        console.log('delivery', index)
                        return (
                            <tbody>
                                <tr>
                                    <td>
                                        <a href={`/edit/${delivery.GroupCode}/${delivery._id}`}><i className="fas fa-edit"></i> עריכה</a>
                                        <br />
                                        <a href={`/groups/${delivery.GroupCode}/${delivery._id}`}><i className="fas fa-search-plus"></i> צפייה</a>
                                    </td>

                                    <td>{deliveryDate}</td>
                                    <td>{deliveryDHour}</td>
                                    <td><a href={`./groups/${delivery.GroupCode}`}>{ }</a></td>


                                </tr>
                            </tbody>
                        );

                        // }
                    }) */}


                    {/* } */}
                </table>











            </div >
        );
    }

    componentDidMount() {

        this.props.getUseresList()
        // console.log('users', this.state.users)






    }

}

const mapStateToProps = (state) => {
    return {
        users: state.usersReducer.users
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUseresList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GetUseres);
