import React, { useEffect, useState } from 'react'
import { MDBContainer, MDBCard, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact'
import EditorInput from '../../../components/EditorInput'
import { Link } from 'react-router-dom';
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendEmailToMuiltipleUsers } from "../../../redux/groupOrders/group-orders-action";

const Sendmail = (props) => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [names, setNames] = useState([])
    const [emails, setEmails] = useState([])

    const [usersObject, setUsersObject] = useState({})
    const [emailHeadline, setEmailHeadline] = useState("")
    const [htmlTemplate, setHtmlTemplate] = useState("")

    const sendEmail = (e) => {

        let myObject = {
            'Emails': usersObject.emails,
            'Subject': emailHeadline,
            'Body': htmlTemplate
        }
        console.log('myObject', myObject)
        props.sendEmailToMuiltipleUsers(myObject).then(() => {
            // console.log('Sent!')
            alert('המייל נשלח')
        }).catch(() => alert('אירעה שגיאה'))
    }

    const setInvitedMessege = () => {
        setEmailHeadline(" הזמנה לרכישה דרך האתר - משק אלמוג")
        // setEmail(usersObject.emails[0])
        usersObject.length > 0 ? setEmail(usersObject.emails[0]) : setEmail('asafalmog119@gmail.com')
        setHtmlTemplate(
            `<p>שלום ${usersObject.names[0]},<br/></p>         
                  </p>
                  <p>זוכרים את <b style = "color: green">המלפפונים </b> הפריכים<b style = 'color: red'> ועגבניות השרי </b> הטריות 
                   שקניתם ישירות מהחקלאי במהלך השנה האחרונה?
                  <br/>
                  אלה אנחנו :)<br/>
                נפגשנו בנסיבות הקורונה ונשמח להיפגש שוב.</p>
                   <p><b> אנו מזמינים אותך לחזור לרכוש ירקות אצלנו והפעם - ישירות באתר.</b></p>
                    <p>ההזמנות יחולקו ישירות עד הבית!</p>
                    <a href="https://www.meshekalmog.co.il">לפרטים נוספים לחץ/י כאן</a>
                    <p>
                    תודה, 
                     <br/>
                    משק אלמוג
                    <p/>
                    <a href = 'https://www.meshekalmog.co.il'> <img src='https://meshekalmog.co.il/static/media/almog.2cf9a1e4.PNG' width='200' height='200' /></a>
                    <small><a href="/emails/removed/${usersObject.emails[0]}">להסרה מרשימת התפוצה</a><small/>
                    `
        )
    }
    const setActionsFromSelected = (e) => {
        if (e.target.value == 0) {
            setHtmlTemplate("")
            setEmailHeadline("")
        }
        else if (e.target.value == 1) {
            setInvitedMessege()

        }
    }
    const parentCallbackOfHtmlHeaderInput = (childData) => {

        setHtmlTemplate(childData)
    }
    const submitForm = (e) => {
        e.preventDefault()
    }
    return (
        <div className='container-fluid'>
            <MDBContainer className="firstDivAfterNav text-center">
                <MDBCard>
                    <h2 className="text-center font-weight-bold">שליחת מייל למשתמשים</h2>
                    <Link to='/admin/users'>חזרה למשתמשים</Link>
                    <MDBRow>
                        <MDBCol>
                            <form className='col-lg-6 col-sm-12' onSubmit={e => submitForm(e)}>
                                {/* {console.log('usersObject', usersObject)} */}

                                <h4>בחירת הודעה</h4>
                                <select className='form-control' onChange={(e) => setActionsFromSelected(e)}>
                                    <option value="0">בחר/י</option>
                                    <option value="1">הזמנה לרכישה באתר</option>
                                    {/* <option value="2">תזכורת לתשלום</option>
                                    <option value="3">תזכורת להזמנה</option>
                                    <option value="4">ביטול חלוקה</option> */}
                                </select>

                                <MDBInput id='email' label="* דואר אלקטרוני (לקבלת ההזמנה למייל)" value={email} onChange={e => setEmail(e.target.value)}
                                    required icon="envelope" group type="email"
                                    error="wrong" name="user_email" validate
                                    success="right" />


                                <MDBInput id='emailHeadline' label="כותרת המייל" value={emailHeadline} onChange={e => setEmailHeadline(e.target.value)}
                                    required icon="user" group type="text" validate
                                    error="wrong" name="user_firstname"
                                    success="right" />

                                <h4>גוף ההודעה</h4>
                                <EditorInput
                                    id="editorHtml"
                                    initialValue={htmlTemplate}
                                    parentCallback={parentCallbackOfHtmlHeaderInput}
                                />

                                <MDBBtn
                                    rounded outline color="secondary"
                                    variant="primary" type="submit" value="Send" validate
                                    onClick={(e) => sendEmail(e)}
                                > שליחה</MDBBtn>


                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {

        orders: state.groupOrdersReducer.orders,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            sendEmailToMuiltipleUsers
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sendmail);
