import { MDBRow, MDBCol } from 'mdbreact'
import React, { useEffect } from 'react'
import Form from '../Reservation/Form'

//redux
import { useSelector } from 'react-redux'


const CartStep2 = (props) => {

    //redux STATE
    let onlineDeliveryState = useSelector((state) => state.onlineDeliveriesReducer.onlineDeliveries)

    useEffect(() => {
        window.scroll(0, 0)
        console.log('onlineDeliveryState', onlineDeliveryState)
    })
    return (
        <>
            {/* <div className='col-sm-12 stepHeadline'>
                <div className='col-lg-3 col-sm-12' style={{ margin: 'auto' }}>
                    <h3 className='text-center'>פרטים אישיים</h3>
                    <p>אנא מלא/י מטה את פרטיך האישיים.</p>
                    <p>להמשך לחצו מטה על <b>"הבא"</b>.</p>
                </div>
            </div> */}
            <MDBRow className='stepHeadline'>
                <MDBCol lg='3' sm='6' className='text-right' style={{ margin: 'auto' }}>
                    <button className="btn text-center almogBtn cartBtnRight"
                        type='button'
                        onClick={() => props.setActiveStep(0)}
                    >
                        <i className="fas fa-arrow-alt-circle-right fa-2x"></i>
                        הקודם&nbsp;
                    </button>
                </MDBCol>
                <MDBCol lg='6' sm='12' className='text-center'>
                    <h3 className='text-center'>פרטים אישיים</h3>
                    <p>אנא מלא/י מטה את פרטיך האישיים.</p>
                    <p>לחצו על<b>"הבא"</b> להמשך.</p>
                </MDBCol>
                <MDBCol lg='3' sm='6' className='text-left' style={{ margin: 'auto' }}>
                    <button className="btn text-center almogBtn cartBtnLeft"
                        form='reservationForm'
                        // onClick={() => props.setActiveStep(2)}
                        type='submit'
                    >הבא&nbsp;
                        <i className="fas fa-arrow-alt-circle-left fa-2x"></i>
                    </button>
                </MDBCol>

            </MDBRow>
            <Form
                personDetails={props.personDetails}
                setActiveStep={props.setActiveStep}
                setcheckedStep2={props.setcheckedStep2}
            />
        </>
    )
}
export default CartStep2