import React, { useEffect, useState } from 'react'
import './css/footer.css'

const NavigationBtn = () => {

    const [displayButton, setDisplayButton] = useState('none')

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [])

    const handleScroll = () => {
        window.scrollY > 300 ? setDisplayButton('block') : setDisplayButton('none')
    };

    return (
        <>
            <a className="animated fadeIn btn-floating btn-lg"
                style={{ display: displayButton, color: 'black' }}
                onClick={() => window.scroll(0, 0)}>
                <i className="fa fa-arrow-alt-circle-up"></i>
            </a>
        </>
    )
}
export default NavigationBtn