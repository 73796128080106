
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GetLocalDayAndHour from '../../../components/GetLocalDayAndHour'
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupDeleveriesByCode } from "../../../redux/deliveries/deliveries-actions";
import { getGroupByCode } from "../../../redux/groups/groups-actions";

const GroupDeliveries = (props) => {

    // let location = useLocation(); //load groupName from previus page
    const [displayFroupName, SetDisplayFroupName] = useState("none")
    let { groupCode } = useParams();
    let counter = 0
    const history = useHistory()

    useEffect(() => {

        props.getGroupByCode(groupCode)
        props.getGroupDeleveriesByCode(groupCode)

        setTimeout(() => {
            SetDisplayFroupName("block")
        }, 100);
        clearTimeout()


    }, [groupCode]);

    const rowClicked = (link) => {
        history.push(link)
    }
    const loadDeliveriesOfGroup = () => props.deliveries.sort((a, b) =>
        new Date(b.DeliveryDateTime) - new Date(a.DeliveryDateTime)).map(delivery => {
            if (counter != new Date(delivery.DeliveryDateTime).getMonth())
                counter++
            // console.log('DeliveryDateTime', new Date(delivery.DeliveryDateTime).getMonth())
            let groupDate = new Date(delivery.DeliveryDateTime).toLocaleString('en-US', { hour12: false })
            let activeForm = 'לא פעיל'
            let month = new Date(delivery.DeliveryDateTime).getMonth() + 1
            if (delivery.IsActive) {
                activeForm = 'פעיל'
            }
            return (
                <>
                    <tr key={delivery._id} onClick={() => rowClicked(`/admin/deliveries/groups-orders/${groupCode}/${delivery._id}`)}>

                        <td>
                            <GetLocalDayAndHour
                                style={{ display: 'inline' }}
                                dateOfDelivery={groupDate}
                            />
                        </td>
                        <td>
                            {
                                delivery.Places != null &&
                                delivery.Places.map((place, index) => {
                                    return <span key={index}>
                                        {
                                            (delivery.Places.length !== index && index !== 0) &&
                                            ' | '
                                        }
                                        <GetLocalDayAndHour
                                            hourOfDelivery={place.Hour}
                                        />
                                    </span>
                                })
                            }
                        </td>
                        <td>
                            {activeForm}
                        </td>
                        <td>{delivery.Revenue} ₪</td>

                    </tr>
                </>
            );
        })

    const loadGroupName = () => props.groups.map(group => {

        return <h2 key={group.Code} style={{ display: displayFroupName }} className='text-center font-weight-bold'>טפסים של {group.Name} </h2>
    })
    return (

        <div className='container-fluid firstDivAfterNav'>
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="12">
                        <MDBCard>
                            <MDBCardBody>
                                {
                                    props.deliveries.length > 0 ?
                                        <>
                                            {loadGroupName()}
                                            <table id="dataTable" className="table table-striped col-sm-12 text-center" border='1' style={{ margin: 'auto', tableLayout: 'fixed', textAlign: 'center' }}>
                                                <thead className="thead-dark">
                                                    <tr className="header">
                                                        {/* <th></th> */}
                                                        <th>תאריך</th>
                                                        <th>שעה</th>
                                                        <th>סטאטוס</th>
                                                        <th>סה"כ הכנסות</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loadDeliveriesOfGroup()}
                                                </tbody>
                                            </table>
                                        </>

                                        :
                                        <div style={{ padding: '150px' }}>
                                            <h2 className='text-center font-weight-bold'>לא קיימים נתונים </h2>
                                        </div>
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div >
    )

}
const mapStateToProps = (state) => {
    return {
        deliveries: state.deliveriesReducer.deliveries,
        groups: state.groupReducer.groups
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroupDeleveriesByCode,
            getGroupByCode
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupDeliveries);