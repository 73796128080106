import React, { useEffect, useState } from "react"
import "./switch.scss"
import Form from 'react-bootstrap/Form';

const Switch = ({
  func,
  defaultChecked,
  id,
  label,
  isDisabled,
  ...props
}) => {

  return (
    <div className="form-switch flex">
      <Form.Check
        type="switch"
        id={`custom-switch${label}`}
        label={label}
        checked={defaultChecked}
        onClick={func}
        onChange={() => { }}
      />
    </div>
  )
}

export default Switch

Switch.defaultProps = {
  id: "",
  isDisabled: false,
}
