import React, { Fragment } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer, } from "mdbreact";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProducts } from "../../../redux/products/products-actions"

class Statistics extends React.Component {
    state = {
        disabledBtn: '',
        inputFields: [{ Id: '', Name: '', Src: '', Units: '', Weight: '', Price: '', Expenses: '', Record: '', Total: '', Total_Taklit: '', Total_Difference: '' }],
        inputFieldsExternalProducts: [{ Id: '', Name: '', Src: '', Units_Bought: '', Price_Bought: '', Units_Sold: '', Price_Sold: '', Total: '' }],
        counter: 0,
        isLoaded: false,
        totalOfOurProducts: 0,
        totalOfMarket: 0,
        totalOfOurProductsExpenses: 0,
        totalOfExternalProducts: 0,
        totalOfExternalProductsRevenues: 0,
        totalOfExternalProductsExpenses: 0,
        totalOfAll: 0,
        total_Difference: 0
    }

    ResetInputs = (index, event, id) => {

        const values = [...this.state.inputFields];

        values[index].Units = 0
        // values[index].Expenses = 0
        values[index].Record = 0
        values[index].Total_Difference = 0
        values[index].Total = ((values[index].Units * values[index].Price) - (values[index].Expenses))

        let price = 0
        let marketPrice = 0
        let totalExpenses = 0
        let totalOfDifference = 0

        values.map(value => {
            price += value.Total
            totalOfDifference += (value.Record * value.Units)
            if (value.Name == "מלפפונים")
                marketPrice += ((value.Record / 100) * 150) * value.Units
            else
                marketPrice += value.Units * value.Record
        })
        if (price != 0)
            totalExpenses = 1000

        document.getElementById(`${id}`).classList.remove("activeProduct")

        this.setState({
            inputFields: values,
            totalOfOurProducts: price,
            totalOfMarket: marketPrice,
            totalOfAll: price + this.state.totalOfExternalProducts,
            totalOfOurProductsExpenses: totalExpenses,
            total_Difference: totalOfDifference
        })

        // console.log(this.state.inputFields)
    };

    handleInputChange = (index, event, id) => {

        const values = [...this.state.inputFields];
        if (event.target.name === "Name") {
            values[index].Name = event.target.value;
        }
        else if (event.target.name === 'Units') {
            values[index].Units = event.target.value
        }
        else if (event.target.name === 'Weight') {
            values[index].Weight = event.target.value
        }
        else if (event.target.name === 'Price') {
            values[index].Price = event.target.value;
        }
        // else if (event.target.name === 'Expenses') {
        //     values[index].Expenses = event.target.value;
        // }
        else if (event.target.name === 'Record') {
            values[index].Record = event.target.value;
        }
        values[index].Total = ((values[index].Units * values[index].Price) - (values[index].Expenses))

        let taklit = 0
        if (values[index].Name == "שרי כתום" || values[index].Name == "שרי צהוב" || values[index].Name == "שרי ליקופן")
            taklit += (values[index].Record / 2) * values[index].Units
        else
            taklit += values[index].Units * values[index].Record

        values[index].Total_Taklit = taklit

        let price = 0
        let marketPrice = 0
        let totalOfDifference = 0
        values.map(value => {
            price += value.Total
            totalOfDifference += (value.Record * value.Units)

            if (value.Name == "שרי כתום" || value.Name == "שרי צהוב" || value.Name == "שרי ליקופן")
                marketPrice += (value.Record / 2) * value.Units
            else
                marketPrice += value.Units * value.Record
        })


        values[index].Total_Difference = values[index].Total - taklit

        if (price > 0)
            document.getElementById(`${id}`).classList.add("activeProduct")
        else
            document.getElementById(`${id}`).classList.remove("activeProduct")

        this.setState({
            inputFields: values,
            totalOfOurProducts: price,
            totalOfMarket: marketPrice,
            totalOfAll: price + this.state.totalOfExternalProducts,
            totalOfOurProductsExpenses: 1000,
            total_Difference: totalOfDifference
        })

        console.log(this.state.inputFields)
    };

    ResetInputsExternalProducts = (index, event, id) => {

        //   {  Name: '', Units_Bought: '', Price_Bought: '', Units_Sold: '', Price_Sold: '', Total: '' }

        const values = [...this.state.inputFieldsExternalProducts];


        values[index].Units_Bought = 0
        values[index].Price_Bought = 0
        values[index].Units_Sold = 0

        values[index].Total = ((values[index].Units_Sold * values[index].Price_Sold) - (values[index].Units_Bought * values[index].Price_Bought))

        let price = 0
        let totalExpenses = 0
        let totalRevenu = 0

        values.map(value => {
            price += value.Total
            totalExpenses += (value.Units_Bought * value.Price_Bought)
            totalRevenu += (value.Units_Sold * value.Price_Sold)
        })

        document.getElementById(`${id}`).classList.remove("activeProduct")

        this.setState({
            inputFieldsExternalProducts: values,
            totalOfExternalProducts: price,
            totalOfAll: this.state.totalOfOurProducts + price,
            totalOfExternalProductsExpenses: totalExpenses,
            totalOfExternalProductsRevenues: totalRevenu,
        })

        // console.log(this.state.inputFields)
    };
    handleInputChangeExternalProducts = (index, event, id) => {

        //   {  Name: '', Units_Bought: '', Price_Bought: '', Units_Sold: '', Price_Sold: '', Total: '' }

        const values = [...this.state.inputFieldsExternalProducts];

        if (event.target.name === "Name") {
            values[index].Name = event.target.value;
        }
        else if (event.target.name === 'Units_Bought') {
            values[index].Units_Bought = event.target.value
        }
        else if (event.target.name === 'Price_Bought') {
            values[index].Price_Bought = event.target.value
        }
        else if (event.target.name === 'Units_Sold') {
            values[index].Units_Sold = event.target.value;
        }
        else if (event.target.name === 'Price_Sold') {
            values[index].Price_Sold = event.target.value;
        }
        values[index].Total = ((values[index].Units_Sold * values[index].Price_Sold) - (values[index].Units_Bought * values[index].Price_Bought))

        let price = 0
        let totalExpenses = 0
        let totalRevenu = 0
        values.map(value => {
            price += value.Total
            totalExpenses += (value.Units_Bought * value.Price_Bought)
            totalRevenu += (value.Units_Sold * value.Price_Sold)
        })

        if (values[index].Total > 0)
            document.getElementById(`${id}`).classList.add("activeProduct")
        else
            document.getElementById(`${id}`).classList.remove("activeProduct")

        //set total of all products
        if (price > 0 && values[index].Units_Sold !== 0)
            this.setState({
                totalOfExternalProducts: price,
                totalOfAll: this.state.totalOfOurProducts + price,
            })

        this.setState({
            inputFieldsExternalProducts: values,
            totalOfExternalProductsExpenses: totalExpenses,
            totalOfExternalProductsRevenues: totalRevenu

        })

        console.log(this.state.inputFieldsExternalProducts)
    };



    loadProductsOurProducts = () => {

        return this.state.inputFields.map((inputField, index) => (
            <Fragment key={inputField.Id}>
                <div className="text-center" id={inputField.Id}>
                    <img className="img-responsive cellHiddenInMobile"
                        src={`../${inputField.Src}`} alt="prewiew" width={120} height={80}>
                    </img>

                    <div className="form-group col-sm-1 statistics">
                        {/* <h6 htmlFor="Address">מוצר</h6> */}
                        <p>{inputField.Name}</p>
                    </div>
                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Hour">יחידות שנמכרו</h6>
                        <input
                            type="number"
                            className="form-control"
                            // id="Weight"
                            name="Units"
                            value={inputField.Units}
                            onChange={event => this.handleInputChange(index, event, inputField.Id)}
                        />
                    </div>
                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Duration">מחיר</h6>
                        <input
                            type="number"
                            disabled
                            className="form-control"
                            // id="Price"
                            name="Price"
                            value={inputField.Price}
                            onChange={event => this.handleInputChange(index, event, inputField.Id)}
                        />
                    </div>
                    <span class="verticalLine"></span>

                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Duration">סה"כ בקבוצות</h6>
                        <input
                            type="text"
                            className="form-control bg-primary text-white"
                            // id="Total"
                            name="Total"
                            value={inputField.Total + " ₪"}
                            onChange={event => this.handleInputChange(index, event, inputField.Id)}
                        />
                    </div>
                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Duration">מחיר שוק</h6>
                        <input
                            type="number"
                            className="form-control"
                            // id="Record"
                            name="Record"
                            value={inputField.Record}
                            onChange={event => this.handleInputChange(index, event, inputField.Id)}
                        />
                    </div>
                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Hour">ק"ג ליחידה </h6>
                        <input
                            type="number"
                            disabled
                            className="form-control"
                            // id="Weight"
                            name="Weight"
                            value={inputField.Weight}
                            onChange={event => this.handleInputChange(index, event, inputField.Id)}
                        />
                    </div>
                    <span class="verticalLine"></span>

                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Duration">סה"כ בשוק</h6>
                        <input
                            type="text"
                            className="form-control bg-primary text-white"
                            // id="Total"
                            name="Total_Taklit"
                            value={inputField.Total_Taklit + " ₪"}
                            onChange={event => this.handleInputChange(index, event, inputField.Id)}
                        />
                    </div>

                    <div className="form-group col-sm-2 statistics">
                        <h6 htmlFor="Duration">סה"כ הפרש</h6>
                        <input
                            type="text"
                            className="form-control bg-info text-white"
                            // id="Total"
                            name="Total"
                            value={inputField.Total_Difference + " ₪"}
                            onChange={event => this.handleInputChange(index, event, inputField.Id)}
                        />
                    </div>
                    {
                        inputField.Total > 0 &&
                        <button className="rounded-button" onClick={event => this.ResetInputs(index, event, inputField.Id)}>
                            <i className="fas fa-undo myCheckbox"></i>
                        </button>
                    }

                </div>
                <hr />
            </Fragment>
        ))

    }

    loadProductsExternalProducts = () => {

        return this.state.inputFieldsExternalProducts.map((inputField, index) => (
            <Fragment key={inputField.Id}>
                <div className="text-center" id={inputField.Id}>
                    <img className="img-responsive cellHiddenInMobile"
                        src={`../${inputField.Src}`} alt="prewiew" width={120} height={80}>
                    </img>
                    <div className="form-group col-sm-1 statistics">
                        <p>{inputField.Name}</p>
                    </div>
                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Hour">יחידות שנקנו</h6>
                        <input
                            type="number"
                            className="form-control"
                            // id="Weight"
                            name="Units_Bought"
                            value={inputField.Units_Bought}
                            onChange={event => this.handleInputChangeExternalProducts(index, event, inputField.Id)}
                        />
                    </div>
                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Hour">מחיר קנייה</h6>
                        <input
                            type="number"
                            className="form-control"
                            // id="Weight"
                            name="Price_Bought"
                            value={inputField.Price_Bought}
                            onChange={event => this.handleInputChangeExternalProducts(index, event, inputField.Id)}
                        />
                    </div>
                    <span class="verticalLine"></span>
                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Duration">סך הוצאות</h6>
                        <input
                            type="text"
                            className="form-control bg-primary text-white"
                            // id="Total"
                            value={inputField.Price_Bought * inputField.Units_Bought + " ₪"}
                            onChange={event => this.handleInputChangeExternalProducts(index, event, inputField.Id)}
                        />
                    </div>
                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Duration">יחידות שנמכרו</h6>
                        <input
                            type="number"
                            className="form-control"
                            // id="Price"
                            name="Units_Sold"
                            value={inputField.Units_Sold}
                            onChange={event => this.handleInputChangeExternalProducts(index, event, inputField.Id)}
                        />
                    </div>

                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Duration">מחיר מכירה</h6>
                        <input
                            type="number"
                            disabled
                            className="form-control"
                            // id="Expenses"
                            name="Price_Sold"
                            value={inputField.Price_Sold}
                            onChange={event => this.handleInputChangeExternalProducts(index, event, inputField.Id)}
                        />
                    </div>

                    <div className="form-group col-sm-1 statistics">
                        <h6 htmlFor="Duration">סך הכנסות</h6>
                        <input
                            type="text"
                            className="form-control bg-primary text-white"
                            // id="Total"
                            value={inputField.Price_Sold * inputField.Units_Sold + " ₪"}
                            onChange={event => this.handleInputChangeExternalProducts(index, event, inputField.Id)}
                        />
                    </div>
                    <span class="verticalLine"></span>
                    <div className="form-group col-sm-2 statistics">
                        <h6 htmlFor="Duration">סה"כ רווח</h6>
                        <input
                            type="text"
                            className="form-control bg-info text-white"
                            // id="Total"
                            name="Total"
                            value={inputField.Total + " ₪"}
                            onChange={event => this.handleInputChangeExternalProducts(index, event, inputField.Id)}
                        />
                    </div>

                    {
                        inputField.Total > 0 &&
                        <button className="rounded-button" onClick={event => this.ResetInputsExternalProducts(index, event, inputField.Id)}>
                            <i className="fas fa-undo myCheckbox"></i>
                        </button>
                    }
                </div>
                <hr />
            </Fragment>
        ))

    }

    render() {
        return (
            <div className='container-fluid firstDivAfterNav'>
                {/* <MDBContainer className="firstDivAfterNav text-center"> */}
                <h2 className="text-center">מחשבון הכנסות</h2>
                <div className="text-right">

                    {
                        this.state.isLoaded &&
                        <>

                            <MDBRow>
                                <MDBCol>
                                    <MDBCard>
                                        <MDBCardBody className="text-center">
                                            <h3>המוצרים שלנו</h3>
                                            {this.loadProductsOurProducts()}


                                            <div style={{ backgroundColor: '#e9ecef' }}>
                                                <Tabs>
                                                    <TabList>
                                                        <Tab>הכנסות בקבוצות</Tab>
                                                        <Tab>הכנסות בשוק</Tab>
                                                        <Tab>השוואה</Tab>
                                                    </TabList>

                                                    <TabPanel>

                                                        <h3>סך הכנסות בקבוצות: {this.state.totalOfOurProducts} ₪</h3>
                                                        <p className="borderLine">&nbsp;</p>
                                                        <h3>סך הוצאות קבועות: {this.state.totalOfOurProductsExpenses} ₪</h3>
                                                        {
                                                            this.state.totalOfOurProductsExpenses > 0 &&
                                                            <>
                                                                <p className="borderLine">&nbsp;</p>
                                                                <h3 className="text-primary font-weight-bold">רווח נקי: {this.state.totalOfOurProducts - this.state.totalOfOurProductsExpenses} ₪</h3>
                                                            </>

                                                        }
                                                        <h5 className="borderLine">&nbsp;</h5>

                                                    </TabPanel>
                                                    <TabPanel>
                                                        {
                                                            this.state.totalOfMarket > 0 ?
                                                                <div>
                                                                    <h3>סך הכנסות אופציונאליות בשוק: {this.state.totalOfMarket} ₪</h3>
                                                                    <p className="borderLine">&nbsp;</p>
                                                                    <h3>סך הוצאות קבועות: {this.state.totalOfOurProductsExpenses} ₪</h3>
                                                                    <p className="borderLine">&nbsp;</p>
                                                                    <h3 className="text-primary font-weight-bold">רווח נקי: {this.state.totalOfMarket - this.state.totalOfOurProductsExpenses} ₪</h3>

                                                                </div>
                                                                : <p>אנא הכנס מחיר שוק למוצרים</p>
                                                        }
                                                    </TabPanel>
                                                    <TabPanel>

                                                        <h3>סך הכנסות בקבוצות: {this.state.totalOfOurProducts} ₪</h3>
                                                        <h5 className="borderLine">&nbsp;</h5>
                                                        {/* <h2 className="font-weight-bold">סה"כ רווח נקי מכל המוצרים {this.state.totalOfAll} ₪</h2> */}
                                                        <h3>סך הכנסות אופציונאליות בשוק: {this.state.totalOfMarket} ₪</h3>
                                                        <p className="borderLine">&nbsp;</p>
                                                        <h2>הפרש בין סך הקבוצות לסך השוק: {this.state.totalOfOurProducts - this.state.totalOfMarket} ₪</h2>
                                                        <p className="borderLine">&nbsp;</p>
                                                    </TabPanel>
                                                </Tabs>

                                            </div>

                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <MDBRow>
                                <MDBCol>
                                    <MDBCard>
                                        <MDBCardBody className="text-center">
                                            <h3>מוצרים שנקנו</h3>
                                            {this.loadProductsExternalProducts()}

                                            <div style={{ backgroundColor: '#e9ecef' }}>
                                                <h3>סך הכנסות ממוצרים שקנינו: {this.state.totalOfExternalProductsRevenues} ₪</h3>
                                                <p className="borderLine">&nbsp;</p>
                                                <h3>סך הוצאות על מוצרים שקנינו: {this.state.totalOfExternalProductsExpenses} ₪</h3>
                                                <p className="borderLine">&nbsp;</p>
                                                <h3 className="text-primary font-weight-bold">רווח נקי: {this.state.totalOfExternalProducts} ₪</h3>
                                                <h5 className="borderLine">&nbsp;</h5>
                                                <h2 className="font-weight-bold">סה"כ רווח נקי מכל המוצרים {this.state.totalOfAll - this.state.totalOfOurProductsExpenses} ₪</h2>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>

                        </>
                    }
                </div>

                {/* </MDBContainer> */}
            </div >
        )
    }


    componentDidMount() {

        document.title = "משק אלמוג - מחשבון"
        let inputPlaces = []
        let externalProducts = []
        this.props.getProducts().then(() => {

            this.props.prodcuts.map(product => {
                let str = product.Weight;
                let res = str.split(" ");

                console.log("first product", product)
                if (product.InStock) {
                    if (product.TypeCode > 1 && product.TypeCode < 8) {
                        return inputPlaces.push({
                            "Id": product._id,
                            "Name": product.ShortName,
                            "Src": product.ReservationImage,
                            "Units": 0,
                            "Weight": res[0],
                            "Price": product.Price,
                            "Expenses": 0,
                            "Record": 0,
                            "Total_Taklit": 0,
                            "Total": 0,
                            "Total_Difference": 0
                        })
                    }
                    else {
                        let price = product.Price
                        //if it's peppers divide the price
                        if (product.Code == 10)
                            price = price / 2

                        externalProducts.push({
                            "Id": product._id,
                            "Name": product.ShortName,
                            "Src": product.ReservationImage,
                            "Units_Bought": 0,
                            "Price_Bought": 0,
                            "Units_Sold": 0,
                            "Price_Sold": price,
                            "Total": 0
                        })
                    }
                }


            })
            this.setState({ inputFields: [...inputPlaces], inputFieldsExternalProducts: [...externalProducts], isLoaded: true })
        })

    }
}

const mapStateToProps = (state) => {
    return {
        prodcuts: state.productReducer.products

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProducts
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
