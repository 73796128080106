import React, { Component } from "react";
import "./tooltip.css";

class Tooltip extends Component {

  render() {
    return (
      <div className="tooltip2">
        {this.props.children}
        <span className="tooltiptext2">{this.props.text}</span>
      </div>
    );
  }
}

export default Tooltip;
