import React from "react";
import logo from '../../../components/img/imageForForm.png'
import "../../../components/css/nav.css";
import "./formNav.css"

import { MDBMask, MDBRow, MDBCol, MDBBtn, MDBView, MDBAnimation } from "mdbreact";
import FormLottie from "../../../components/FormLottie/FormLottie";

class FormNav extends React.Component {

    render() {

        return (

            <div id="apppage">

                <MDBView className='navHeader' id='WelcomeImg2'>

                    <MDBMask className="d-flex justify-content-center align-items-center gradient">
                        {/* <MDBContainer> */}
                        <MDBRow>
                            <MDBCol
                                style={{ margin: 'auto', padding: '0' }}
                                md="12"
                                className="white-text text-center text-md-right mt-xl-5 mb-5 WelcomeImageCols"
                            >
                                <MDBAnimation type="fadeIn" delay=".5s" className="text-center">

                                    <img className='text-center' id='navFormLogo' src={logo} alt='logo' style={{ margin: 'auto' }}></img>
                                    <FormLottie />
                                    <h1 id="navHeadline" className="h1-responsive font-weight-bold" style={{ textAlign: 'center', paddingRight: '0' }}>
                                        הזמנת ירקות ממשק אלמוג&nbsp;
                                        <br class="mobile-break" />
                                        ל{this.props.groupName} 🍅🥒
                                    </h1>

                                    {this.props.deliveryAdress}
                                    {this.props.deliveryDate}
                                    {this.props.deliveryPlaces}

                                    <hr className="hr-light" />
                                    <div className='text-center'>
                                        <h3 className='text-center' style={{ paddingRight: '0' }}> מהחקלאי ישירות אליך לצלחת!</h3>

                                        <MDBBtn outline color="white"
                                            style={{ borderRadius: '5px' }}
                                            href="#formContainer"
                                            className="font-weight-bold"
                                        >
                                            התחלה&nbsp;
                                            <i className="far fa-arrow-alt-circle-down fa-lg"></i>
                                        </MDBBtn>
                                    </div>
                                </MDBAnimation>
                            </MDBCol>
                            <MDBAnimation type="fadeIn" delay=".8s" className="text-center" id="arrowDownA">
                                <a href="#formContainer">
                                    <i className="arrow bounce text-center fas fa-angle-down"></i>
                                </a>
                            </MDBAnimation>
                        </MDBRow>

                    </MDBMask>
                </MDBView>
            </div >
        );
    }
}
export default FormNav
