// import axios from "axios";
import Api from '../../utils/Api'

let token = "";

if (localStorage.getItem("token"))
    token = localStorage.getItem("token");
else if (sessionStorage.getItem("token"))
    token = sessionStorage.getItem("token");


export const addOnlineOrder = async (objectPost) => {
    const res = await Api.post(`onlineOrders/add/`, objectPost, {
        headers: { Authorization: `Bearer ${token}` },
    })
    if (res.request.status == 200) {
        // alert('הצלחה')
        return {
            type: "ADD_ONLINE_ORDER",
            payload: res.data.Payload,
        };
    } else {
        alert('אירעה שגיאה')
        return {
            type: "ADD_ONLINE_ORDER_FAILED",
        };
    }
};
// export const addOnlineOrder = (objectPost) => {
//     return async (dispatch) => {

//         return await Api.post(`onlineOrders/add/`, objectPost, {
//             headers: { Authorization: `Bearer ${token}` },
//         })
//             .then((res) => {
//                 // console.log('res from server', res)
//                 dispatch({
//                     type: "ADD_ONLINE_ORDER",
//                     payload: res.data.Payload,
//                 });
//             })
//             .catch((e) => {
//                 // alert('error')
//                 console.log(e);
//             });

//     }
// };


export const getOnlineOrdersByGroupDeliveryId = (deliveryId) => {
    return (dispatch) => {
        return Api
            .get(`onlineOrders/${deliveryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                // alert('Success')
                if (res.data) {
                    res.data.Payload.sort((a, b) =>
                        new Date(b.OrderDate) - new Date(a.OrderDate)

                    );
                    dispatch({
                        type: "GET_GROUP_ONLINE_ORDERS",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                // alert('error')
                console.log(e);
            });
    };
};

export const getOnlineOrderByGroupDeliveryIdAndOrderNumber = (deliveryId, regId) => {
    console.log('deliveryId', deliveryId)
    console.log('regId', regId)

    return (dispatch) => {
        return Api
            .get(`onlineOrders/${deliveryId}/${regId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                // alert('Success')
                // console.log('res', res.data.Payload)
                if (res.data) {
                    dispatch({
                        type: "GET_ONLINE_ORDER_BY_GROUP_DELIVERY_ID_AND_ORDER_NUMBER",
                        payload: [res.data.Payload],
                    });
                }
            })
            .catch((e) => {
                // alert('error')
                console.log(e);
            });
    };
};

export const updateOnlineOrderByDeliveryIdAndOrderNumber = (deliveryId, orderNumber, objectPost) => {
    // console.log('data:', deliveryId, orderNumber, objectPost)
    return (dispatch) => {
        return Api
            .post(`onlineOrders/update/${deliveryId}/${orderNumber}`, objectPost, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                // console.log("res from server", res.request.status);

                if (res.request.status == 200) {
                    // alert("ההזמנה עודכנה");
                    dispatch({
                        type: "UPDATE_GROUP_ONLINE_ORDER_BY_GROUP_DELIVERY_ID_AND_ORDER_NUMBER",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert("אירעה שגיאה");
            });
    };
};

export const updateGroupOrderPaidStatus = (objectPost) => {
    // console.log('data:', deliveryId, orderNumber)
    return (dispatch) => {
        return Api
            .post(`groupOrders/update/paid`, objectPost, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                console.log("res from server", res.request.status);



                if (res.request.status == 200) {
                    // alert("סטאטוס התשלום עודכן");


                    dispatch({
                        type: "UPDATE_GROUP_ORDER_PAID_STATUS",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert("failed");
            });
    };
};




export const changeGroupDeliveryStatus = (objectPost) => {
    // console.log('id, ', id)
    // console.log('data:', deliveryId, orderNumber)
    return (dispatch) => {
        return Api
            .post(`/groupDeliveries/update-status`, objectPost, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                console.log("res from server", res.request.status);

                if (res.request.status == 200) {
                    // alert("סטאטוס עודכן");
                    dispatch({
                        type: "CHANGE_GROUP_DELIVERY_STATUS",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert("failed");
            });
    };
};

// export const addGroupOrder = (objectPost) => {
//   // alert('in')
//   return (dispatch) => {
//     return axios
//       .post(`groupOrders/add/`, objectPost, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((res) => {
//         console.log("res from server", res);

//         if (res.request.status == 200) {
//           alert("ההזמנה נשלחה");
//           dispatch({
//             type: "ADD_GROUP_ORDER",
//             payload: res.data.Payload,
//           });
//         }
//       })
//       .catch((e) => {
//         console.log(e);
//         alert("failed");
//       });
//   };
// };

export const deleteOnlinerderByOrderId = (orderId) => {
    // console.log("orderId", orderId);

    return (dispatch) => {
        return Api
            .delete(`onlineOrders/${orderId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                // console.log("res", res);
                if (res.request.status == 200) {
                    //alert("ההזמנה נמחקה");
                    dispatch({
                        type: "DELETE_ONLINE_ORDER_BY_ID",
                        payload: res.data.Payload,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert("failed");
            });
    };
};
export const sendEmailToMuiltipleUsers = (objectPost) => {
    console.log('data:', objectPost)
    return (dispatch) => {
        return Api
            .post(`groupOrders/sendMultipleEmails`, objectPost, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                console.log("res from server", res.request.status);
                if (res.request.status == 200) {
                    // alert("הרווח בפועל עודכן");
                    dispatch({
                        type: "SEND_GROUP_ORDER_EMAILS_OF_CHOSEN_ORDERS",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert("failed");
            });
    };
};