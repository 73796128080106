import React, { useEffect, useState } from 'react'
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { delete_cart } from '../../redux/cart/cart-actions';
import { getOnlineOrderByGroupDeliveryIdAndOrderNumber } from '../../redux/onlineOrders/online-orders-action';

const CartStep4 = (props) => {
    const [load, isLoad] = useState(true)

    useEffect(() => {
        window.scroll(0, 0)
        console.log('orderNumber deliveryId', props.deliveryId)
        console.log(' props.orderNumber', props.orderNumber)


        props.getOnlineOrderByGroupDeliveryIdAndOrderNumber(props.deliveryId, props.orderNumber).then(() => {
            props.delete_cart()
            isLoad(false)
        })

    }, [])
    return (
        <>
            <h3 className='text-center'>תודה על ההזמנה ממשק אלמוג!</h3>
            {
                props.onlineOrders.length > 0 ?
                    <div className='col-lg-8 col-sm-12' style={{ margin: 'auto' }}>
                        {console.log('onlineOrders', props.onlineOrders)}

                        <p>
                            שלום {props.onlineOrders[0].PersonInfo.FirstName},
                            <br />
                            מספר הזמנתך הוא: {props.orderNumber}
                            <br />
                            מועד החלוקה:&nbsp;
                            {moment(props.onlineOrders[0].DeliveryDate).format("DD/MM/YYYY")},&nbsp;
                            בין השעות:&nbsp;
                            {props.onlineOrders[0].DeliveryTime}
                        </p>
                        <p>בדקות הקרובות ישלח אליך מייל עם הפרטים המלאים</p>
                        <a href={`/online/orders/userUpdate/999/${props.deliveryId}/${props.orderNumber}`} >מעבר לעריכת ההזמנה</a>
                        <p>תודה,
                            <br />
                            משק אלמוג
                        </p>

                    </div>
                    : ''
            }

        </>
    )
}
const mapStateToProps = (state) => {
    return {

        onlineOrders: state.onlineOrdersReducer.onlineOrders,
        cart: state.cartReducer.cart
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOnlineOrderByGroupDeliveryIdAndOrderNumber,
            delete_cart
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CartStep4);

