
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GetLocalDayAndHour from '../../../components/GetLocalDayAndHour'
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupDeleveriesByCode } from "../../../redux/deliveries/deliveries-actions";
import { getGroupByCode } from "../../../redux/groups/groups-actions";
import SumChart from "./Charts/SumChart";
import moment from "moment";
import { Input } from "reactstrap";
import { Label } from "semantic-ui-react";

const GroupDeliveriesStatitstics = (props) => {

    // let location = useLocation(); //load groupName from previus page
    const [displayFroupName, SetDisplayFroupName] = useState("none")
    const [dataForStatistics, setDataForStatistics] = useState([])
    const [startDate, setstartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    let { groupCode } = useParams();
    let counter = 0
    const history = useHistory()

    useEffect(() => {

        props.getGroupByCode(groupCode)
        props.getGroupDeleveriesByCode(groupCode)

        setTimeout(() => {
            SetDisplayFroupName("block")
        }, 100);
        clearTimeout()

        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setstartDate(defaultValue)

        const monthAgoDate = new Date(today.setMonth(today.getMonth() - 6)).toISOString().split('T')[0];

        setEndDate(monthAgoDate)
    }, [groupCode]);


    //initial chart data
    useEffect(() => {
        if (props.deliveries.length > 0 && startDate != "" && endDate != "") {
            const data = props.deliveries
                .sort((a, b) =>
                    new Date(b.DeliveryDateTime) - new Date(a.DeliveryDateTime))
                // .filter((d, i) => i < 6)
                .filter(d => new Date(d.DeliveryDateTime).getTime() >= new Date(endDate).getTime() &&
                    new Date(d.DeliveryDateTime).getTime() <= new Date(startDate).getTime())
                .map((d, i) => ({
                    // x: new Date('01/01/2018'),
                    x: new Date(d.DeliveryDateTime).toLocaleDateString('he-IL', { hour12: false }).toString(),
                    y: d.Revenue
                }))

            setDataForStatistics(data)
        }
    }, [props.deliveries, startDate, endDate])

    const rowClicked = (link) => {
        history.push(link)
    }
    const loadDeliveriesOfGroup = () => props.deliveries
        .sort((a, b) =>
            new Date(b.DeliveryDateTime) - new Date(a.DeliveryDateTime))
        .map(delivery => {
            if (counter != new Date(delivery.DeliveryDateTime).getMonth())
                counter++
            // console.log('DeliveryDateTime', new Date(delivery.DeliveryDateTime).getMonth())
            let groupDate = new Date(delivery.DeliveryDateTime).toLocaleString('en-US', { hour12: false })
            let activeForm = 'לא פעיל'
            let month = new Date(delivery.DeliveryDateTime).getMonth() + 1
            if (delivery.IsActive) {
                activeForm = 'פעיל'
            }
            return (
                <>
                    <tr key={delivery._id} onClick={() => rowClicked(`/admin/deliveries/groups-orders/${groupCode}/${delivery._id}`)}>

                        <td>
                            <GetLocalDayAndHour
                                style={{ display: 'inline' }}
                                dateOfDelivery={groupDate}
                            />
                        </td>
                        <td>
                            {
                                delivery.Places != null &&
                                delivery.Places.map((place, index) => {
                                    return <span key={index}>
                                        {
                                            (delivery.Places.length !== index && index !== 0) &&
                                            ' | '
                                        }
                                        <GetLocalDayAndHour
                                            hourOfDelivery={place.Hour}
                                        />
                                    </span>
                                })
                            }
                        </td>
                        <td>
                            {activeForm}
                        </td>
                        <td>{delivery.Revenue} ₪</td>

                    </tr>
                </>
            );
        })

    const loadGroupName = () => props.groups.map(group => {

        return <h2 key={group.Code} style={{ display: displayFroupName }} className='text-center font-weight-bold'>סטטיסטיקה של {group.Name} </h2>
    })
    return (

        <div className='container-fluid firstDivAfterNav'>
            <MDBContainer className="card">
                {/* <MDBRow>
                    <MDBCol md="12">
                        <MDBCard>
                            <MDBCardBody> */}
                {loadGroupName()}
                <div className="d-flex align-items-center justify-content-start">
                    <div>
                        <Label>מתאריך</Label>
                        <Input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                    </div>
                    <div>
                        <Label>עד תאריך</Label>
                        <Input type="date" value={startDate} onChange={e => setstartDate(e.target.value)} />
                    </div>
                </div>

                {
                    dataForStatistics.length > 0 ?
                        <SumChart
                            data={
                                dataForStatistics
                            }
                        />

                        //     <>
                        //         {loadGroupName()}
                        //         <table id="dataTable" className="table table-striped col-sm-12 text-center" border='1' style={{ margin: 'auto', tableLayout: 'fixed', textAlign: 'center' }}>
                        //             <thead className="thead-dark">
                        //                 <tr className="header">
                        //                     {/* <th></th> */}
                        //                     <th>תאריך</th>
                        //                     <th>שעה</th>
                        //                     <th>סטאטוס</th>
                        //                     <th>סה"כ הכנסות</th>
                        //                 </tr>
                        //             </thead>
                        //             <tbody>
                        //                 {loadDeliveriesOfGroup()}
                        //             </tbody>
                        //         </table>
                        //     </>

                        :
                        <div style={{ padding: '150px' }}>
                            <h2 className='text-center font-weight-bold'>לא קיימים נתונים </h2>
                        </div>
                }
                {/* </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow> */}
            </MDBContainer>
        </div >
    )

}
const mapStateToProps = (state) => {
    return {
        deliveries: state.deliveriesReducer.deliveries,
        groups: state.groupReducer.groups
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroupDeleveriesByCode,
            getGroupByCode
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupDeliveriesStatitstics);