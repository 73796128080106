import React, { useState, useEffect } from 'react'
import { MDBBtn, MDBCard, MDBRow, MDBCol, MDBContainer, MDBInput } from 'mdbreact';
import { useParams } from "react-router-dom";
import MyModal from '../../../components/MyModal'
import MyModal2 from '../../../components/MyModal'
import { useHistory } from 'react-router-dom';
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupByCode, updateGroupByCode, deleteGroupByCode } from "../../../redux/groups/groups-actions";

const UpdateGroup = (props) => {
    let { groupCode } = useParams();
    const [modalShow, setModalShow] = useState(false)
    const [modalShowDelete, setModalShowDelete] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const history = useHistory()

    useEffect(() => {

        // console.log('groupCode', groupCode)
        props.getGroupByCode(groupCode).then(() => {
            window.scrollTo(0, 0)
        })

    }, []);

    const postGroup = () => {
        let name = document.getElementById('groupName').value
        let internalName = document.getElementById('internalName').value
        let code = document.getElementById('groupCode').value
        let whatssApp = document.getElementById('groupWA').value
        let isActive;
        if (document.getElementById('isNotActive') != null)
            isActive = !document.getElementById('isNotActive').checked
        else
            isActive = document.getElementById('isActive').checked
        const objectPost = {
            "Name": name,
            "InternalName": internalName,
            "Code": code,
            "WhatsappGroupLink": whatssApp,
            "IsActive": isActive
        }

        // console.log('objectPost', objectPost)
        props.updateGroupByCode(groupCode, objectPost).then(() => {
            history.push('/admin/groups')
        })
    }

    const deleteGroup = () => {
        props.deleteGroupByCode(groupCode).then(() => {
            history.push('/admin/groups')
        })
    }
    const setModalShowToShow = (e) => {
        e.preventDefault()
        setModalShow(true)
    }

    return (

        <div className='container-fluid firstDivAfterNav'>
            <MDBContainer id='formContainer'>
                <MDBRow>
                    <MDBCol md="12" style={{ margin: 'auto' }}>
                        <MDBCard>
                            <form className='col-sm-8'>
                                <h2 className='text-center font-weight-bold'>עריכת קבוצה </h2>

                                <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>
                                <div className="black-text">

                                    {props.groups.map(group => {
                                        return (
                                            <form key={group._id}>
                                                <MDBInput label="*קוד הקבוצה" valueDefault={group.Code}
                                                    id='groupCode'
                                                    // onChange={e => setMyGroupCode(e.target.value)}
                                                    required icon="password" group type="text" disabled
                                                    error="wrong"
                                                    success="right" />

                                                <MDBInput label="*שם ציבורי" valueDefault={group.Name}
                                                    id='groupName'
                                                    // onChange={e => setGroupName(e.target.value)}
                                                    required icon="user" group type="text" validate
                                                    error="wrong"
                                                    success="right" />

                                                <MDBInput label="* שם פנימי (לדוחות)" valueDefault={group.InternalName}
                                                    id='internalName'
                                                    // onChange={e => setGroupInternalName(e.target.value)}
                                                    required icon="user" group type="text" validate
                                                    error="wrong"
                                                    success="right" />

                                                <MDBInput label="לינק לווטסאפ" valueDefault={group.WhatsappGroupLink}
                                                    id='groupWA'
                                                    // onChange={e => setGroupWhatssApp(e.target.value)}
                                                    icon="phone" group type="text" validate
                                                    error="wrong"
                                                    success="right" />

                                                {group.IsActive ?
                                                    <div><label id='stockText' className='myCheckbox'>
                                                        <input
                                                            id='isNotActive'
                                                            type="checkbox"
                                                            value={group.IsActive}
                                                        // onChange={() => setGroupInStock(false)}
                                                        /> שינוי ללא פעילה </label>
                                                    </div>
                                                    :
                                                    <div><label id='stockText' className='myCheckbox'>
                                                        <input
                                                            id='isActive'
                                                            type="checkbox"
                                                            value={group.IsActive}
                                                        // onChange={() => setGroupInStock(true)}
                                                        /> שינוי לפעילה </label>
                                                    </div>
                                                }

                                            </form>
                                        )
                                    })
                                    }

                                    <p className='text-center'>{errorMessage}</p>
                                    <div className=' col-sm-12 text-center'>
                                        <MDBBtn
                                            rounded outline color="secondary"
                                            variant="primary" type="submit" value="Send" validate
                                            onClick={(e) => document.getElementById('groupName').value != ''
                                                && document.getElementById('internalName').value ?
                                                setModalShowToShow(e) : setErrorMessage('מלא את כל שדות החובה')}
                                        > עדכון קבוצה</MDBBtn>

                                        <MDBBtn unded outline color="secondary"
                                            variant="primary" type="" value="Send" validate
                                            onClick={() => setModalShowDelete(true)}
                                        > מחיקת קבוצה</MDBBtn>

                                    </div>

                                    <MyModal />
                                    <MyModal
                                        headlineText='האם ברצונך לעדכן את הקבוצה'
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        ButtonText='ביטול'
                                        ButtonDeleteText='אישור'
                                        ButtonDinamicFunction={() => postGroup()}
                                    />

                                    <MyModal2 />
                                    <MyModal2
                                        headlineText='האם ברצונך למחוק את הקבוצה?'
                                        headlineBody='לתשומך ליבך - לא ניתן יהיה לשחזר לאחר המחיקה'
                                        show={modalShowDelete}
                                        onHide={() => setModalShowDelete(false)}
                                        ButtonText='ביטול'
                                        ButtonDeleteText='אישור'
                                        ButtonDinamicFunction={() => deleteGroup()}
                                    />

                                </div>
                            </form>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer >
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroupByCode,
            updateGroupByCode,
            deleteGroupByCode
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateGroup)