
let cartFromStorage = JSON.parse(localStorage.getItem('cart'))

const initialState = {
    cart: cartFromStorage != null ? cartFromStorage : []
};

export default function (state = initialState, action) {
    // console.log('cart is', state)
    switch (action.type) {
        case "GET_CART":
            return { ...state };
        case "GET_CART_BY_ID":
            return {
                // Again copy the entire state object
                ...state,
                // This time, we need to make a copy of the old todos array
                cart: state.cart.map(todo => {
                    // If this isn't the todo item we're looking for, leave it alone
                    if (todo.Code !== action.payload) {
                        return todo
                    }

                    // We've found the todo that has to change. Return a copy:
                    return {
                        ...todo,
                    }
                })
            };

        case "ADD_TO_CART":
            return {
                ...state,
                cart: state.cart.concat(action.payload)
            };
        case "UPDATE_CART_BY_ID":
            let myArray = [...state.cart]
            //Find index of specific object using findIndex method.    
            let objIndex = state.cart.findIndex((obj => obj.Code == action.payload.Code));
            myArray[objIndex] = action.payload;
            return {
                ...state,
                cart: myArray
                // cart: state.cart.map(item => item.id == action.payload.id ? item = action.payload : item)
            };

        case "DELETE_CART_BY_ID":
            return { ...state, cart: state.cart.filter(c => c.Code != action.payload) };
        case "DELETE_CART":
            return { ...state, cart: [] };
        default:
            return state;
    }
}
