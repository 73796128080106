import React, { useState } from 'react'
import { MDBBtn, MDBRow, MDBCol, MDBContainer, MDBInput } from 'mdbreact';

import MyModal from '../../../components/MyModal'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { registerUser } from '../../../redux/users/useres-actions'

const CreateDelivery = (props) => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [modalShow, setModalShow] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [role, setRole] = useState('')
    const [password, setPassword] = useState('')
    const [passwordShown, setPasswordShown] = useState(false);
    const [display, setDisplay] = useState('none')

    const poastUser = () => {

        // console.log('groupCode', groupCode)
        const objectPost = {
            "FirstName": firstName,
            "LastName": lastName,
            "Email": email,
            "Password": password,
            "Role": role
        }

        // console.log('objectPost', objectPost)

        props.registerUser(objectPost).then(() => {
            window.location.reload()
        })
    }




    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };


    const setPsswordAndIcon = (e) => {
        setPassword(e.target.value)
        setDisplay('block')
    }


    return (
        <div className=' container-fluid'>
            <MDBContainer id='formContainer' className='firstDivAfterNav'>
                <MDBRow>
                    <MDBCol md="12" style={{ margin: 'auto' }}>
                        <form style={{ background: 'white' }}>

                            <h2 className='text-center'>הוספת משתמש חדש </h2>



                            <div className="black-text">

                                <div className='col-sm-8' style={{ margin: 'auto' }}>
                                    <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>
                                    <select className="browser-default custom-select" onChange={e => setRole(e.target.value)}>
                                        <option value='0'>* סוג הרשאה</option>
                                        <option value={'User'}>משתמש</option>
                                        <option value={'Admin'}>מנהל</option>
                                    </select>


                                    <MDBInput id='firstName' label="* שם פרטי" value={firstName} onChange={e => setFirstName(e.target.value)}
                                        required icon="user" group type="text" validate
                                        error="wrong" name="user_firstname"
                                        success="right" />


                                    <MDBInput id='lastName' label="* שם משפחה" value={lastName} onChange={e => setLastName(e.target.value)}
                                        required icon="user" group type="text" validate
                                        error="wrong" name="user_lastname"
                                        success="right" />

                                    <MDBInput id='email' label="* דואר אלקטרוני"
                                        className='loginFields'
                                        value={email} onChange={e => setEmail(e.target.value)}
                                        required icon="envelope" group type="email"
                                        error="wrong" name="user_email"
                                        success="right" />

                                    <MDBInput id='password' label="* סיסמה"
                                        className='loginFields'
                                        value={password} onChange={e => setPsswordAndIcon(e)}
                                        required icon="lock" group type="password" validate
                                        error="wrong" name="user_password"
                                        success="right"
                                        type={passwordShown ? "text" : "password"}
                                    >
                                        {
                                            password ?
                                                <i style={{ display: display }}
                                                    className="fas fa-eye passwordIcon"
                                                    onClick={togglePasswordVisiblity}>
                                                </i>
                                                : ''
                                        }


                                    </MDBInput>


                                </div>
                                <div className=' col-sm-12 text-center'>
                                    <MDBBtn rounded outline color="secondary"
                                        variant="primary" type="" value="Send"
                                        className='' validate
                                        style={{ margin: 'auto' }}

                                        onClick={() =>
                                            firstName != '' && lastName != '' && email != '' && role != '' && password != ''
                                                ? setModalShow(true)
                                                : setErrorMessage('מלאי/י את כל שדות החובה')}
                                    > הוספת משתמש
                                                    </MDBBtn>
                                </div>

                                <br />
                                <p className='text-center'>{errorMessage}</p>
                                <br />

                                <MyModal />

                                <MyModal
                                    headlineText='האם ברצונך להוסיף את המשתמש?'
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    ButtonText='ביטול'
                                    ButtonDeleteText='אישור'
                                    ButtonDinamicFunction={() => poastUser()}
                                />

                            </div>

                        </form>
                    </MDBCol>
                </MDBRow>
            </MDBContainer >
        </div>
    )
}
const mapStateToProps = (state) => {
    return {

        users: state.usersReducer.users
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            registerUser
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDelivery);
