import React, { useEffect, useRef } from 'react'
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer, MDBCardImage, MDBAnimation } from "mdbreact";
import { useParams } from "react-router-dom";
import GetLocalDayAndHour from '../../../components/GetLocalDayAndHour'
import Tooltip from '../../../components/Tooltip/Tooltip'
import RoundedButton from '../../../components/RoundedButton/RoundedButton'
import { useHistory } from 'react-router-dom';
import { WhatsappIcon } from "react-share";
import disableBrowserBackButton from 'disable-browser-back-navigation';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetGroupDeliveryById, } from "../../../redux/deliveries/deliveries-actions";
import { getGroupByCode } from "../../../redux/groups/groups-actions";
import { getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber } from "../../../redux/groupOrders/group-orders-action"
import { getProducts } from '../../../redux/products/products-actions';
import GedDayOfWeek from '../../../components/GedDayOfWeek';

const FormLastPage = (props) => {

    let { deliveryId, groupCode, regId } = useParams();
    const componentRef = useRef();
    const history = useHistory()

    useEffect(() => {

        window.scrollTo(0, 0);
        document.getElementById('WelcomeImg').style.display = 'none';

        //unshow footer
        for (let element of document.getElementsByClassName("footer")) {
            element.style.display = "none";
        }
        // show footer after form rendered
        setTimeout(
            function () {
                for (let element of document.getElementsByClassName("footer")) {
                    element.style.display = "block";
                }
            }, 1000);
        clearTimeout()

        disableBrowserBackButton();

        props.getGroupByCode(parseInt(groupCode))
        props.GetGroupDeliveryById(deliveryId)
        props.getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber(deliveryId, regId)
        props.getProducts()

        document.getElementById('myNav').style.display = 'none'


    }, [deliveryId, groupCode, regId])

    const loadgroup = () => props.groups.map(group => {
        document.title = `משק אלמוג - מהחקלאי ל${group.Name} - סיכום הזמנה`

        return group.Name
    })

    const rowClicked = (link, openInNew) => {
        if (openInNew)
            window.open(link)
        else
            history.push(link)
    }

    const loadOrder = () => props.orders.map((order, index2) => {
        return (
            <MDBCardBody key={index2}>

                <div className='col-sm-12 roudedButtonsAboveTable text-center' style={{ margin: 'auto' }}>

                    {
                        props.deliveries.map((delivery, index) => {
                            return (
                                <>
                                    {
                                        delivery.Places.map(place => {

                                            let oldDateObj = new Date(place.Hour);
                                            let newDateObj = new Date();
                                            newDateObj.setTime(oldDateObj.getTime() + (place.Duration * 60 * 1000));

                                            return place.Address === order.Place &&
                                                <div>
                                                    <h4 colSpan='6'> {order.Place} בשעות&nbsp;
                                                        <GetLocalDayAndHour
                                                            style={{ display: 'inline' }}
                                                            hourOfDelivery={place.Hour}
                                                        />-
                                                        <GetLocalDayAndHour
                                                            style={{ display: 'inline' }}
                                                            hourOfDelivery={newDateObj}
                                                        />,

                                                        <br />
                                                        <>
                                                            יום <GedDayOfWeek dayOfWeek={delivery.DeliveryDateTime} />,&nbsp;
                                                            <GetLocalDayAndHour
                                                                style={{ display: 'inline' }}
                                                                dateOfDelivery={delivery.DeliveryDateTime}
                                                            />
                                                        </>

                                                    </h4>
                                                </div>
                                        })
                                    }

                                    {delivery.PayboxGroupLink != '' && delivery.PayboxGroupLink != null ?
                                        <>
                                            <br />
                                            <div className='flex'>
                                                <a className='col-sm-2 iconsInLastPageOfForm' href={delivery.PayboxGroupLink}
                                                    target='_blank' rel="noopener noreferrer">
                                                    <img src='../../../../../img/Paybox.png' style={{ width: '50px' }} alt="payBox"></img>
                                                    <p>לתשלום ב PayBox</p>
                                                </a>
                                            </div>
                                        </>
                                        : ''
                                    }
                                </>
                            )
                        })
                    }

                    <div className={props.groups.map(group => group.WhatsappGroupLink != '' && group.WhatsappGroupLink != null ? "flex-row" : "flex")}>

                        <div className='flex'>
                            <RoundedButton
                                icon="fas  fa-pencil-alt"
                                onClick={() => rowClicked(`/groups/update/${groupCode}/${deliveryId}/${regId}`)}
                            />
                            <a href={`/groups/update/${groupCode}/${deliveryId}/${regId}`}>
                                <p>עריכת ההזמנה</p>
                            </a>
                        </div>

                        {
                            props.groups.map(group => {
                                return group.WhatsappGroupLink != '' && group.WhatsappGroupLink != null ?
                                    <div className='flex'>
                                        <a href={group.WhatsappGroupLink} target="_blank">
                                            <WhatsappIcon className='reactShareIcons' size={32} round={true} />
                                            <p>קבוצת ווטסאפ</p>
                                        </a>
                                    </div>
                                    : ''
                            })
                        }
                    </div>

                </div>

                <h5 className='text-center'><u>פרטי ההזמנה:</u></h5>

                <br />
                <table id='dataTableForForm' className='col-sm-12 reservationTable' ref={componentRef} style={{ margin: 0 }}>
                    <tr>
                        <td colSpan='6'>
                            <span> הזמנה מספר {order.OrderNumber}</span>
                        </td>
                    </tr>
                    <tr className='firstTd'>
                        <td colSpan='6'>
                            <span id='dateAandHour'>
                                <GetLocalDayAndHour
                                    style={{ display: 'inline' }}
                                    hourOfDelivery={order.OrderDate}
                                /> |    <GetLocalDayAndHour
                                    style={{ display: 'inline' }}
                                    dateOfDelivery={order.OrderDate}
                                />
                            </span>
                        </td>
                    </tr>
                    <tr className='notHasHoverAttributes'>
                        <td colSpan='3'><span>{order.PersonInfo.FirstName} {order.PersonInfo.LastName}</span></td>
                        <td colSpan='3'><span>{order.PersonInfo.Email}</span></td>
                    </tr>
                    <tr className='firstTd'>
                        <td colSpan='3'><span>{order.PersonInfo.Phone}</span> </td>
                        <td colSpan='3'><span>תשלום ב{order.PaymentMethod}</span> </td>
                    </tr>
                    {

                        order.Comment.length > 0 ?
                            <tr className='firstTd'>
                                <td colSpan='6'>   {order.Comment}</td>
                            </tr>
                            : ''
                    }

                    <tr className='firstTd'>
                        <td colSpan='6'>&nbsp;</td>
                    </tr>


                    <tr>
                        {/* <th></th> */}
                        <th className='myCell'>מוצר</th>
                        <th className='myCell'>כמות</th>
                        <th className='myCell'>פירוט</th>
                        <th className='myCell'>מחיר ליחידה</th>
                        <th className='myCell'>סה"כ</th>
                    </tr>

                    {
                        props.products.map(product => {

                            for (let index = 0; index < order.Products.length; index++) {

                                if (product.Code === order.Products[index].Code) {

                                    let qnt = order.Products[index].Amount
                                    let price = qnt * product.Price

                                    return (



                                        <tr key={product.Code}>
                                            <td className='myCell'>
                                                <div className='flex'>
                                                    <img src={`../../../../${product.ReservationImage}`} width="40px" />
                                                    <p>{product.ShortName}</p>
                                                </div>

                                            </td>
                                            <td className='myCell'> {qnt} </td>
                                            <td className='myCell'> {product.DescriptionForRes}</td>
                                            <td className='myCell'>
                                                {
                                                    product.Code === 10 ?
                                                        <> {product.Price} ₪   (ל - 2 יחידות)</>
                                                        : <> {product.Price} ₪ </>
                                                }

                                            </td>
                                            <td className='myCell'>
                                                {
                                                    product.Code === 10 ?
                                                        price / 2
                                                        : price
                                                } ₪</td>
                                        </tr>
                                    )
                                }


                            }
                        })
                    }
                    <tr className='firstTd'>
                        <td colSpan='6'> <span>סה"כ סכום ההזמנה: <b>{order.TotalPrice} ₪</b></span></td>
                    </tr>
                </table>
                <a href='/' target='_blank'>
                    <MDBCardImage
                        style={{ width: '30%', margin: 'auto' }}
                        hover
                        overlay='white-light'
                        className='card-img-top'
                        src={require('../../../components//img/almog.PNG')}
                        alt='man'
                    />
                </a>

            </MDBCardBody >
        )
    })

    return (
        <div className='container-fluid animated fadeIn' id="myContainerFluid">
            {
                props.orders && props.orders.length > 0 &&
                <MDBAnimation type="fadeIn" delay="0.5s">
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol md='10' style={{ margin: 'auto' }}>
                                <MDBCard>
                                    <h2 className="text-center font-weight-bold">
                                        {props.orders[0].PersonInfo.FirstName},
                                        <br />

                                        תודה על הרשמתך לחלוקה ב{loadgroup()}
                                    </h2>
                                    {loadOrder()}
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <br />
                    </MDBContainer>
                </MDBAnimation>
            }

        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
        deliveries: state.deliveriesReducer.deliveries,
        products: state.productReducer.products,
        orders: state.groupOrdersReducer.orders,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGroupByCode,
            GetGroupDeliveryById,
            getProducts,
            getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(FormLastPage);