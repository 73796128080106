import React from "react";
import './spinner.css'
const Spinner = ({ display }) => {
    // useEffect(() => {
    //     document.querySelector('.footer').style.display = 'none'
    // }, [])
    return (
        <div className='spinnerContainer'
            style={{ display }}
        >
            <div className='spinnerContent'>
                <div className="spinner-border text-dark marginAuto text-center" role="status" >
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    );
}

export default Spinner;