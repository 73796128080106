import React, { useEffect, useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { MDBInput } from 'mdbreact'
import './places.css'

const Places = (props) => {
    // const [addrress, setAddress] = useState('')

    useEffect(() => {

        console.log('props.addrress', props.addrress)
        // if (props.addrress != '')
        //     props.setAddress(props.addrress)
    }, [props.addrres])

    const handleChange = value => {
        props.setAddress(value)
    }
    const handleSelect = value => {
        props.setAddress(value)
    }

    return (
        <div>
            <PlacesAutocomplete
                value={props.addrress}
                onChange={handleChange}
                onSelect={handleSelect}
            >{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return <div>
                    {/* <input
                        {...getInputProps({
                            placeholder: 'כתובת',
                        })}
                        className='form-control'
                    /> */}
                    <MDBInput label="* כתובת"
                        {...getInputProps({
                            placeholder: 'כתובת',
                        })}
                        required icon="home" group type="text"
                        error="wrong" name="user_name"
                        success="right" />

                    <div className='placesDiv'>
                        {loading && <div>טוען...</div>}
                        {suggestions.map(suggestion => {
                            const style = suggestion.active ?
                                {
                                    background: 'black',
                                    color: 'white',
                                    cursor: 'pointer'
                                }
                                : { background: 'white' }
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, { style })}>
                                    {suggestion.description}
                                </div>
                            )



                        })}
                    </div>
                </div>
            }}

            </PlacesAutocomplete>
        </div>
    )
}
export default Places